<template>

	<div class="home" v-if="ready">

		<nav-bar-home />
		<!-- <m-menu></m-menu> -->
		<!--Main index : Begin-->
		<main class="main index">

			<!--Home slider : Begin-->
			<section class="home-slidershow">
				<div class="slim-banner" v-if="homePageSlimBoxData[0]"
					:style="{ backgroundColor: homePageSlimBoxData[1] }">
					<span v-html="homePageSlimBoxData[0]"></span>
				</div>
				<carousel ref="carousel" :autoplayTimeout="5000" :per-page="1" :mouse-drag="true" :autoplay="true"
					:loop="false" :autoplayHoverPause="true" :paginationEnabled="false" :speed="500" :centerMode="true">
					<slide v-if="homeSlider.length > 0" v-for="slide in homeSlider" :key="slide.id">
						<div class="visible-md visible-lg">
							<div :style="{ backgroundColor: slide.sliderbgColor }" class="ttttt" style="">
								<div class="slide-cont " v-if="slide.sliderType != 'service'">
									<div :class="slide.sliderimgDirec">
										<img height="400" :src="slide.image" alt="" class="animate__animated"
											:class="[items[Math.floor(Math.random() * items.length)]]" />
									</div>
									<div class="animate__animated contentPositions"
										:style="{ fontFamily: slide.textfontfamily }"
										:class="[items[Math.floor(Math.random() * items.length)], slide.txDirec]">
										<p class="slider-title-left"
											:style="{ color: slide.txColor, fontSize: slide.headingTextSize, fontWeight: slide.headingBold ? 'bold' : 'normal' }">
											{{
												slide.title }}</p>
										<p class="slider-sub-left" v-html="slide.desc"
											:style="{ color: slide.txColor, fontSize: slide.subHeadingTextSize, fontWeight: slide.subheadingBold ? 'bold' : 'normal' }">
										</p>
										<a class="btn btn-danger" v-if="slide.product_id"
											:href="'category/' + slide.product_id">Shop Now</a>

									</div>
								</div>
								<div class="slide-cont" v-else>
									<div :class="slide.sliderimgDirec">
										<img height="400" :src="slide.image" alt="" class="animate__animated"
											:class="[items[Math.floor(Math.random() * items.length)]]" />
									</div>
									<div class="animate__animated contentPositions"
										:style="{ fontFamily: slide.textfontfamily }"
										:class="[items[Math.floor(Math.random() * items.length)], slide.txDirec]">
										<p class="slider-title-left"
											:style="{ color: slide.txColor, fontSize: slide.headingTextSize, fontWeight: slide.headingBold ? 'bold' : 'normal' }">
											{{
												slide.title }}</p>
										<p class="slider-sub-left" v-html="slide.desc"
											:style="{ color: slide.txColor, fontSize: slide.subHeadingTextSize, fontWeight: slide.subheadingBold ? 'bold' : 'normal' }">
										</p>
										<!-- <a class="btn btn-danger" href="#">VIEW MORE</a>				 -->
									</div>
								</div>
							</div>
						</div>
						<div class="visible-xs visible-sm hidden-md">
							<div :style="{ 'color': 'red', 'background-image': 'url(' + $store.state.URL + '/uploads/slider/bg-' + slide.image + ')' }"
								class="tttttSmall" style="">
								<div class="slide-cont">
									<img style="width:300px;display: block; margin-left: auto; margin-right: auto;"
										:src="$store.state.URL + '/uploads/slider/' + slide.image" alt=""
										class="animate__animated"
										:class="[items[Math.floor(Math.random() * items.length)]]" />
									<div class="animate__animated contentPositions pos-topLeft smallSlider"
										:class="[items[Math.floor(Math.random() * items.length)]]">
										<h2 class="slider-title-right">{{ slide.title }}</h2>
										<h4 class="slider-sub-right" v-html="slide.desc"></h4>
										<a class="btn btn-danger" href="#">VIEW MORE</a>

									</div>
								</div>

							</div>
						</div>
					</slide>
				</carousel>
				<div class="navigation-buttons">
					<button class="nav-btn prev" @click="prevSlide"> &lt; </button>
					<button class="nav-btn next" @click="nextSlide"> &gt; </button>
				</div>
			</section>
			<!--Home Category : Begin-->
			<section>
				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<a class="promostionLink"
								:href="(sliderType == 'category') ? 'category/' + slug : 'javascript:void()'">
								<div class="brochure" v-if="previewImage"
									:style="{ backgroundColor: bgColor, fontFamily: textfontfamily }">
									<img class="brochImg mt-1" v-if="previewImage" :src="previewImage"
										alt="Slider Image" />
									<div class="offLabel" v-if="subHeading"
										:style="{ fontSize: subHeadingTextSize, background: subheadingbgColor, color: subheadingColor, fontWeight: subheadingBold ? 'bold' : 'normal' }"
										v-html="subHeading">
									</div>
									<div class="brochTitle"
										:style="{ fontSize: headingTextSize, background: rgbaColor, color: headingColor, fontWeight: headingBold ? 'bold' : 'normal' }"
										v-html="heading">
									</div>
								</div>
							</a>
						</div>
						<div class="col-md-4">
							<div class="row">
								<div class="col-md-12">
									<a class="promostionLink"
										:href="(TCsliderType == 'category') ? 'category/' + TCslug : 'javascript:void()'">
										<div :class="['postCardWrap', TCheadingPosition]" v-if="TCpreviewImage"
											:style="{ backgroundColor: TCbgcolor, fontFamily: TCtextfontfamily }">
											<div class="postCont">
												<div class="cardTitle"
													:style="{ fontSize: TCmainheadingsize, color: TCmainheadingcolor }"
													v-html="TCmainheading">

												</div>
												<p :style="{ fontSize: TCheadingtextsize, color: TCheadingcolor, fontWeight: TCheadingBold ? 'bold' : 'normal' }"
													v-html="TCheading">
												</p>
												<p class="cardTitlesm"
													:style="{ fontSize: TCsubheadingtextsize, color: TCsubheadingcolor, fontWeight: TCsubheadingBold ? 'bold' : 'normal' }"
													v-html="TCsubheading">
												</p>
											</div>
											<div class="postImg">
												<img v-if="TCpreviewImage" :src="TCpreviewImage" alt="Slider Image">
											</div>
										</div>
									</a>
								</div>
								<div class="col-md-12">
									<a class="promostionLink"
										:href="(BCsliderType == 'category') ? 'category/' + BCslug : 'javascript:void()'">
										<div :class="['postCardWrap', BCheadingposition]" v-if="BCpreviewimage"
											:style="{ backgroundColor: BCbgcolor, fontFamily: BCtextfontfamily }">
											<div class="postCont">
												<div class="cardTitle"
													:style="{ fontSize: BCheadingtextsize, color: BCheadingcolor, fontWeight: BCheadingBold ? 'bold' : 'normal' }"
													v-html="BCheading">
												</div>
												<p :style="{ fontSize: BCsubheadingtextsize, color: BCsubheadingcolor, fontWeight: BCsubheadingBold ? 'bold' : 'normal' }"
													v-html="BCsubheading">
												</p>
											</div>
											<div class="postImg">
												<img v-if="BCpreviewimage" :src="BCpreviewimage" alt="Slider Image">
											</div>
										</div>
									</a>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<a class="promostionLink"
								:href="(RsliderType == 'category') ? 'category/' + Rslug : 'javascript:void()'">
								<div class="brochure bgCard" v-if="RpreviewImage"
									:style="{ backgroundColor: RbgColor, fontFamily: Rtextfontfamily }">
									<div class="center-title">
										<div class="topSlug"
											:style="{ fontSize: RheadingTextSize, color: RheadingColor, fontWeight: RheadingBold ? 'bold' : 'normal' }"
											v-html="Rheading">
										</div>
										<!-- <div class="lgTitle">SALE</div> -->
										<div class="OffSlug"
											:style="{ fontSize: RsubheadingTextSize, color: RsubheadingColor, fontWeight: RsubheadingBold ? 'bold' : 'normal' }"
											v-html="Rsubheading">
										</div>
									</div>
									<div class="businessImg">
										<img v-if="RpreviewImage" :src="RpreviewImage" alt="Slider Image">
									</div>
								</div>
							</a>
						</div>
					</div>
					<!-- <div class="row">
						<div class="col-md-4">
							<div class="brochure">
								<img class="brochImg" src="../assets/img1.png" alt="">
								<div class="offLabel">15 % OFF</div>
								<div class="brochTitle">Brochures</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="row">
								<div class="col-md-12">
									<div class="postCardWrap">
										<div class="postCont">
											<div class="cardTitle">FLYERS</div>
											<p>Impress Customers with can`t-miss announcements</p>
											<p class="cardTitlesm">50 STARTING AT <strike>$10.00</strike> <b>$6.99</b>
											</p>
										</div>
										<div class="postImg">
											<img src="../assets/img1.png" alt="">
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="postCardWrap postCardWrap-bg">
										<div class="postCont">
											<div class="cardTitle">POSTCARD</div>
											<p>SALE 30% OFF</p>
										</div>
										<div class="postImg">
											<img src="../assets/img2.png" alt="">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="brochure bgCard">
								<div class="center-title">
									<div class="topSlug">Business Cards</div>
									<div class="lgTitle">SALE</div>
									<div class="OffSlug">UP TO 30% OF</div>
								</div>
								<div class="businessImg">
									<img src="../assets/img3.png" alt="">
								</div>
							</div>
						</div>
					</div> -->
				</div>
			</section>

			<section class="home-promotion-product home-product parten-bg">
				<div class="container">
					<div class="row">
						<div class="block-title-w">
							<h2 class="block-title">Popular Products</h2>
							<span class="icon-title">
								<span></span>
								<i class="fa fa-star"></i>
							</span>
						</div>
						<ul class="slider-w slider-owl">
							<li class="pro-item" v-for="pro in popularProducts.data.homeProducts" :key="pro.id"
								style="max-width:33%; width:100%; display:inline-block;padding: 15px 15px;">
								<div class="product-image-action">
									<img :src="pro.productImages" :alt="pro.productName">
									<div class="action">
										<!-- <button type="button" data-toggle="tooltip" data-placement="top" class="add-to-cart gbtn" title="Add to cart">
										<i class="fa fa-shopping-cart"></i> 
									</button>
									<a href="#" data-toggle="tooltip" data-placement="top" title="Wishlist" class="add-to-wishlist">
										<i class="fa fa-heart"></i>
									</a>
									<a href="#" data-toggle="tooltip" data-placement="top" title="compare" class="add-to-compare">
										<i class="fa fa-refresh"></i> 
									</a> 
									<a href="#" data-toggle="tooltip" data-placement="top" title="Quickview" class="quick-view">
										<i class="fa fa-eye"></i>
									</a> -->
									</div>
									<!-- <span class="product-icon sale-icon" v-if="pro.isSale">sale!</span>
									<span class="product-icon new-icon" v-if="pro.isNew">new!</span> -->

								</div>
								<div class="product-info">
									<a :href="'/custom-product/' + pro.productId" title="product"
										class="product-name">{{
											pro.productName }}</a>
									<div class="price-box">
										<!-- <span class="special-price"> &euro; {{Number(pro.quantityGroups[0].sellingProductPrice).toFixed(2)}}</span> -->
										<!-- <span class="special-price">$ {{ (Number(pro.price) - Number(pro.discount)).toFixed(2) }}</span>  -->
									</div>
									<!-- <div class="price-box" v-else>
									<span class="old-price">$ {{pro.sellingProductPrice}}</span>
									
								</div> -->
									<div class="rating-box">
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
										<i class="fa fa-star-half-o"></i>
										<i class="fa fa-star-o"></i>
										<i class="fa fa-star-o"></i>
									</div>
								</div>
							</li>

						</ul>
					</div>
				</div>
			</section>
			<section>
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="cta-wrap">
								<div class="flex-item" v-for="(pro, index) in homePageInformationData.data.bannerData"
									v-if="pro" :key="pro._id"
									:style="{ backgroundColor: pro.bgColor, fontFamily: pro.textfontfamily }">
									<div class="flex">
										<div class="ctaImg"><img :src='pro.images' alt="">
										</div>
										<div class="ctaCont">
											<p
												:style="{ color: pro.headingColor, fontSize: pro.headingtextsize, fontWeight: pro.headingBold ? 'bold' : 'normal' }">
												{{ pro.heading }}</p>
											<p
												:style="{ color: pro.subheadingColor, fontSize: pro.subheadingtextsize, fontWeight: pro.subheadingBold ? 'bold' : 'normal' }">
												{{ pro.subheading }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
		<!--Footer : Begin-->
		<footer-section> </footer-section>
		<div id="sitebodyoverlay"></div>
		<nav id="mb-main-menu" class="main-menu" style="display:none;">
			<div class="mb-menu-title">
				<h3>Navigation</h3>
				<span id="close-mb-menu">
					<i class="fa fa-times-circle"></i>
				</span>
			</div>
			<ul class="cate_list">
				<li class="level0 parent col1 all-product">
					<a href="#">
						<span>All Product</span>
						<i class="fa fa-chevron-down"></i><i class="fa fa-chevron-right"></i>
					</a>
					<ul class="level0">
						<li class="level1">
							<a href="#" title="Business Card">Business Card</a>
						</li>
						<li class="level1">
							<a href="#" title="Premium Business Card">Premium Business Card</a>
						</li>
						<li class="level1">
							<a href="#" title="Free Business Card">Free Business Card</a>
						</li>
						<li class="level1">
							<a href="#" title="Marketing Materials">Marketing Materials</a>
						</li>
						<li class="level1">
							<a href="#" title="Dance Marketing Kit">Dance Marketing Kit</a>
						</li>
						<li class="level1 view-all-pro">
							<a href="#" title="view all product">View all</a>
						</li>
					</ul>
				</li>
				<li class="level0 parent col1">
					<a href="#" title="Business Cards">
						<span>Business Cards</span>
						<i class="fa fa-chevron-down"></i><i class="fa fa-chevron-right"></i>
					</a>
					<ul class="level0">
						<li class="level1 nav-1-1 first item">
							<a href="#" title="Premium Business Cards">Premium Business Cards</a>
						</li>
						<li class="level1 nav-1-2 item">
							<a href="#" title="Free Business Cards">Free Business Cards</a>
						</li>
						<li class="level1 nav-1-3 item">
							<a href="#" title="Die-Cut Business Cards">Die-Cut Business Cards</a>
						</li>
						<li class="level1 nav-1-4 item">
							<a href="#" title="Standard Business Cards">Standard Business Cards</a>
						</li>
						<li class="level1 nav-1-5 item">
							<a href="#" class="Personal Business Cards">Personal Business Cards</a>
						</li>
						<li class="level1 nav-1-6 item">
							<a href="#" title="Business Card Holders">Business Card Holders</a>
						</li>
						<li class="level1 nav-1-7 item">
							<a href="#" title="Networking Cards">Networking Cards</a>
						</li>
						<li class="level1 nav-1-8 item">
							<a href="#" title="Appointment Cards">Appointment Cards</a>
						</li>
						<li class="level1 nav-1-9 last item">
							<a href="#" title="Mommy Cards">Mommy Cards</a>
						</li>
					</ul>
				</li>
				<li class="level0">
					<a href="/category" title="Marketing">Marketing</a>
				</li>
				<li class="level0">
					<a href="#" title="Postcards">Postcards</a>
				</li>
				<li class="level0">
					<a href="#" title="Stickers & Badges">Stickers & Badges</a>
				</li>
				<li class="level0" title="Promotional">
					<a href="#">Promotional</a>
				</li>
			</ul>
		</nav>

	</div>
</template>

<script>
// @ is an alias to /src
import 'animate.css';
import NavBarHome from '@/components/NavBarHome.vue'
import FooterSection from '@/components/FooterSection.vue'
import MMenu from '@/views/MMenu.vue'
import { mapState } from 'vuex';
export default {
	name: 'Home',
	components: {
		FooterSection, NavBarHome, MMenu
	},
	data() {
		return {
			slideShow: null,
			ready: false,
			items: ["animate__backInDown", "animate__backInLeft", "animate__backInRight", "animate__backInUp"],
			currentSlide: 0,
			someLocalProperty: "/",

			previewImage: '',
			bgColor: '',
			heading: '',
			headingTextSize: '',
			subHeading: '',
			subHeadingTextSize: '',
			sliderType: '',
			slug: '',
			subheadingbgColor: '',
			headingbgColor: '',
			alpha: '',
			rgbaColor: '',
			subheadingColor: '',
			headingColor: '',
			headingBold: '',
			subheadingBold: '',
			textfontfamily: '',
			//HORIZONTAL-BOX-RIGHT-SIDE
			RsliderType: '',
			Rslug: '',
			RpreviewImage: '',
			RbgColor: '',
			Rheading: '',
			RheadingTextSize: '',
			RheadingColor: '',
			Rsubheading: '',
			RsubheadingTextSize: '',
			RsubheadingColor: '',
			RheadingBold: '',
			RsubheadingBold: '',
			Rtextfontfamily: '',
			//RECTANGLE-SMALL-BOX-TOP-CENTER
			TCsliderType: '',
			TCheading: '',
			TCheadingtextsize: '',
			TCheadingcolor: '',
			TCsubheading: '',
			TCsubheadingtextsize: '',
			TCsubheadingcolor: '',
			TCslug: '',
			TCmainheading: '',
			TCmainheadingcolor: '',
			TCmainheadingsize: '',
			TCpreviewImage: '',
			TCbgcolor: '',
			TCheadingPosition: '',
			TCheadingBold: '',
			TCsubheadingBold: '',
			TCtextfontfamily: '',
			// RECTANGLE-SMALL-BOX-BOTTOM-CENTER
			BCsliderType: '',
			BCslug: '',
			BCheading: '',
			BCheadingtextsize: '',
			BCheadingcolor: '',
			BCpreviewimage: '',
			BCbgcolor: '',
			BCsubheading: '',
			BCsubheadingtextsize: '',
			BCsubheadingcolor: '',
			BCheadingposition: '',
			BCheadingBold: '',
			BCsubheadingBold: '',
			BCtextfontfamily: '',
		}
	},
	computed: {
		...mapState(['homeSlider', 'mainProducts', 'popularProducts', 'homePageInformationData', 'homePageSlimBoxData']),
		anyClass() {
			var item = this.items[Math.floor(Math.random() * this.items.length)];
			return item
		},

	},
	mounted() {
		this.checkSlider();
		this.getHomePromotion();
	},
	methods: {
		prevSlide() {
			const carousel = this.$refs.carousel;
			const totalSlides = this.homeSlider.length;
			const currentPage = carousel.currentPage;

			if (currentPage === 0) {
				carousel.goToPage(totalSlides - 1); // Go to the last slide
			} else {
				carousel.goToPage(currentPage - 1); // Go to the previous slide
			}
		},
		nextSlide() {
			const carousel = this.$refs.carousel;
			const totalSlides = this.homeSlider.length;
			const currentPage = carousel.currentPage;

			if (currentPage === totalSlides - 1) {
				carousel.goToPage(0); // Go to the first slide
			} else {
				carousel.goToPage(currentPage + 1); // Go to the next slide
			}
		},
		checkSlider() {
			if (this.homeSlider.length) {
				this.setSlideShow()
			} else {
				setTimeout(() => {
					this.checkSlider()
				}, 500);
			}
		},
		setSlideShow() {
			if (this.homeSlider.length) {
				let content = ` <div class="slide-show" > <div class="vt-slideshow" > <ul>`;
				this.homeSlider.forEach((element, index) => {
					let ct = `
				<li class="slide${(index + 1)}" data-transition="random" ><img src="images/slider/home/bg_slider_2.jpg" alt="" />
							<div class="tp-caption lfr" data-x="left"  data-hoffset="" data-y="170" data-start="800" data-speed="2000" data-endspeed="300"><span class="style1">
								<span class="textcolor">Flyers</span> & Leaflets</span></div> 
							<div class="tp-caption lfb" data-x="left"  data-hoffset="" data-y="225" data-start="800" data-speed="2000" data-endspeed="300">
								<span class="style2">
									Our A5 flyers and leaflets are our bestselling size.<br> 
									This is because they're perfect for potential prospects<br>  
									to carry around and are extremely cost effective. We<br> 
									currently have an offer of 1000 flyers and leaflets<br> 
									for only &pound; 24!
								</span>
							</div>
							<div class="tp-caption lfr" data-x="left" data-y="367" data-start="1300" data-speed="2000" data-easing="easeInOutQuint" data-endspeed="300"><a class="btn-sn" href="#">buy now</a></div> 
						</li>
				`
					content = content + ct
				});
				content = content + '</ul> </div> </div>'
				this.slideShow = content;
				this.ready = true
			} else {
				this.checkSlider()
			}
		},
		gtImgUrlBig(pet) {
			var images = require.context('../../public/images/product/', false, /\.jpg$/)
			return images('./' + pet)
		},
		getImgUrl(pet) {
			var images = require.context('../../public/images/slider/home/', false, /\.jpg$/)
			return images('./' + pet)
		},
		getImgUrlPro(pet) {
			var images = require.context('../../public/images/product/263x263/', false, /\.jpg$/)
			return images('./' + pet)
		},
		async getHomePromotion() {
			var items = await this.$store.dispatch("getHomePromotionData");
			if (items.length > 0) {
				// HORIZONTAL-BOX-LEFT-SIDE   
				this.previewImage = items[0].previewImage;
				this.bgColor = items[0].bgColor;
				this.heading = items[0].heading;
				this.headingTextSize = items[0].headingTextSize;
				this.subHeading = items[0].subHeading;
				this.subHeadingTextSize = items[0].subHeadingTextSize;
				this.sliderType = items[0].sliderType;
				this.slug = items[0].slug;
				this.subheadingbgColor = items[0].subheadingbgColor;
				this.headingbgColor = items[0].headingbgColor;
				this.alpha = items[0].alpha;
				this.rgbaColor = items[0].rgbaColor;
				this.subheadingColor = items[0].subheadingColor;
				this.headingColor = items[0].headingColor;
				this.headingBold = items[0].headingBold;
				this.subheadingBold = items[0].subheadingBold;
				this.textfontfamily = items[0].textfontfamily;
				//HORIZONTAL-BOX-RIGHT-SIDE
				if (items.length > 1) {
					this.RsliderType = items[1].RsliderType;
					this.Rslug = items[1].Rslug;
					this.RpreviewImage = items[1].RpreviewImage
					this.RbgColor = items[1].RbgColor
					this.Rheading = items[1].Rheading;
					this.RheadingTextSize = items[1].RheadingTextSize;
					this.RheadingColor = items[1].RheadingColor
					this.Rsubheading = items[1].Rsubheading;
					this.RsubheadingTextSize = items[1].RsubheadingTextSize;
					this.RsubheadingColor = items[1].RsubheadingColor
					this.RheadingBold = items[1].RheadingBold
					this.RsubheadingBold = items[1].RsubheadingBold
					this.Rtextfontfamily = items[1].Rtextfontfamily
				}
				//RECTANGLE-SMALL-BOX-TOP-CENTER
				if (items.length > 2) {
					this.TCsliderType = items[2].TCsliderType
					this.TCheading = items[2].TCheading
					this.TCheadingtextsize = items[2].TCheadingtextsize
					this.TCheadingcolor = items[2].TCheadingcolor
					this.TCsubheading = items[2].TCsubheading
					this.TCsubheadingtextsize = items[2].TCsubheadingtextsize
					this.TCsubheadingcolor = items[2].TCsubheadingcolor
					this.TCslug = items[2].TCslug
					this.TCmainheading = items[2].TCmainheading
					this.TCmainheadingcolor = items[2].TCmainheadingcolor
					this.TCmainheadingsize = items[2].TCmainheadingsize
					this.TCpreviewImage = items[2].TCpreviewImage
					this.TCbgcolor = items[2].TCbgcolor
					this.TCheadingPosition = items[2].TCheadingPosition
					this.TCheadingBold = items[2].TCheadingBold
					this.TCsubheadingBold = items[2].TCsubheadingBold
					this.TCtextfontfamily = items[2].TCtextfontfamily
				}
				// RECTANGLE-SMALL-BOX-BOTTOM-CENTER
				if (items.length > 3) {
					this.BCsliderType = items[3].BCsliderType
					this.BCslug = items[3].BCslug
					this.BCheading = items[3].BCheading
					this.BCheadingtextsize = items[3].BCheadingtextsize
					this.BCheadingcolor = items[3].BCheadingcolor
					this.BCpreviewimage = items[3].BCpreviewimage
					this.BCbgcolor = items[3].BCbgcolor
					this.BCsubheading = items[3].BCsubheading
					this.BCsubheadingtextsize = items[3].BCsubheadingtextsize
					this.BCsubheadingcolor = items[3].BCsubheadingcolor
					this.BCheadingposition = items[3].BCheadingposition
					this.BCheadingBold = items[3].BCheadingBold
					this.BCsubheadingBold = items[3].BCsubheadingBold
					this.BCtextfontfamily = items[3].BCtextfontfamily
				}


			} else {
				console.warn("No promotion data available");
			}
		}
	},

}
</script>
<style>
.VueCarousel-wrapper {

	max-width: 1351px !important;
	display: block !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.slider-title-left {
	font-size: 3vw;
}

.slider-sub-left {
	font-size: 1.5vw;
}

.slide-left-cont {
	left: 44px;
	position: absolute;
	top: 177px;
}

.slider-title-right {
	font-size: 3vw;
}

.slider-sub-right {
	font-size: 1.5vw;
}

.slide-right-cont {
	right: 44px;
	position: absolute;
	top: 177px;
}

.slide-cont {
	position: relative;
	height: auto;
}

.slide-cont h2 {
	color: #fd5b4e;
}

.ttttt {
	padding: 61px 0px 61px 369px;
}

.tttttSmall {
	padding: 61px 0px 61px 0px;
}

.smallSlider {
	width: 300px;
	margin-left: auto;
	margin-right: auto;
}

.smallSlider * {
	text-align: center;
	margin-top: 8px;
	/* font-size:11vw; */
}

.smallSlider h2 {
	font-size: 8vw;
}

.smallSlider h4 {
	font-size: 3vw;
}

.smallSlider a {
	display: block;
	width: 115px;
	margin-left: auto;
	margin-right: auto;
}

.promostionLink {
	text-decoration: none;
}

a.promostionLink:focus {
	text-decoration: none;
}

.slim-banner {
	border: 1px solid #51565b;
	padding: 7px 20px 5px;
	border-radius: 5px;
	text-align: center;
	margin: 5px 10px;
}
</style>
<style>
.navigation-buttons {
	position: absolute;
	top: 50%;
	width: 100%;
	display: flex;
	justify-content: space-between;
	transform: translateY(-50%);
	z-index: 1000;
}

section.home-slidershow {
	position: relative;
}

header {
	z-index: 9999;
	position: relative;
}

.nav-btn {
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border: none;
	font-size: 24px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nav-btn:hover {
	background-color: rgba(0, 0, 0, 0.8);
}

.nav-btn.prev {
	margin-left: 10px;
}

.nav-btn.next {
	margin-right: 10px;
}

.slim-banner span h1 {
	font-size: 36px !important;
}
.slim-banner span h2 {
	font-size: 30px !important;
}
.slim-banner span h3 {
    font-size: 24px !important;
}
.slim-banner span h4 {
    font-size: 20px !important;
}
.slim-banner span h5 {
    font-size: 16px !important;
}
.slim-banner span h6 {
    font-size: 14px !important;
}
</style>