<template>
    <div>
        <div class="c-mobile-menu" :class="{ 'open': MOM }">
            <header>
                <h3 class="j-mobile-menu__title">Select a category</h3>
                <button class="c-mobile-menu__close rotate"><img
                        src="https://www.digitalprintingireland.ie/img/ui/icon-close-white.svg" alt="Close"
                        @click="MOM = false"></button>
            </header>
            <section class="c-mobile-menu__items">
                <nav class="c-navbar__sticky" id="sticky-nav-mobile">
                    <ul>
                        <li id="sticky-nav-mobile-1" class="c-navbar__item j-navbar__item first">
                            <a class="c-navbar__toplevel" href="/products/" id="sticky-nav-mobile-products">Products</a>
                            <div id="mega-products" class="c-navbar__dropdown mega">

                                <div class="c-navbar__group" :class="{ 'active': SCM == cat.name }"
                                    v-for="cat in categories" :key="cat._id">
                                    <h2 @click="SCM = cat.name">{{ cat.name }}</h2>
                                    <ul>
                                        <!-- <li class="c-mobile-menu__back" @click="MOM = false"><a>Back to Home</a></li> -->
                                        <li class="c-mobile-menu__back" @click="SCM = ''"><a>Back to all items</a></li>

                                        <li class="c-mobile-menu__back" v-for="subcat in subCategories"
                                            :key="subcat._id" v-if="subcat.category._id == cat._id"><a
                                                :href="'/category/' + subcat.slug">{{ subcat.name }}</a></li>

                                    </ul>
                                </div>


                                <!-- @TODO: BE  - Make promo image dynamic (This doesn't change throughout, always the same promo image for the full menu) -->
                                <!-- <a class="c-navbar__feature u-hidden" href="#0"><img src="/img/menu-delivery-ie.jpg" height="258px" alt="Delivery"></a> -->
                                <!-- END @TODO: BE  - Make promo image dynamic (This doesn't change throughout, always the same promo image for the full menu) -->

                                <!-- <div class="col">
                        <a id="view-all-products" href="/products/" class="c-btn c-btn--small">All Products</a>
                    </div> -->
                            </div>
                        </li>




                        <!-- <li class="c-navbar__item "> <a class="c-navbar__toplevel" href="/products/booklet-printing/12/">Booklets</a></li>
        <li class="c-navbar__item "> <a class="c-navbar__toplevel" href="/products/wedding-stationery/95/">Wedding</a></li>
        <li class="c-navbar__item "> <a class="c-navbar__toplevel" href="/products/same-day-products/90/">Same Day</a></li>
        <li class="c-navbar__item u-hidden@xl"> <a class="c-navbar__toplevel" href="/products/roll-up-banner-stands/10/">Roll Up Banners</a></li> -->

                    </ul>
                </nav>

            </section>
        </div>
        <div id="header-sticky" class="c-navbar cf">
            <div class="o-wrapper  ie">
                <div class="o-grid">
                    <div class="o-grid__item u-width-full">
                        <div class="c-navbar__wrap">
                            <button class="c-hamburger c-hamburger--spin c-mobile-nav__target js-mobileNav__target"
                                type="button" @click="MOM = true">
                                <span class="c-hamburger__inner">&nbsp;</span> <span
                                    class="c-hamburger__text">Menu</span>
                            </button>
                            <nav class="c-navbar__sticky" id="sticky-nav">
                                <ul>
                                    <li id="sticky-nav-1" class="c-navbar__item j-navbar__item first"
                                        style="display:none">
                                        <a class="c-navbar__toplevel" :class="{ 'selected': MM }" @click="MM = !MM"
                                            id="sticky-nav-products">Categories</a>
                                        <div id="mega-products" class="c-navbar__dropdown mega"
                                            :class="{ 'selected': MM }">

                                            <div class="c-navbar__group" :class="{ 'active': SC == cat.name }"
                                                v-for="cat in categories" :key="cat._id">
                                                <h2 @click="SC = cat.name">{{ cat.name }}</h2>
                                                <ul>
                                                    <!-- <li class="c-mobile-menu__back"><a href="#0">Back to all items</a></li> -->

                                                    <li v-for="subcat in subCategories" :key="subcat._id"
                                                        v-if="subcat.category._id == cat._id"><a
                                                            :href="'/category/' + subcat.slug">{{ subcat.name }}</a>
                                                    </li>
                                                    <!-- <li><a href="/products/business-card-collection/422/">Business Card Collection</a></li>
                                                    <li><a href="/products/brochure-printing/11/">Brochure Printing</a></li>
                                                    <li><a href="/products/flat-unfinished-sheets/16/">Flat Unfinished Sheets</a></li>
                                                    <li><a href="/products/folded-leaflets/15/">Folded Leaflets</a></li>
                                                    <li><a href="/products/greeting-cards/43/">Greeting Cards</a></li>
                                                    <li><a href="/products/indoor-posters/44/">Indoor Posters</a></li>
                                                    <li><a href="/products/leaflets-and-flyers/8/">Leaflets and Flyers</a></li>
                                                    <li><a href="/products/luxury-business-cards/24/">Luxury Business Cards</a></li>
                                                    <li><a href="/products/luxury-papers/9/">Luxury Papers</a></li>
                                                    <li><a href="/products/new-products/152/">New Products</a></li>
                                                    <li><a href="/products/perfect-bound-books/17/">Perfect Bound Books</a></li>
                                                    <li><a href="/products/postcards/42/">Postcards</a></li>
                                                    <li><a href="/products/posters-large-format/3/">Posters/Large Format</a></li>
                                                    <li><a href="/products/roll-up-banner-stands/10/">Roll Up Banner Stands</a></li>
                                                    <li><a href="/products/saddle-stitched/18/">Saddle Stitched</a></li>
                                                    <li><a href="/products/same-day-products/90/">Same Day Products</a></li>
                                                    <li><a href="/products/stickers-and-labels/25/">Stickers and Labels</a></li>
                                                    <li><a href="/products/wedding-stationery/95/">Wedding Stationery</a></li>
                                                    <li><a href="/products/wiro-bound-documents/19/">Wiro Bound Documents</a></li>
                                                    <li><a href="/products/business-cards/2/">Business Cards</a></li>
                                                    <li><a href="/products/recycled-products/443/">Recycled Products</a></li>
                                                    <li><a href="/products/display-boards/419/">Display Boards</a></li> -->

                                                    <li class="c-navbar__category-link">
                                                        <a :href="'/category/' + cat.slug"
                                                            class="c-btn c-btn--grey2 c-btn--small">All {{ cat.name
                                                            }}</a>
                                                    </li>

                                                    <!-- @TODO: BE - Make promo image dynamic (This doesn't change throughout, always the same promo image for the full menu) -->
                                                    <li class="u-hidden u-block@m"><a class="c-navbar__feature u-hidden"
                                                            href="#0"><img src="/img/menu-delivery-ie.jpg"
                                                                alt="Delivery" class="u-width-full"></a></li>
                                                    <!-- END @TODO: BE - Make promo image dynamic (This doesn't change throughout, always the same promo image for the full menu) -->



                                                </ul>
                                            </div>



                                            <!-- @TODO: BE  - Make promo image dynamic (This doesn't change throughout, always the same promo image for the full menu) -->
                                            <!-- <a class="c-navbar__feature u-hidden" href="#0"><img src="/img/menu-delivery-ie.jpg" height="258px" alt="Delivery"></a> -->
                                            <!-- END @TODO: BE  - Make promo image dynamic (This doesn't change throughout, always the same promo image for the full menu) -->

                                            <div class="col">
                                                <a id="view-all-products" href="/products/"
                                                    class="c-btn c-btn--small">All Products</a>
                                            </div>
                                        </div>
                                    </li>


                                    <template v-if="headerLinkData.length > 0">
                                        <li class="c-navbar__item second-header" v-for="subLink in headerLinkData" :key="subLink.id">
                                            <a class="c-navbar__toplevel" :href="`/category/${subLink.titleSlug}`">{{ subLink.title }}</a>
                                        </li>
                                    </template>

                                </ul>
                            </nav>

                            <div class="c-navbar__basket-search">
                                <div id="search-spin"><span>&nbsp;</span></div>
                                <form class="search">
                                    <input type="search" name="search" v-model="search" @change="handle" id="search"
                                        placeholder="Business Cards, Posters, Booklets..."
                                        onfocus="this.placeholder = ''"
                                        onblur="this.placeholder = 'Business Cards, Posters, Booklets...'"
                                        class="js-search-cta sprite" autocomplete="off">
                                </form>
                                <!-- <div class="c-navbar__basket" id="basket">     -->
                                <!-- <button id="basket-toggle" class="c-navbar__basket-cta empty"><img src="https://www.digitalprinting.co.uk/img/ui/icon-basket.svg" alt="Basket"><span></span></button> -->

                                <!-- 
                                <section id="sticky-basket">
                                    <div id="basket-content">
                                        <div class="arrow-up arrow-shadow"></div>
                                        <div class="arrow-up"></div>
                                                    <p class="u-p2">Your shopping cart is empty</p>
                                            </div>
                                </section> -->
                                <!-- </div> -->
                            </div>
                        </div>
                        <div id="search-window" style="display: none;">
                            <div class="c-search-results">
                                <div id="results-list" class="c-search-results__wrap">
                                    <div v-if="searching">
                                        <h1>Searching......</h1>
                                    </div>
                                    <div v-else>
                                        <div class="c-search-results__item" tabindex="0">
                                            <a href="/products/bronze-roll-up-stand/85/?q=bud">
                                                <aside>
                                                    <img
                                                        src="//www.digitalprinting.co.uk/media/images/products/slides/85/bronze-roll-up-stand-4.jpg">
                                                </aside>
                                                <header>
                                                    <h4 class="u-semibold">'Bronze' Roll Up Stand</h4>
                                                    <p class="short-description">€40.56 each for 10 stands*</p>
                                                    <p class="from-price"><span>From</span> €40.56</p>
                                                </header>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState(['categories', 'user', 'userCart', 'userCartItems', 'products', 'subCategories', 'headerLinkData']),
    },
    data() {
        return {
            MM: false,
            MOM: false,
            SC: '',
            searching: false,
            SCM: '',
            search: "",
        }
    },
    methods: {
        handle(val) {
            console.log(val)
        }
    },
}
</script>

<style scoped>
@charset "UTF-8";

@-webkit-keyframes pulse {

    0%,
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(227, 5, 19, .6);
        box-shadow: 0 0 0 0 rgba(227, 5, 19, .6)
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px transparent;
        box-shadow: 0 0 0 10px transparent
    }

    to {
        -webkit-box-shadow: 0 0 0 0 transparent;
        box-shadow: 0 0 0 0 transparent
    }
}

.pop {
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    -webkit-animation-iteration-count: 5;
    animation-iteration-count: 5;
    -webkit-box-shadow: 0 0 0 rgba(227, 5, 19, .6);
    box-shadow: 0 0 0 rgba(227, 5, 19, .6);
    cursor: pointer
}

* {
    outline: 0;
    -webkit-font-smoothing: antialiased
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    color: #282827;
    font-family: "Arial", sans-serif;
    font-size: 1em;
    line-height: 1.5;
    min-height: 100%;
    overflow-y: scroll
}

blockquote,
body,
caption,
dd,
form,
hr,
p,
pre,
table {
    margin: 0;
    padding: 0
}

dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul {
    padding: 0
}

legend,
td,
th {
    margin: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

template {
    display: none
}

a {
    background-color: transparent
}

a:active,
a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: 700
}

dfn,
img {
    font-style: italic
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 0
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background-color: #d1d1d1;
    height: 1px;
    border: 0
}

pre {
    overflow: auto
}

code,
kbd,
pre,
samp {
    font-family: monospace;
    font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type=checkbox],
input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    border: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: 700
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

button,
legend,
td,
th {
    padding: 0
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

button {
    background: 0 0;
    border-radius: 0;
    border: 0
}

[role=button] {
    cursor: pointer
}

button:focus {
    outline: 0
}

[role=button],
a,
area,
button,
input,
label,
select,
summary,
textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

address {
    font-style: normal
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    text-rendering: optimizeLegibility
}

h6 {
    line-height: 1.5
}

h1,
h3 {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    font-weight: 500
}

h2,
h5 {
    font-family: "AvenirNextRoundedW01-Re", "Arial", sans-serif;
    font-weight: 400
}

h4 {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    font-weight: 600;
    letter-spacing: -.15px;
    font-size: 1rem;
    line-height: 1.4375
}

h1,
h2 {
    letter-spacing: -.4px;
    font-size: 2rem;
    line-height: 1.1875
}

h3 {
    letter-spacing: -.2px;
    font-size: 1.3125rem;
    line-height: 1.28571
}

h5 {
    letter-spacing: -.1px;
    font-size: .8125rem;
    line-height: 1.46154
}

@media only screen and (max-width: 1279px) {

    h1,
    h2 {
        font-size: 2rem;
        line-height: 1.1875
    }

    h3 {
        font-size: 1.3125rem;
        line-height: 1.28571
    }

    h4 {
        font-size: 1rem;
        line-height: 1.4375
    }

    h5 {
        font-size: .8125rem;
        line-height: 1.46154
    }
}

@media only screen and (max-width: 1020px) {

    h1,
    h2 {
        font-size: 2rem;
        line-height: 1.1875
    }

    h3 {
        font-size: 1.3125rem;
        line-height: 1.28571
    }

    h4 {
        font-size: 1rem;
        line-height: 1.4375
    }

    h5 {
        font-size: .8125rem;
        line-height: 1.46154
    }
}

@media only screen and (max-width: 760px) {

    h1,
    h2 {
        font-size: 2rem;
        line-height: 1.1875
    }

    h3 {
        font-size: 1.3125rem;
        line-height: 1.28571
    }

    h4 {
        font-size: 1rem;
        line-height: 1.4375
    }

    h5 {
        font-size: .8125rem;
        line-height: 1.46154
    }
}

@media only screen and (max-width: 479px) {

    h1,
    h2 {
        font-size: 2rem;
        line-height: 1.1875
    }

    h3 {
        font-size: 1.3125rem;
        line-height: 1.28571
    }

    h4 {
        font-size: 1rem;
        line-height: 1.4375
    }

    h5 {
        font-size: .8125rem;
        line-height: 1.46154
    }
}

@media only screen and (max-width: 375px) {

    h1,
    h2 {
        font-size: 2rem;
        line-height: 1.1875
    }

    h3 {
        font-size: 1.3125rem;
        line-height: 1.28571
    }

    h4 {
        font-size: 1rem;
        line-height: 1.4375
    }

    h5 {
        font-size: .8125rem;
        line-height: 1.46154
    }
}

img[height],
img[width] {
    max-width: none
}

canvas {
    max-width: 100%
}

input[type=email],
input[type=number],
input[type=password],
input[type=text],
textarea {
    border: 1px solid #d8d8d8;
    display: block;
    max-width: 100%;
    padding: 11px 12px;
    width: 100%
}

textarea {
    min-height: 80px;
    resize: vertical
}

input[type=search] {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    -webkit-appearance: none
}

[type=submit] {
    background: 0 0;
    border: 0
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

dd,
dt,
label {
    display: inline-block
}

[hidden] {
    display: none !important
}

a {
    text-decoration: none;
    color: inherit
}

dl,
ol,
ul {
    margin: 0 0 16px
}

ul {
    list-style: disc outside;
    margin-left: 24px
}

li>ol,
li>ul {
    margin-left: 16px;
    margin-bottom: 0
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

@-ms-viewport {
    width: device-width
}

@-o-viewport {
    width: device-width
}

@viewport {
    width: device-width
}

body {
    height: 100%;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Arial", sans-serif;
    font-weight: 300;
    overflow-x: hidden;
    width: 100%
}

body.noscroll {
    height: 100vh;
    overflow: hidden
}

p {
    letter-spacing: -.15px;
    line-height: 23px
}

blockquote {
    quotes: "“" "”"
}

blockquote p {
    text-indent: -.42em
}

blockquote p:before {
    content: open-quote
}

blockquote p:after {
    content: no-close-quote
}

blockquote p:last-of-type {
    margin-bottom: 0
}

blockquote p:last-of-type:after {
    content: close-quote
}

select {
    background: url(https://www.digitalprinting.co.uk/img/ui/icon-select.svg) no-repeat right 16px top 16px;
    -webkit-appearance: none;
    -moz-appearance: window;
    appearance: none;
    border-radius: 0;
    border: 1px solid #d8d8d8;
    display: block;
    line-height: 18px;
    max-width: 100%;
    padding: 11px 12px;
    width: 100%
}

select::-ms-expand {
    display: none
}

.o-column {
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px
}

.o-column-0 {
    -webkit-columns: 0;
    -moz-columns: 0;
    columns: 0
}

.o-column-1 {
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1
}

.o-column-2 {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2
}

.o-column-3 {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3
}

.o-column-4 {
    -webkit-columns: 4;
    -moz-columns: 4;
    columns: 4
}

.o-column-5 {
    -webkit-columns: 5;
    -moz-columns: 5;
    columns: 5
}

.o-column-6 {
    -webkit-columns: 6;
    -moz-columns: 6;
    columns: 6
}

.o-column-7 {
    -webkit-columns: 7;
    -moz-columns: 7;
    columns: 7
}

.o-column-8 {
    -webkit-columns: 8;
    -moz-columns: 8;
    columns: 8
}

.o-column-9 {
    -webkit-columns: 9;
    -moz-columns: 9;
    columns: 9
}

.o-column-10 {
    -webkit-columns: 10;
    -moz-columns: 10;
    columns: 10
}

.o-column-11 {
    -webkit-columns: 11;
    -moz-columns: 11;
    columns: 11
}

.o-column-12 {
    -webkit-columns: 12;
    -moz-columns: 12;
    columns: 12
}

@media only screen and (max-width: 1279px) {
    .o-column-0\@xl {
        -webkit-columns: 0;
        -moz-columns: 0;
        columns: 0
    }

    .o-column-1\@xl {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1
    }

    .o-column-2\@xl {
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2
    }

    .o-column-3\@xl {
        -webkit-columns: 3;
        -moz-columns: 3;
        columns: 3
    }

    .o-column-4\@xl {
        -webkit-columns: 4;
        -moz-columns: 4;
        columns: 4
    }

    .o-column-5\@xl {
        -webkit-columns: 5;
        -moz-columns: 5;
        columns: 5
    }

    .o-column-6\@xl {
        -webkit-columns: 6;
        -moz-columns: 6;
        columns: 6
    }

    .o-column-7\@xl {
        -webkit-columns: 7;
        -moz-columns: 7;
        columns: 7
    }

    .o-column-8\@xl {
        -webkit-columns: 8;
        -moz-columns: 8;
        columns: 8
    }

    .o-column-9\@xl {
        -webkit-columns: 9;
        -moz-columns: 9;
        columns: 9
    }

    .o-column-10\@xl {
        -webkit-columns: 10;
        -moz-columns: 10;
        columns: 10
    }

    .o-column-11\@xl {
        -webkit-columns: 11;
        -moz-columns: 11;
        columns: 11
    }

    .o-column-12\@xl {
        -webkit-columns: 12;
        -moz-columns: 12;
        columns: 12
    }
}

@media only screen and (max-width: 1020px) {
    .o-column-0\@l {
        -webkit-columns: 0;
        -moz-columns: 0;
        columns: 0
    }

    .o-column-1\@l {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1
    }

    .o-column-2\@l {
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2
    }

    .o-column-3\@l {
        -webkit-columns: 3;
        -moz-columns: 3;
        columns: 3
    }

    .o-column-4\@l {
        -webkit-columns: 4;
        -moz-columns: 4;
        columns: 4
    }

    .o-column-5\@l {
        -webkit-columns: 5;
        -moz-columns: 5;
        columns: 5
    }

    .o-column-6\@l {
        -webkit-columns: 6;
        -moz-columns: 6;
        columns: 6
    }

    .o-column-7\@l {
        -webkit-columns: 7;
        -moz-columns: 7;
        columns: 7
    }

    .o-column-8\@l {
        -webkit-columns: 8;
        -moz-columns: 8;
        columns: 8
    }

    .o-column-9\@l {
        -webkit-columns: 9;
        -moz-columns: 9;
        columns: 9
    }

    .o-column-10\@l {
        -webkit-columns: 10;
        -moz-columns: 10;
        columns: 10
    }

    .o-column-11\@l {
        -webkit-columns: 11;
        -moz-columns: 11;
        columns: 11
    }

    .o-column-12\@l {
        -webkit-columns: 12;
        -moz-columns: 12;
        columns: 12
    }
}

@media only screen and (max-width: 760px) {
    .o-column-0\@m {
        -webkit-columns: 0;
        -moz-columns: 0;
        columns: 0
    }

    .o-column-1\@m {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1
    }

    .o-column-2\@m {
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2
    }

    .o-column-3\@m {
        -webkit-columns: 3;
        -moz-columns: 3;
        columns: 3
    }

    .o-column-4\@m {
        -webkit-columns: 4;
        -moz-columns: 4;
        columns: 4
    }

    .o-column-5\@m {
        -webkit-columns: 5;
        -moz-columns: 5;
        columns: 5
    }

    .o-column-6\@m {
        -webkit-columns: 6;
        -moz-columns: 6;
        columns: 6
    }

    .o-column-7\@m {
        -webkit-columns: 7;
        -moz-columns: 7;
        columns: 7
    }

    .o-column-8\@m {
        -webkit-columns: 8;
        -moz-columns: 8;
        columns: 8
    }

    .o-column-9\@m {
        -webkit-columns: 9;
        -moz-columns: 9;
        columns: 9
    }

    .o-column-10\@m {
        -webkit-columns: 10;
        -moz-columns: 10;
        columns: 10
    }

    .o-column-11\@m {
        -webkit-columns: 11;
        -moz-columns: 11;
        columns: 11
    }

    .o-column-12\@m {
        -webkit-columns: 12;
        -moz-columns: 12;
        columns: 12
    }
}

@media only screen and (max-width: 479px) {
    .o-column-0\@s {
        -webkit-columns: 0;
        -moz-columns: 0;
        columns: 0
    }

    .o-column-1\@s {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1
    }

    .o-column-2\@s {
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2
    }

    .o-column-3\@s {
        -webkit-columns: 3;
        -moz-columns: 3;
        columns: 3
    }

    .o-column-4\@s {
        -webkit-columns: 4;
        -moz-columns: 4;
        columns: 4
    }

    .o-column-5\@s {
        -webkit-columns: 5;
        -moz-columns: 5;
        columns: 5
    }

    .o-column-6\@s {
        -webkit-columns: 6;
        -moz-columns: 6;
        columns: 6
    }

    .o-column-7\@s {
        -webkit-columns: 7;
        -moz-columns: 7;
        columns: 7
    }

    .o-column-8\@s {
        -webkit-columns: 8;
        -moz-columns: 8;
        columns: 8
    }

    .o-column-9\@s {
        -webkit-columns: 9;
        -moz-columns: 9;
        columns: 9
    }

    .o-column-10\@s {
        -webkit-columns: 10;
        -moz-columns: 10;
        columns: 10
    }

    .o-column-11\@s {
        -webkit-columns: 11;
        -moz-columns: 11;
        columns: 11
    }

    .o-column-12\@s {
        -webkit-columns: 12;
        -moz-columns: 12;
        columns: 12
    }
}

@media only screen and (max-width: 375px) {
    .o-column-0\@xs {
        -webkit-columns: 0;
        -moz-columns: 0;
        columns: 0
    }

    .o-column-1\@xs {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1
    }

    .o-column-2\@xs {
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2
    }

    .o-column-3\@xs {
        -webkit-columns: 3;
        -moz-columns: 3;
        columns: 3
    }

    .o-column-4\@xs {
        -webkit-columns: 4;
        -moz-columns: 4;
        columns: 4
    }

    .o-column-5\@xs {
        -webkit-columns: 5;
        -moz-columns: 5;
        columns: 5
    }

    .o-column-6\@xs {
        -webkit-columns: 6;
        -moz-columns: 6;
        columns: 6
    }

    .o-column-7\@xs {
        -webkit-columns: 7;
        -moz-columns: 7;
        columns: 7
    }

    .o-column-8\@xs {
        -webkit-columns: 8;
        -moz-columns: 8;
        columns: 8
    }

    .o-column-9\@xs {
        -webkit-columns: 9;
        -moz-columns: 9;
        columns: 9
    }

    .o-column-10\@xs {
        -webkit-columns: 10;
        -moz-columns: 10;
        columns: 10
    }

    .o-column-11\@xs {
        -webkit-columns: 11;
        -moz-columns: 11;
        columns: 11
    }

    .o-column-12\@xs {
        -webkit-columns: 12;
        -moz-columns: 12;
        columns: 12
    }
}

.o-grid {
    list-style: none;
    padding: 0;
    margin: 0 -16px;
    position: relative;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media only screen and (max-width: 760px) {
    .o-grid {
        margin-right: -8px;
        margin-left: -8px
    }
}

.o-grid__item {
    padding-left: 16px;
    padding-right: 16px;
    position: relative
}

@media only screen and (max-width: 760px) {
    .o-grid__item {
        padding-right: 8px;
        padding-left: 8px
    }
}

.o-grid--rev {
    direction: rtl;
    text-align: left
}

.o-grid--rev>.o-grid__item {
    direction: ltr;
    text-align: left
}

.o-grid--columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.o-grid--full {
    margin-right: 0;
    margin-left: 0
}

.o-grid--full>.o-grid__item {
    padding: 0
}

.o-grid--fill {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.o-grid--right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.o-grid--center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.o-grid--middle>.o-grid__item {
    -ms-flex-item-align: center;
    align-self: center
}

.o-grid--bottom>.o-grid__item {
    -ms-flex-item-align: end;
    align-self: flex-end
}

.o-grid--narrow {
    margin-right: -8px;
    margin-left: -8px
}

.o-grid--narrow>.o-grid__item {
    padding-right: 8px;
    padding-left: 8px
}

.o-grid--wide,
.o-grid--wide>.o-grid__item {
    padding-right: 32px;
    padding-left: 32px
}

.o-grid--space {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.o-wrapper {
    padding-left: 32px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 32px;
    max-width: 1360px
}

@media only screen and (max-width: 1020px) {
    .o-wrapper {
        padding-right: 20px;
        padding-left: 20px
    }
}

@media only screen and (max-width: 479px) {
    .o-wrapper {
        padding-right: 16px;
        padding-left: 16px
    }
}

.alert-warning,
.c-alert,
.c-alert-warning {
    border: 2px solid #e30513;
    border-radius: 10px;
    margin: 16px auto 24px;
    max-width: 852px;
    padding: 16px 16px 16px 24px;
    text-align: left;
    width: 100%
}

.alert-warning h3,
.c-alert h3 {
    color: #e30513 !important;
    padding-bottom: 16px
}

.c-alert.alert-confirm,
.c-btn {
    text-align: center
}

.alert-warning ul,
.c-alert ul {
    list-style: none;
    margin: 0
}

.alert-warning li,
.c-alert li {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif
}

.c-btn {
    font-size: .9375rem;
    background-color: #e30513;
    border-color: transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    letter-spacing: -.23px;
    line-height: 14px;
    min-width: 160px;
    font-weight: 700;
    padding: 12px 32px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear
}

.c-btn[disabled] {
    opacity: .25
}

.c-btn:hover {
    background-color: #fa212f
}

.c-btn--spec {
    background-color: transparent;
    border: 1px solid #777;
    color: #777
}

.c-btn--spec:hover {
    background-color: #919191
}

.c-btn--large {
    font-size: 1.0625rem;
    min-width: 200px;
    padding: 20px
}

.c-btn--300 {
    min-width: 300px
}

.c-btn--small {
    padding: 9px 12px
}

.c-btn--full {
    width: 100%
}

.c-btn--basket {
    padding: 12px
}

.c-btn:disabled {
    border-color: transparent;
    cursor: not-allowed;
    opacity: .8;
    pointer-events: none
}

.c-btn--black {
    background-color: #000
}

.c-btn--black:hover {
    background-color: #1a1a1a
}

.c-btn--white,
.c-btn--white:hover {
    background-color: #fff
}

.c-btn--red {
    background-color: #e30513
}

.c-btn--red2 {
    background-color: #f4b5b9
}

.c-btn--red2:hover {
    background-color: #fbe1e3
}

.c-btn--blue {
    background-color: #456aa6
}

.c-btn--blue:hover {
    background-color: #6184bd
}

.c-btn--blue2 {
    background-color: #80d0f0
}

.c-btn--blue2:hover {
    background-color: #aee1f5
}

.c-btn--green {
    background-color: #37a003
}

.c-btn--green2 {
    background-color: #c1e8ae
}

.c-btn--green2:hover {
    background-color: #dff3d6
}

.c-btn--yellow {
    background-color: #ffd500
}

.c-btn--yellow:hover {
    background-color: #fd3
}

.c-btn--mint {
    background-color: #d3f7d8
}

.c-btn--mint:hover {
    background-color: #fefffe
}

.c-btn--grass {
    background-color: #4d6012
}

.c-btn--grass:hover {
    background-color: #6f8b1a
}

.c-btn--gold {
    background-color: #ffd500
}

.c-btn--gold:hover {
    background-color: #fd3
}

.c-btn--gold2 {
    background-color: #f6f0cd
}

.c-btn--gold2:hover {
    background-color: #fefdf8
}

.c-btn--grey {
    background-color: #777
}

.c-btn--grey:hover {
    background-color: #919191
}

.c-btn--grey2 {
    background-color: #a0a0a0
}

.c-btn--grey2:hover {
    background-color: #bababa
}

.c-btn--grey-dark {
    background-color: #282827
}

.c-btn--grey-dark:hover {
    background-color: #424240
}

.c-btn--grey-dark2 {
    background-color: #51514f
}

.c-btn--grey-dark2:hover {
    background-color: #6b6b68
}

.c-btn--grey-light {
    background-color: #d8d8d8
}

.c-btn--grey-light:hover {
    background-color: #f2f2f2
}

.c-btn--grey-light2 {
    background-color: #f3f3f3
}

.c-btn--grey-light2:hover {
    background-color: #fff
}

.c-btn--text {
    background-color: #282827
}

.c-btn--text:hover {
    background-color: #424240
}

.c-btn--divider {
    background-color: #979797
}

.c-btn--divider:hover {
    background-color: #b1b1b1
}

.c-btn--green:hover {
    background-color: #6ac43e
}

.c-btn--red:hover {
    background-color: #ff4e5a
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
@-webkit-keyframes wave {

    0%,
    60%,
    to {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }

    30% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: 1
    }
}

@keyframes wave {

    0%,
    60%,
    to {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }

    30% {
        -webkit-transform: scale(1.4);
        transform: scale(1.4);
        opacity: 1
    }
}

@-webkit-keyframes pulse-me {
    0% {
        opacity: 0;
        -webkit-transform: scale(.15);
        transform: scale(.15)
    }

    50% {
        opacity: .2
    }

    70% {
        opacity: .15
    }

    to {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2)
    }
}

@keyframes pulse-me {
    0% {
        opacity: 0;
        -webkit-transform: scale(.15);
        transform: scale(.15)
    }

    50% {
        opacity: .2
    }

    70% {
        opacity: .15
    }

    to {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2)
    }
}

@-webkit-keyframes fade {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fade {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.c-hamburger {
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    display: none;
    line-height: 20px;
    margin-right: 32px;
    top: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 11
}

@media only screen and (max-width: 1020px) {
    .c-hamburger {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.c-hamburger:focus {
    outline: 0
}

.c-hamburger.sticky {
    top: 64px;
    -webkit-transition: all .3s;
    transition: all .3s
}

.c-hamburger__text {
    font-size: 1.3125rem;
    color: #fff;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    padding-left: 32px
}

.c-hamburger__inner,
.c-hamburger__inner:after,
.c-hamburger__inner:before {
    width: 22px;
    height: 2px;
    background-color: #fff;
    border-radius: 6px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: .15s;
    transition-duration: .15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    display: block
}

.c-hamburger__inner {
    margin: 0 auto;
    top: 18px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.c-hamburger__inner:after,
.c-hamburger__inner:before {
    content: ""
}

.c-hamburger__inner:before {
    top: -8px
}

.c-hamburger__inner:after {
    bottom: -8px
}

.c-hamburger--spin .c-hamburger__inner {
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.c-hamburger--spin .c-hamburger__inner:before {
    -webkit-transition: top .1s .34s ease-in, opacity .1s ease-in;
    transition: top .1s .34s ease-in, opacity .1s ease-in
}

.c-hamburger--spin .c-hamburger__inner:after {
    -webkit-transition: bottom .1s .34s ease-in, -webkit-transform .3s cubic-bezier(.55, .055, .675, .19);
    transition: bottom .1s .34s ease-in, transform .3s cubic-bezier(.55, .055, .675, .19);
    transition: bottom .1s .34s ease-in, transform .3s cubic-bezier(.55, .055, .675, .19), -webkit-transform .3s cubic-bezier(.55, .055, .675, .19)
}

.c-hamburger--spin.is-active .c-hamburger__inner {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
    -webkit-transition-delay: .14s;
    transition-delay: .14s;
    -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.c-hamburger--spin.is-active .c-hamburger__inner:before,
.c-mobile-menu {
    top: 0;
    opacity: 0;
    -webkit-transition: top .1s ease-out, opacity .1s .14s ease-out;
    transition: top .1s ease-out, opacity .1s .14s ease-out
}

.c-hamburger--spin.is-active .c-hamburger__inner:after {
    bottom: 0;
    height: 2px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: bottom .1s ease-out, -webkit-transform .3s .14s cubic-bezier(.215, .61, .355, 1);
    transition: bottom .1s ease-out, transform .3s .14s cubic-bezier(.215, .61, .355, 1);
    transition: bottom .1s ease-out, transform .3s .14s cubic-bezier(.215, .61, .355, 1), -webkit-transform .3s .14s cubic-bezier(.215, .61, .355, 1)
}

.c-mobile-menu {
    background-color: #fff;
    border: 3px solid #282827;
    border-top: 0;
    height: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    -webkit-transition: height .7s, opacity .2s;
    transition: height .7s, opacity .2s;
    width: 100%;
    z-index: 21
}

.c-mobile-menu.open,
.c-mobile-menu.open .c-page-head__account {
    opacity: 1;
    -webkit-transition: top .7s, opacity .5s;
    transition: top .7s, opacity .5s
}

.c-mobile-menu.open {
    height: 100%
}

.c-mobile-menu.open .c-page-head__account {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    top: 0;
    -webkit-transition-delay: .4s;
    transition-delay: .4s
}

.c-mobile-menu.open #sticky-nav-mobile {
    left: 0;
    opacity: 1;
    -webkit-transition: left .7s, opacity .5s;
    transition: left .7s, opacity .5s
}

.c-mobile-menu .c-navbar__group h2,
.c-mobile-menu header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.c-mobile-menu header {
    background-color: #282827;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 24px
}

.c-mobile-menu .c-mobile-menu__close {
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out
}

.c-mobile-menu .c-mobile-menu__close.rotate {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.c-mobile-menu .c-navbar__toplevel,
.c-mobile-menu__back,
.c-navbar form.search label {
    display: none
}

.c-mobile-menu ul {
    list-style: none;
    margin: 0
}

.c-mobile-menu .c-navbar__group h2 {
    font-size: 1rem;
    color: #282827;
    font-weight: 500;
    position: relative
}

.c-mobile-menu .c-navbar__group h2:after,
.c-mobile-menu__back a:before,
.c-navbar .c-navbar__dropdown h2:after {
    content: url(https://www.digitalprinting.co.uk/img/ui/icon-arrow-right-black.svg)
}

.c-mobile-menu .c-navbar__group ul {
    left: -60%;
    -webkit-transition: left 1s, opacity .3s;
    transition: left 1s, opacity .3s;
    background-color: #fff;
    height: calc(100% - 77px);
    opacity: 0;
    overflow-y: scroll;
    padding: 24px;
    position: fixed;
    top: 74px;
    width: 60%;
    z-index: 3
}

@media only screen and (max-width: 760px) {
    .c-mobile-menu .c-navbar__group ul {
        left: -100%;
        width: calc(100% - 6px)
    }
}

.c-mobile-menu .c-navbar__group.active ul {
    left: 3px;
    opacity: 1;
    -webkit-transition: left .3s, opacity .3s;
    transition: left .3s, opacity .3s
}

.c-mobile-menu .c-navbar__group.active ul li a {
    border-bottom: 1px solid #d8d8d8;
    display: block;
    font-family: "AvenirNextRoundedW01-Re", "Arial", sans-serif;
    margin-bottom: 12px;
    padding-bottom: 12px
}

.c-mobile-menu #sticky-nav-mobile {
    border-right: 1px solid #d8d8d8;
    left: -50px;
    margin-right: 32px;
    opacity: 1;
    padding-right: 32px;
    position: relative;
    -webkit-transition: left 1s, opacity .2s;
    transition: left 1s, opacity .2s;
    width: 60%
}

@media only screen and (max-width: 760px) {
    .c-mobile-menu #sticky-nav-mobile {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
        width: 100%
    }
}

.c-mobile-menu .c-navbar__group h2,
.c-mobile-menu .col {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 16px;
    padding-bottom: 16px
}

@media only screen and (max-width: 1020px) {
    .c-mobile-menu__back {
        display: block
    }
}

.c-mobile-menu__back a {
    color: #282827;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    font-weight: 500;
    padding-left: 20px !important;
    position: relative
}

.c-mobile-menu__back a:before {
    display: block !important;
    left: 0;
    position: absolute;
    top: -2px
}

.c-mobile-menu__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 24px
}

@media only screen and (max-width: 760px) {
    .c-mobile-menu__items {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-mobile__menu-image-promo {
    opacity: 0;
    height: 0;
    width: 0
}

.c-mobile__menu-image-promo.shown {
    height: auto;
    opacity: 1;
    width: auto
}

.c-navbar {
    background-color: #282827;
    padding: 12px 0;
    width: 100%;
    z-index: 10
}

.c-navbar .c-navbar__sticky ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 24px 0 0
}

@media only screen and (max-width: 1020px) {
    .c-navbar .c-navbar__sticky ul {
        display: none !important
    }
}

.c-navbar .c-navbar__sticky li {
    cursor: pointer;
    position: relative
}

.c-mobile-menu__back a:before,
.c-navbar .c-navbar__sticky li.mega-active:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.c-mobile-menu .c-navbar__group h2,
.c-navbar .c-navbar__dropdown h2,
.c-navbar .c-navbar__sticky li.mega-active .mega {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-navbar .c-navbar__sticky li li {
    position: static
}

.c-navbar .c-navbar__dropdown {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #fff;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, .16);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .16);
    color: #2d2d2c;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 0;
    left: 0;
    margin: -2px 0 0;
    max-width: 1298px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 20px;
    -webkit-transition: opacity .2s, top .3s;
    transition: opacity .2s, top .3s;
    width: calc(100vw - 64px);
    z-index: 1001;
    pointer-events: none
}

.c-navbar .c-navbar__dropdown:before {
    background-image: url(https://www.digitalprinting.co.uk/img/ui/icon-dropdown-triangle.svg);
    content: "";
    display: block;
    height: 24px;
    overflow: hidden;
    position: absolute;
    top: -24px;
    z-index: -1;
    left: -20px;
    width: 130px
}

.c-navbar .c-navbar__dropdown h2 {
    font-size: 1rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    margin-bottom: 24px;
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s
}

.c-navbar .c-navbar__dropdown h2:after {
    display: block
}

.c-navbar .c-navbar__dropdown .c-navbar__group.active h2,
.c-navbar .c-navbar__dropdown h2:hover,
.c-navbar .c-navbar__dropdown li a:hover {
    color: #e30513
}

.c-navbar .c-navbar__dropdown ul {
    border-left: 1px solid #d8d8d8;
    color: #777;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-fill: auto;
    -moz-column-fill: auto;
    column-fill: auto;
    -webkit-column-gap: 60px;
    -moz-column-gap: 60px;
    column-gap: 60px;
    display: block;
    height: calc(100% - 80px);
    left: 200px;
    opacity: 0;
    overflow: hidden;
    padding: 0 0 48px 32px;
    position: absolute;
    top: 40px;
    -webkit-transition: all .2s;
    transition: all .2s;
    width: auto
}

.c-navbar .c-navbar__dropdown .c-navbar__group {
    min-width: 190px;
    padding-right: 16px
}

.c-navbar .c-navbar__dropdown .c-navbar__group.active ul {
    left: 240px;
    opacity: 1;
    z-index: 2
}

.c-navbar .c-navbar__dropdown .c-navbar__group.active h2:after {
    content: url(https://www.digitalprinting.co.uk/img/ui/icon-arrow-right-red.svg)
}

.c-navbar .c-navbar__dropdown li a {
    display: block;
    margin-bottom: 12px;
    -webkit-transition: color .15s;
    transition: color .15s
}

.c-navbar,
.c-navbar .c-navbar__dropdown li a.c-btn--grey:hover,
.c-navbar .c-navbar__dropdown li.c-navbar__category-link a:hover {
    color: #fff
}

.c-navbar .c-navbar__dropdown .c-navbar__category-link {
    bottom: 0;
    left: 32px;
    position: absolute
}

.c-navbar .c-navbar__dropdown .c-navbar__category-link a {
    margin-bottom: 0
}

.c-navbar .c-navbar__dropdown .c-navbar__feature {
    display: block;
    max-width: 258px;
    opacity: 1;
    position: absolute;
    right: 40px;
    top: 40px;
    -webkit-transition: opacity .2s;
    transition: opacity .2s
}

.c-navbar form.search {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    margin-right: 16px
}

@media only screen and (max-width: 760px) {
    .c-navbar form.search {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }
}

.c-navbar form.search input#search {
    font-size: .8125rem;
    background-color: #fff;
    background-image: url(https://www.digitalprinting.co.uk/img/ui/icon-search.svg);
    background-position: right 16px top 8px;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 10px;
    color: #777;
    letter-spacing: -.1px;
    line-height: 20px;
    min-width: 290px;
    padding: 10px 16px;
    position: relative;
    width: 100%;
    z-index: 2
}

@media only screen and (max-width: 760px) {
    .c-navbar form.search input#search {
        background-position: right 22px top 8px;
        min-width: 65px;
        width: 220px
    }

    .c-navbar form.search input#search::-webkit-input-placeholder,
    .c-navbar form.search input#search:focus::-webkit-input-placeholder {
        color: transparent
    }

    .c-navbar form.search input#search::-moz-placeholder,
    .c-navbar form.search input#search:focus::-moz-placeholder {
        color: transparent
    }

    .c-navbar form.search input#search:-ms-input-placeholder,
    .c-navbar form.search input#search:focus:-ms-input-placeholder {
        color: transparent
    }

    .c-navbar form.search input#search::-ms-input-placeholder,
    .c-navbar form.search input#search:focus::-ms-input-placeholder {
        color: transparent
    }

    .c-navbar form.search input#search::placeholder,
    .c-navbar form.search input#search:focus::placeholder {
        color: transparent
    }
}

.c-navbar #sticky-basket #basket-content {
    background: #fff;
    border: 3px solid #e30513;
    color: #2d2d2c;
    display: none;
    margin: 0;
    position: absolute;
    right: 16px;
    top: 53px;
    max-width: 440px;
    width: 100%;
    z-index: 999
}

@media only screen and (max-width: 760px) {
    .c-navbar #sticky-basket #basket-content {
        right: 8px
    }
}

.c-navbar #sticky-basket #basket-content.basket-active {
    display: block
}

.c-navbar #sticky-basket #basket-content .price,
.c-navbar #sticky-basket #basket-content table.basket-items td.totals table td.value {
    color: #6f6f6e;
    font-weight: 700;
    text-align: right
}

.c-navbar #sticky-basket #basket-content .basket-prices td {
    border-bottom: none !important;
    padding-bottom: 8px
}

.c-navbar #sticky-basket #basket-content .basket-prices:last-child td {
    border-top: 1px solid #e8e8e8;
    border-bottom: none;
    margin-bottom: 16px;
    padding: 8px 0 0 !important
}

.c-navbar #sticky-basket #basket-content .basket-prices.total td {
    padding-bottom: 0 !important;
    padding-top: 0 !important
}

.c-navbar #sticky-basket #basket-content .basket-scroll {
    max-height: 400px;
    overflow: auto;
    padding: 24px 32px
}

@media screen and (max-width: 820px) and (orientation:landscape) {
    .c-navbar #sticky-basket #basket-content .basket-scroll {
        max-height: 200px
    }
}

.c-navbar #sticky-basket #basket-content .basket-box,
.c-navbar__basket-search.searching .search {
    position: relative
}

.c-navbar #sticky-basket #basket-content table.basket-items,
.c-navbar__basket-search form.search.open input#search {
    width: 100%
}

.c-navbar #sticky-basket #basket-content table.basket-items td {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 8px;
    padding-top: 8px;
    vertical-align: top
}

.c-navbar #sticky-basket #basket-content table.basket-items td.description {
    padding: 10px
}

.c-navbar #sticky-basket #basket-content table.basket-items td img {
    margin-top: 4px
}

.c-navbar #sticky-basket #basket-content table.basket-items tr:first-child td {
    padding-top: 0
}

.c-navbar #sticky-basket #basket-content table.basket-items td.totals {
    border: 0;
    padding: 10px 0 0
}

.c-navbar #sticky-basket #basket-content table.basket-items td.totals table {
    float: right
}

.c-navbar #sticky-basket #basket-content table.basket-items td.totals table td {
    border: 0;
    padding: 0 0 2px 10px
}

.c-navbar #sticky-basket #basket-content table.basket-items td.totals table tr.grand-total td {
    border-top: 1px solid #e8e8e8;
    color: #2d2d2c;
    padding: 5px 0 0 10px
}

.c-navbar__feature {
    max-width: 258px
}

.c-navbar__item>a {
    display: inline-block;
    font-size: 1.3125rem;
    color: #fff;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    letter-spacing: -.2px;
    line-height: 28px;
    padding: 6px 22px;
    position: relative;
    z-index: 1002
}

.c-navbar__item>a:before {
    background-color: #777;
    content: "";
    height: 24px;
    width: 1px;
    position: absolute;
    left: 0
}

.c-navbar__item:first-child a {
    margin-left: 0;
    padding-left: 0
}

.c-navbar__item:first-child a:before {
    display: none
}

.c-navbar__item:first-child a.c-btn {
    padding-left: 14px
}

.j-navbar__item>a:after {
    content: url(https://www.digitalprinting.co.uk/img/ui/icon-down-white.svg);
    display: inline-block;
    height: 6px;
    left: 8px;
    line-height: 10px;
    position: relative;
    top: -1px;
    -webkit-transition: all .2s;
    transition: all .2s
}

.c-navbar__item>a.selected:after {
    top: 8px;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.c-navbar__dropdown.selected {
    border: 3px solid #e30513;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    opacity: 1;
    overflow: visible;
    padding: 40px;
    top: 51px;
    pointer-events: initial
}

.c-navbar__basket-search,
.c-navbar__wrap {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.c-navbar__basket-search {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

@media only screen and (max-width: 760px) {
    .c-navbar__basket-search form.search.open {
        width: 100%
    }
}

.c-navbar__basket-search form.search.open input#search::-webkit-input-placeholder {
    color: #777
}

.c-navbar__basket-search form.search.open input#search::-moz-placeholder {
    color: #777
}

.c-navbar__basket-search form.search.open input#search:-ms-input-placeholder {
    color: #777
}

.c-navbar__basket-search form.search.open input#search::-ms-input-placeholder {
    color: #777
}

.c-navbar__basket-search form.search.open input#search::placeholder {
    color: #777
}

.c-navbar__basket-search.searching .search:before {
    background-color: #f3f3f3;
    bottom: -20px;
    content: "";
    height: 24px;
    position: absolute;
    width: 100%;
    z-index: 2
}

.c-navbar__basket-search.searching .search:after {
    border: 3px solid #e30513;
    border-bottom: 0;
    border-radius: 10px 10px 0 0;
    content: "";
    height: 53px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2
}

.c-navbar__basket-search.searching #search {
    border-radius: 10px 10px 0 0
}

#search-spin {
    position: relative;
    right: 16px;
    top: -14px
}

.c-navbar__basket {
    background-color: #fff;
    border-radius: 10px
}

@media only screen and (max-width: 760px) {
    .c-navbar__basket {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }
}

.c-navbar__basket #basket-toggle {
    background-image: url(https://www.digitalprinting.co.uk/img/ui/icon-basket.svg);
    background-position: 8px 8px;
    background-repeat: no-repeat;
    min-height: 40px;
    min-width: 42px;
    padding: 8px;
    position: relative;
    z-index: 1003
}

.c-navbar__basket .c-navbar__basket-cta {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #282827;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    font-weight: 600;
    position: relative
}

.c-navbar__basket .c-navbar__basket-cta span {
    margin-left: 2px;
    pointer-events: none
}

.c-navbar__basket .c-navbar__basket-cta img {
    pointer-events: none
}

.c-navbar__basket.active #basket-toggle:before {
    background-color: #fff;
    content: "";
    left: 3px;
    height: 40px;
    position: absolute;
    top: 36px;
    width: 38px;
    z-index: 1111
}

.c-navbar__basket.active #basket-toggle:after {
    border: 3px solid #e30513;
    border-bottom: 0;
    border-radius: 10px 10px 0 0;
    content: "";
    left: 0;
    height: 53px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1111
}

.c-navbar__basket.active #basket-toggle.has-contents:before {
    height: 20px;
    width: calc(100% - 6px)
}

.c-navbar__basket-bar {
    background-color: #80d0f0;
    text-align: center;
    padding: 12px
}

.c-navbar__basket-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 24px
}

@media only screen and (max-width: 375px) {
    .c-navbar__basket-btns {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

.c-navbar__basket-btns a:first-child {
    margin-right: 8px
}

@media only screen and (max-width: 375px) {
    .c-navbar__basket-btns a:first-child {
        margin-bottom: 12px;
        margin-right: 0
    }
}

.c-navbar__basket-btns a:last-child {
    margin-left: 8px
}

@media only screen and (max-width: 375px) {
    .c-navbar__basket-btns a:last-child {
        margin-left: 0
    }
}

#content,
.c-page-body {
    position: relative;
    -webkit-transition: opacity .5s;
    transition: opacity .5s
}

#content.covered,
#content.covered2,
.c-page-body.covered,
.c-page-body.covered2 {
    opacity: .24;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

.c-page__inner h1,
.c-page__sidebar a:hover,
.c-promo-banners .banner p {
    color: #282827
}

.c-page__sidebar {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif
}

.c-page__sidebar ul {
    margin: 0 0 24px
}

.c-page__sidebar li {
    border-bottom: 1px solid #d8d8d8;
    color: #777;
    display: block;
    margin-bottom: 10px;
    padding-bottom: 10px;
    line-height: 1.2
}

.c-page__sidebar li.selected {
    color: #282827;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-page__sidebar a {
    display: block
}

.c-page-standard h1,
.c-page-standard p,
.c-page__sidebar h3 {
    padding-bottom: 16px
}

@media only screen and (max-width: 760px) {
    .c-page__sidebar h3 {
        padding-bottom: 4px
    }
}

.c-page-standard h3 {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    padding-bottom: 16px
}

.c-page-standard img {
    max-width: 100%;
    width: auto;
    height: auto
}

.c-page-standard img[align=right] {
    margin-bottom: 16px;
    margin-left: 16px
}

.c-page-head {
    padding: 12px 0;
    position: relative
}

.c-page-head .c-page__sidebar {
    font-family: "Arial", sans-serif
}

.c-page-head .c-page__sidebar li {
    border-bottom: none;
    color: #282827;
    display: inline-block;
    margin-bottom: 0;
    padding-bottom: 0
}

.c-page-head__header,
.c-page-head__header nav ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-page-head__header {
    font-size: .8125rem;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #777;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.c-page-head__header nav ul {
    list-style: none;
    margin: 0
}

.c-page-head__header nav a {
    padding: 10px 12px;
    -webkit-transition: all .1s;
    transition: all .1s
}

@media only screen and (max-width: 1279px) {
    .c-page-head__header nav a {
        padding: 10px 9px
    }
}

.c-page-head__header nav a:hover {
    color: #e30513
}

.c-page-head__header nav li:last-child a {
    padding-right: 24px
}

.c-page-head__logo {
    display: block;
    height: 100%;
    width: 100%
}

.c-page-head__logo img {
    height: 53px;
    max-width: 100%
}

@media only screen and (max-width: 1020px) {
    .c-page-head__logo img {
        width: 195px
    }
}

.c-page-head__header.logged-in-user .c-page-head__links {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-top: 12px
}

.c-page-head__header.logged-in-user .c-page-head__country {
    display: none
}

.c-page-head__country {
    margin-bottom: 8px;
    text-decoration: underline
}

.c-page-head__account {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    color: #777;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative
}

@media only screen and (max-width: 1020px) {
    .c-page-head__account {
        font-size: .8125rem;
        opacity: 0;
        top: -50px;
        -webkit-transition: all .7s;
        transition: all .7s;
        -webkit-transition-delay: .4s;
        transition-delay: .4s
    }
}

.c-page-head__account .c-page__sidebar li {
    border-bottom: none;
    color: #777;
    font-family: "Arial", sans-serif;
    margin-bottom: 0;
    padding-bottom: 0
}

#delivery-modal p,
.c-page-head__account .c-page__sidebar li a,
.c-quote__other-options h4 {
    margin-bottom: 12px
}

.c-page-head__account .name {
    font-size: 1.3125rem;
    color: #282827;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    letter-spacing: -.2px;
    line-height: 27px;
    text-align: right
}

@media only screen and (max-width: 1020px) {
    .c-page-head__account .name {
        text-align: left
    }

    .c-page-head__account .account {
        margin-bottom: 24px
    }
}

.c-page-head__account .account a {
    color: #777;
    margin-left: 8px;
    text-decoration: underline
}

@media only screen and (max-width: 1020px) {
    .c-page-head__account .account a {
        margin-left: 0;
        margin-right: 10px
    }
}

.c-page-head__links,
.c-page-head__minimal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-page-head__links {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.c-page-head__links .c-page__sidebar {
    font-family: "Arial", sans-serif
}

.c-page-head__links .c-page__sidebar li,
.c-promo-banners .banner .title a {
    color: #777
}

.c-page-head__links .c-page__sidebar.logged-in-user {
    margin-right: 32px
}

.c-page-head__links .c-page__sidebar.logged-in-user li a {
    padding-bottom: 2px
}

#main-nav-mobile li a {
    display: inline-block;
    margin-bottom: 12px
}

.c-page-head__minimal {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 24px 0
}

@media only screen and (max-width: 760px) {
    .c-page-head__minimal {
        margin: 24px 0 16px
    }
}

#fullscreen-spinner,
.c-spinner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

#fullscreen-spinner {
    background-color: rgba(255, 255, 255, .9);
    height: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
    width: 100%;
    z-index: 9999999
}

#fullscreen-spinner img {
    max-width: 60px
}

#fullscreen-spinner.active {
    height: 100%;
    opacity: 1
}

.c-spinner {
    height: 40px;
    margin: 0 auto;
    position: relative;
    text-align: center
}

.c-spinner .c-spinner__dot {
    -webkit-animation: wave 1s linear infinite;
    animation: wave 1s linear infinite;
    background: #e30513;
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    margin-right: 12px;
    opacity: .5;
    width: 12px
}

.c-spinner .c-spinner__dot:nth-child(2) {
    -webkit-animation-delay: -1.25s;
    animation-delay: -1.25s
}

.c-page-head__redirect {
    background-color: #e30513;
    color: #fff;
    padding: 9px;
    text-align: center
}

.c-page-head__redirect.hide-it {
    display: none
}

.c-page-head__redirect .close {
    position: absolute;
    right: 16px;
    top: 12px
}

.c-page-head__redirect .flag {
    margin-right: 16px;
    padding: 1px;
    max-height: 69px
}

.c-page-head__redirect .c-btn {
    background-color: #fff;
    border-radius: 3px;
    color: #282827;
    padding: 8px;
    width: 162px
}

@media only screen and (max-width: 760px) {
    .c-page-head__redirect .c-btn {
        width: 100px
    }
}

@media only screen and (max-width: 375px) {
    .c-page-head__redirect .c-btn {
        min-width: 60px
    }
}

.c-promo-banners {
    font-size: .8125rem;
    letter-spacing: -.1px;
    background: #f3f3f3;
    max-height: 40px;
    padding: 10px 0;
    width: 100%
}

.c-promo-banners .banner {
    border-left: #d8d8d8;
    text-align: center;
    width: 100%
}

.c-promo-banners .banner .title {
    color: #777;
    font-weight: 700;
    padding: 0
}

.c-promo-banners .merchant-rating-wrap {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-promo-banners #merchant-rating-USP {
    line-height: 22px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.c-promo-banners .empty-stars:before,
.c-promo-banners .full-stars:before {
    font-size: 24px;
    line-height: 18px
}

.c-quote__item input,
.c-quote__item-recommended input,
.c-quote__summary .spec.no-edit:after,
.products .c-promo-banners,
body.quote .c-breadcrumbs {
    display: none
}

#promo-banners .content,
.c-promo-banners #merchant-rating-USP,
.c-promo-banners__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-promo-banners__wrap {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: distribute;
    justify-content: space-around
}

#promo-banners .content {
    margin: 0 auto;
    max-width: 940px;
    width: 100%
}

#promo-banners.banners1 .banner,
#promo-banners.banners3 .banner,
.c-quote__item-recommended.active.bespoke:after,
.c-quote__item.active.bespoke:after {
    border: 0
}

#promo-banners.banners2 .banner:first-child,
#promo-banners.banners4 .banner:first-child {
    border: 0
}

#promo-banners.banners3 .banner2 {
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8
}

@media only screen and (max-width: 479px) {
    #promo-banners.banners3 .banner2 {
        border: 0
    }
}

#promo-banners .USP,
.c-quote__steps-overview {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

#promo-banners .USP {
    -ms-flex-line-pack: justify;
    align-content: space-between
}

#promo-banners .USP img {
    line-height: 38px;
    margin-bottom: 4px;
    max-height: 20px;
    max-width: 96px;
    padding-right: 8px
}

.c-quote__steps .step {
    display: none;
    opacity: 0;
    -webkit-transition: opacity 1s;
    transition: opacity 1s
}

.c-quote__steps .step.active {
    display: block !important;
    opacity: 1
}

.c-quote__steps input[type=text] {
    display: inline-block;
    max-width: 300px;
    padding: 11px;
    width: 100%
}

.c-quote__steps-overview {
    background-color: #f3f3f3;
    color: #a0a0a0;
    list-style: none;
    margin: 0 0 24px;
    padding: 20px
}

@media only screen and (max-width: 760px) {
    .c-quote__steps-overview a {
        display: none
    }
}

#promo-banners .USP,
.c-quote__steps-overview,
.c-quote__steps-overview li,
.c-quote__steps-overview li:before {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-quote__steps-overview li {
    font-size: .8125rem;
    font-weight: 700;
    letter-spacing: -.1px;
    line-height: 19px;
    margin: 0 8px
}

.c-quote__steps-overview li:before {
    font-size: 1rem;
    border-radius: 10px;
    border: 2px solid #a0a0a0;
    content: "";
    height: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 8px;
    width: 38px
}

.c-quote__steps-overview li.active,
.c-quote__steps-overview li.current {
    color: #37a003
}

.c-quote__steps-overview li.active:before,
.c-quote__steps-overview li.current:before {
    border-color: #37a003
}

.c-quote__steps-overview li:first-child:before {
    content: "1"
}

.c-quote__steps-overview li:nth-child(2):before {
    content: "2"
}

.c-quote__steps-overview li:nth-child(3):before {
    content: "3"
}

.c-quote__steps h1 {
    color: #282827
}

.c-quote__special-message,
.c-quote__steps h2 {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 40px
}

.c-quote__steps h2 {
    font-size: 1rem;
    background-color: #282827;
    border-radius: 4px;
    font-weight: 600;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    line-height: 21px;
    margin-bottom: 16px;
    padding: 0 0 0 16px;
    position: relative;
    width: 100%
}

@media only screen and (max-width: 760px) {
    .c-quote__steps h2 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        padding-top: 8px
    }
}

.c-quote__special-message {
    background-color: #80d0f0;
    border-top-right-radius: 10px;
    padding: 0 40px;
    text-align: center
}

@media only screen and (max-width: 760px) {
    .c-quote__special-message {
        border-radius: 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 12px;
        padding: 8px 12px;
        width: 100%
    }
}

.c-quote__special-message span {
    min-height: 40px
}

.c-quote__summary {
    border: 2px solid transparent
}

.c-quote__summary.active {
    border: 2px solid #37a003
}

@media only screen and (max-width: 1279px) {
    .c-quote__summary.hidden {
        display: none
    }

    .c-quote__summary {
        width: 100%
    }
}

.c-quote__summary .details {
    font-size: .8125rem
}

.c-quote__summary .details.hide-edit .spec:after {
    content: ""
}

.c-quote__summary .spec {
    border-top: 1px solid #d8d8d8;
    cursor: pointer;
    margin-top: 4px;
    padding-top: 4px;
    position: relative
}

.c-quote__summary .spec:after {
    color: #777;
    content: "Edit";
    position: absolute;
    right: 0;
    top: 12px;
    text-decoration: underline
}

.c-quote__summary .spec-reorder:first-child,
.c-quote__summary .spec:first-child {
    margin-top: 4px
}

.c-quote__summary h3,
.c-quote__summary h5 {
    padding-right: 24px
}

.c-quote__summary {
    background-color: #f3f3f3;
    border-radius: 4px 4px 0 0;
    color: #282827;
    padding: 24px;
    width: 300px
}

@media only screen and (max-width: 1279px) {
    .c-quote__summary {
        width: 100%
    }
}

.c-quote__summary .details-reorder {
    font-size: .8125rem
}

.c-quote__summary .spec-reorder {
    border-top: 1px solid #d8d8d8;
    cursor: pointer;
    margin-top: 4px;
    padding-top: 4px;
    position: relative
}

.c-quote__summary .spec-reorder:after,
.js-remove-edit .details .spec:after {
    content: ""
}

.c-quote__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 16px
}

.c-quote__items.recommendations .c-quote__item {
    margin-bottom: 40px
}

@media only screen and (max-width: 479px) {
    .c-quote__items {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.c-quote__item {
    background-color: #fff;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    margin-bottom: 16px;
    margin-right: 16px;
    padding: 0 8px 8px;
    position: relative;
    text-align: center;
    width: 140px
}

@media only screen and (max-width: 1279px) {

    .c-quote__item,
    .c-quote__item-recommended {
        width: 174px
    }
}

@media only screen and (max-width: 1020px) {

    .c-quote__item,
    .c-quote__item-recommended {
        width: 156px
    }
}

@media only screen and (max-width: 760px) {

    .c-quote__item,
    .c-quote__item-recommended {
        width: 128px
    }
}

@media only screen and (max-width: 375px) {

    .c-quote__item,
    .c-quote__item-recommended {
        width: 122px
    }
}

.c-quote__item-recommended:last-child,
.c-quote__item:last-child {
    margin-right: 0
}

.c-quote__item label,
.c-quote__item-recommended label {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    color: #282827;
    letter-spacing: -.15px;
    line-height: 19px
}

.c-quote__item-recommended.active .recommendation,
.c-quote__item.active .recommendation {
    background-color: #37a003
}

.c-quote__item-recommended.active.bespoke .recommendation,
.c-quote__item.active.bespoke .recommendation {
    background-color: #a0a0a0
}

.c-quote__item-recommended img {
    max-height: 80px
}

.c-quote__item.weight {
    padding: 18px 8px
}

.c-quote__same-day-options {
    margin-top: 16px;
    padding-top: 24px;
    position: relative
}

.c-quote__same-day-options:before {
    background-color: #d8d8d8;
    content: "";
    height: 1px;
    position: absolute;
    top: 4px;
    width: 100%
}

.c-quote__note {
    background: url(/img/ui/icon-info-green.png) no-repeat 0 18px;
    background-size: 40px !important;
    color: #282827;
    padding-left: 52px;
    padding-top: 16px
}

.c-quote__note a {
    font-weight: 700;
    text-decoration: underline
}

.c-quote__note--personalisation {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #282827;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 8px;
    padding-bottom: 8px;
    padding-top: 9px
}

.c-quote__note--personalisation img {
    width: 40px
}

.c-quote__note--personalisation .pulse-css {
    border-radius: 20px;
    height: 40px;
    width: 40px;
    margin-right: 8px;
    position: relative
}

.c-quote__note--personalisation .pulse-css:after,
.c-quote__note--personalisation .pulse-css:before {
    border-radius: 20px;
    height: 40px;
    width: 40px;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    background: #37a003;
    bottom: 0;
    content: "";
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform-origin: center center;
    transform-origin: center center
}

.c-quote__note--personalisation .pulse-css:after {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s
}

.c-quote__item__wrap {
    margin-right: 16px;
    max-width: 140px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.c-quote__item__wrap:last-child {
    margin-right: 0
}

.c-quote__item__wrap h5 {
    font-size: .9375rem;
    font-weight: 600;
    margin-bottom: 4px;
    letter-spacing: -.6px
}

.c-quote__item__wrap .js-title.edge {
    letter-spacing: -.3px
}

.c-quote__item__wrap .js-title.safari {
    letter-spacing: -1px
}

.product-options .upload {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 24px
}

.c-quote__item-recommended {
    background-color: #fff;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    margin-bottom: 16px;
    margin-right: 16px;
    min-height: 120px;
    width: 100%;
    padding: 0 8px 8px;
    position: relative;
    text-align: center
}

.c-quote__item {
    cursor: pointer
}

.c-quote__item .recommendation {
    background: #a0a0a0;
    bottom: -26px;
    color: #fff;
    font-size: .75rem;
    left: 0;
    padding: 4px 0;
    position: absolute;
    width: 100%
}

.c-quote__tip-cta {
    font-size: 1.375rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: transparent;
    border-radius: 0 0 0 10px;
    color: #456aa6;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    height: 38px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    z-index: 2
}

.c-quote__tip-cta.active {
    background-color: #456aa6;
    color: #fff
}

.c-quote__item-icon {
    margin-bottom: 8px
}

.c-quote__item-icon img.max60 {
    display: block;
    margin: 0 auto;
    height: 60vm;
    height: 60vmin;
    max-height: 555px
}

.c-quote__item-recommended.active:after,
.c-quote__item.active:after {
    border: 2px solid #37a003;
    content: "";
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3
}

.c-quote__item.unavailable {
    opacity: .25
}

.c-quote__tip,
.c-quote__tip.open .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-quote__tip {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .75);
    height: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    top: -50px;
    -webkit-transition: opacity .5s, top .5s !important;
    transition: opacity .5s, top .5s !important;
    width: 0;
    z-index: 11
}

.c-quote__tip h3 {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    padding: 8px 0
}

.c-quote__tip.open {
    height: 100vh;
    opacity: 1;
    padding: 48px 40px 40px;
    top: 0;
    width: 100vw
}

@media only screen and (max-width: 479px) {
    .c-quote__tip.open {
        padding: 0
    }
}

.c-quote__tip.open .inner {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #456aa6;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, .16);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .16);
    max-width: 960px;
    padding: 40px 40px 24px;
    position: relative;
    text-align: left
}

@media only screen and (max-width: 760px) {
    .c-quote__tip.open .inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media only screen and (max-width: 479px) {
    .c-quote__tip.open .inner {
        border-radius: 0;
        border-width: 6px;
        height: 100%;
        min-height: 100%;
        padding: 32px 24px
    }
}

.c-quote__tip.open .tooltip-info {
    position: relative;
    width: 100%
}

@media only screen and (max-width: 479px) {
    .c-quote__tip.open .tooltip-info {
        height: 100%;
        min-height: 100%
    }
}

.c-quote__tip.open p {
    max-width: 500px
}

.c-quote__tip.open .c-quote__tip-close {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 16px
}

.c-quote__tip.open .buttons {
    margin-top: 24px
}

@media only screen and (max-width: 479px) {
    .c-quote__tip.open .buttons {
        position: absolute;
        bottom: 0
    }
}

.c-quote__tip.open .buttons .c-btn--green {
    margin-bottom: 16px;
    margin-right: 8px
}

.tooltip-image {
    margin-right: 32px
}

@media only screen and (max-width: 760px) {
    .tooltip-image {
        margin-top: 16px;
        margin-right: 0
    }
}

.c-quote__btns {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media only screen and (max-width: 760px) {
    .c-quote__btns {
        background-color: #fff;
        bottom: 0;
        -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, .15);
        box-shadow: 0 0 16px rgba(0, 0, 0, .15);
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        left: 0;
        margin: 0;
        padding: 12px 16px;
        position: fixed;
        right: 0;
        z-index: 9
    }
}

.c-quote__quoting {
    margin-bottom: 16px
}

.c-quote__quoting h2 {
    border-radius: 10px 12px 0 0;
    margin-bottom: 0
}

.c-quote__quoting .features {
    background-color: #f3f3f3;
    border: 2px solid #d8d8d8;
    border-top: none;
    border-radius: 0 0 10px 10px;
    padding: 24px 32px
}

@media only screen and (max-width: 479px) {
    .c-quote__quoting .features {
        padding: 16px
    }
}

.c-quote__quoting input[type=number] {
    display: inline-block;
    max-width: 290px;
    padding: 9px;
    width: 100%
}

@media only screen and (max-width: 1020px) {
    .c-quote__quoting input[type=number] {
        max-width: 150px
    }
}

@media only screen and (max-width: 760px) {
    .c-quote__quoting input[type=number] {
        max-width: 195px
    }
}

@media only screen and (max-width: 479px) {
    .c-quote__quoting input[type=number] {
        max-width: 100%
    }
}

.c-quote__quoting .quantity {
    padding-top: 4px
}

.c-quote__quote-details,
.c-quote__tooltip {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-quote__tooltip {
    font-size: .8125rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #456aa6;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    font-weight: 600;
    height: 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 16px
}

.c-quote__checkbox .c-quote__tooltip {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.c-quote__quote-details {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media only screen and (max-width: 760px) {
    .c-quote__quote-details {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-quote__quote-details .quantity-amount {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-right: 64px
}

@media only screen and (max-width: 1020px) {
    .c-quote__quote-details .quantity-amount {
        margin-right: 32px
    }
}

@media only screen and (max-width: 760px) {
    .c-quote__quote-details .quantity-amount {
        margin-right: 0;
        margin-bottom: 16px
    }
}

.c-quote__quote-details .quantity-amount p {
    padding-bottom: 4px
}

.c-quote__quote-details .quantity-amount__inputs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media only screen and (max-width: 479px) {
    .c-quote__quote-details .quantity-amount__inputs {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-quote__quote-details .c-quote__total-cost {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 320px
}

@media only screen and (max-width: 760px) {
    .c-quote__quote-details .c-quote__total-cost {
        max-width: 100%;
        padding-top: 16px;
        position: relative
    }

    .c-quote__quote-details .c-quote__total-cost:before {
        content: "";
        height: 2px;
        left: -32px;
        position: absolute;
        top: 0;
        background-color: #d8d8d8;
        width: calc(100% + 64px)
    }
}

@media only screen and (max-width: 479px) {
    .c-quote__quote-details .c-quote__total-cost:before {
        content: "";
        height: 2px;
        left: -16px;
        width: calc(100% + 32px)
    }
}

.c-quote__disabled * {
    cursor: not-allowed;
    opacity: .5
}

.c-quote__total-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media only screen and (max-width: 760px) {
    .c-quote__total-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-quote__total-cost {
    margin-top: 16px;
    max-width: 310px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100%
}

@media only screen and (max-width: 760px) {
    .c-quote__total-cost {
        max-width: 100%
    }
}

.c-quote__total-cost .cost-is {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    margin-right: 12px
}

.c-quote__total-cost .price {
    font-size: 2rem;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    letter-spacing: -.4px;
    line-height: 1;
    text-align: right
}

.c-quote__total-cost .price--small {
    font-size: 1.3125rem;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    letter-spacing: -.2px;
    line-height: 1;
    text-align: right
}

.c-quote__total-cost .per-unit {
    font-size: .8125rem;
    color: #777;
    font-weight: 600
}

@media only screen and (max-width: 760px) {
    .c-quote__total-cost .disclaimer {
        max-width: 320px
    }
}

.c-quote__total-wrap {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-bottom: 8px
}

@media only screen and (max-width: 760px) {
    .c-quote__total-wrap {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.c-quote__total-wrap:last-child {
    border-bottom: none
}

.c-quote__job-name,
.c-quote__pages-total {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-quote__pages-total {
    margin-bottom: 6px
}

.c-quote__pages-total label {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-quote__pages-total input {
    margin-right: 8px;
    max-width: 150px !important
}

.c-quote__pages-total input:hover {
    cursor: auto
}

.c-quote__pages-total .spine-thickness {
    color: #777;
    font-size: 13px;
    margin-left: 12px
}

.c-quote__pages-total .spine-thickness span {
    color: #282827;
    font-size: 16px;
    font-weight: 700
}

.c-quote__job-name {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 4px
}

.c-quote__job-name input {
    margin-bottom: 4px;
    margin-right: 12px
}

.c-has-sidebar,
.c-quote__choose-start img {
    margin-bottom: 16px
}

.c-has-sidebar .c-btn--quote-back,
.c-has-sidebar .c-btn--quote-next,
.modal {
    display: none
}

@media only screen and (max-width: 760px) {

    .c-has-sidebar .c-btn--quote-back,
    .c-has-sidebar .c-btn--quote-next {
        display: block;
        margin: 0 auto 16px
    }

    .c-has-sidebar .c-btn--quote-back.u-hidden,
    .c-has-sidebar .c-btn--quote-next.u-hidden {
        display: none !important
    }
}

@media only screen and (max-width: 479px) {

    .c-has-sidebar .c-btn--quote-back,
    .c-has-sidebar .c-btn--quote-next {
        min-width: 90%
    }
}

#delivery-modal {
    background-color: #fff;
    border: 2px solid red;
    left: 0;
    margin: 0 auto;
    max-width: 940px;
    padding: 32px;
    right: 0;
    width: 100% !important
}

#delivery-modal .contentWrap {
    height: 500px;
    overflow-y: auto
}

#modal-overlay {
    background-color: rgba(255, 255, 255, .8);
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9999
}

.modal {
    background-color: #fff;
    border: solid 1px #101820;
    min-height: 200px;
    padding: 5px;
    width: 940px;
    z-index: 10000
}

.modal .close {
    position: absolute;
    right: 25px;
    top: 2px;
    color: #101820
}

.c-quote__uploader .qq-upload-button {
    background-color: #e30513;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    margin-right: 24px;
    min-width: 300px;
    padding: 18px 64px;
    text-align: center
}

@media only screen and (max-width: 760px) {
    .c-quote__uploader .qq-upload-button {
        min-width: 280px
    }
}

@media only screen and (max-width: 479px) {
    .c-quote__uploader .qq-upload-button {
        display: block;
        margin-right: 0;
        min-width: unset;
        width: 100%
    }
}

.c-quote__uploader .flexed {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-quote__uploader .qq-upload-list-selector {
    max-width: 850px
}

.c-quote__uploader .qq-upload-success {
    font-size: .9375rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #f3f3f3 url(/img/rearange.png) no-repeat right 20px top 14px;
    border: 2px solid #777;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 12px
}

@media only screen and (max-width: 479px) {
    .c-quote__uploader .qq-upload-success {
        background-position: right 8px top 14px;
        padding: 8px
    }
}

.c-quote__uploader #html5-uploader #upload-errors-list li,
.c-quote__uploader #html5-uploader .qq-upload-list li,
.c-quote__uploader #html5-uploader-csv #upload-errors-list li,
.c-quote__uploader #html5-uploader-csv .qq-upload-list li {
    cursor: row-resize
}

.c-quote__uploader #html5-uploader .info,
.c-quote__uploader #html5-uploader-csv .info {
    font-size: .9375rem;
    color: #777;
    display: none;
    line-height: 18px;
    margin-bottom: 2em;
    max-width: 650px
}

.c-quote__uploader #html5-uploader .info span.icon,
.c-quote__uploader #html5-uploader-csv .info span.icon {
    display: inline-block;
    width: 20px;
    height: 18px;
    background: url(/img/sprites.png) 0 -1464px no-repeat;
    text-indent: -99999px
}

.c-quote__uploader .qq-upload-list {
    margin: 0 0 24px;
    list-style: none
}

.c-quote__uploader .qty {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #d8d8d8;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    font-weight: 700;
    height: 25px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 16px;
    width: 25px
}

@media only screen and (max-width: 760px) {
    .c-quote__uploader .qty {
        margin-right: 8px
    }
}

.c-quote__uploader .qq-upload-file {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    margin-right: 16px;
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media only screen and (max-width: 760px) {
    .c-quote__uploader .qq-upload-file {
        max-width: 200px
    }
}

@media only screen and (max-width: 479px) {
    .c-quote__uploader .qq-upload-file {
        max-width: 160px
    }
}

.c-quote__uploader .qq-upload-size {
    margin-right: 16px
}

@media only screen and (max-width: 760px) {
    .c-quote__uploader .qq-upload-size {
        display: none
    }
}

.c-quote__uploader .qq-upload-cancel,
.c-quote__uploader .qq-upload-delete {
    font-size: .8125rem;
    color: #777;
    line-height: 19px;
    text-decoration: underline
}

.c-quote__uploader .qq-upload-delete {
    color: #e30513;
    padding: 0 50px 0 20px
}

@media only screen and (max-width: 479px) {
    .c-quote__uploader .qq-upload-delete {
        padding: 0 30px 0 0
    }
}

.c-quote__uploader .qq-hide {
    display: none
}

.c-qq-text {
    font-size: .8125rem;
    color: #777;
    line-height: 19px;
    letter-spacing: -.1px;
    max-width: 640px;
    padding-bottom: 8px;
    padding-top: 18px
}

#fileUploadsTitle,
#fileUploadsTitle-csv {
    font-size: 1.3125rem;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    margin-top: 24px
}

.c-quote__other-options li,
.c-quote__other-options ul,
.disclaimer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.disclaimer {
    font-size: .8125rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #777;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 16px auto 8px;
    padding-left: 36px;
    position: relative
}

@media only screen and (max-width: 760px) {
    .disclaimer {
        max-width: 290px
    }
}

.disclaimer:before {
    background-image: url(/img/ui/icon-info-green.svg);
    background-repeat: no-repeat;
    content: "";
    height: 24px;
    left: 0;
    margin-right: 12px;
    position: absolute;
    top: 8px;
    width: 24px
}

.disclaimer p {
    line-height: 19px
}

@media only screen and (max-width: 760px) {
    .c-quote__upload-area {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-quote__upload-area p {
    font-size: .8125rem;
    color: #777;
    line-height: 19px;
    letter-spacing: -.1px;
    padding-bottom: 8px;
    padding-top: 18px
}

@media only screen and (max-width: 760px) {
    .c-quote__upload-area p {
        padding-top: 12px
    }
}

.c-quote__other-options ul {
    list-style: none
}

@media only screen and (max-width: 760px) {
    .c-quote__other-options ul {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-quote__other-options li {
    border: 2px solid #d8d8d8;
    border-radius: 10px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-top: 16px;
    padding: 16px
}

.c-quote__other-options li:first-child {
    margin-right: 8px
}

@media only screen and (max-width: 760px) {
    .c-quote__other-options li:first-child {
        margin-bottom: 12px;
        margin-right: 0
    }
}

.c-quote__other-options li:last-child {
    margin-left: 8px
}

@media only screen and (max-width: 760px) {
    .c-quote__other-options li:last-child {
        margin-left: 0
    }
}

.c-quote__other-options li.selected {
    border-color: #37a003
}

.c-quote__other-options .c-radio {
    cursor: pointer;
    margin-right: 16px
}

.c-quote__other-options p,
.c-quote__other-options small {
    color: #777
}

.c-quote__other-options small {
    display: block;
    letter-spacing: -.1px;
    margin-top: 12px
}

.c-quote__other-options a,
.c-quote__other-options span {
    color: #282827
}

.c-quote__choose-start,
.c-quote__choose-start li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-quote__choose-start {
    list-style: none;
    margin: 16px 0 24px;
    position: relative
}

.c-quote__choose-start:after {
    content: "or";
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    font-size: 21px;
    font-weight: 600;
    left: 48.9%;
    position: absolute;
    top: 40%
}

@media only screen and (max-width: 1020px) {
    .c-quote__choose-start:after {
        left: 48.5%
    }
}

@media only screen and (max-width: 760px) {
    .c-quote__choose-start {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .c-quote__choose-start:after {
        left: 49%;
        top: 48%
    }
}

@media only screen and (max-width: 479px) {
    .c-quote__choose-start:after {
        top: 46%
    }
}

@media only screen and (max-width: 375px) {
    .c-quote__choose-start:after {
        margin-top: 12px;
        top: 49%
    }
}

.c-quote__choose-start li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid transparent;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    cursor: pointer;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0 40px 32px;
    text-align: center
}

@media only screen and (max-width: 760px) {
    .c-quote__choose-start li {
        padding: 0 24px 16px
    }
}

@media only screen and (max-width: 479px) {
    .c-quote__choose-start li {
        padding: 0 8px 16px
    }
}

.c-quote__choose-start li:first-child {
    margin-right: 20px
}

@media only screen and (max-width: 760px) {
    .c-quote__choose-start li:first-child {
        margin-bottom: 32px;
        margin-right: 0
    }
}

.c-quote__choose-start li:last-child {
    margin-left: 20px
}

@media only screen and (max-width: 760px) {
    .c-quote__choose-start li:last-child {
        margin-left: 0
    }
}

.c-quote__choose-start li.active {
    border: 2px solid #37a003
}

.c-quote__choose-start h3 {
    font-weight: 600;
    margin-bottom: 4px
}

.c-quote__choose-start p {
    color: #777;
    width: 100%
}

.c-quote__recommended-options h4,
.production-conflict-info {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-quote__recommended-options h4 {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f3f3f3;
    border-radius: 4px;
    color: #282827;
    font-weight: 600;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    line-height: 21px;
    margin-bottom: 16px;
    min-height: 40px;
    padding: 0 0 0 16px;
    width: 100%
}

.c-quote__colours .c-quote__item,
.c-quote__luxury-papers .c-quote__item {
    padding-left: 0;
    padding-right: 0
}

@media only screen and (max-width: 1279px) {
    .js-quote-sidebar .inner-wrapper-sticky {
        position: static !important;
        -webkit-transform: none !important;
        transform: none !important
    }

    .js-quote-sidebar #summary-outer {
        height: auto !important;
        margin-bottom: 24px
    }
}

.production-conflict-info {
    background-color: #e30513;
    color: #fff;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 16px auto 8px;
    padding-left: 36px
}

@media only screen and (max-width: 760px) {
    .production-conflict-info {
        max-width: 290px
    }
}

.production-conflict-info:before {
    background-image: url(/img/ui/icon-info-white.svg);
    background-repeat: no-repeat;
    content: "";
    height: 24px;
    left: 16px;
    margin-right: 12px;
    position: absolute;
    top: 16px;
    width: 24px
}

.production-conflict-info p {
    line-height: 19px;
    padding: 16px 32px 16px 16px
}

.production-conflict-info-small {
    font-size: .8125rem;
    background: url(/img/ui/icon-production-conflict.svg) no-repeat 16px 12px #d8d8d8;
    margin-top: 12px;
    max-width: 410px;
    padding: 8px 8px 8px 52px;
    position: relative
}

.production-conflict-info-small p {
    letter-spacing: -.1px;
    line-height: 19px
}

.production-conflict-info-small:before {
    border-color: transparent transparent #d8d8d8;
    border-style: solid;
    border-width: 0 8px 8px;
    content: "";
    height: 0;
    left: 6px;
    position: absolute;
    top: -8px;
    width: 0
}

.c-quote__production-wrap {
    margin-right: 32px;
    padding-right: 32px;
    position: relative
}

.c-quote__production-wrap:after {
    background-color: #d8d8d8;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 2px
}

@media only screen and (max-width: 760px) {
    .c-quote__production-wrap {
        margin-bottom: 24px
    }

    .c-quote__production-wrap:after {
        height: 0
    }
}

@media only screen and (max-width: 479px) {
    .c-quote__production-wrap {
        margin-right: 0
    }
}

.c-quote__production-speed {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media only screen and (max-width: 760px) {
    .c-quote__production-speed {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-quote__artwork-error,
.c-quote__production-error {
    background-color: #d8d8d8;
    background-image: url(/img/ui/icon-production-conflict.svg);
    background-repeat: no-repeat;
    background-position: left 8px top 12px;
    color: #282827;
    font-size: 13px;
    margin-top: 8px;
    max-width: 280px;
    padding: 8px 12px 8px 40px;
    position: relative
}

.c-quote__production-error:before {
    border-bottom: 12px solid #d8d8d8;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: "";
    height: 0;
    left: 8px;
    position: absolute;
    top: -12px;
    width: 0
}

.c-quote__artwork-error {
    background-position: left 8px center;
    margin-top: 16px;
    max-width: 360px;
    padding: 16px 12px 16px 40px
}

.fade-animation {
    -webkit-animation: fade 1.5s infinite;
    animation: fade 1.5s infinite
}

#delivery-info-tooltip,
.js-remove-express-production-via-quote:hover {
    cursor: pointer
}

.js-remove-express-production-via-quote {
    color: #e30513;
    text-decoration: underline
}

.js-production-speed-tooltip .c-tooltip__trigger {
    top: 2px
}

.js-production-speed-tooltip svg {
    border-radius: 8px
}

#step3 table.dataTable {
    border-radius: 6px;
    overflow: hidden
}

#step3 .dataTables_wrapper thead th {
    background-color: #282827;
    color: #fff;
    text-align: center
}

#step3 .dataTables_wrapper tbody td {
    text-align: center;
    border-right: 1px solid #ddd;
    background-color: #fff
}

#step3 .dataTables_wrapper tbody td.highlight {
    background-color: #f5f5f5
}

#step3 .dataTables_wrapper tbody td.selected {
    background-color: #37a0038a
}

#step3 .dataTables_wrapper tbody td:hover {
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 1px 2px #37a003;
    box-shadow: inset 0 0 1px 2px #37a003
}

#step3 .dataTables_wrapper tbody td:last-child {
    border-right: 0
}

#step3 .dataTable tbody td {
    font-size: 14px
}

#step3 table.dataTable.no-footer {
    border-bottom: 1px solid #ddd
}

#step3 .dataTables_wrapper .dataTables_paginate {
    float: none;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

#step3 .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: 0 0 !important;
    border-color: transparent !important;
    color: #282827 !important
}

#step3 .dataTables_wrapper .dataTables_paginate .paginate_button {
    font-weight: 700;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#step3 .dataTables_wrapper .dataTables_paginate .paginate_button span {
    background-color: #37a003;
    color: #fff !important;
    overflow: hidden;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    max-height: 20px;
    max-width: 20px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    font-size: 18px
}

#step3 .dataTables_wrapper .dataTables_paginate .paginate_button.previous span {
    margin-right: 8px
}

#step3 .dataTables_wrapper .dataTables_paginate .paginate_button.next span {
    margin-left: 8px;
    line-height: 1
}

.hide-when-unavailable .unavailable {
    display: none
}

.c-slick {
    background-color: rgba(0, 0, 0, .7);
    display: table;
    height: 100%;
    left: 0;
    margin-bottom: 4px;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 1111
}

.c-slick.is-open {
    opacity: 1;
    visibility: visible
}

.c-slick__basket-features article,
.c-slick__home-features article {
    float: left;
    max-width: 320px
}

@media only screen and (max-width: 1020px) {

    .c-slick__basket-features article,
    .c-slick__home-features article {
        max-width: 325px
    }
}

@media only screen and (max-width: 760px) {

    .c-slick__basket-features article,
    .c-slick__home-features article {
        max-width: 380px
    }
}

@media only screen and (max-width: 479px) {

    .c-slick__basket-features article,
    .c-slick__home-features article {
        max-width: 480px
    }
}

@media only screen and (max-width: 375px) {

    .c-slick__basket-features article,
    .c-slick__home-features article {
        max-width: 375px
    }
}

@media only screen and (max-width: 1020px) {

    .c-slick__basket-features .o-grid,
    .c-slick__home-features .o-grid {
        margin: 0
    }

    .c-slick__basket-features,
    .c-slick__basket-features .o-grid__item,
    .c-slick__home-features,
    .c-slick__home-features .o-grid__item {
        padding: 0
    }
}

.c-slick__basket-features .slide,
.c-slick__home-features .slide {
    background-color: #f3f3f3;
    margin: 0 2px;
    text-align: center
}

@media only screen and (max-width: 479px) {

    .c-slick__basket-features .slide,
    .c-slick__home-features .slide {
        margin: 0
    }
}

.c-slick__basket-features h3,
.c-slick__home-features h3 {
    color: #282827
}

.c-slick__basket-features footer,
.c-slick__home-features footer {
    padding: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 108px
}

.c-slick__basket-features footer {
    min-height: 124px
}

.c-slick__basket-features .description,
.c-slick__basket-features .price,
.c-slick__home-features .description,
.c-slick__home-features .price {
    font-size: .8125rem;
    color: #777
}

.c-slick__basket-features .price,
.c-slick__home-features .price,
.c-warning,
.c-warning-orange {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.c-slick__basket-features .price,
.c-slick__home-features .price {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto
}

.c-slick__basket-features strong,
.c-slick__home-features strong {
    font-size: 1.3125rem;
    color: #282827;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    margin-left: 4px
}

.c-slick__basket-features article a,
.c-slick__basket-features section,
.c-slick__home-features article a,
.c-slick__home-features section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto
}

.c-slick__basket-features .j-slick-home-featured,
.c-slick__basket-features .slick-track,
.c-slick__home-features .j-slick-home-featured,
.c-slick__home-features .slick-track {
    max-height: 284px;
    overflow: hidden
}

@media only screen and (max-width: 760px) {

    .c-slick__basket-features .j-slick-home-featured,
    .c-slick__basket-features .slick-track,
    .c-slick__home-features .j-slick-home-featured,
    .c-slick__home-features .slick-track {
        max-height: 318px
    }
}

@media only screen and (max-width: 479px) {

    .c-slick__basket-features .j-slick-home-featured,
    .c-slick__basket-features .slick-track,
    .c-slick__home-features .j-slick-home-featured,
    .c-slick__home-features .slick-track {
        max-height: 375px
    }
}

.c-slick__basket-features .slick-track .slick-slide,
.c-slick__home-features .slick-track .slick-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto
}

.c-slick__basket-features .slick-next,
.c-slick__home-features .slick-next {
    right: -14px
}

@media only screen and (max-width: 1020px) {

    .c-slick__basket-features .slick-next,
    .c-slick__home-features .slick-next {
        background-color: #fff;
        border-radius: 10px 0 0 10px;
        height: 38px;
        right: 0;
        top: 60px;
        width: 30px
    }
}

.c-slick__basket-features .slick-prev,
.c-slick__home-features .slick-prev {
    left: -14px
}

@media only screen and (max-width: 1020px) {

    .c-slick__basket-features .slick-prev,
    .c-slick__home-features .slick-prev {
        background-color: #fff;
        border-radius: 0 10px 10px 0;
        height: 38px;
        left: 0;
        top: 60px;
        width: 30px
    }
}

.c-slick__basket-features h3 {
    font-size: large;
    font-weight: 500
}

.c-slick__basket-features article a {
    display: block
}

.c-slick__home-features article a {
    display: inherit
}

.c-warning,
.c-warning-orange {
    background-color: #e30513;
    color: #fff;
    opacity: 0;
    top: -300px;
    position: fixed;
    text-align: center;
    -webkit-transition: all .4s;
    transition: all .4s;
    width: 100%
}

.c-warning p,
.c-warning-orange p {
    max-width: 900px;
    padding: 16px 32px
}

.c-warning-orange {
    background-color: #ffd866;
    color: #282827
}

.c-warning__remove {
    position: absolute;
    right: 24px;
    top: 16px
}

@media only screen and (max-width: 760px) {
    .c-warning__remove {
        right: 16px
    }
}

.c-warning.active {
    opacity: 1;
    top: 0;
    z-index: 1004
}

.u-table {
    display: table
}

.u-table-cell {
    display: table-cell
}

.u-table-row {
    display: table-row
}

.u-invisible {
    visibility: hidden
}

.u-visible {
    visibility: visible
}

.u-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.u-inline {
    display: inline
}

.u-inline-block {
    display: inline-block
}

.u-block {
    display: block
}

.u-hidden {
    display: none
}

@media print {
    .u-print-block {
        display: block
    }

    .u-print-inline-block {
        display: inline-block
    }

    .u-print-hidden {
        display: none
    }
}

@media only screen and (max-width: 1279px) {
    .u-table\@xl {
        display: table
    }

    .u-table-cell\@xl {
        display: table-cell
    }

    .u-table-row\@xl {
        display: table-row
    }

    .u-invisible\@xl {
        visibility: hidden
    }

    .u-visible\@xl {
        visibility: visible
    }

    .u-inline\@xl {
        display: inline
    }

    .u-inline-block\@xl {
        display: inline-block
    }

    .u-block\@xl {
        display: block
    }

    .u-hidden\@xl {
        display: none
    }
}

@media only screen and (max-width: 1020px) {
    .u-table\@l {
        display: table
    }

    .u-table-cell\@l {
        display: table-cell
    }

    .u-table-row\@l {
        display: table-row
    }

    .u-invisible\@l {
        visibility: hidden
    }

    .u-visible\@l {
        visibility: visible
    }

    .u-inline\@l {
        display: inline
    }

    .u-inline-block\@l {
        display: inline-block
    }

    .u-block\@l {
        display: block
    }

    .u-hidden\@l {
        display: none
    }
}

@media only screen and (max-width: 760px) {
    .u-table\@m {
        display: table
    }

    .u-table-cell\@m {
        display: table-cell
    }

    .u-table-row\@m {
        display: table-row
    }

    .u-invisible\@m {
        visibility: hidden
    }

    .u-visible\@m {
        visibility: visible
    }

    .u-inline\@m {
        display: inline
    }

    .u-inline-block\@m {
        display: inline-block
    }

    .u-block\@m {
        display: block
    }

    .u-hidden\@m {
        display: none
    }
}

@media only screen and (max-width: 479px) {
    .u-table\@s {
        display: table
    }

    .u-table-cell\@s {
        display: table-cell
    }

    .u-table-row\@s {
        display: table-row
    }

    .u-invisible\@s {
        visibility: hidden
    }

    .u-visible\@s {
        visibility: visible
    }

    .u-inline\@s {
        display: inline
    }

    .u-inline-block\@s {
        display: inline-block
    }

    .u-block\@s {
        display: block
    }

    .u-hidden\@s {
        display: none
    }
}

@media only screen and (max-width: 375px) {
    .u-table\@xs {
        display: table
    }

    .u-table-cell\@xs {
        display: table-cell
    }

    .u-table-row\@xs {
        display: table-row
    }

    .u-invisible\@xs {
        visibility: hidden
    }

    .u-visible\@xs {
        visibility: visible
    }

    .u-inline\@xs {
        display: inline
    }

    .u-inline-block\@xs {
        display: inline-block
    }

    .u-block\@xs {
        display: block
    }

    .u-hidden\@xs {
        display: none
    }
}

.u-p0 {
    padding: 0
}

.u-p1 {
    padding: 8px
}

.u-p2 {
    padding: 16px
}

.u-p3 {
    padding: 24px
}

.u-p4 {
    padding: 32px
}

.u-p5 {
    padding: 40px
}

.u-p6 {
    padding: 48px
}

.u-p7 {
    padding: 56px
}

.u-p8 {
    padding: 64px
}

.u-p9 {
    padding: 72px
}

.u-p10 {
    padding: 80px
}

.u-p11 {
    padding: 88px
}

.u-p12 {
    padding: 96px
}

.u-p13 {
    padding: 104px
}

.u-p14 {
    padding: 112px
}

.u-p15 {
    padding: 120px
}

.u-p16 {
    padding: 128px
}

.u-p-x0 {
    padding-left: 0;
    padding-right: 0
}

.u-p-x1 {
    padding-left: 8px;
    padding-right: 8px
}

.u-p-x2 {
    padding-left: 16px;
    padding-right: 16px
}

.u-p-x3 {
    padding-left: 24px;
    padding-right: 24px
}

.u-p-x4 {
    padding-left: 32px;
    padding-right: 32px
}

.u-p-x5 {
    padding-left: 40px;
    padding-right: 40px
}

.u-p-x6 {
    padding-left: 48px;
    padding-right: 48px
}

.u-p-x7 {
    padding-left: 56px;
    padding-right: 56px
}

.u-p-x8 {
    padding-left: 64px;
    padding-right: 64px
}

.u-p-x9 {
    padding-left: 72px;
    padding-right: 72px
}

.u-p-x10 {
    padding-left: 80px;
    padding-right: 80px
}

.u-p-x11 {
    padding-left: 88px;
    padding-right: 88px
}

.u-p-x12 {
    padding-left: 96px;
    padding-right: 96px
}

.u-p-x13 {
    padding-left: 104px;
    padding-right: 104px
}

.u-p-x14 {
    padding-left: 112px;
    padding-right: 112px
}

.u-p-x15 {
    padding-left: 120px;
    padding-right: 120px
}

.u-p-x16 {
    padding-left: 128px;
    padding-right: 128px
}

.u-p-y0 {
    padding-top: 0;
    padding-bottom: 0
}

.u-p-y1 {
    padding-top: 8px;
    padding-bottom: 8px
}

.u-p-y2 {
    padding-top: 16px;
    padding-bottom: 16px
}

.u-p-y3 {
    padding-top: 24px;
    padding-bottom: 24px
}

.u-p-y4 {
    padding-top: 32px;
    padding-bottom: 32px
}

.u-p-y5 {
    padding-top: 40px;
    padding-bottom: 40px
}

.u-p-y6 {
    padding-top: 48px;
    padding-bottom: 48px
}

.u-p-y7 {
    padding-top: 56px;
    padding-bottom: 56px
}

.u-p-y8 {
    padding-top: 64px;
    padding-bottom: 64px
}

.u-p-y9 {
    padding-top: 72px;
    padding-bottom: 72px
}

.u-p-y10 {
    padding-top: 80px;
    padding-bottom: 80px
}

.u-p-y11 {
    padding-top: 88px;
    padding-bottom: 88px
}

.u-p-y12 {
    padding-top: 96px;
    padding-bottom: 96px
}

.u-p-y13 {
    padding-top: 104px;
    padding-bottom: 104px
}

.u-p-y14 {
    padding-top: 112px;
    padding-bottom: 112px
}

.u-p-y15 {
    padding-top: 120px;
    padding-bottom: 120px
}

.u-p-y16 {
    padding-top: 128px;
    padding-bottom: 128px
}

.u-p-t0 {
    padding-top: 0
}

.u-p-t1 {
    padding-top: 8px
}

.u-p-t2 {
    padding-top: 16px
}

.u-p-t3 {
    padding-top: 24px
}

.u-p-t4 {
    padding-top: 32px
}

.u-p-t5 {
    padding-top: 40px
}

.u-p-t6 {
    padding-top: 48px
}

.u-p-t7 {
    padding-top: 56px
}

.u-p-t8 {
    padding-top: 64px
}

.u-p-t9 {
    padding-top: 72px
}

.u-p-t10 {
    padding-top: 80px
}

.u-p-t11 {
    padding-top: 88px
}

.u-p-t12 {
    padding-top: 96px
}

.u-p-t13 {
    padding-top: 104px
}

.u-p-t14 {
    padding-top: 112px
}

.u-p-t15 {
    padding-top: 120px
}

.u-p-t16 {
    padding-top: 128px
}

.u-p-r0 {
    padding-right: 0
}

.u-p-r1 {
    padding-right: 8px
}

.u-p-r2 {
    padding-right: 16px
}

.u-p-r3 {
    padding-right: 24px
}

.u-p-r4 {
    padding-right: 32px
}

.u-p-r5 {
    padding-right: 40px
}

.u-p-r6 {
    padding-right: 48px
}

.u-p-r7 {
    padding-right: 56px
}

.u-p-r8 {
    padding-right: 64px
}

.u-p-r9 {
    padding-right: 72px
}

.u-p-r10 {
    padding-right: 80px
}

.u-p-r11 {
    padding-right: 88px
}

.u-p-r12 {
    padding-right: 96px
}

.u-p-r13 {
    padding-right: 104px
}

.u-p-r14 {
    padding-right: 112px
}

.u-p-r15 {
    padding-right: 120px
}

.u-p-r16 {
    padding-right: 128px
}

.u-p-b0 {
    padding-bottom: 0
}

.u-p-b1 {
    padding-bottom: 8px
}

.u-p-b2 {
    padding-bottom: 16px
}

.u-p-b3 {
    padding-bottom: 24px
}

.u-p-b4 {
    padding-bottom: 32px
}

.u-p-b5 {
    padding-bottom: 40px
}

.u-p-b6 {
    padding-bottom: 48px
}

.u-p-b7 {
    padding-bottom: 56px
}

.u-p-b8 {
    padding-bottom: 64px
}

.u-p-b9 {
    padding-bottom: 72px
}

.u-p-b10 {
    padding-bottom: 80px
}

.u-p-b11 {
    padding-bottom: 88px
}

.u-p-b12 {
    padding-bottom: 96px
}

.u-p-b13 {
    padding-bottom: 104px
}

.u-p-b14 {
    padding-bottom: 112px
}

.u-p-b15 {
    padding-bottom: 120px
}

.u-p-b16 {
    padding-bottom: 128px
}

.u-p-l0 {
    padding-left: 0
}

.u-p-l1 {
    padding-left: 8px
}

.u-p-l2 {
    padding-left: 16px
}

.u-p-l3 {
    padding-left: 24px
}

.u-p-l4 {
    padding-left: 32px
}

.u-p-l5 {
    padding-left: 40px
}

.u-p-l6 {
    padding-left: 48px
}

.u-p-l7 {
    padding-left: 56px
}

.u-p-l8 {
    padding-left: 64px
}

.u-p-l9 {
    padding-left: 72px
}

.u-p-l10 {
    padding-left: 80px
}

.u-p-l11 {
    padding-left: 88px
}

.u-p-l12 {
    padding-left: 96px
}

.u-p-l13 {
    padding-left: 104px
}

.u-p-l14 {
    padding-left: 112px
}

.u-p-l15 {
    padding-left: 120px
}

.u-p-l16 {
    padding-left: 128px
}

.u-m0 {
    margin: 0
}

.u-m1 {
    margin: 8px
}

.u-m2 {
    margin: 16px
}

.u-m3 {
    margin: 24px
}

.u-m4 {
    margin: 32px
}

.u-m5 {
    margin: 40px
}

.u-m6 {
    margin: 48px
}

.u-m7 {
    margin: 56px
}

.u-m8 {
    margin: 64px
}

.u-m9 {
    margin: 72px
}

.u-m10 {
    margin: 80px
}

.u-m11 {
    margin: 88px
}

.u-m12 {
    margin: 96px
}

.u-m13 {
    margin: 104px
}

.u-m14 {
    margin: 112px
}

.u-m15 {
    margin: 120px
}

.u-m16 {
    margin: 128px
}

.u-m-x0 {
    margin-left: 0;
    margin-right: 0
}

.u-m-x1 {
    margin-left: 8px;
    margin-right: 8px
}

.u-m-x2 {
    margin-left: 16px;
    margin-right: 16px
}

.u-m-x3 {
    margin-left: 24px;
    margin-right: 24px
}

.u-m-x4 {
    margin-left: 32px;
    margin-right: 32px
}

.u-m-x5 {
    margin-left: 40px;
    margin-right: 40px
}

.u-m-x6 {
    margin-left: 48px;
    margin-right: 48px
}

.u-m-x7 {
    margin-left: 56px;
    margin-right: 56px
}

.u-m-x8 {
    margin-left: 64px;
    margin-right: 64px
}

.u-m-x9 {
    margin-left: 72px;
    margin-right: 72px
}

.u-m-x10 {
    margin-left: 80px;
    margin-right: 80px
}

.u-m-x11 {
    margin-left: 88px;
    margin-right: 88px
}

.u-m-x12 {
    margin-left: 96px;
    margin-right: 96px
}

.u-m-x13 {
    margin-left: 104px;
    margin-right: 104px
}

.u-m-x14 {
    margin-left: 112px;
    margin-right: 112px
}

.u-m-x15 {
    margin-left: 120px;
    margin-right: 120px
}

.u-m-x16 {
    margin-left: 128px;
    margin-right: 128px
}

.u-m-y0 {
    margin-top: 0;
    margin-bottom: 0
}

.u-m-y1 {
    margin-top: 8px;
    margin-bottom: 8px
}

.u-m-y2 {
    margin-top: 16px;
    margin-bottom: 16px
}

.u-m-y3 {
    margin-top: 24px;
    margin-bottom: 24px
}

.u-m-y4 {
    margin-top: 32px;
    margin-bottom: 32px
}

.u-m-y5 {
    margin-top: 40px;
    margin-bottom: 40px
}

.u-m-y6 {
    margin-top: 48px;
    margin-bottom: 48px
}

.u-m-y7 {
    margin-top: 56px;
    margin-bottom: 56px
}

.u-m-y8 {
    margin-top: 64px;
    margin-bottom: 64px
}

.u-m-y9 {
    margin-top: 72px;
    margin-bottom: 72px
}

.u-m-y10 {
    margin-top: 80px;
    margin-bottom: 80px
}

.u-m-y11 {
    margin-top: 88px;
    margin-bottom: 88px
}

.u-m-y12 {
    margin-top: 96px;
    margin-bottom: 96px
}

.u-m-y13 {
    margin-top: 104px;
    margin-bottom: 104px
}

.u-m-y14 {
    margin-top: 112px;
    margin-bottom: 112px
}

.u-m-y15 {
    margin-top: 120px;
    margin-bottom: 120px
}

.u-m-y16 {
    margin-top: 128px;
    margin-bottom: 128px
}

.u-m-t0 {
    margin-top: 0
}

.u-m-t1 {
    margin-top: 8px
}

.u-m-t2 {
    margin-top: 16px
}

.u-m-t3 {
    margin-top: 24px
}

.u-m-t4 {
    margin-top: 32px
}

.u-m-t5 {
    margin-top: 40px
}

.u-m-t6 {
    margin-top: 48px
}

.u-m-t7 {
    margin-top: 56px
}

.u-m-t8 {
    margin-top: 64px
}

.u-m-t9 {
    margin-top: 72px
}

.u-m-t10 {
    margin-top: 80px
}

.u-m-t11 {
    margin-top: 88px
}

.u-m-t12 {
    margin-top: 96px
}

.u-m-t13 {
    margin-top: 104px
}

.u-m-t14 {
    margin-top: 112px
}

.u-m-t15 {
    margin-top: 120px
}

.u-m-t16 {
    margin-top: 128px
}

.u-m-r0 {
    margin-right: 0
}

.u-m-r1 {
    margin-right: 8px
}

.u-m-r2 {
    margin-right: 16px
}

.u-m-r3 {
    margin-right: 24px
}

.u-m-r4 {
    margin-right: 32px
}

.u-m-r5 {
    margin-right: 40px
}

.u-m-r6 {
    margin-right: 48px
}

.u-m-r7 {
    margin-right: 56px
}

.u-m-r8 {
    margin-right: 64px
}

.u-m-r9 {
    margin-right: 72px
}

.u-m-r10 {
    margin-right: 80px
}

.u-m-r11 {
    margin-right: 88px
}

.u-m-r12 {
    margin-right: 96px
}

.u-m-r13 {
    margin-right: 104px
}

.u-m-r14 {
    margin-right: 112px
}

.u-m-r15 {
    margin-right: 120px
}

.u-m-r16 {
    margin-right: 128px
}

.u-m-b0 {
    margin-bottom: 0
}

.u-m-b1 {
    margin-bottom: 8px
}

.u-m-b2 {
    margin-bottom: 16px
}

.u-m-b3 {
    margin-bottom: 24px
}

.u-m-b4 {
    margin-bottom: 32px
}

.u-m-b5 {
    margin-bottom: 40px
}

.u-m-b6 {
    margin-bottom: 48px
}

.u-m-b7 {
    margin-bottom: 56px
}

.u-m-b8 {
    margin-bottom: 64px
}

.u-m-b9 {
    margin-bottom: 72px
}

.u-m-b10 {
    margin-bottom: 80px
}

.u-m-b11 {
    margin-bottom: 88px
}

.u-m-b12 {
    margin-bottom: 96px
}

.u-m-b13 {
    margin-bottom: 104px
}

.u-m-b14 {
    margin-bottom: 112px
}

.u-m-b15 {
    margin-bottom: 120px
}

.u-m-b16 {
    margin-bottom: 128px
}

.u-m-l0 {
    margin-left: 0
}

.u-m-l1 {
    margin-left: 8px
}

.u-m-l2 {
    margin-left: 16px
}

.u-m-l3 {
    margin-left: 24px
}

.u-m-l4 {
    margin-left: 32px
}

.u-m-l5 {
    margin-left: 40px
}

.u-m-l6 {
    margin-left: 48px
}

.u-m-l7 {
    margin-left: 56px
}

.u-m-l8 {
    margin-left: 64px
}

.u-m-l9 {
    margin-left: 72px
}

.u-m-l10 {
    margin-left: 80px
}

.u-m-l11 {
    margin-left: 88px
}

.u-m-l12 {
    margin-left: 96px
}

.u-m-l13 {
    margin-left: 104px
}

.u-m-l14 {
    margin-left: 112px
}

.u-m-l15 {
    margin-left: 120px
}

.u-m-l16 {
    margin-left: 128px
}

@media only screen and (max-width: 1279px) {
    .u-p0\@xl {
        padding: 0
    }

    .u-p1\@xl {
        padding: 8px
    }

    .u-p2\@xl {
        padding: 16px
    }

    .u-p3\@xl {
        padding: 24px
    }

    .u-p4\@xl {
        padding: 32px
    }

    .u-p5\@xl {
        padding: 40px
    }

    .u-p6\@xl {
        padding: 48px
    }

    .u-p7\@xl {
        padding: 56px
    }

    .u-p8\@xl {
        padding: 64px
    }

    .u-p9\@xl {
        padding: 72px
    }

    .u-p10\@xl {
        padding: 80px
    }

    .u-p11\@xl {
        padding: 88px
    }

    .u-p12\@xl {
        padding: 96px
    }

    .u-p13\@xl {
        padding: 104px
    }

    .u-p14\@xl {
        padding: 112px
    }

    .u-p15\@xl {
        padding: 120px
    }

    .u-p16\@xl {
        padding: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-p0\@l {
        padding: 0
    }

    .u-p1\@l {
        padding: 8px
    }

    .u-p2\@l {
        padding: 16px
    }

    .u-p3\@l {
        padding: 24px
    }

    .u-p4\@l {
        padding: 32px
    }

    .u-p5\@l {
        padding: 40px
    }

    .u-p6\@l {
        padding: 48px
    }

    .u-p7\@l {
        padding: 56px
    }

    .u-p8\@l {
        padding: 64px
    }

    .u-p9\@l {
        padding: 72px
    }

    .u-p10\@l {
        padding: 80px
    }

    .u-p11\@l {
        padding: 88px
    }

    .u-p12\@l {
        padding: 96px
    }

    .u-p13\@l {
        padding: 104px
    }

    .u-p14\@l {
        padding: 112px
    }

    .u-p15\@l {
        padding: 120px
    }

    .u-p16\@l {
        padding: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-p0\@m {
        padding: 0
    }

    .u-p1\@m {
        padding: 8px
    }

    .u-p2\@m {
        padding: 16px
    }

    .u-p3\@m {
        padding: 24px
    }

    .u-p4\@m {
        padding: 32px
    }

    .u-p5\@m {
        padding: 40px
    }

    .u-p6\@m {
        padding: 48px
    }

    .u-p7\@m {
        padding: 56px
    }

    .u-p8\@m {
        padding: 64px
    }

    .u-p9\@m {
        padding: 72px
    }

    .u-p10\@m {
        padding: 80px
    }

    .u-p11\@m {
        padding: 88px
    }

    .u-p12\@m {
        padding: 96px
    }

    .u-p13\@m {
        padding: 104px
    }

    .u-p14\@m {
        padding: 112px
    }

    .u-p15\@m {
        padding: 120px
    }

    .u-p16\@m {
        padding: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-p0\@s {
        padding: 0
    }

    .u-p1\@s {
        padding: 8px
    }

    .u-p2\@s {
        padding: 16px
    }

    .u-p3\@s {
        padding: 24px
    }

    .u-p4\@s {
        padding: 32px
    }

    .u-p5\@s {
        padding: 40px
    }

    .u-p6\@s {
        padding: 48px
    }

    .u-p7\@s {
        padding: 56px
    }

    .u-p8\@s {
        padding: 64px
    }

    .u-p9\@s {
        padding: 72px
    }

    .u-p10\@s {
        padding: 80px
    }

    .u-p11\@s {
        padding: 88px
    }

    .u-p12\@s {
        padding: 96px
    }

    .u-p13\@s {
        padding: 104px
    }

    .u-p14\@s {
        padding: 112px
    }

    .u-p15\@s {
        padding: 120px
    }

    .u-p16\@s {
        padding: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-p0\@xs {
        padding: 0
    }

    .u-p1\@xs {
        padding: 8px
    }

    .u-p2\@xs {
        padding: 16px
    }

    .u-p3\@xs {
        padding: 24px
    }

    .u-p4\@xs {
        padding: 32px
    }

    .u-p5\@xs {
        padding: 40px
    }

    .u-p6\@xs {
        padding: 48px
    }

    .u-p7\@xs {
        padding: 56px
    }

    .u-p8\@xs {
        padding: 64px
    }

    .u-p9\@xs {
        padding: 72px
    }

    .u-p10\@xs {
        padding: 80px
    }

    .u-p11\@xs {
        padding: 88px
    }

    .u-p12\@xs {
        padding: 96px
    }

    .u-p13\@xs {
        padding: 104px
    }

    .u-p14\@xs {
        padding: 112px
    }

    .u-p15\@xs {
        padding: 120px
    }

    .u-p16\@xs {
        padding: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-p-x0\@xl {
        padding-left: 0;
        padding-right: 0
    }

    .u-p-x1\@xl {
        padding-left: 8px;
        padding-right: 8px
    }

    .u-p-x2\@xl {
        padding-left: 16px;
        padding-right: 16px
    }

    .u-p-x3\@xl {
        padding-left: 24px;
        padding-right: 24px
    }

    .u-p-x4\@xl {
        padding-left: 32px;
        padding-right: 32px
    }

    .u-p-x5\@xl {
        padding-left: 40px;
        padding-right: 40px
    }

    .u-p-x6\@xl {
        padding-left: 48px;
        padding-right: 48px
    }

    .u-p-x7\@xl {
        padding-left: 56px;
        padding-right: 56px
    }

    .u-p-x8\@xl {
        padding-left: 64px;
        padding-right: 64px
    }

    .u-p-x9\@xl {
        padding-left: 72px;
        padding-right: 72px
    }

    .u-p-x10\@xl {
        padding-left: 80px;
        padding-right: 80px
    }

    .u-p-x11\@xl {
        padding-left: 88px;
        padding-right: 88px
    }

    .u-p-x12\@xl {
        padding-left: 96px;
        padding-right: 96px
    }

    .u-p-x13\@xl {
        padding-left: 104px;
        padding-right: 104px
    }

    .u-p-x14\@xl {
        padding-left: 112px;
        padding-right: 112px
    }

    .u-p-x15\@xl {
        padding-left: 120px;
        padding-right: 120px
    }

    .u-p-x16\@xl {
        padding-left: 128px;
        padding-right: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-p-x0\@l {
        padding-left: 0;
        padding-right: 0
    }

    .u-p-x1\@l {
        padding-left: 8px;
        padding-right: 8px
    }

    .u-p-x2\@l {
        padding-left: 16px;
        padding-right: 16px
    }

    .u-p-x3\@l {
        padding-left: 24px;
        padding-right: 24px
    }

    .u-p-x4\@l {
        padding-left: 32px;
        padding-right: 32px
    }

    .u-p-x5\@l {
        padding-left: 40px;
        padding-right: 40px
    }

    .u-p-x6\@l {
        padding-left: 48px;
        padding-right: 48px
    }

    .u-p-x7\@l {
        padding-left: 56px;
        padding-right: 56px
    }

    .u-p-x8\@l {
        padding-left: 64px;
        padding-right: 64px
    }

    .u-p-x9\@l {
        padding-left: 72px;
        padding-right: 72px
    }

    .u-p-x10\@l {
        padding-left: 80px;
        padding-right: 80px
    }

    .u-p-x11\@l {
        padding-left: 88px;
        padding-right: 88px
    }

    .u-p-x12\@l {
        padding-left: 96px;
        padding-right: 96px
    }

    .u-p-x13\@l {
        padding-left: 104px;
        padding-right: 104px
    }

    .u-p-x14\@l {
        padding-left: 112px;
        padding-right: 112px
    }

    .u-p-x15\@l {
        padding-left: 120px;
        padding-right: 120px
    }

    .u-p-x16\@l {
        padding-left: 128px;
        padding-right: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-p-x0\@m {
        padding-left: 0;
        padding-right: 0
    }

    .u-p-x1\@m {
        padding-left: 8px;
        padding-right: 8px
    }

    .u-p-x2\@m {
        padding-left: 16px;
        padding-right: 16px
    }

    .u-p-x3\@m {
        padding-left: 24px;
        padding-right: 24px
    }

    .u-p-x4\@m {
        padding-left: 32px;
        padding-right: 32px
    }

    .u-p-x5\@m {
        padding-left: 40px;
        padding-right: 40px
    }

    .u-p-x6\@m {
        padding-left: 48px;
        padding-right: 48px
    }

    .u-p-x7\@m {
        padding-left: 56px;
        padding-right: 56px
    }

    .u-p-x8\@m {
        padding-left: 64px;
        padding-right: 64px
    }

    .u-p-x9\@m {
        padding-left: 72px;
        padding-right: 72px
    }

    .u-p-x10\@m {
        padding-left: 80px;
        padding-right: 80px
    }

    .u-p-x11\@m {
        padding-left: 88px;
        padding-right: 88px
    }

    .u-p-x12\@m {
        padding-left: 96px;
        padding-right: 96px
    }

    .u-p-x13\@m {
        padding-left: 104px;
        padding-right: 104px
    }

    .u-p-x14\@m {
        padding-left: 112px;
        padding-right: 112px
    }

    .u-p-x15\@m {
        padding-left: 120px;
        padding-right: 120px
    }

    .u-p-x16\@m {
        padding-left: 128px;
        padding-right: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-p-x0\@s {
        padding-left: 0;
        padding-right: 0
    }

    .u-p-x1\@s {
        padding-left: 8px;
        padding-right: 8px
    }

    .u-p-x2\@s {
        padding-left: 16px;
        padding-right: 16px
    }

    .u-p-x3\@s {
        padding-left: 24px;
        padding-right: 24px
    }

    .u-p-x4\@s {
        padding-left: 32px;
        padding-right: 32px
    }

    .u-p-x5\@s {
        padding-left: 40px;
        padding-right: 40px
    }

    .u-p-x6\@s {
        padding-left: 48px;
        padding-right: 48px
    }

    .u-p-x7\@s {
        padding-left: 56px;
        padding-right: 56px
    }

    .u-p-x8\@s {
        padding-left: 64px;
        padding-right: 64px
    }

    .u-p-x9\@s {
        padding-left: 72px;
        padding-right: 72px
    }

    .u-p-x10\@s {
        padding-left: 80px;
        padding-right: 80px
    }

    .u-p-x11\@s {
        padding-left: 88px;
        padding-right: 88px
    }

    .u-p-x12\@s {
        padding-left: 96px;
        padding-right: 96px
    }

    .u-p-x13\@s {
        padding-left: 104px;
        padding-right: 104px
    }

    .u-p-x14\@s {
        padding-left: 112px;
        padding-right: 112px
    }

    .u-p-x15\@s {
        padding-left: 120px;
        padding-right: 120px
    }

    .u-p-x16\@s {
        padding-left: 128px;
        padding-right: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-p-x0\@xs {
        padding-left: 0;
        padding-right: 0
    }

    .u-p-x1\@xs {
        padding-left: 8px;
        padding-right: 8px
    }

    .u-p-x2\@xs {
        padding-left: 16px;
        padding-right: 16px
    }

    .u-p-x3\@xs {
        padding-left: 24px;
        padding-right: 24px
    }

    .u-p-x4\@xs {
        padding-left: 32px;
        padding-right: 32px
    }

    .u-p-x5\@xs {
        padding-left: 40px;
        padding-right: 40px
    }

    .u-p-x6\@xs {
        padding-left: 48px;
        padding-right: 48px
    }

    .u-p-x7\@xs {
        padding-left: 56px;
        padding-right: 56px
    }

    .u-p-x8\@xs {
        padding-left: 64px;
        padding-right: 64px
    }

    .u-p-x9\@xs {
        padding-left: 72px;
        padding-right: 72px
    }

    .u-p-x10\@xs {
        padding-left: 80px;
        padding-right: 80px
    }

    .u-p-x11\@xs {
        padding-left: 88px;
        padding-right: 88px
    }

    .u-p-x12\@xs {
        padding-left: 96px;
        padding-right: 96px
    }

    .u-p-x13\@xs {
        padding-left: 104px;
        padding-right: 104px
    }

    .u-p-x14\@xs {
        padding-left: 112px;
        padding-right: 112px
    }

    .u-p-x15\@xs {
        padding-left: 120px;
        padding-right: 120px
    }

    .u-p-x16\@xs {
        padding-left: 128px;
        padding-right: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-p-y0\@xl {
        padding-top: 0;
        padding-bottom: 0
    }

    .u-p-y1\@xl {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .u-p-y2\@xl {
        padding-top: 16px;
        padding-bottom: 16px
    }

    .u-p-y3\@xl {
        padding-top: 24px;
        padding-bottom: 24px
    }

    .u-p-y4\@xl {
        padding-top: 32px;
        padding-bottom: 32px
    }

    .u-p-y5\@xl {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .u-p-y6\@xl {
        padding-top: 48px;
        padding-bottom: 48px
    }

    .u-p-y7\@xl {
        padding-top: 56px;
        padding-bottom: 56px
    }

    .u-p-y8\@xl {
        padding-top: 64px;
        padding-bottom: 64px
    }

    .u-p-y9\@xl {
        padding-top: 72px;
        padding-bottom: 72px
    }

    .u-p-y10\@xl {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .u-p-y11\@xl {
        padding-top: 88px;
        padding-bottom: 88px
    }

    .u-p-y12\@xl {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .u-p-y13\@xl {
        padding-top: 104px;
        padding-bottom: 104px
    }

    .u-p-y14\@xl {
        padding-top: 112px;
        padding-bottom: 112px
    }

    .u-p-y15\@xl {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .u-p-y16\@xl {
        padding-top: 128px;
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-p-y0\@l {
        padding-top: 0;
        padding-bottom: 0
    }

    .u-p-y1\@l {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .u-p-y2\@l {
        padding-top: 16px;
        padding-bottom: 16px
    }

    .u-p-y3\@l {
        padding-top: 24px;
        padding-bottom: 24px
    }

    .u-p-y4\@l {
        padding-top: 32px;
        padding-bottom: 32px
    }

    .u-p-y5\@l {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .u-p-y6\@l {
        padding-top: 48px;
        padding-bottom: 48px
    }

    .u-p-y7\@l {
        padding-top: 56px;
        padding-bottom: 56px
    }

    .u-p-y8\@l {
        padding-top: 64px;
        padding-bottom: 64px
    }

    .u-p-y9\@l {
        padding-top: 72px;
        padding-bottom: 72px
    }

    .u-p-y10\@l {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .u-p-y11\@l {
        padding-top: 88px;
        padding-bottom: 88px
    }

    .u-p-y12\@l {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .u-p-y13\@l {
        padding-top: 104px;
        padding-bottom: 104px
    }

    .u-p-y14\@l {
        padding-top: 112px;
        padding-bottom: 112px
    }

    .u-p-y15\@l {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .u-p-y16\@l {
        padding-top: 128px;
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-p-y0\@m {
        padding-top: 0;
        padding-bottom: 0
    }

    .u-p-y1\@m {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .u-p-y2\@m {
        padding-top: 16px;
        padding-bottom: 16px
    }

    .u-p-y3\@m {
        padding-top: 24px;
        padding-bottom: 24px
    }

    .u-p-y4\@m {
        padding-top: 32px;
        padding-bottom: 32px
    }

    .u-p-y5\@m {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .u-p-y6\@m {
        padding-top: 48px;
        padding-bottom: 48px
    }

    .u-p-y7\@m {
        padding-top: 56px;
        padding-bottom: 56px
    }

    .u-p-y8\@m {
        padding-top: 64px;
        padding-bottom: 64px
    }

    .u-p-y9\@m {
        padding-top: 72px;
        padding-bottom: 72px
    }

    .u-p-y10\@m {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .u-p-y11\@m {
        padding-top: 88px;
        padding-bottom: 88px
    }

    .u-p-y12\@m {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .u-p-y13\@m {
        padding-top: 104px;
        padding-bottom: 104px
    }

    .u-p-y14\@m {
        padding-top: 112px;
        padding-bottom: 112px
    }

    .u-p-y15\@m {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .u-p-y16\@m {
        padding-top: 128px;
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-p-y0\@s {
        padding-top: 0;
        padding-bottom: 0
    }

    .u-p-y1\@s {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .u-p-y2\@s {
        padding-top: 16px;
        padding-bottom: 16px
    }

    .u-p-y3\@s {
        padding-top: 24px;
        padding-bottom: 24px
    }

    .u-p-y4\@s {
        padding-top: 32px;
        padding-bottom: 32px
    }

    .u-p-y5\@s {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .u-p-y6\@s {
        padding-top: 48px;
        padding-bottom: 48px
    }

    .u-p-y7\@s {
        padding-top: 56px;
        padding-bottom: 56px
    }

    .u-p-y8\@s {
        padding-top: 64px;
        padding-bottom: 64px
    }

    .u-p-y9\@s {
        padding-top: 72px;
        padding-bottom: 72px
    }

    .u-p-y10\@s {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .u-p-y11\@s {
        padding-top: 88px;
        padding-bottom: 88px
    }

    .u-p-y12\@s {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .u-p-y13\@s {
        padding-top: 104px;
        padding-bottom: 104px
    }

    .u-p-y14\@s {
        padding-top: 112px;
        padding-bottom: 112px
    }

    .u-p-y15\@s {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .u-p-y16\@s {
        padding-top: 128px;
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-p-y0\@xs {
        padding-top: 0;
        padding-bottom: 0
    }

    .u-p-y1\@xs {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .u-p-y2\@xs {
        padding-top: 16px;
        padding-bottom: 16px
    }

    .u-p-y3\@xs {
        padding-top: 24px;
        padding-bottom: 24px
    }

    .u-p-y4\@xs {
        padding-top: 32px;
        padding-bottom: 32px
    }

    .u-p-y5\@xs {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .u-p-y6\@xs {
        padding-top: 48px;
        padding-bottom: 48px
    }

    .u-p-y7\@xs {
        padding-top: 56px;
        padding-bottom: 56px
    }

    .u-p-y8\@xs {
        padding-top: 64px;
        padding-bottom: 64px
    }

    .u-p-y9\@xs {
        padding-top: 72px;
        padding-bottom: 72px
    }

    .u-p-y10\@xs {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .u-p-y11\@xs {
        padding-top: 88px;
        padding-bottom: 88px
    }

    .u-p-y12\@xs {
        padding-top: 96px;
        padding-bottom: 96px
    }

    .u-p-y13\@xs {
        padding-top: 104px;
        padding-bottom: 104px
    }

    .u-p-y14\@xs {
        padding-top: 112px;
        padding-bottom: 112px
    }

    .u-p-y15\@xs {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .u-p-y16\@xs {
        padding-top: 128px;
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-p-t0\@xl {
        padding-top: 0
    }

    .u-p-t1\@xl {
        padding-top: 8px
    }

    .u-p-t2\@xl {
        padding-top: 16px
    }

    .u-p-t3\@xl {
        padding-top: 24px
    }

    .u-p-t4\@xl {
        padding-top: 32px
    }

    .u-p-t5\@xl {
        padding-top: 40px
    }

    .u-p-t6\@xl {
        padding-top: 48px
    }

    .u-p-t7\@xl {
        padding-top: 56px
    }

    .u-p-t8\@xl {
        padding-top: 64px
    }

    .u-p-t9\@xl {
        padding-top: 72px
    }

    .u-p-t10\@xl {
        padding-top: 80px
    }

    .u-p-t11\@xl {
        padding-top: 88px
    }

    .u-p-t12\@xl {
        padding-top: 96px
    }

    .u-p-t13\@xl {
        padding-top: 104px
    }

    .u-p-t14\@xl {
        padding-top: 112px
    }

    .u-p-t15\@xl {
        padding-top: 120px
    }

    .u-p-t16\@xl {
        padding-top: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-p-t0\@l {
        padding-top: 0
    }

    .u-p-t1\@l {
        padding-top: 8px
    }

    .u-p-t2\@l {
        padding-top: 16px
    }

    .u-p-t3\@l {
        padding-top: 24px
    }

    .u-p-t4\@l {
        padding-top: 32px
    }

    .u-p-t5\@l {
        padding-top: 40px
    }

    .u-p-t6\@l {
        padding-top: 48px
    }

    .u-p-t7\@l {
        padding-top: 56px
    }

    .u-p-t8\@l {
        padding-top: 64px
    }

    .u-p-t9\@l {
        padding-top: 72px
    }

    .u-p-t10\@l {
        padding-top: 80px
    }

    .u-p-t11\@l {
        padding-top: 88px
    }

    .u-p-t12\@l {
        padding-top: 96px
    }

    .u-p-t13\@l {
        padding-top: 104px
    }

    .u-p-t14\@l {
        padding-top: 112px
    }

    .u-p-t15\@l {
        padding-top: 120px
    }

    .u-p-t16\@l {
        padding-top: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-p-t0\@m {
        padding-top: 0
    }

    .u-p-t1\@m {
        padding-top: 8px
    }

    .u-p-t2\@m {
        padding-top: 16px
    }

    .u-p-t3\@m {
        padding-top: 24px
    }

    .u-p-t4\@m {
        padding-top: 32px
    }

    .u-p-t5\@m {
        padding-top: 40px
    }

    .u-p-t6\@m {
        padding-top: 48px
    }

    .u-p-t7\@m {
        padding-top: 56px
    }

    .u-p-t8\@m {
        padding-top: 64px
    }

    .u-p-t9\@m {
        padding-top: 72px
    }

    .u-p-t10\@m {
        padding-top: 80px
    }

    .u-p-t11\@m {
        padding-top: 88px
    }

    .u-p-t12\@m {
        padding-top: 96px
    }

    .u-p-t13\@m {
        padding-top: 104px
    }

    .u-p-t14\@m {
        padding-top: 112px
    }

    .u-p-t15\@m {
        padding-top: 120px
    }

    .u-p-t16\@m {
        padding-top: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-p-t0\@s {
        padding-top: 0
    }

    .u-p-t1\@s {
        padding-top: 8px
    }

    .u-p-t2\@s {
        padding-top: 16px
    }

    .u-p-t3\@s {
        padding-top: 24px
    }

    .u-p-t4\@s {
        padding-top: 32px
    }

    .u-p-t5\@s {
        padding-top: 40px
    }

    .u-p-t6\@s {
        padding-top: 48px
    }

    .u-p-t7\@s {
        padding-top: 56px
    }

    .u-p-t8\@s {
        padding-top: 64px
    }

    .u-p-t9\@s {
        padding-top: 72px
    }

    .u-p-t10\@s {
        padding-top: 80px
    }

    .u-p-t11\@s {
        padding-top: 88px
    }

    .u-p-t12\@s {
        padding-top: 96px
    }

    .u-p-t13\@s {
        padding-top: 104px
    }

    .u-p-t14\@s {
        padding-top: 112px
    }

    .u-p-t15\@s {
        padding-top: 120px
    }

    .u-p-t16\@s {
        padding-top: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-p-t0\@xs {
        padding-top: 0
    }

    .u-p-t1\@xs {
        padding-top: 8px
    }

    .u-p-t2\@xs {
        padding-top: 16px
    }

    .u-p-t3\@xs {
        padding-top: 24px
    }

    .u-p-t4\@xs {
        padding-top: 32px
    }

    .u-p-t5\@xs {
        padding-top: 40px
    }

    .u-p-t6\@xs {
        padding-top: 48px
    }

    .u-p-t7\@xs {
        padding-top: 56px
    }

    .u-p-t8\@xs {
        padding-top: 64px
    }

    .u-p-t9\@xs {
        padding-top: 72px
    }

    .u-p-t10\@xs {
        padding-top: 80px
    }

    .u-p-t11\@xs {
        padding-top: 88px
    }

    .u-p-t12\@xs {
        padding-top: 96px
    }

    .u-p-t13\@xs {
        padding-top: 104px
    }

    .u-p-t14\@xs {
        padding-top: 112px
    }

    .u-p-t15\@xs {
        padding-top: 120px
    }

    .u-p-t16\@xs {
        padding-top: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-p-r0\@xl {
        padding-right: 0
    }

    .u-p-r1\@xl {
        padding-right: 8px
    }

    .u-p-r2\@xl {
        padding-right: 16px
    }

    .u-p-r3\@xl {
        padding-right: 24px
    }

    .u-p-r4\@xl {
        padding-right: 32px
    }

    .u-p-r5\@xl {
        padding-right: 40px
    }

    .u-p-r6\@xl {
        padding-right: 48px
    }

    .u-p-r7\@xl {
        padding-right: 56px
    }

    .u-p-r8\@xl {
        padding-right: 64px
    }

    .u-p-r9\@xl {
        padding-right: 72px
    }

    .u-p-r10\@xl {
        padding-right: 80px
    }

    .u-p-r11\@xl {
        padding-right: 88px
    }

    .u-p-r12\@xl {
        padding-right: 96px
    }

    .u-p-r13\@xl {
        padding-right: 104px
    }

    .u-p-r14\@xl {
        padding-right: 112px
    }

    .u-p-r15\@xl {
        padding-right: 120px
    }

    .u-p-r16\@xl {
        padding-right: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-p-r0\@l {
        padding-right: 0
    }

    .u-p-r1\@l {
        padding-right: 8px
    }

    .u-p-r2\@l {
        padding-right: 16px
    }

    .u-p-r3\@l {
        padding-right: 24px
    }

    .u-p-r4\@l {
        padding-right: 32px
    }

    .u-p-r5\@l {
        padding-right: 40px
    }

    .u-p-r6\@l {
        padding-right: 48px
    }

    .u-p-r7\@l {
        padding-right: 56px
    }

    .u-p-r8\@l {
        padding-right: 64px
    }

    .u-p-r9\@l {
        padding-right: 72px
    }

    .u-p-r10\@l {
        padding-right: 80px
    }

    .u-p-r11\@l {
        padding-right: 88px
    }

    .u-p-r12\@l {
        padding-right: 96px
    }

    .u-p-r13\@l {
        padding-right: 104px
    }

    .u-p-r14\@l {
        padding-right: 112px
    }

    .u-p-r15\@l {
        padding-right: 120px
    }

    .u-p-r16\@l {
        padding-right: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-p-r0\@m {
        padding-right: 0
    }

    .u-p-r1\@m {
        padding-right: 8px
    }

    .u-p-r2\@m {
        padding-right: 16px
    }

    .u-p-r3\@m {
        padding-right: 24px
    }

    .u-p-r4\@m {
        padding-right: 32px
    }

    .u-p-r5\@m {
        padding-right: 40px
    }

    .u-p-r6\@m {
        padding-right: 48px
    }

    .u-p-r7\@m {
        padding-right: 56px
    }

    .u-p-r8\@m {
        padding-right: 64px
    }

    .u-p-r9\@m {
        padding-right: 72px
    }

    .u-p-r10\@m {
        padding-right: 80px
    }

    .u-p-r11\@m {
        padding-right: 88px
    }

    .u-p-r12\@m {
        padding-right: 96px
    }

    .u-p-r13\@m {
        padding-right: 104px
    }

    .u-p-r14\@m {
        padding-right: 112px
    }

    .u-p-r15\@m {
        padding-right: 120px
    }

    .u-p-r16\@m {
        padding-right: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-p-r0\@s {
        padding-right: 0
    }

    .u-p-r1\@s {
        padding-right: 8px
    }

    .u-p-r2\@s {
        padding-right: 16px
    }

    .u-p-r3\@s {
        padding-right: 24px
    }

    .u-p-r4\@s {
        padding-right: 32px
    }

    .u-p-r5\@s {
        padding-right: 40px
    }

    .u-p-r6\@s {
        padding-right: 48px
    }

    .u-p-r7\@s {
        padding-right: 56px
    }

    .u-p-r8\@s {
        padding-right: 64px
    }

    .u-p-r9\@s {
        padding-right: 72px
    }

    .u-p-r10\@s {
        padding-right: 80px
    }

    .u-p-r11\@s {
        padding-right: 88px
    }

    .u-p-r12\@s {
        padding-right: 96px
    }

    .u-p-r13\@s {
        padding-right: 104px
    }

    .u-p-r14\@s {
        padding-right: 112px
    }

    .u-p-r15\@s {
        padding-right: 120px
    }

    .u-p-r16\@s {
        padding-right: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-p-r0\@xs {
        padding-right: 0
    }

    .u-p-r1\@xs {
        padding-right: 8px
    }

    .u-p-r2\@xs {
        padding-right: 16px
    }

    .u-p-r3\@xs {
        padding-right: 24px
    }

    .u-p-r4\@xs {
        padding-right: 32px
    }

    .u-p-r5\@xs {
        padding-right: 40px
    }

    .u-p-r6\@xs {
        padding-right: 48px
    }

    .u-p-r7\@xs {
        padding-right: 56px
    }

    .u-p-r8\@xs {
        padding-right: 64px
    }

    .u-p-r9\@xs {
        padding-right: 72px
    }

    .u-p-r10\@xs {
        padding-right: 80px
    }

    .u-p-r11\@xs {
        padding-right: 88px
    }

    .u-p-r12\@xs {
        padding-right: 96px
    }

    .u-p-r13\@xs {
        padding-right: 104px
    }

    .u-p-r14\@xs {
        padding-right: 112px
    }

    .u-p-r15\@xs {
        padding-right: 120px
    }

    .u-p-r16\@xs {
        padding-right: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-p-b0\@xl {
        padding-bottom: 0
    }

    .u-p-b1\@xl {
        padding-bottom: 8px
    }

    .u-p-b2\@xl {
        padding-bottom: 16px
    }

    .u-p-b3\@xl {
        padding-bottom: 24px
    }

    .u-p-b4\@xl {
        padding-bottom: 32px
    }

    .u-p-b5\@xl {
        padding-bottom: 40px
    }

    .u-p-b6\@xl {
        padding-bottom: 48px
    }

    .u-p-b7\@xl {
        padding-bottom: 56px
    }

    .u-p-b8\@xl {
        padding-bottom: 64px
    }

    .u-p-b9\@xl {
        padding-bottom: 72px
    }

    .u-p-b10\@xl {
        padding-bottom: 80px
    }

    .u-p-b11\@xl {
        padding-bottom: 88px
    }

    .u-p-b12\@xl {
        padding-bottom: 96px
    }

    .u-p-b13\@xl {
        padding-bottom: 104px
    }

    .u-p-b14\@xl {
        padding-bottom: 112px
    }

    .u-p-b15\@xl {
        padding-bottom: 120px
    }

    .u-p-b16\@xl {
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-p-b0\@l {
        padding-bottom: 0
    }

    .u-p-b1\@l {
        padding-bottom: 8px
    }

    .u-p-b2\@l {
        padding-bottom: 16px
    }

    .u-p-b3\@l {
        padding-bottom: 24px
    }

    .u-p-b4\@l {
        padding-bottom: 32px
    }

    .u-p-b5\@l {
        padding-bottom: 40px
    }

    .u-p-b6\@l {
        padding-bottom: 48px
    }

    .u-p-b7\@l {
        padding-bottom: 56px
    }

    .u-p-b8\@l {
        padding-bottom: 64px
    }

    .u-p-b9\@l {
        padding-bottom: 72px
    }

    .u-p-b10\@l {
        padding-bottom: 80px
    }

    .u-p-b11\@l {
        padding-bottom: 88px
    }

    .u-p-b12\@l {
        padding-bottom: 96px
    }

    .u-p-b13\@l {
        padding-bottom: 104px
    }

    .u-p-b14\@l {
        padding-bottom: 112px
    }

    .u-p-b15\@l {
        padding-bottom: 120px
    }

    .u-p-b16\@l {
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-p-b0\@m {
        padding-bottom: 0
    }

    .u-p-b1\@m {
        padding-bottom: 8px
    }

    .u-p-b2\@m {
        padding-bottom: 16px
    }

    .u-p-b3\@m {
        padding-bottom: 24px
    }

    .u-p-b4\@m {
        padding-bottom: 32px
    }

    .u-p-b5\@m {
        padding-bottom: 40px
    }

    .u-p-b6\@m {
        padding-bottom: 48px
    }

    .u-p-b7\@m {
        padding-bottom: 56px
    }

    .u-p-b8\@m {
        padding-bottom: 64px
    }

    .u-p-b9\@m {
        padding-bottom: 72px
    }

    .u-p-b10\@m {
        padding-bottom: 80px
    }

    .u-p-b11\@m {
        padding-bottom: 88px
    }

    .u-p-b12\@m {
        padding-bottom: 96px
    }

    .u-p-b13\@m {
        padding-bottom: 104px
    }

    .u-p-b14\@m {
        padding-bottom: 112px
    }

    .u-p-b15\@m {
        padding-bottom: 120px
    }

    .u-p-b16\@m {
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-p-b0\@s {
        padding-bottom: 0
    }

    .u-p-b1\@s {
        padding-bottom: 8px
    }

    .u-p-b2\@s {
        padding-bottom: 16px
    }

    .u-p-b3\@s {
        padding-bottom: 24px
    }

    .u-p-b4\@s {
        padding-bottom: 32px
    }

    .u-p-b5\@s {
        padding-bottom: 40px
    }

    .u-p-b6\@s {
        padding-bottom: 48px
    }

    .u-p-b7\@s {
        padding-bottom: 56px
    }

    .u-p-b8\@s {
        padding-bottom: 64px
    }

    .u-p-b9\@s {
        padding-bottom: 72px
    }

    .u-p-b10\@s {
        padding-bottom: 80px
    }

    .u-p-b11\@s {
        padding-bottom: 88px
    }

    .u-p-b12\@s {
        padding-bottom: 96px
    }

    .u-p-b13\@s {
        padding-bottom: 104px
    }

    .u-p-b14\@s {
        padding-bottom: 112px
    }

    .u-p-b15\@s {
        padding-bottom: 120px
    }

    .u-p-b16\@s {
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-p-b0\@xs {
        padding-bottom: 0
    }

    .u-p-b1\@xs {
        padding-bottom: 8px
    }

    .u-p-b2\@xs {
        padding-bottom: 16px
    }

    .u-p-b3\@xs {
        padding-bottom: 24px
    }

    .u-p-b4\@xs {
        padding-bottom: 32px
    }

    .u-p-b5\@xs {
        padding-bottom: 40px
    }

    .u-p-b6\@xs {
        padding-bottom: 48px
    }

    .u-p-b7\@xs {
        padding-bottom: 56px
    }

    .u-p-b8\@xs {
        padding-bottom: 64px
    }

    .u-p-b9\@xs {
        padding-bottom: 72px
    }

    .u-p-b10\@xs {
        padding-bottom: 80px
    }

    .u-p-b11\@xs {
        padding-bottom: 88px
    }

    .u-p-b12\@xs {
        padding-bottom: 96px
    }

    .u-p-b13\@xs {
        padding-bottom: 104px
    }

    .u-p-b14\@xs {
        padding-bottom: 112px
    }

    .u-p-b15\@xs {
        padding-bottom: 120px
    }

    .u-p-b16\@xs {
        padding-bottom: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-p-l0\@xl {
        padding-left: 0
    }

    .u-p-l1\@xl {
        padding-left: 8px
    }

    .u-p-l2\@xl {
        padding-left: 16px
    }

    .u-p-l3\@xl {
        padding-left: 24px
    }

    .u-p-l4\@xl {
        padding-left: 32px
    }

    .u-p-l5\@xl {
        padding-left: 40px
    }

    .u-p-l6\@xl {
        padding-left: 48px
    }

    .u-p-l7\@xl {
        padding-left: 56px
    }

    .u-p-l8\@xl {
        padding-left: 64px
    }

    .u-p-l9\@xl {
        padding-left: 72px
    }

    .u-p-l10\@xl {
        padding-left: 80px
    }

    .u-p-l11\@xl {
        padding-left: 88px
    }

    .u-p-l12\@xl {
        padding-left: 96px
    }

    .u-p-l13\@xl {
        padding-left: 104px
    }

    .u-p-l14\@xl {
        padding-left: 112px
    }

    .u-p-l15\@xl {
        padding-left: 120px
    }

    .u-p-l16\@xl {
        padding-left: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-p-l0\@l {
        padding-left: 0
    }

    .u-p-l1\@l {
        padding-left: 8px
    }

    .u-p-l2\@l {
        padding-left: 16px
    }

    .u-p-l3\@l {
        padding-left: 24px
    }

    .u-p-l4\@l {
        padding-left: 32px
    }

    .u-p-l5\@l {
        padding-left: 40px
    }

    .u-p-l6\@l {
        padding-left: 48px
    }

    .u-p-l7\@l {
        padding-left: 56px
    }

    .u-p-l8\@l {
        padding-left: 64px
    }

    .u-p-l9\@l {
        padding-left: 72px
    }

    .u-p-l10\@l {
        padding-left: 80px
    }

    .u-p-l11\@l {
        padding-left: 88px
    }

    .u-p-l12\@l {
        padding-left: 96px
    }

    .u-p-l13\@l {
        padding-left: 104px
    }

    .u-p-l14\@l {
        padding-left: 112px
    }

    .u-p-l15\@l {
        padding-left: 120px
    }

    .u-p-l16\@l {
        padding-left: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-p-l0\@m {
        padding-left: 0
    }

    .u-p-l1\@m {
        padding-left: 8px
    }

    .u-p-l2\@m {
        padding-left: 16px
    }

    .u-p-l3\@m {
        padding-left: 24px
    }

    .u-p-l4\@m {
        padding-left: 32px
    }

    .u-p-l5\@m {
        padding-left: 40px
    }

    .u-p-l6\@m {
        padding-left: 48px
    }

    .u-p-l7\@m {
        padding-left: 56px
    }

    .u-p-l8\@m {
        padding-left: 64px
    }

    .u-p-l9\@m {
        padding-left: 72px
    }

    .u-p-l10\@m {
        padding-left: 80px
    }

    .u-p-l11\@m {
        padding-left: 88px
    }

    .u-p-l12\@m {
        padding-left: 96px
    }

    .u-p-l13\@m {
        padding-left: 104px
    }

    .u-p-l14\@m {
        padding-left: 112px
    }

    .u-p-l15\@m {
        padding-left: 120px
    }

    .u-p-l16\@m {
        padding-left: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-p-l0\@s {
        padding-left: 0
    }

    .u-p-l1\@s {
        padding-left: 8px
    }

    .u-p-l2\@s {
        padding-left: 16px
    }

    .u-p-l3\@s {
        padding-left: 24px
    }

    .u-p-l4\@s {
        padding-left: 32px
    }

    .u-p-l5\@s {
        padding-left: 40px
    }

    .u-p-l6\@s {
        padding-left: 48px
    }

    .u-p-l7\@s {
        padding-left: 56px
    }

    .u-p-l8\@s {
        padding-left: 64px
    }

    .u-p-l9\@s {
        padding-left: 72px
    }

    .u-p-l10\@s {
        padding-left: 80px
    }

    .u-p-l11\@s {
        padding-left: 88px
    }

    .u-p-l12\@s {
        padding-left: 96px
    }

    .u-p-l13\@s {
        padding-left: 104px
    }

    .u-p-l14\@s {
        padding-left: 112px
    }

    .u-p-l15\@s {
        padding-left: 120px
    }

    .u-p-l16\@s {
        padding-left: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-p-l0\@xs {
        padding-left: 0
    }

    .u-p-l1\@xs {
        padding-left: 8px
    }

    .u-p-l2\@xs {
        padding-left: 16px
    }

    .u-p-l3\@xs {
        padding-left: 24px
    }

    .u-p-l4\@xs {
        padding-left: 32px
    }

    .u-p-l5\@xs {
        padding-left: 40px
    }

    .u-p-l6\@xs {
        padding-left: 48px
    }

    .u-p-l7\@xs {
        padding-left: 56px
    }

    .u-p-l8\@xs {
        padding-left: 64px
    }

    .u-p-l9\@xs {
        padding-left: 72px
    }

    .u-p-l10\@xs {
        padding-left: 80px
    }

    .u-p-l11\@xs {
        padding-left: 88px
    }

    .u-p-l12\@xs {
        padding-left: 96px
    }

    .u-p-l13\@xs {
        padding-left: 104px
    }

    .u-p-l14\@xs {
        padding-left: 112px
    }

    .u-p-l15\@xs {
        padding-left: 120px
    }

    .u-p-l16\@xs {
        padding-left: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-m0\@xl {
        margin: 0
    }

    .u-m1\@xl {
        margin: 8px
    }

    .u-m2\@xl {
        margin: 16px
    }

    .u-m3\@xl {
        margin: 24px
    }

    .u-m4\@xl {
        margin: 32px
    }

    .u-m5\@xl {
        margin: 40px
    }

    .u-m6\@xl {
        margin: 48px
    }

    .u-m7\@xl {
        margin: 56px
    }

    .u-m8\@xl {
        margin: 64px
    }

    .u-m9\@xl {
        margin: 72px
    }

    .u-m10\@xl {
        margin: 80px
    }

    .u-m11\@xl {
        margin: 88px
    }

    .u-m12\@xl {
        margin: 96px
    }

    .u-m13\@xl {
        margin: 104px
    }

    .u-m14\@xl {
        margin: 112px
    }

    .u-m15\@xl {
        margin: 120px
    }

    .u-m16\@xl {
        margin: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-m0\@l {
        margin: 0
    }

    .u-m1\@l {
        margin: 8px
    }

    .u-m2\@l {
        margin: 16px
    }

    .u-m3\@l {
        margin: 24px
    }

    .u-m4\@l {
        margin: 32px
    }

    .u-m5\@l {
        margin: 40px
    }

    .u-m6\@l {
        margin: 48px
    }

    .u-m7\@l {
        margin: 56px
    }

    .u-m8\@l {
        margin: 64px
    }

    .u-m9\@l {
        margin: 72px
    }

    .u-m10\@l {
        margin: 80px
    }

    .u-m11\@l {
        margin: 88px
    }

    .u-m12\@l {
        margin: 96px
    }

    .u-m13\@l {
        margin: 104px
    }

    .u-m14\@l {
        margin: 112px
    }

    .u-m15\@l {
        margin: 120px
    }

    .u-m16\@l {
        margin: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-m0\@m {
        margin: 0
    }

    .u-m1\@m {
        margin: 8px
    }

    .u-m2\@m {
        margin: 16px
    }

    .u-m3\@m {
        margin: 24px
    }

    .u-m4\@m {
        margin: 32px
    }

    .u-m5\@m {
        margin: 40px
    }

    .u-m6\@m {
        margin: 48px
    }

    .u-m7\@m {
        margin: 56px
    }

    .u-m8\@m {
        margin: 64px
    }

    .u-m9\@m {
        margin: 72px
    }

    .u-m10\@m {
        margin: 80px
    }

    .u-m11\@m {
        margin: 88px
    }

    .u-m12\@m {
        margin: 96px
    }

    .u-m13\@m {
        margin: 104px
    }

    .u-m14\@m {
        margin: 112px
    }

    .u-m15\@m {
        margin: 120px
    }

    .u-m16\@m {
        margin: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-m0\@s {
        margin: 0
    }

    .u-m1\@s {
        margin: 8px
    }

    .u-m2\@s {
        margin: 16px
    }

    .u-m3\@s {
        margin: 24px
    }

    .u-m4\@s {
        margin: 32px
    }

    .u-m5\@s {
        margin: 40px
    }

    .u-m6\@s {
        margin: 48px
    }

    .u-m7\@s {
        margin: 56px
    }

    .u-m8\@s {
        margin: 64px
    }

    .u-m9\@s {
        margin: 72px
    }

    .u-m10\@s {
        margin: 80px
    }

    .u-m11\@s {
        margin: 88px
    }

    .u-m12\@s {
        margin: 96px
    }

    .u-m13\@s {
        margin: 104px
    }

    .u-m14\@s {
        margin: 112px
    }

    .u-m15\@s {
        margin: 120px
    }

    .u-m16\@s {
        margin: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-m0\@xs {
        margin: 0
    }

    .u-m1\@xs {
        margin: 8px
    }

    .u-m2\@xs {
        margin: 16px
    }

    .u-m3\@xs {
        margin: 24px
    }

    .u-m4\@xs {
        margin: 32px
    }

    .u-m5\@xs {
        margin: 40px
    }

    .u-m6\@xs {
        margin: 48px
    }

    .u-m7\@xs {
        margin: 56px
    }

    .u-m8\@xs {
        margin: 64px
    }

    .u-m9\@xs {
        margin: 72px
    }

    .u-m10\@xs {
        margin: 80px
    }

    .u-m11\@xs {
        margin: 88px
    }

    .u-m12\@xs {
        margin: 96px
    }

    .u-m13\@xs {
        margin: 104px
    }

    .u-m14\@xs {
        margin: 112px
    }

    .u-m15\@xs {
        margin: 120px
    }

    .u-m16\@xs {
        margin: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-m-x0\@xl {
        margin-left: 0;
        margin-right: 0
    }

    .u-m-x1\@xl {
        margin-left: 8px;
        margin-right: 8px
    }

    .u-m-x2\@xl {
        margin-left: 16px;
        margin-right: 16px
    }

    .u-m-x3\@xl {
        margin-left: 24px;
        margin-right: 24px
    }

    .u-m-x4\@xl {
        margin-left: 32px;
        margin-right: 32px
    }

    .u-m-x5\@xl {
        margin-left: 40px;
        margin-right: 40px
    }

    .u-m-x6\@xl {
        margin-left: 48px;
        margin-right: 48px
    }

    .u-m-x7\@xl {
        margin-left: 56px;
        margin-right: 56px
    }

    .u-m-x8\@xl {
        margin-left: 64px;
        margin-right: 64px
    }

    .u-m-x9\@xl {
        margin-left: 72px;
        margin-right: 72px
    }

    .u-m-x10\@xl {
        margin-left: 80px;
        margin-right: 80px
    }

    .u-m-x11\@xl {
        margin-left: 88px;
        margin-right: 88px
    }

    .u-m-x12\@xl {
        margin-left: 96px;
        margin-right: 96px
    }

    .u-m-x13\@xl {
        margin-left: 104px;
        margin-right: 104px
    }

    .u-m-x14\@xl {
        margin-left: 112px;
        margin-right: 112px
    }

    .u-m-x15\@xl {
        margin-left: 120px;
        margin-right: 120px
    }

    .u-m-x16\@xl {
        margin-left: 128px;
        margin-right: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-m-x0\@l {
        margin-left: 0;
        margin-right: 0
    }

    .u-m-x1\@l {
        margin-left: 8px;
        margin-right: 8px
    }

    .u-m-x2\@l {
        margin-left: 16px;
        margin-right: 16px
    }

    .u-m-x3\@l {
        margin-left: 24px;
        margin-right: 24px
    }

    .u-m-x4\@l {
        margin-left: 32px;
        margin-right: 32px
    }

    .u-m-x5\@l {
        margin-left: 40px;
        margin-right: 40px
    }

    .u-m-x6\@l {
        margin-left: 48px;
        margin-right: 48px
    }

    .u-m-x7\@l {
        margin-left: 56px;
        margin-right: 56px
    }

    .u-m-x8\@l {
        margin-left: 64px;
        margin-right: 64px
    }

    .u-m-x9\@l {
        margin-left: 72px;
        margin-right: 72px
    }

    .u-m-x10\@l {
        margin-left: 80px;
        margin-right: 80px
    }

    .u-m-x11\@l {
        margin-left: 88px;
        margin-right: 88px
    }

    .u-m-x12\@l {
        margin-left: 96px;
        margin-right: 96px
    }

    .u-m-x13\@l {
        margin-left: 104px;
        margin-right: 104px
    }

    .u-m-x14\@l {
        margin-left: 112px;
        margin-right: 112px
    }

    .u-m-x15\@l {
        margin-left: 120px;
        margin-right: 120px
    }

    .u-m-x16\@l {
        margin-left: 128px;
        margin-right: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-m-x0\@m {
        margin-left: 0;
        margin-right: 0
    }

    .u-m-x1\@m {
        margin-left: 8px;
        margin-right: 8px
    }

    .u-m-x2\@m {
        margin-left: 16px;
        margin-right: 16px
    }

    .u-m-x3\@m {
        margin-left: 24px;
        margin-right: 24px
    }

    .u-m-x4\@m {
        margin-left: 32px;
        margin-right: 32px
    }

    .u-m-x5\@m {
        margin-left: 40px;
        margin-right: 40px
    }

    .u-m-x6\@m {
        margin-left: 48px;
        margin-right: 48px
    }

    .u-m-x7\@m {
        margin-left: 56px;
        margin-right: 56px
    }

    .u-m-x8\@m {
        margin-left: 64px;
        margin-right: 64px
    }

    .u-m-x9\@m {
        margin-left: 72px;
        margin-right: 72px
    }

    .u-m-x10\@m {
        margin-left: 80px;
        margin-right: 80px
    }

    .u-m-x11\@m {
        margin-left: 88px;
        margin-right: 88px
    }

    .u-m-x12\@m {
        margin-left: 96px;
        margin-right: 96px
    }

    .u-m-x13\@m {
        margin-left: 104px;
        margin-right: 104px
    }

    .u-m-x14\@m {
        margin-left: 112px;
        margin-right: 112px
    }

    .u-m-x15\@m {
        margin-left: 120px;
        margin-right: 120px
    }

    .u-m-x16\@m {
        margin-left: 128px;
        margin-right: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-m-x0\@s {
        margin-left: 0;
        margin-right: 0
    }

    .u-m-x1\@s {
        margin-left: 8px;
        margin-right: 8px
    }

    .u-m-x2\@s {
        margin-left: 16px;
        margin-right: 16px
    }

    .u-m-x3\@s {
        margin-left: 24px;
        margin-right: 24px
    }

    .u-m-x4\@s {
        margin-left: 32px;
        margin-right: 32px
    }

    .u-m-x5\@s {
        margin-left: 40px;
        margin-right: 40px
    }

    .u-m-x6\@s {
        margin-left: 48px;
        margin-right: 48px
    }

    .u-m-x7\@s {
        margin-left: 56px;
        margin-right: 56px
    }

    .u-m-x8\@s {
        margin-left: 64px;
        margin-right: 64px
    }

    .u-m-x9\@s {
        margin-left: 72px;
        margin-right: 72px
    }

    .u-m-x10\@s {
        margin-left: 80px;
        margin-right: 80px
    }

    .u-m-x11\@s {
        margin-left: 88px;
        margin-right: 88px
    }

    .u-m-x12\@s {
        margin-left: 96px;
        margin-right: 96px
    }

    .u-m-x13\@s {
        margin-left: 104px;
        margin-right: 104px
    }

    .u-m-x14\@s {
        margin-left: 112px;
        margin-right: 112px
    }

    .u-m-x15\@s {
        margin-left: 120px;
        margin-right: 120px
    }

    .u-m-x16\@s {
        margin-left: 128px;
        margin-right: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-m-x0\@xs {
        margin-left: 0;
        margin-right: 0
    }

    .u-m-x1\@xs {
        margin-left: 8px;
        margin-right: 8px
    }

    .u-m-x2\@xs {
        margin-left: 16px;
        margin-right: 16px
    }

    .u-m-x3\@xs {
        margin-left: 24px;
        margin-right: 24px
    }

    .u-m-x4\@xs {
        margin-left: 32px;
        margin-right: 32px
    }

    .u-m-x5\@xs {
        margin-left: 40px;
        margin-right: 40px
    }

    .u-m-x6\@xs {
        margin-left: 48px;
        margin-right: 48px
    }

    .u-m-x7\@xs {
        margin-left: 56px;
        margin-right: 56px
    }

    .u-m-x8\@xs {
        margin-left: 64px;
        margin-right: 64px
    }

    .u-m-x9\@xs {
        margin-left: 72px;
        margin-right: 72px
    }

    .u-m-x10\@xs {
        margin-left: 80px;
        margin-right: 80px
    }

    .u-m-x11\@xs {
        margin-left: 88px;
        margin-right: 88px
    }

    .u-m-x12\@xs {
        margin-left: 96px;
        margin-right: 96px
    }

    .u-m-x13\@xs {
        margin-left: 104px;
        margin-right: 104px
    }

    .u-m-x14\@xs {
        margin-left: 112px;
        margin-right: 112px
    }

    .u-m-x15\@xs {
        margin-left: 120px;
        margin-right: 120px
    }

    .u-m-x16\@xs {
        margin-left: 128px;
        margin-right: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-m-y0\@xl {
        margin-top: 0;
        margin-bottom: 0
    }

    .u-m-y1\@xl {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .u-m-y2\@xl {
        margin-top: 16px;
        margin-bottom: 16px
    }

    .u-m-y3\@xl {
        margin-top: 24px;
        margin-bottom: 24px
    }

    .u-m-y4\@xl {
        margin-top: 32px;
        margin-bottom: 32px
    }

    .u-m-y5\@xl {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .u-m-y6\@xl {
        margin-top: 48px;
        margin-bottom: 48px
    }

    .u-m-y7\@xl {
        margin-top: 56px;
        margin-bottom: 56px
    }

    .u-m-y8\@xl {
        margin-top: 64px;
        margin-bottom: 64px
    }

    .u-m-y9\@xl {
        margin-top: 72px;
        margin-bottom: 72px
    }

    .u-m-y10\@xl {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .u-m-y11\@xl {
        margin-top: 88px;
        margin-bottom: 88px
    }

    .u-m-y12\@xl {
        margin-top: 96px;
        margin-bottom: 96px
    }

    .u-m-y13\@xl {
        margin-top: 104px;
        margin-bottom: 104px
    }

    .u-m-y14\@xl {
        margin-top: 112px;
        margin-bottom: 112px
    }

    .u-m-y15\@xl {
        margin-top: 120px;
        margin-bottom: 120px
    }

    .u-m-y16\@xl {
        margin-top: 128px;
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-m-y0\@l {
        margin-top: 0;
        margin-bottom: 0
    }

    .u-m-y1\@l {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .u-m-y2\@l {
        margin-top: 16px;
        margin-bottom: 16px
    }

    .u-m-y3\@l {
        margin-top: 24px;
        margin-bottom: 24px
    }

    .u-m-y4\@l {
        margin-top: 32px;
        margin-bottom: 32px
    }

    .u-m-y5\@l {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .u-m-y6\@l {
        margin-top: 48px;
        margin-bottom: 48px
    }

    .u-m-y7\@l {
        margin-top: 56px;
        margin-bottom: 56px
    }

    .u-m-y8\@l {
        margin-top: 64px;
        margin-bottom: 64px
    }

    .u-m-y9\@l {
        margin-top: 72px;
        margin-bottom: 72px
    }

    .u-m-y10\@l {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .u-m-y11\@l {
        margin-top: 88px;
        margin-bottom: 88px
    }

    .u-m-y12\@l {
        margin-top: 96px;
        margin-bottom: 96px
    }

    .u-m-y13\@l {
        margin-top: 104px;
        margin-bottom: 104px
    }

    .u-m-y14\@l {
        margin-top: 112px;
        margin-bottom: 112px
    }

    .u-m-y15\@l {
        margin-top: 120px;
        margin-bottom: 120px
    }

    .u-m-y16\@l {
        margin-top: 128px;
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-m-y0\@m {
        margin-top: 0;
        margin-bottom: 0
    }

    .u-m-y1\@m {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .u-m-y2\@m {
        margin-top: 16px;
        margin-bottom: 16px
    }

    .u-m-y3\@m {
        margin-top: 24px;
        margin-bottom: 24px
    }

    .u-m-y4\@m {
        margin-top: 32px;
        margin-bottom: 32px
    }

    .u-m-y5\@m {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .u-m-y6\@m {
        margin-top: 48px;
        margin-bottom: 48px
    }

    .u-m-y7\@m {
        margin-top: 56px;
        margin-bottom: 56px
    }

    .u-m-y8\@m {
        margin-top: 64px;
        margin-bottom: 64px
    }

    .u-m-y9\@m {
        margin-top: 72px;
        margin-bottom: 72px
    }

    .u-m-y10\@m {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .u-m-y11\@m {
        margin-top: 88px;
        margin-bottom: 88px
    }

    .u-m-y12\@m {
        margin-top: 96px;
        margin-bottom: 96px
    }

    .u-m-y13\@m {
        margin-top: 104px;
        margin-bottom: 104px
    }

    .u-m-y14\@m {
        margin-top: 112px;
        margin-bottom: 112px
    }

    .u-m-y15\@m {
        margin-top: 120px;
        margin-bottom: 120px
    }

    .u-m-y16\@m {
        margin-top: 128px;
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-m-y0\@s {
        margin-top: 0;
        margin-bottom: 0
    }

    .u-m-y1\@s {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .u-m-y2\@s {
        margin-top: 16px;
        margin-bottom: 16px
    }

    .u-m-y3\@s {
        margin-top: 24px;
        margin-bottom: 24px
    }

    .u-m-y4\@s {
        margin-top: 32px;
        margin-bottom: 32px
    }

    .u-m-y5\@s {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .u-m-y6\@s {
        margin-top: 48px;
        margin-bottom: 48px
    }

    .u-m-y7\@s {
        margin-top: 56px;
        margin-bottom: 56px
    }

    .u-m-y8\@s {
        margin-top: 64px;
        margin-bottom: 64px
    }

    .u-m-y9\@s {
        margin-top: 72px;
        margin-bottom: 72px
    }

    .u-m-y10\@s {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .u-m-y11\@s {
        margin-top: 88px;
        margin-bottom: 88px
    }

    .u-m-y12\@s {
        margin-top: 96px;
        margin-bottom: 96px
    }

    .u-m-y13\@s {
        margin-top: 104px;
        margin-bottom: 104px
    }

    .u-m-y14\@s {
        margin-top: 112px;
        margin-bottom: 112px
    }

    .u-m-y15\@s {
        margin-top: 120px;
        margin-bottom: 120px
    }

    .u-m-y16\@s {
        margin-top: 128px;
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-m-y0\@xs {
        margin-top: 0;
        margin-bottom: 0
    }

    .u-m-y1\@xs {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .u-m-y2\@xs {
        margin-top: 16px;
        margin-bottom: 16px
    }

    .u-m-y3\@xs {
        margin-top: 24px;
        margin-bottom: 24px
    }

    .u-m-y4\@xs {
        margin-top: 32px;
        margin-bottom: 32px
    }

    .u-m-y5\@xs {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .u-m-y6\@xs {
        margin-top: 48px;
        margin-bottom: 48px
    }

    .u-m-y7\@xs {
        margin-top: 56px;
        margin-bottom: 56px
    }

    .u-m-y8\@xs {
        margin-top: 64px;
        margin-bottom: 64px
    }

    .u-m-y9\@xs {
        margin-top: 72px;
        margin-bottom: 72px
    }

    .u-m-y10\@xs {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .u-m-y11\@xs {
        margin-top: 88px;
        margin-bottom: 88px
    }

    .u-m-y12\@xs {
        margin-top: 96px;
        margin-bottom: 96px
    }

    .u-m-y13\@xs {
        margin-top: 104px;
        margin-bottom: 104px
    }

    .u-m-y14\@xs {
        margin-top: 112px;
        margin-bottom: 112px
    }

    .u-m-y15\@xs {
        margin-top: 120px;
        margin-bottom: 120px
    }

    .u-m-y16\@xs {
        margin-top: 128px;
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-m-t0\@xl {
        margin-top: 0
    }

    .u-m-t1\@xl {
        margin-top: 8px
    }

    .u-m-t2\@xl {
        margin-top: 16px
    }

    .u-m-t3\@xl {
        margin-top: 24px
    }

    .u-m-t4\@xl {
        margin-top: 32px
    }

    .u-m-t5\@xl {
        margin-top: 40px
    }

    .u-m-t6\@xl {
        margin-top: 48px
    }

    .u-m-t7\@xl {
        margin-top: 56px
    }

    .u-m-t8\@xl {
        margin-top: 64px
    }

    .u-m-t9\@xl {
        margin-top: 72px
    }

    .u-m-t10\@xl {
        margin-top: 80px
    }

    .u-m-t11\@xl {
        margin-top: 88px
    }

    .u-m-t12\@xl {
        margin-top: 96px
    }

    .u-m-t13\@xl {
        margin-top: 104px
    }

    .u-m-t14\@xl {
        margin-top: 112px
    }

    .u-m-t15\@xl {
        margin-top: 120px
    }

    .u-m-t16\@xl {
        margin-top: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-m-t0\@l {
        margin-top: 0
    }

    .u-m-t1\@l {
        margin-top: 8px
    }

    .u-m-t2\@l {
        margin-top: 16px
    }

    .u-m-t3\@l {
        margin-top: 24px
    }

    .u-m-t4\@l {
        margin-top: 32px
    }

    .u-m-t5\@l {
        margin-top: 40px
    }

    .u-m-t6\@l {
        margin-top: 48px
    }

    .u-m-t7\@l {
        margin-top: 56px
    }

    .u-m-t8\@l {
        margin-top: 64px
    }

    .u-m-t9\@l {
        margin-top: 72px
    }

    .u-m-t10\@l {
        margin-top: 80px
    }

    .u-m-t11\@l {
        margin-top: 88px
    }

    .u-m-t12\@l {
        margin-top: 96px
    }

    .u-m-t13\@l {
        margin-top: 104px
    }

    .u-m-t14\@l {
        margin-top: 112px
    }

    .u-m-t15\@l {
        margin-top: 120px
    }

    .u-m-t16\@l {
        margin-top: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-m-t0\@m {
        margin-top: 0
    }

    .u-m-t1\@m {
        margin-top: 8px
    }

    .u-m-t2\@m {
        margin-top: 16px
    }

    .u-m-t3\@m {
        margin-top: 24px
    }

    .u-m-t4\@m {
        margin-top: 32px
    }

    .u-m-t5\@m {
        margin-top: 40px
    }

    .u-m-t6\@m {
        margin-top: 48px
    }

    .u-m-t7\@m {
        margin-top: 56px
    }

    .u-m-t8\@m {
        margin-top: 64px
    }

    .u-m-t9\@m {
        margin-top: 72px
    }

    .u-m-t10\@m {
        margin-top: 80px
    }

    .u-m-t11\@m {
        margin-top: 88px
    }

    .u-m-t12\@m {
        margin-top: 96px
    }

    .u-m-t13\@m {
        margin-top: 104px
    }

    .u-m-t14\@m {
        margin-top: 112px
    }

    .u-m-t15\@m {
        margin-top: 120px
    }

    .u-m-t16\@m {
        margin-top: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-m-t0\@s {
        margin-top: 0
    }

    .u-m-t1\@s {
        margin-top: 8px
    }

    .u-m-t2\@s {
        margin-top: 16px
    }

    .u-m-t3\@s {
        margin-top: 24px
    }

    .u-m-t4\@s {
        margin-top: 32px
    }

    .u-m-t5\@s {
        margin-top: 40px
    }

    .u-m-t6\@s {
        margin-top: 48px
    }

    .u-m-t7\@s {
        margin-top: 56px
    }

    .u-m-t8\@s {
        margin-top: 64px
    }

    .u-m-t9\@s {
        margin-top: 72px
    }

    .u-m-t10\@s {
        margin-top: 80px
    }

    .u-m-t11\@s {
        margin-top: 88px
    }

    .u-m-t12\@s {
        margin-top: 96px
    }

    .u-m-t13\@s {
        margin-top: 104px
    }

    .u-m-t14\@s {
        margin-top: 112px
    }

    .u-m-t15\@s {
        margin-top: 120px
    }

    .u-m-t16\@s {
        margin-top: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-m-t0\@xs {
        margin-top: 0
    }

    .u-m-t1\@xs {
        margin-top: 8px
    }

    .u-m-t2\@xs {
        margin-top: 16px
    }

    .u-m-t3\@xs {
        margin-top: 24px
    }

    .u-m-t4\@xs {
        margin-top: 32px
    }

    .u-m-t5\@xs {
        margin-top: 40px
    }

    .u-m-t6\@xs {
        margin-top: 48px
    }

    .u-m-t7\@xs {
        margin-top: 56px
    }

    .u-m-t8\@xs {
        margin-top: 64px
    }

    .u-m-t9\@xs {
        margin-top: 72px
    }

    .u-m-t10\@xs {
        margin-top: 80px
    }

    .u-m-t11\@xs {
        margin-top: 88px
    }

    .u-m-t12\@xs {
        margin-top: 96px
    }

    .u-m-t13\@xs {
        margin-top: 104px
    }

    .u-m-t14\@xs {
        margin-top: 112px
    }

    .u-m-t15\@xs {
        margin-top: 120px
    }

    .u-m-t16\@xs {
        margin-top: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-m-r0\@xl {
        margin-right: 0
    }

    .u-m-r1\@xl {
        margin-right: 8px
    }

    .u-m-r2\@xl {
        margin-right: 16px
    }

    .u-m-r3\@xl {
        margin-right: 24px
    }

    .u-m-r4\@xl {
        margin-right: 32px
    }

    .u-m-r5\@xl {
        margin-right: 40px
    }

    .u-m-r6\@xl {
        margin-right: 48px
    }

    .u-m-r7\@xl {
        margin-right: 56px
    }

    .u-m-r8\@xl {
        margin-right: 64px
    }

    .u-m-r9\@xl {
        margin-right: 72px
    }

    .u-m-r10\@xl {
        margin-right: 80px
    }

    .u-m-r11\@xl {
        margin-right: 88px
    }

    .u-m-r12\@xl {
        margin-right: 96px
    }

    .u-m-r13\@xl {
        margin-right: 104px
    }

    .u-m-r14\@xl {
        margin-right: 112px
    }

    .u-m-r15\@xl {
        margin-right: 120px
    }

    .u-m-r16\@xl {
        margin-right: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-m-r0\@l {
        margin-right: 0
    }

    .u-m-r1\@l {
        margin-right: 8px
    }

    .u-m-r2\@l {
        margin-right: 16px
    }

    .u-m-r3\@l {
        margin-right: 24px
    }

    .u-m-r4\@l {
        margin-right: 32px
    }

    .u-m-r5\@l {
        margin-right: 40px
    }

    .u-m-r6\@l {
        margin-right: 48px
    }

    .u-m-r7\@l {
        margin-right: 56px
    }

    .u-m-r8\@l {
        margin-right: 64px
    }

    .u-m-r9\@l {
        margin-right: 72px
    }

    .u-m-r10\@l {
        margin-right: 80px
    }

    .u-m-r11\@l {
        margin-right: 88px
    }

    .u-m-r12\@l {
        margin-right: 96px
    }

    .u-m-r13\@l {
        margin-right: 104px
    }

    .u-m-r14\@l {
        margin-right: 112px
    }

    .u-m-r15\@l {
        margin-right: 120px
    }

    .u-m-r16\@l {
        margin-right: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-m-r0\@m {
        margin-right: 0
    }

    .u-m-r1\@m {
        margin-right: 8px
    }

    .u-m-r2\@m {
        margin-right: 16px
    }

    .u-m-r3\@m {
        margin-right: 24px
    }

    .u-m-r4\@m {
        margin-right: 32px
    }

    .u-m-r5\@m {
        margin-right: 40px
    }

    .u-m-r6\@m {
        margin-right: 48px
    }

    .u-m-r7\@m {
        margin-right: 56px
    }

    .u-m-r8\@m {
        margin-right: 64px
    }

    .u-m-r9\@m {
        margin-right: 72px
    }

    .u-m-r10\@m {
        margin-right: 80px
    }

    .u-m-r11\@m {
        margin-right: 88px
    }

    .u-m-r12\@m {
        margin-right: 96px
    }

    .u-m-r13\@m {
        margin-right: 104px
    }

    .u-m-r14\@m {
        margin-right: 112px
    }

    .u-m-r15\@m {
        margin-right: 120px
    }

    .u-m-r16\@m {
        margin-right: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-m-r0\@s {
        margin-right: 0
    }

    .u-m-r1\@s {
        margin-right: 8px
    }

    .u-m-r2\@s {
        margin-right: 16px
    }

    .u-m-r3\@s {
        margin-right: 24px
    }

    .u-m-r4\@s {
        margin-right: 32px
    }

    .u-m-r5\@s {
        margin-right: 40px
    }

    .u-m-r6\@s {
        margin-right: 48px
    }

    .u-m-r7\@s {
        margin-right: 56px
    }

    .u-m-r8\@s {
        margin-right: 64px
    }

    .u-m-r9\@s {
        margin-right: 72px
    }

    .u-m-r10\@s {
        margin-right: 80px
    }

    .u-m-r11\@s {
        margin-right: 88px
    }

    .u-m-r12\@s {
        margin-right: 96px
    }

    .u-m-r13\@s {
        margin-right: 104px
    }

    .u-m-r14\@s {
        margin-right: 112px
    }

    .u-m-r15\@s {
        margin-right: 120px
    }

    .u-m-r16\@s {
        margin-right: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-m-r0\@xs {
        margin-right: 0
    }

    .u-m-r1\@xs {
        margin-right: 8px
    }

    .u-m-r2\@xs {
        margin-right: 16px
    }

    .u-m-r3\@xs {
        margin-right: 24px
    }

    .u-m-r4\@xs {
        margin-right: 32px
    }

    .u-m-r5\@xs {
        margin-right: 40px
    }

    .u-m-r6\@xs {
        margin-right: 48px
    }

    .u-m-r7\@xs {
        margin-right: 56px
    }

    .u-m-r8\@xs {
        margin-right: 64px
    }

    .u-m-r9\@xs {
        margin-right: 72px
    }

    .u-m-r10\@xs {
        margin-right: 80px
    }

    .u-m-r11\@xs {
        margin-right: 88px
    }

    .u-m-r12\@xs {
        margin-right: 96px
    }

    .u-m-r13\@xs {
        margin-right: 104px
    }

    .u-m-r14\@xs {
        margin-right: 112px
    }

    .u-m-r15\@xs {
        margin-right: 120px
    }

    .u-m-r16\@xs {
        margin-right: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-m-b0\@xl {
        margin-bottom: 0
    }

    .u-m-b1\@xl {
        margin-bottom: 8px
    }

    .u-m-b2\@xl {
        margin-bottom: 16px
    }

    .u-m-b3\@xl {
        margin-bottom: 24px
    }

    .u-m-b4\@xl {
        margin-bottom: 32px
    }

    .u-m-b5\@xl {
        margin-bottom: 40px
    }

    .u-m-b6\@xl {
        margin-bottom: 48px
    }

    .u-m-b7\@xl {
        margin-bottom: 56px
    }

    .u-m-b8\@xl {
        margin-bottom: 64px
    }

    .u-m-b9\@xl {
        margin-bottom: 72px
    }

    .u-m-b10\@xl {
        margin-bottom: 80px
    }

    .u-m-b11\@xl {
        margin-bottom: 88px
    }

    .u-m-b12\@xl {
        margin-bottom: 96px
    }

    .u-m-b13\@xl {
        margin-bottom: 104px
    }

    .u-m-b14\@xl {
        margin-bottom: 112px
    }

    .u-m-b15\@xl {
        margin-bottom: 120px
    }

    .u-m-b16\@xl {
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-m-b0\@l {
        margin-bottom: 0
    }

    .u-m-b1\@l {
        margin-bottom: 8px
    }

    .u-m-b2\@l {
        margin-bottom: 16px
    }

    .u-m-b3\@l {
        margin-bottom: 24px
    }

    .u-m-b4\@l {
        margin-bottom: 32px
    }

    .u-m-b5\@l {
        margin-bottom: 40px
    }

    .u-m-b6\@l {
        margin-bottom: 48px
    }

    .u-m-b7\@l {
        margin-bottom: 56px
    }

    .u-m-b8\@l {
        margin-bottom: 64px
    }

    .u-m-b9\@l {
        margin-bottom: 72px
    }

    .u-m-b10\@l {
        margin-bottom: 80px
    }

    .u-m-b11\@l {
        margin-bottom: 88px
    }

    .u-m-b12\@l {
        margin-bottom: 96px
    }

    .u-m-b13\@l {
        margin-bottom: 104px
    }

    .u-m-b14\@l {
        margin-bottom: 112px
    }

    .u-m-b15\@l {
        margin-bottom: 120px
    }

    .u-m-b16\@l {
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-m-b0\@m {
        margin-bottom: 0
    }

    .u-m-b1\@m {
        margin-bottom: 8px
    }

    .u-m-b2\@m {
        margin-bottom: 16px
    }

    .u-m-b3\@m {
        margin-bottom: 24px
    }

    .u-m-b4\@m {
        margin-bottom: 32px
    }

    .u-m-b5\@m {
        margin-bottom: 40px
    }

    .u-m-b6\@m {
        margin-bottom: 48px
    }

    .u-m-b7\@m {
        margin-bottom: 56px
    }

    .u-m-b8\@m {
        margin-bottom: 64px
    }

    .u-m-b9\@m {
        margin-bottom: 72px
    }

    .u-m-b10\@m {
        margin-bottom: 80px
    }

    .u-m-b11\@m {
        margin-bottom: 88px
    }

    .u-m-b12\@m {
        margin-bottom: 96px
    }

    .u-m-b13\@m {
        margin-bottom: 104px
    }

    .u-m-b14\@m {
        margin-bottom: 112px
    }

    .u-m-b15\@m {
        margin-bottom: 120px
    }

    .u-m-b16\@m {
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-m-b0\@s {
        margin-bottom: 0
    }

    .u-m-b1\@s {
        margin-bottom: 8px
    }

    .u-m-b2\@s {
        margin-bottom: 16px
    }

    .u-m-b3\@s {
        margin-bottom: 24px
    }

    .u-m-b4\@s {
        margin-bottom: 32px
    }

    .u-m-b5\@s {
        margin-bottom: 40px
    }

    .u-m-b6\@s {
        margin-bottom: 48px
    }

    .u-m-b7\@s {
        margin-bottom: 56px
    }

    .u-m-b8\@s {
        margin-bottom: 64px
    }

    .u-m-b9\@s {
        margin-bottom: 72px
    }

    .u-m-b10\@s {
        margin-bottom: 80px
    }

    .u-m-b11\@s {
        margin-bottom: 88px
    }

    .u-m-b12\@s {
        margin-bottom: 96px
    }

    .u-m-b13\@s {
        margin-bottom: 104px
    }

    .u-m-b14\@s {
        margin-bottom: 112px
    }

    .u-m-b15\@s {
        margin-bottom: 120px
    }

    .u-m-b16\@s {
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-m-b0\@xs {
        margin-bottom: 0
    }

    .u-m-b1\@xs {
        margin-bottom: 8px
    }

    .u-m-b2\@xs {
        margin-bottom: 16px
    }

    .u-m-b3\@xs {
        margin-bottom: 24px
    }

    .u-m-b4\@xs {
        margin-bottom: 32px
    }

    .u-m-b5\@xs {
        margin-bottom: 40px
    }

    .u-m-b6\@xs {
        margin-bottom: 48px
    }

    .u-m-b7\@xs {
        margin-bottom: 56px
    }

    .u-m-b8\@xs {
        margin-bottom: 64px
    }

    .u-m-b9\@xs {
        margin-bottom: 72px
    }

    .u-m-b10\@xs {
        margin-bottom: 80px
    }

    .u-m-b11\@xs {
        margin-bottom: 88px
    }

    .u-m-b12\@xs {
        margin-bottom: 96px
    }

    .u-m-b13\@xs {
        margin-bottom: 104px
    }

    .u-m-b14\@xs {
        margin-bottom: 112px
    }

    .u-m-b15\@xs {
        margin-bottom: 120px
    }

    .u-m-b16\@xs {
        margin-bottom: 128px
    }
}

@media only screen and (max-width: 1279px) {
    .u-m-l0\@xl {
        margin-left: 0
    }

    .u-m-l1\@xl {
        margin-left: 8px
    }

    .u-m-l2\@xl {
        margin-left: 16px
    }

    .u-m-l3\@xl {
        margin-left: 24px
    }

    .u-m-l4\@xl {
        margin-left: 32px
    }

    .u-m-l5\@xl {
        margin-left: 40px
    }

    .u-m-l6\@xl {
        margin-left: 48px
    }

    .u-m-l7\@xl {
        margin-left: 56px
    }

    .u-m-l8\@xl {
        margin-left: 64px
    }

    .u-m-l9\@xl {
        margin-left: 72px
    }

    .u-m-l10\@xl {
        margin-left: 80px
    }

    .u-m-l11\@xl {
        margin-left: 88px
    }

    .u-m-l12\@xl {
        margin-left: 96px
    }

    .u-m-l13\@xl {
        margin-left: 104px
    }

    .u-m-l14\@xl {
        margin-left: 112px
    }

    .u-m-l15\@xl {
        margin-left: 120px
    }

    .u-m-l16\@xl {
        margin-left: 128px
    }
}

@media only screen and (max-width: 1020px) {
    .u-m-l0\@l {
        margin-left: 0
    }

    .u-m-l1\@l {
        margin-left: 8px
    }

    .u-m-l2\@l {
        margin-left: 16px
    }

    .u-m-l3\@l {
        margin-left: 24px
    }

    .u-m-l4\@l {
        margin-left: 32px
    }

    .u-m-l5\@l {
        margin-left: 40px
    }

    .u-m-l6\@l {
        margin-left: 48px
    }

    .u-m-l7\@l {
        margin-left: 56px
    }

    .u-m-l8\@l {
        margin-left: 64px
    }

    .u-m-l9\@l {
        margin-left: 72px
    }

    .u-m-l10\@l {
        margin-left: 80px
    }

    .u-m-l11\@l {
        margin-left: 88px
    }

    .u-m-l12\@l {
        margin-left: 96px
    }

    .u-m-l13\@l {
        margin-left: 104px
    }

    .u-m-l14\@l {
        margin-left: 112px
    }

    .u-m-l15\@l {
        margin-left: 120px
    }

    .u-m-l16\@l {
        margin-left: 128px
    }
}

@media only screen and (max-width: 760px) {
    .u-m-l0\@m {
        margin-left: 0
    }

    .u-m-l1\@m {
        margin-left: 8px
    }

    .u-m-l2\@m {
        margin-left: 16px
    }

    .u-m-l3\@m {
        margin-left: 24px
    }

    .u-m-l4\@m {
        margin-left: 32px
    }

    .u-m-l5\@m {
        margin-left: 40px
    }

    .u-m-l6\@m {
        margin-left: 48px
    }

    .u-m-l7\@m {
        margin-left: 56px
    }

    .u-m-l8\@m {
        margin-left: 64px
    }

    .u-m-l9\@m {
        margin-left: 72px
    }

    .u-m-l10\@m {
        margin-left: 80px
    }

    .u-m-l11\@m {
        margin-left: 88px
    }

    .u-m-l12\@m {
        margin-left: 96px
    }

    .u-m-l13\@m {
        margin-left: 104px
    }

    .u-m-l14\@m {
        margin-left: 112px
    }

    .u-m-l15\@m {
        margin-left: 120px
    }

    .u-m-l16\@m {
        margin-left: 128px
    }
}

@media only screen and (max-width: 479px) {
    .u-m-l0\@s {
        margin-left: 0
    }

    .u-m-l1\@s {
        margin-left: 8px
    }

    .u-m-l2\@s {
        margin-left: 16px
    }

    .u-m-l3\@s {
        margin-left: 24px
    }

    .u-m-l4\@s {
        margin-left: 32px
    }

    .u-m-l5\@s {
        margin-left: 40px
    }

    .u-m-l6\@s {
        margin-left: 48px
    }

    .u-m-l7\@s {
        margin-left: 56px
    }

    .u-m-l8\@s {
        margin-left: 64px
    }

    .u-m-l9\@s {
        margin-left: 72px
    }

    .u-m-l10\@s {
        margin-left: 80px
    }

    .u-m-l11\@s {
        margin-left: 88px
    }

    .u-m-l12\@s {
        margin-left: 96px
    }

    .u-m-l13\@s {
        margin-left: 104px
    }

    .u-m-l14\@s {
        margin-left: 112px
    }

    .u-m-l15\@s {
        margin-left: 120px
    }

    .u-m-l16\@s {
        margin-left: 128px
    }
}

@media only screen and (max-width: 375px) {
    .u-m-l0\@xs {
        margin-left: 0
    }

    .u-m-l1\@xs {
        margin-left: 8px
    }

    .u-m-l2\@xs {
        margin-left: 16px
    }

    .u-m-l3\@xs {
        margin-left: 24px
    }

    .u-m-l4\@xs {
        margin-left: 32px
    }

    .u-m-l5\@xs {
        margin-left: 40px
    }

    .u-m-l6\@xs {
        margin-left: 48px
    }

    .u-m-l7\@xs {
        margin-left: 56px
    }

    .u-m-l8\@xs {
        margin-left: 64px
    }

    .u-m-l9\@xs {
        margin-left: 72px
    }

    .u-m-l10\@xs {
        margin-left: 80px
    }

    .u-m-l11\@xs {
        margin-left: 88px
    }

    .u-m-l12\@xs {
        margin-left: 96px
    }

    .u-m-l13\@xs {
        margin-left: 104px
    }

    .u-m-l14\@xs {
        margin-left: 112px
    }

    .u-m-l15\@xs {
        margin-left: 120px
    }

    .u-m-l16\@xs {
        margin-left: 128px
    }
}

.u-flex-justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.u-flex-justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.u-flex-justify-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.u-flex-align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.u-flex-align-flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.u-flex-align-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.u-flex-align-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.u-flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.u-flex-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.u-flex-direction-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

@media only screen and (max-width: 1279px) {
    .u-flex\@xl {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .u-flex-justify-center\@xl {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .u-flex-justify-between\@xl {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .u-flex-justify-end\@xl {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .u-flex-align-start\@xl {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .u-flex-align-center\@xl {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .u-flex-align-stretch\@xl {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .u-flex-align-flex-end\@xl {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .u-flex-wrap\@xl {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .u-flex-nowrap\@xl {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .u-flex-direction-column\@xl {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media only screen and (max-width: 1020px) {
    .u-flex\@l {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .u-flex-justify-center\@l {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .u-flex-justify-between\@l {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .u-flex-justify-end\@l {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .u-flex-align-start\@l {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .u-flex-align-center\@l {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .u-flex-align-stretch\@l {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .u-flex-align-flex-end\@l {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .u-flex-wrap\@l {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .u-flex-nowrap\@l {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .u-flex-direction-column\@l {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media only screen and (max-width: 760px) {
    .u-flex\@m {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .u-flex-justify-center\@m {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .u-flex-justify-between\@m {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .u-flex-justify-end\@m {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .u-flex-align-start\@m {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .u-flex-align-center\@m {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .u-flex-align-stretch\@m {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .u-flex-align-flex-end\@m {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .u-flex-wrap\@m {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .u-flex-nowrap\@m {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .u-flex-direction-column\@m {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media only screen and (max-width: 479px) {
    .u-flex\@s {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .u-flex-justify-center\@s {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .u-flex-justify-between\@s {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .u-flex-justify-end\@s {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .u-flex-align-start\@s {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .u-flex-align-center\@s {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .u-flex-align-stretch\@s {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .u-flex-align-flex-end\@s {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .u-flex-wrap\@s {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .u-flex-nowrap\@s {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .u-flex-direction-column\@s {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media only screen and (max-width: 375px) {
    .u-flex\@xs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .u-flex-justify-center\@xs {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .u-flex-justify-between\@xs {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .u-flex-justify-end\@xs {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .u-flex-align-start\@xs {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .u-flex-align-center\@xs {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .u-flex-align-stretch\@xs {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .u-flex-align-flex-end\@xs {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .u-flex-wrap\@xs {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .u-flex-nowrap\@xs {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .u-flex-direction-column\@xs {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.u-height-a {
    height: auto
}

.u-height-full {
    height: 100%
}

.u-height-2\/4,
.u-height-3\/6,
.u-height-4\/8,
.u-height-5\/10,
.u-height-6\/12,
.u-height-half {
    height: 50%
}

.u-height-1\/3,
.u-height-2\/6,
.u-height-3\/9,
.u-height-4\/12 {
    height: 33.333%
}

.u-height-2\/3,
.u-height-4\/6,
.u-height-6\/9,
.u-height-8\/12 {
    height: 66.666%
}

.u-height-1\/4,
.u-height-2\/8,
.u-height-3\/12 {
    height: 25%
}

.u-height-3\/4,
.u-height-6\/8,
.u-height-9\/12 {
    height: 75%
}

.u-height-1\/5,
.u-height-2\/10 {
    height: 20%
}

.u-height-2\/5,
.u-height-4\/10 {
    height: 40%
}

.u-height-3\/5,
.u-height-6\/10 {
    height: 60%
}

.u-height-4\/5,
.u-height-8\/10 {
    height: 80%
}

.u-height-1\/6,
.u-height-2\/12 {
    height: 16.666%
}

.u-height-10\/12,
.u-height-5\/6 {
    height: 83.333%
}

.u-height-1\/8 {
    height: 12.5%
}

.u-height-3\/8 {
    height: 37.5%
}

.u-height-5\/8 {
    height: 62.5%
}

.u-height-7\/8 {
    height: 87.5%
}

.u-height-1\/9 {
    height: 11.111%
}

.u-height-2\/9 {
    height: 22.222%
}

.u-height-4\/9 {
    height: 44.444%
}

.u-height-5\/9 {
    height: 55.556%
}

.u-height-7\/9 {
    height: 77.778%
}

.u-height-8\/9 {
    height: 88.889%
}

.u-height-1\/10 {
    height: 10%
}

.u-height-3\/10 {
    height: 30%
}

.u-height-7\/10 {
    height: 70%
}

.u-height-9\/10 {
    height: 90%
}

.u-height-1\/12 {
    height: 8.333%
}

.u-height-5\/12 {
    height: 41.666%
}

.u-height-7\/12 {
    height: 58.333%
}

.u-height-11\/12 {
    height: 91.666%
}

.u-height-full {
    min-height: 100%
}

.u-height-2\/4,
.u-height-3\/6,
.u-height-4\/8,
.u-height-5\/10,
.u-height-6\/12,
.u-height-half {
    min-height: 50%
}

.u-height-1\/3,
.u-height-2\/6,
.u-height-3\/9,
.u-height-4\/12 {
    min-height: 33.333%
}

.u-height-2\/3,
.u-height-4\/6,
.u-height-6\/9,
.u-height-8\/12 {
    min-height: 66.666%
}

.u-height-1\/4,
.u-height-2\/8,
.u-height-3\/12 {
    min-height: 25%
}

.u-height-3\/4,
.u-height-6\/8,
.u-height-9\/12 {
    min-height: 75%
}

.u-height-1\/5,
.u-height-2\/10 {
    min-height: 20%
}

.u-height-2\/5,
.u-height-4\/10 {
    min-height: 40%
}

.u-height-3\/5,
.u-height-6\/10 {
    min-height: 60%
}

.u-height-4\/5,
.u-height-8\/10 {
    min-height: 80%
}

.u-height-1\/6,
.u-height-2\/12 {
    min-height: 16.666%
}

.u-height-10\/12,
.u-height-5\/6 {
    min-height: 83.333%
}

.u-height-1\/8 {
    min-height: 12.5%
}

.u-height-3\/8 {
    min-height: 37.5%
}

.u-height-5\/8 {
    min-height: 62.5%
}

.u-height-7\/8 {
    min-height: 87.5%
}

.u-height-1\/9 {
    min-height: 11.111%
}

.u-height-2\/9 {
    min-height: 22.222%
}

.u-height-4\/9 {
    min-height: 44.444%
}

.u-height-5\/9 {
    min-height: 55.556%
}

.u-height-7\/9 {
    min-height: 77.778%
}

.u-height-8\/9 {
    min-height: 88.889%
}

.u-height-1\/10 {
    min-height: 10%
}

.u-height-3\/10 {
    min-height: 30%
}

.u-height-7\/10 {
    min-height: 70%
}

.u-height-9\/10 {
    min-height: 90%
}

.u-height-1\/12 {
    min-height: 8.333%
}

.u-height-5\/12 {
    min-height: 41.666%
}

.u-height-7\/12 {
    min-height: 58.333%
}

.u-height-11\/12 {
    min-height: 91.666%
}

@media only screen and (max-width: 1279px) {
    .u-height-full\@xl {
        height: 100%
    }

    .u-height-2\/4\@xl,
    .u-height-3\/6\@xl,
    .u-height-4\/8\@xl,
    .u-height-5\/10\@xl,
    .u-height-6\/12\@xl,
    .u-height-half\@xl {
        height: 50%
    }

    .u-height-1\/3\@xl,
    .u-height-2\/6\@xl,
    .u-height-3\/9\@xl,
    .u-height-4\/12\@xl {
        height: 33.333%
    }

    .u-height-2\/3\@xl,
    .u-height-4\/6\@xl,
    .u-height-6\/9\@xl,
    .u-height-8\/12\@xl {
        height: 66.666%
    }

    .u-height-1\/4\@xl,
    .u-height-2\/8\@xl,
    .u-height-3\/12\@xl {
        height: 25%
    }

    .u-height-3\/4\@xl,
    .u-height-6\/8\@xl,
    .u-height-9\/12\@xl {
        height: 75%
    }

    .u-height-1\/5\@xl,
    .u-height-2\/10\@xl {
        height: 20%
    }

    .u-height-2\/5\@xl,
    .u-height-4\/10\@xl {
        height: 40%
    }

    .u-height-3\/5\@xl,
    .u-height-6\/10\@xl {
        height: 60%
    }

    .u-height-4\/5\@xl,
    .u-height-8\/10\@xl {
        height: 80%
    }

    .u-height-1\/6\@xl,
    .u-height-2\/12\@xl {
        height: 16.666%
    }

    .u-height-10\/12\@xl,
    .u-height-5\/6\@xl {
        height: 83.333%
    }

    .u-height-1\/8\@xl {
        height: 12.5%
    }

    .u-height-3\/8\@xl {
        height: 37.5%
    }

    .u-height-5\/8\@xl {
        height: 62.5%
    }

    .u-height-7\/8\@xl {
        height: 87.5%
    }

    .u-height-1\/9\@xl {
        height: 11.111%
    }

    .u-height-2\/9\@xl {
        height: 22.222%
    }

    .u-height-4\/9\@xl {
        height: 44.444%
    }

    .u-height-5\/9\@xl {
        height: 55.556%
    }

    .u-height-7\/9\@xl {
        height: 77.778%
    }

    .u-height-8\/9\@xl {
        height: 88.889%
    }

    .u-height-1\/10\@xl {
        height: 10%
    }

    .u-height-3\/10\@xl {
        height: 30%
    }

    .u-height-7\/10\@xl {
        height: 70%
    }

    .u-height-9\/10\@xl {
        height: 90%
    }

    .u-height-1\/12\@xl {
        height: 8.333%
    }

    .u-height-5\/12\@xl {
        height: 41.666%
    }

    .u-height-7\/12\@xl {
        height: 58.333%
    }

    .u-height-11\/12\@xl {
        height: 91.666%
    }

    .u-height-full\@xl {
        min-height: 100%
    }

    .u-height-2\/4\@xl,
    .u-height-3\/6\@xl,
    .u-height-4\/8\@xl,
    .u-height-5\/10\@xl,
    .u-height-6\/12\@xl,
    .u-height-half\@xl {
        min-height: 50%
    }

    .u-height-1\/3\@xl,
    .u-height-2\/6\@xl,
    .u-height-3\/9\@xl,
    .u-height-4\/12\@xl {
        min-height: 33.333%
    }

    .u-height-2\/3\@xl,
    .u-height-4\/6\@xl,
    .u-height-6\/9\@xl,
    .u-height-8\/12\@xl {
        min-height: 66.666%
    }

    .u-height-1\/4\@xl,
    .u-height-2\/8\@xl,
    .u-height-3\/12\@xl {
        min-height: 25%
    }

    .u-height-3\/4\@xl,
    .u-height-6\/8\@xl,
    .u-height-9\/12\@xl {
        min-height: 75%
    }

    .u-height-1\/5\@xl,
    .u-height-2\/10\@xl {
        min-height: 20%
    }

    .u-height-2\/5\@xl,
    .u-height-4\/10\@xl {
        min-height: 40%
    }

    .u-height-3\/5\@xl,
    .u-height-6\/10\@xl {
        min-height: 60%
    }

    .u-height-4\/5\@xl,
    .u-height-8\/10\@xl {
        min-height: 80%
    }

    .u-height-1\/6\@xl,
    .u-height-2\/12\@xl {
        min-height: 16.666%
    }

    .u-height-10\/12\@xl,
    .u-height-5\/6\@xl {
        min-height: 83.333%
    }

    .u-height-1\/8\@xl {
        min-height: 12.5%
    }

    .u-height-3\/8\@xl {
        min-height: 37.5%
    }

    .u-height-5\/8\@xl {
        min-height: 62.5%
    }

    .u-height-7\/8\@xl {
        min-height: 87.5%
    }

    .u-height-1\/9\@xl {
        min-height: 11.111%
    }

    .u-height-2\/9\@xl {
        min-height: 22.222%
    }

    .u-height-4\/9\@xl {
        min-height: 44.444%
    }

    .u-height-5\/9\@xl {
        min-height: 55.556%
    }

    .u-height-7\/9\@xl {
        min-height: 77.778%
    }

    .u-height-8\/9\@xl {
        min-height: 88.889%
    }

    .u-height-1\/10\@xl {
        min-height: 10%
    }

    .u-height-3\/10\@xl {
        min-height: 30%
    }

    .u-height-7\/10\@xl {
        min-height: 70%
    }

    .u-height-9\/10\@xl {
        min-height: 90%
    }

    .u-height-1\/12\@xl {
        min-height: 8.333%
    }

    .u-height-5\/12\@xl {
        min-height: 41.666%
    }

    .u-height-7\/12\@xl {
        min-height: 58.333%
    }

    .u-height-11\/12\@xl {
        min-height: 91.666%
    }
}

@media only screen and (max-width: 1020px) {
    .u-height-full\@l {
        height: 100%
    }

    .u-height-2\/4\@l,
    .u-height-3\/6\@l,
    .u-height-4\/8\@l,
    .u-height-5\/10\@l,
    .u-height-6\/12\@l,
    .u-height-half\@l {
        height: 50%
    }

    .u-height-1\/3\@l,
    .u-height-2\/6\@l,
    .u-height-3\/9\@l,
    .u-height-4\/12\@l {
        height: 33.333%
    }

    .u-height-2\/3\@l,
    .u-height-4\/6\@l,
    .u-height-6\/9\@l,
    .u-height-8\/12\@l {
        height: 66.666%
    }

    .u-height-1\/4\@l,
    .u-height-2\/8\@l,
    .u-height-3\/12\@l {
        height: 25%
    }

    .u-height-3\/4\@l,
    .u-height-6\/8\@l,
    .u-height-9\/12\@l {
        height: 75%
    }

    .u-height-1\/5\@l,
    .u-height-2\/10\@l {
        height: 20%
    }

    .u-height-2\/5\@l,
    .u-height-4\/10\@l {
        height: 40%
    }

    .u-height-3\/5\@l,
    .u-height-6\/10\@l {
        height: 60%
    }

    .u-height-4\/5\@l,
    .u-height-8\/10\@l {
        height: 80%
    }

    .u-height-1\/6\@l,
    .u-height-2\/12\@l {
        height: 16.666%
    }

    .u-height-10\/12\@l,
    .u-height-5\/6\@l {
        height: 83.333%
    }

    .u-height-1\/8\@l {
        height: 12.5%
    }

    .u-height-3\/8\@l {
        height: 37.5%
    }

    .u-height-5\/8\@l {
        height: 62.5%
    }

    .u-height-7\/8\@l {
        height: 87.5%
    }

    .u-height-1\/9\@l {
        height: 11.111%
    }

    .u-height-2\/9\@l {
        height: 22.222%
    }

    .u-height-4\/9\@l {
        height: 44.444%
    }

    .u-height-5\/9\@l {
        height: 55.556%
    }

    .u-height-7\/9\@l {
        height: 77.778%
    }

    .u-height-8\/9\@l {
        height: 88.889%
    }

    .u-height-1\/10\@l {
        height: 10%
    }

    .u-height-3\/10\@l {
        height: 30%
    }

    .u-height-7\/10\@l {
        height: 70%
    }

    .u-height-9\/10\@l {
        height: 90%
    }

    .u-height-1\/12\@l {
        height: 8.333%
    }

    .u-height-5\/12\@l {
        height: 41.666%
    }

    .u-height-7\/12\@l {
        height: 58.333%
    }

    .u-height-11\/12\@l {
        height: 91.666%
    }

    .u-height-full\@l {
        min-height: 100%
    }

    .u-height-2\/4\@l,
    .u-height-3\/6\@l,
    .u-height-4\/8\@l,
    .u-height-5\/10\@l,
    .u-height-6\/12\@l,
    .u-height-half\@l {
        min-height: 50%
    }

    .u-height-1\/3\@l,
    .u-height-2\/6\@l,
    .u-height-3\/9\@l,
    .u-height-4\/12\@l {
        min-height: 33.333%
    }

    .u-height-2\/3\@l,
    .u-height-4\/6\@l,
    .u-height-6\/9\@l,
    .u-height-8\/12\@l {
        min-height: 66.666%
    }

    .u-height-1\/4\@l,
    .u-height-2\/8\@l,
    .u-height-3\/12\@l {
        min-height: 25%
    }

    .u-height-3\/4\@l,
    .u-height-6\/8\@l,
    .u-height-9\/12\@l {
        min-height: 75%
    }

    .u-height-1\/5\@l,
    .u-height-2\/10\@l {
        min-height: 20%
    }

    .u-height-2\/5\@l,
    .u-height-4\/10\@l {
        min-height: 40%
    }

    .u-height-3\/5\@l,
    .u-height-6\/10\@l {
        min-height: 60%
    }

    .u-height-4\/5\@l,
    .u-height-8\/10\@l {
        min-height: 80%
    }

    .u-height-1\/6\@l,
    .u-height-2\/12\@l {
        min-height: 16.666%
    }

    .u-height-10\/12\@l,
    .u-height-5\/6\@l {
        min-height: 83.333%
    }

    .u-height-1\/8\@l {
        min-height: 12.5%
    }

    .u-height-3\/8\@l {
        min-height: 37.5%
    }

    .u-height-5\/8\@l {
        min-height: 62.5%
    }

    .u-height-7\/8\@l {
        min-height: 87.5%
    }

    .u-height-1\/9\@l {
        min-height: 11.111%
    }

    .u-height-2\/9\@l {
        min-height: 22.222%
    }

    .u-height-4\/9\@l {
        min-height: 44.444%
    }

    .u-height-5\/9\@l {
        min-height: 55.556%
    }

    .u-height-7\/9\@l {
        min-height: 77.778%
    }

    .u-height-8\/9\@l {
        min-height: 88.889%
    }

    .u-height-1\/10\@l {
        min-height: 10%
    }

    .u-height-3\/10\@l {
        min-height: 30%
    }

    .u-height-7\/10\@l {
        min-height: 70%
    }

    .u-height-9\/10\@l {
        min-height: 90%
    }

    .u-height-1\/12\@l {
        min-height: 8.333%
    }

    .u-height-5\/12\@l {
        min-height: 41.666%
    }

    .u-height-7\/12\@l {
        min-height: 58.333%
    }

    .u-height-11\/12\@l {
        min-height: 91.666%
    }
}

@media only screen and (max-width: 760px) {
    .u-height-full\@m {
        height: 100%
    }

    .u-height-2\/4\@m,
    .u-height-3\/6\@m,
    .u-height-4\/8\@m,
    .u-height-5\/10\@m,
    .u-height-6\/12\@m,
    .u-height-half\@m {
        height: 50%
    }

    .u-height-1\/3\@m,
    .u-height-2\/6\@m,
    .u-height-3\/9\@m,
    .u-height-4\/12\@m {
        height: 33.333%
    }

    .u-height-2\/3\@m,
    .u-height-4\/6\@m,
    .u-height-6\/9\@m,
    .u-height-8\/12\@m {
        height: 66.666%
    }

    .u-height-1\/4\@m,
    .u-height-2\/8\@m,
    .u-height-3\/12\@m {
        height: 25%
    }

    .u-height-3\/4\@m,
    .u-height-6\/8\@m,
    .u-height-9\/12\@m {
        height: 75%
    }

    .u-height-1\/5\@m,
    .u-height-2\/10\@m {
        height: 20%
    }

    .u-height-2\/5\@m,
    .u-height-4\/10\@m {
        height: 40%
    }

    .u-height-3\/5\@m,
    .u-height-6\/10\@m {
        height: 60%
    }

    .u-height-4\/5\@m,
    .u-height-8\/10\@m {
        height: 80%
    }

    .u-height-1\/6\@m,
    .u-height-2\/12\@m {
        height: 16.666%
    }

    .u-height-10\/12\@m,
    .u-height-5\/6\@m {
        height: 83.333%
    }

    .u-height-1\/8\@m {
        height: 12.5%
    }

    .u-height-3\/8\@m {
        height: 37.5%
    }

    .u-height-5\/8\@m {
        height: 62.5%
    }

    .u-height-7\/8\@m {
        height: 87.5%
    }

    .u-height-1\/9\@m {
        height: 11.111%
    }

    .u-height-2\/9\@m {
        height: 22.222%
    }

    .u-height-4\/9\@m {
        height: 44.444%
    }

    .u-height-5\/9\@m {
        height: 55.556%
    }

    .u-height-7\/9\@m {
        height: 77.778%
    }

    .u-height-8\/9\@m {
        height: 88.889%
    }

    .u-height-1\/10\@m {
        height: 10%
    }

    .u-height-3\/10\@m {
        height: 30%
    }

    .u-height-7\/10\@m {
        height: 70%
    }

    .u-height-9\/10\@m {
        height: 90%
    }

    .u-height-1\/12\@m {
        height: 8.333%
    }

    .u-height-5\/12\@m {
        height: 41.666%
    }

    .u-height-7\/12\@m {
        height: 58.333%
    }

    .u-height-11\/12\@m {
        height: 91.666%
    }

    .u-height-full\@m {
        min-height: 100%
    }

    .u-height-2\/4\@m,
    .u-height-3\/6\@m,
    .u-height-4\/8\@m,
    .u-height-5\/10\@m,
    .u-height-6\/12\@m,
    .u-height-half\@m {
        min-height: 50%
    }

    .u-height-1\/3\@m,
    .u-height-2\/6\@m,
    .u-height-3\/9\@m,
    .u-height-4\/12\@m {
        min-height: 33.333%
    }

    .u-height-2\/3\@m,
    .u-height-4\/6\@m,
    .u-height-6\/9\@m,
    .u-height-8\/12\@m {
        min-height: 66.666%
    }

    .u-height-1\/4\@m,
    .u-height-2\/8\@m,
    .u-height-3\/12\@m {
        min-height: 25%
    }

    .u-height-3\/4\@m,
    .u-height-6\/8\@m,
    .u-height-9\/12\@m {
        min-height: 75%
    }

    .u-height-1\/5\@m,
    .u-height-2\/10\@m {
        min-height: 20%
    }

    .u-height-2\/5\@m,
    .u-height-4\/10\@m {
        min-height: 40%
    }

    .u-height-3\/5\@m,
    .u-height-6\/10\@m {
        min-height: 60%
    }

    .u-height-4\/5\@m,
    .u-height-8\/10\@m {
        min-height: 80%
    }

    .u-height-1\/6\@m,
    .u-height-2\/12\@m {
        min-height: 16.666%
    }

    .u-height-10\/12\@m,
    .u-height-5\/6\@m {
        min-height: 83.333%
    }

    .u-height-1\/8\@m {
        min-height: 12.5%
    }

    .u-height-3\/8\@m {
        min-height: 37.5%
    }

    .u-height-5\/8\@m {
        min-height: 62.5%
    }

    .u-height-7\/8\@m {
        min-height: 87.5%
    }

    .u-height-1\/9\@m {
        min-height: 11.111%
    }

    .u-height-2\/9\@m {
        min-height: 22.222%
    }

    .u-height-4\/9\@m {
        min-height: 44.444%
    }

    .u-height-5\/9\@m {
        min-height: 55.556%
    }

    .u-height-7\/9\@m {
        min-height: 77.778%
    }

    .u-height-8\/9\@m {
        min-height: 88.889%
    }

    .u-height-1\/10\@m {
        min-height: 10%
    }

    .u-height-3\/10\@m {
        min-height: 30%
    }

    .u-height-7\/10\@m {
        min-height: 70%
    }

    .u-height-9\/10\@m {
        min-height: 90%
    }

    .u-height-1\/12\@m {
        min-height: 8.333%
    }

    .u-height-5\/12\@m {
        min-height: 41.666%
    }

    .u-height-7\/12\@m {
        min-height: 58.333%
    }

    .u-height-11\/12\@m {
        min-height: 91.666%
    }
}

@media only screen and (max-width: 479px) {
    .u-height-full\@s {
        height: 100%
    }

    .u-height-2\/4\@s,
    .u-height-3\/6\@s,
    .u-height-4\/8\@s,
    .u-height-5\/10\@s,
    .u-height-6\/12\@s,
    .u-height-half\@s {
        height: 50%
    }

    .u-height-1\/3\@s,
    .u-height-2\/6\@s,
    .u-height-3\/9\@s,
    .u-height-4\/12\@s {
        height: 33.333%
    }

    .u-height-2\/3\@s,
    .u-height-4\/6\@s,
    .u-height-6\/9\@s,
    .u-height-8\/12\@s {
        height: 66.666%
    }

    .u-height-1\/4\@s,
    .u-height-2\/8\@s,
    .u-height-3\/12\@s {
        height: 25%
    }

    .u-height-3\/4\@s,
    .u-height-6\/8\@s,
    .u-height-9\/12\@s {
        height: 75%
    }

    .u-height-1\/5\@s,
    .u-height-2\/10\@s {
        height: 20%
    }

    .u-height-2\/5\@s,
    .u-height-4\/10\@s {
        height: 40%
    }

    .u-height-3\/5\@s,
    .u-height-6\/10\@s {
        height: 60%
    }

    .u-height-4\/5\@s,
    .u-height-8\/10\@s {
        height: 80%
    }

    .u-height-1\/6\@s,
    .u-height-2\/12\@s {
        height: 16.666%
    }

    .u-height-10\/12\@s,
    .u-height-5\/6\@s {
        height: 83.333%
    }

    .u-height-1\/8\@s {
        height: 12.5%
    }

    .u-height-3\/8\@s {
        height: 37.5%
    }

    .u-height-5\/8\@s {
        height: 62.5%
    }

    .u-height-7\/8\@s {
        height: 87.5%
    }

    .u-height-1\/9\@s {
        height: 11.111%
    }

    .u-height-2\/9\@s {
        height: 22.222%
    }

    .u-height-4\/9\@s {
        height: 44.444%
    }

    .u-height-5\/9\@s {
        height: 55.556%
    }

    .u-height-7\/9\@s {
        height: 77.778%
    }

    .u-height-8\/9\@s {
        height: 88.889%
    }

    .u-height-1\/10\@s {
        height: 10%
    }

    .u-height-3\/10\@s {
        height: 30%
    }

    .u-height-7\/10\@s {
        height: 70%
    }

    .u-height-9\/10\@s {
        height: 90%
    }

    .u-height-1\/12\@s {
        height: 8.333%
    }

    .u-height-5\/12\@s {
        height: 41.666%
    }

    .u-height-7\/12\@s {
        height: 58.333%
    }

    .u-height-11\/12\@s {
        height: 91.666%
    }

    .u-height-full\@s {
        min-height: 100%
    }

    .u-height-2\/4\@s,
    .u-height-3\/6\@s,
    .u-height-4\/8\@s,
    .u-height-5\/10\@s,
    .u-height-6\/12\@s,
    .u-height-half\@s {
        min-height: 50%
    }

    .u-height-1\/3\@s,
    .u-height-2\/6\@s,
    .u-height-3\/9\@s,
    .u-height-4\/12\@s {
        min-height: 33.333%
    }

    .u-height-2\/3\@s,
    .u-height-4\/6\@s,
    .u-height-6\/9\@s,
    .u-height-8\/12\@s {
        min-height: 66.666%
    }

    .u-height-1\/4\@s,
    .u-height-2\/8\@s,
    .u-height-3\/12\@s {
        min-height: 25%
    }

    .u-height-3\/4\@s,
    .u-height-6\/8\@s,
    .u-height-9\/12\@s {
        min-height: 75%
    }

    .u-height-1\/5\@s,
    .u-height-2\/10\@s {
        min-height: 20%
    }

    .u-height-2\/5\@s,
    .u-height-4\/10\@s {
        min-height: 40%
    }

    .u-height-3\/5\@s,
    .u-height-6\/10\@s {
        min-height: 60%
    }

    .u-height-4\/5\@s,
    .u-height-8\/10\@s {
        min-height: 80%
    }

    .u-height-1\/6\@s,
    .u-height-2\/12\@s {
        min-height: 16.666%
    }

    .u-height-10\/12\@s,
    .u-height-5\/6\@s {
        min-height: 83.333%
    }

    .u-height-1\/8\@s {
        min-height: 12.5%
    }

    .u-height-3\/8\@s {
        min-height: 37.5%
    }

    .u-height-5\/8\@s {
        min-height: 62.5%
    }

    .u-height-7\/8\@s {
        min-height: 87.5%
    }

    .u-height-1\/9\@s {
        min-height: 11.111%
    }

    .u-height-2\/9\@s {
        min-height: 22.222%
    }

    .u-height-4\/9\@s {
        min-height: 44.444%
    }

    .u-height-5\/9\@s {
        min-height: 55.556%
    }

    .u-height-7\/9\@s {
        min-height: 77.778%
    }

    .u-height-8\/9\@s {
        min-height: 88.889%
    }

    .u-height-1\/10\@s {
        min-height: 10%
    }

    .u-height-3\/10\@s {
        min-height: 30%
    }

    .u-height-7\/10\@s {
        min-height: 70%
    }

    .u-height-9\/10\@s {
        min-height: 90%
    }

    .u-height-1\/12\@s {
        min-height: 8.333%
    }

    .u-height-5\/12\@s {
        min-height: 41.666%
    }

    .u-height-7\/12\@s {
        min-height: 58.333%
    }

    .u-height-11\/12\@s {
        min-height: 91.666%
    }
}

@media only screen and (max-width: 375px) {
    .u-height-full\@xs {
        height: 100%
    }

    .u-height-2\/4\@xs,
    .u-height-3\/6\@xs,
    .u-height-4\/8\@xs,
    .u-height-5\/10\@xs,
    .u-height-6\/12\@xs,
    .u-height-half\@xs {
        height: 50%
    }

    .u-height-1\/3\@xs,
    .u-height-2\/6\@xs,
    .u-height-3\/9\@xs,
    .u-height-4\/12\@xs {
        height: 33.333%
    }

    .u-height-2\/3\@xs,
    .u-height-4\/6\@xs,
    .u-height-6\/9\@xs,
    .u-height-8\/12\@xs {
        height: 66.666%
    }

    .u-height-1\/4\@xs,
    .u-height-2\/8\@xs,
    .u-height-3\/12\@xs {
        height: 25%
    }

    .u-height-3\/4\@xs,
    .u-height-6\/8\@xs,
    .u-height-9\/12\@xs {
        height: 75%
    }

    .u-height-1\/5\@xs,
    .u-height-2\/10\@xs {
        height: 20%
    }

    .u-height-2\/5\@xs,
    .u-height-4\/10\@xs {
        height: 40%
    }

    .u-height-3\/5\@xs,
    .u-height-6\/10\@xs {
        height: 60%
    }

    .u-height-4\/5\@xs,
    .u-height-8\/10\@xs {
        height: 80%
    }

    .u-height-1\/6\@xs,
    .u-height-2\/12\@xs {
        height: 16.666%
    }

    .u-height-10\/12\@xs,
    .u-height-5\/6\@xs {
        height: 83.333%
    }

    .u-height-1\/8\@xs {
        height: 12.5%
    }

    .u-height-3\/8\@xs {
        height: 37.5%
    }

    .u-height-5\/8\@xs {
        height: 62.5%
    }

    .u-height-7\/8\@xs {
        height: 87.5%
    }

    .u-height-1\/9\@xs {
        height: 11.111%
    }

    .u-height-2\/9\@xs {
        height: 22.222%
    }

    .u-height-4\/9\@xs {
        height: 44.444%
    }

    .u-height-5\/9\@xs {
        height: 55.556%
    }

    .u-height-7\/9\@xs {
        height: 77.778%
    }

    .u-height-8\/9\@xs {
        height: 88.889%
    }

    .u-height-1\/10\@xs {
        height: 10%
    }

    .u-height-3\/10\@xs {
        height: 30%
    }

    .u-height-7\/10\@xs {
        height: 70%
    }

    .u-height-9\/10\@xs {
        height: 90%
    }

    .u-height-1\/12\@xs {
        height: 8.333%
    }

    .u-height-5\/12\@xs {
        height: 41.666%
    }

    .u-height-7\/12\@xs {
        height: 58.333%
    }

    .u-height-11\/12\@xs {
        height: 91.666%
    }

    .u-height-full\@xs {
        min-height: 100%
    }

    .u-height-2\/4\@xs,
    .u-height-3\/6\@xs,
    .u-height-4\/8\@xs,
    .u-height-5\/10\@xs,
    .u-height-6\/12\@xs,
    .u-height-half\@xs {
        min-height: 50%
    }

    .u-height-1\/3\@xs,
    .u-height-2\/6\@xs,
    .u-height-3\/9\@xs,
    .u-height-4\/12\@xs {
        min-height: 33.333%
    }

    .u-height-2\/3\@xs,
    .u-height-4\/6\@xs,
    .u-height-6\/9\@xs,
    .u-height-8\/12\@xs {
        min-height: 66.666%
    }

    .u-height-1\/4\@xs,
    .u-height-2\/8\@xs,
    .u-height-3\/12\@xs {
        min-height: 25%
    }

    .u-height-3\/4\@xs,
    .u-height-6\/8\@xs,
    .u-height-9\/12\@xs {
        min-height: 75%
    }

    .u-height-1\/5\@xs,
    .u-height-2\/10\@xs {
        min-height: 20%
    }

    .u-height-2\/5\@xs,
    .u-height-4\/10\@xs {
        min-height: 40%
    }

    .u-height-3\/5\@xs,
    .u-height-6\/10\@xs {
        min-height: 60%
    }

    .u-height-4\/5\@xs,
    .u-height-8\/10\@xs {
        min-height: 80%
    }

    .u-height-1\/6\@xs,
    .u-height-2\/12\@xs {
        min-height: 16.666%
    }

    .u-height-10\/12\@xs,
    .u-height-5\/6\@xs {
        min-height: 83.333%
    }

    .u-height-1\/8\@xs {
        min-height: 12.5%
    }

    .u-height-3\/8\@xs {
        min-height: 37.5%
    }

    .u-height-5\/8\@xs {
        min-height: 62.5%
    }

    .u-height-7\/8\@xs {
        min-height: 87.5%
    }

    .u-height-1\/9\@xs {
        min-height: 11.111%
    }

    .u-height-2\/9\@xs {
        min-height: 22.222%
    }

    .u-height-4\/9\@xs {
        min-height: 44.444%
    }

    .u-height-5\/9\@xs {
        min-height: 55.556%
    }

    .u-height-7\/9\@xs {
        min-height: 77.778%
    }

    .u-height-8\/9\@xs {
        min-height: 88.889%
    }

    .u-height-1\/10\@xs {
        min-height: 10%
    }

    .u-height-3\/10\@xs {
        min-height: 30%
    }

    .u-height-7\/10\@xs {
        min-height: 70%
    }

    .u-height-9\/10\@xs {
        min-height: 90%
    }

    .u-height-1\/12\@xs {
        min-height: 8.333%
    }

    .u-height-5\/12\@xs {
        min-height: 41.666%
    }

    .u-height-7\/12\@xs {
        min-height: 58.333%
    }

    .u-height-11\/12\@xs {
        min-height: 91.666%
    }
}

.u-width-full {
    width: 100%
}

.u-width-2\/4,
.u-width-3\/6,
.u-width-4\/8,
.u-width-5\/10,
.u-width-6\/12,
.u-width-half {
    width: 50%
}

.u-width-1\/3,
.u-width-2\/6,
.u-width-3\/9,
.u-width-4\/12 {
    width: 33.333%
}

.u-width-2\/3,
.u-width-4\/6,
.u-width-6\/9,
.u-width-8\/12 {
    width: 66.666%
}

.u-width-1\/4,
.u-width-2\/8,
.u-width-3\/12 {
    width: 25%
}

.u-width-3\/4,
.u-width-6\/8,
.u-width-9\/12 {
    width: 75%
}

.u-width-1\/5,
.u-width-2\/10 {
    width: 20%
}

.u-width-2\/5,
.u-width-4\/10 {
    width: 40%
}

.u-width-3\/5,
.u-width-6\/10 {
    width: 60%
}

.u-width-4\/5,
.u-width-8\/10 {
    width: 80%
}

.u-width-1\/6,
.u-width-2\/12 {
    width: 16.666%
}

.u-width-10\/12,
.u-width-5\/6 {
    width: 83.333%
}

.u-width-1\/8 {
    width: 12.5%
}

.u-width-3\/8 {
    width: 37.5%
}

.u-width-5\/8 {
    width: 62.5%
}

.u-width-7\/8 {
    width: 87.5%
}

.u-width-1\/9 {
    width: 11.111%
}

.u-width-2\/9 {
    width: 22.222%
}

.u-width-4\/9 {
    width: 44.444%
}

.u-width-5\/9 {
    width: 55.556%
}

.u-width-7\/9 {
    width: 77.778%
}

.u-width-8\/9 {
    width: 88.889%
}

.u-width-1\/10 {
    width: 10%
}

.u-width-3\/10 {
    width: 30%
}

.u-width-7\/10 {
    width: 70%
}

.u-width-9\/10 {
    width: 90%
}

.u-width-1\/12 {
    width: 8.333%
}

.u-width-5\/12 {
    width: 41.666%
}

.u-width-7\/12 {
    width: 58.333%
}

.u-width-11\/12 {
    width: 91.666%
}

.u-w-full {
    width: 100%
}

.u-w-2\/4,
.u-w-3\/6,
.u-w-4\/8,
.u-w-5\/10,
.u-w-6\/12,
.u-w-half {
    width: 50%
}

.u-w-1\/3,
.u-w-2\/6,
.u-w-3\/9,
.u-w-4\/12 {
    width: 33.333%
}

.u-w-2\/3,
.u-w-4\/6,
.u-w-6\/9,
.u-w-8\/12 {
    width: 66.666%
}

.u-w-1\/4,
.u-w-2\/8,
.u-w-3\/12 {
    width: 25%
}

.u-w-3\/4,
.u-w-6\/8,
.u-w-9\/12 {
    width: 75%
}

.u-w-1\/5,
.u-w-2\/10 {
    width: 20%
}

.u-w-2\/5,
.u-w-4\/10 {
    width: 40%
}

.u-w-3\/5,
.u-w-6\/10 {
    width: 60%
}

.u-w-4\/5,
.u-w-8\/10 {
    width: 80%
}

.u-w-1\/6,
.u-w-2\/12 {
    width: 16.666%
}

.u-w-10\/12,
.u-w-5\/6 {
    width: 83.333%
}

.u-w-1\/8 {
    width: 12.5%
}

.u-w-3\/8 {
    width: 37.5%
}

.u-w-5\/8 {
    width: 62.5%
}

.u-w-7\/8 {
    width: 87.5%
}

.u-w-1\/9 {
    width: 11.111%
}

.u-w-2\/9 {
    width: 22.222%
}

.u-w-4\/9 {
    width: 44.444%
}

.u-w-5\/9 {
    width: 55.556%
}

.u-w-7\/9 {
    width: 77.778%
}

.u-w-8\/9 {
    width: 88.889%
}

.u-w-1\/10 {
    width: 10%
}

.u-w-3\/10 {
    width: 30%
}

.u-w-7\/10 {
    width: 70%
}

.u-w-9\/10 {
    width: 90%
}

.u-w-1\/12 {
    width: 8.333%
}

.u-w-5\/12 {
    width: 41.666%
}

.u-w-7\/12 {
    width: 58.333%
}

.u-w-11\/12 {
    width: 91.666%
}

@media only screen and (max-width: 1279px) {
    .u-width-full\@xl {
        width: 100%
    }

    .u-width-2\/4\@xl,
    .u-width-3\/6\@xl,
    .u-width-4\/8\@xl,
    .u-width-5\/10\@xl,
    .u-width-6\/12\@xl,
    .u-width-half\@xl {
        width: 50%
    }

    .u-width-1\/3\@xl,
    .u-width-2\/6\@xl,
    .u-width-3\/9\@xl,
    .u-width-4\/12\@xl {
        width: 33.333%
    }

    .u-width-2\/3\@xl,
    .u-width-4\/6\@xl,
    .u-width-6\/9\@xl,
    .u-width-8\/12\@xl {
        width: 66.666%
    }

    .u-width-1\/4\@xl,
    .u-width-2\/8\@xl,
    .u-width-3\/12\@xl {
        width: 25%
    }

    .u-width-3\/4\@xl,
    .u-width-6\/8\@xl,
    .u-width-9\/12\@xl {
        width: 75%
    }

    .u-width-1\/5\@xl,
    .u-width-2\/10\@xl {
        width: 20%
    }

    .u-width-2\/5\@xl,
    .u-width-4\/10\@xl {
        width: 40%
    }

    .u-width-3\/5\@xl,
    .u-width-6\/10\@xl {
        width: 60%
    }

    .u-width-4\/5\@xl,
    .u-width-8\/10\@xl {
        width: 80%
    }

    .u-width-1\/6\@xl,
    .u-width-2\/12\@xl {
        width: 16.666%
    }

    .u-width-10\/12\@xl,
    .u-width-5\/6\@xl {
        width: 83.333%
    }

    .u-width-1\/8\@xl {
        width: 12.5%
    }

    .u-width-3\/8\@xl {
        width: 37.5%
    }

    .u-width-5\/8\@xl {
        width: 62.5%
    }

    .u-width-7\/8\@xl {
        width: 87.5%
    }

    .u-width-1\/9\@xl {
        width: 11.111%
    }

    .u-width-2\/9\@xl {
        width: 22.222%
    }

    .u-width-4\/9\@xl {
        width: 44.444%
    }

    .u-width-5\/9\@xl {
        width: 55.556%
    }

    .u-width-7\/9\@xl {
        width: 77.778%
    }

    .u-width-8\/9\@xl {
        width: 88.889%
    }

    .u-width-1\/10\@xl {
        width: 10%
    }

    .u-width-3\/10\@xl {
        width: 30%
    }

    .u-width-7\/10\@xl {
        width: 70%
    }

    .u-width-9\/10\@xl {
        width: 90%
    }

    .u-width-1\/12\@xl {
        width: 8.333%
    }

    .u-width-5\/12\@xl {
        width: 41.666%
    }

    .u-width-7\/12\@xl {
        width: 58.333%
    }

    .u-width-11\/12\@xl {
        width: 91.666%
    }

    .u-w-full\@xl {
        width: 100%
    }

    .u-w-2\/4\@xl,
    .u-w-3\/6\@xl,
    .u-w-4\/8\@xl,
    .u-w-5\/10\@xl,
    .u-w-6\/12\@xl,
    .u-w-half\@xl {
        width: 50%
    }

    .u-w-1\/3\@xl,
    .u-w-2\/6\@xl,
    .u-w-3\/9\@xl,
    .u-w-4\/12\@xl {
        width: 33.333%
    }

    .u-w-2\/3\@xl,
    .u-w-4\/6\@xl,
    .u-w-6\/9\@xl,
    .u-w-8\/12\@xl {
        width: 66.666%
    }

    .u-w-1\/4\@xl,
    .u-w-2\/8\@xl,
    .u-w-3\/12\@xl {
        width: 25%
    }

    .u-w-3\/4\@xl,
    .u-w-6\/8\@xl,
    .u-w-9\/12\@xl {
        width: 75%
    }

    .u-w-1\/5\@xl,
    .u-w-2\/10\@xl {
        width: 20%
    }

    .u-w-2\/5\@xl,
    .u-w-4\/10\@xl {
        width: 40%
    }

    .u-w-3\/5\@xl,
    .u-w-6\/10\@xl {
        width: 60%
    }

    .u-w-4\/5\@xl,
    .u-w-8\/10\@xl {
        width: 80%
    }

    .u-w-1\/6\@xl,
    .u-w-2\/12\@xl {
        width: 16.666%
    }

    .u-w-10\/12\@xl,
    .u-w-5\/6\@xl {
        width: 83.333%
    }

    .u-w-1\/8\@xl {
        width: 12.5%
    }

    .u-w-3\/8\@xl {
        width: 37.5%
    }

    .u-w-5\/8\@xl {
        width: 62.5%
    }

    .u-w-7\/8\@xl {
        width: 87.5%
    }

    .u-w-1\/9\@xl {
        width: 11.111%
    }

    .u-w-2\/9\@xl {
        width: 22.222%
    }

    .u-w-4\/9\@xl {
        width: 44.444%
    }

    .u-w-5\/9\@xl {
        width: 55.556%
    }

    .u-w-7\/9\@xl {
        width: 77.778%
    }

    .u-w-8\/9\@xl {
        width: 88.889%
    }

    .u-w-1\/10\@xl {
        width: 10%
    }

    .u-w-3\/10\@xl {
        width: 30%
    }

    .u-w-7\/10\@xl {
        width: 70%
    }

    .u-w-9\/10\@xl {
        width: 90%
    }

    .u-w-1\/12\@xl {
        width: 8.333%
    }

    .u-w-5\/12\@xl {
        width: 41.666%
    }

    .u-w-7\/12\@xl {
        width: 58.333%
    }

    .u-w-11\/12\@xl {
        width: 91.666%
    }
}

@media only screen and (max-width: 1020px) {
    .u-width-full\@l {
        width: 100%
    }

    .u-width-2\/4\@l,
    .u-width-3\/6\@l,
    .u-width-4\/8\@l,
    .u-width-5\/10\@l,
    .u-width-6\/12\@l,
    .u-width-half\@l {
        width: 50%
    }

    .u-width-1\/3\@l,
    .u-width-2\/6\@l,
    .u-width-3\/9\@l,
    .u-width-4\/12\@l {
        width: 33.333%
    }

    .u-width-2\/3\@l,
    .u-width-4\/6\@l,
    .u-width-6\/9\@l,
    .u-width-8\/12\@l {
        width: 66.666%
    }

    .u-width-1\/4\@l,
    .u-width-2\/8\@l,
    .u-width-3\/12\@l {
        width: 25%
    }

    .u-width-3\/4\@l,
    .u-width-6\/8\@l,
    .u-width-9\/12\@l {
        width: 75%
    }

    .u-width-1\/5\@l,
    .u-width-2\/10\@l {
        width: 20%
    }

    .u-width-2\/5\@l,
    .u-width-4\/10\@l {
        width: 40%
    }

    .u-width-3\/5\@l,
    .u-width-6\/10\@l {
        width: 60%
    }

    .u-width-4\/5\@l,
    .u-width-8\/10\@l {
        width: 80%
    }

    .u-width-1\/6\@l,
    .u-width-2\/12\@l {
        width: 16.666%
    }

    .u-width-10\/12\@l,
    .u-width-5\/6\@l {
        width: 83.333%
    }

    .u-width-1\/8\@l {
        width: 12.5%
    }

    .u-width-3\/8\@l {
        width: 37.5%
    }

    .u-width-5\/8\@l {
        width: 62.5%
    }

    .u-width-7\/8\@l {
        width: 87.5%
    }

    .u-width-1\/9\@l {
        width: 11.111%
    }

    .u-width-2\/9\@l {
        width: 22.222%
    }

    .u-width-4\/9\@l {
        width: 44.444%
    }

    .u-width-5\/9\@l {
        width: 55.556%
    }

    .u-width-7\/9\@l {
        width: 77.778%
    }

    .u-width-8\/9\@l {
        width: 88.889%
    }

    .u-width-1\/10\@l {
        width: 10%
    }

    .u-width-3\/10\@l {
        width: 30%
    }

    .u-width-7\/10\@l {
        width: 70%
    }

    .u-width-9\/10\@l {
        width: 90%
    }

    .u-width-1\/12\@l {
        width: 8.333%
    }

    .u-width-5\/12\@l {
        width: 41.666%
    }

    .u-width-7\/12\@l {
        width: 58.333%
    }

    .u-width-11\/12\@l {
        width: 91.666%
    }

    .u-w-full\@l {
        width: 100%
    }

    .u-w-2\/4\@l,
    .u-w-3\/6\@l,
    .u-w-4\/8\@l,
    .u-w-5\/10\@l,
    .u-w-6\/12\@l,
    .u-w-half\@l {
        width: 50%
    }

    .u-w-1\/3\@l,
    .u-w-2\/6\@l,
    .u-w-3\/9\@l,
    .u-w-4\/12\@l {
        width: 33.333%
    }

    .u-w-2\/3\@l,
    .u-w-4\/6\@l,
    .u-w-6\/9\@l,
    .u-w-8\/12\@l {
        width: 66.666%
    }

    .u-w-1\/4\@l,
    .u-w-2\/8\@l,
    .u-w-3\/12\@l {
        width: 25%
    }

    .u-w-3\/4\@l,
    .u-w-6\/8\@l,
    .u-w-9\/12\@l {
        width: 75%
    }

    .u-w-1\/5\@l,
    .u-w-2\/10\@l {
        width: 20%
    }

    .u-w-2\/5\@l,
    .u-w-4\/10\@l {
        width: 40%
    }

    .u-w-3\/5\@l,
    .u-w-6\/10\@l {
        width: 60%
    }

    .u-w-4\/5\@l,
    .u-w-8\/10\@l {
        width: 80%
    }

    .u-w-1\/6\@l,
    .u-w-2\/12\@l {
        width: 16.666%
    }

    .u-w-10\/12\@l,
    .u-w-5\/6\@l {
        width: 83.333%
    }

    .u-w-1\/8\@l {
        width: 12.5%
    }

    .u-w-3\/8\@l {
        width: 37.5%
    }

    .u-w-5\/8\@l {
        width: 62.5%
    }

    .u-w-7\/8\@l {
        width: 87.5%
    }

    .u-w-1\/9\@l {
        width: 11.111%
    }

    .u-w-2\/9\@l {
        width: 22.222%
    }

    .u-w-4\/9\@l {
        width: 44.444%
    }

    .u-w-5\/9\@l {
        width: 55.556%
    }

    .u-w-7\/9\@l {
        width: 77.778%
    }

    .u-w-8\/9\@l {
        width: 88.889%
    }

    .u-w-1\/10\@l {
        width: 10%
    }

    .u-w-3\/10\@l {
        width: 30%
    }

    .u-w-7\/10\@l {
        width: 70%
    }

    .u-w-9\/10\@l {
        width: 90%
    }

    .u-w-1\/12\@l {
        width: 8.333%
    }

    .u-w-5\/12\@l {
        width: 41.666%
    }

    .u-w-7\/12\@l {
        width: 58.333%
    }

    .u-w-11\/12\@l {
        width: 91.666%
    }
}

@media only screen and (max-width: 760px) {
    .u-width-full\@m {
        width: 100%
    }

    .u-width-2\/4\@m,
    .u-width-3\/6\@m,
    .u-width-4\/8\@m,
    .u-width-5\/10\@m,
    .u-width-6\/12\@m,
    .u-width-half\@m {
        width: 50%
    }

    .u-width-1\/3\@m,
    .u-width-2\/6\@m,
    .u-width-3\/9\@m,
    .u-width-4\/12\@m {
        width: 33.333%
    }

    .u-width-2\/3\@m,
    .u-width-4\/6\@m,
    .u-width-6\/9\@m,
    .u-width-8\/12\@m {
        width: 66.666%
    }

    .u-width-1\/4\@m,
    .u-width-2\/8\@m,
    .u-width-3\/12\@m {
        width: 25%
    }

    .u-width-3\/4\@m,
    .u-width-6\/8\@m,
    .u-width-9\/12\@m {
        width: 75%
    }

    .u-width-1\/5\@m,
    .u-width-2\/10\@m {
        width: 20%
    }

    .u-width-2\/5\@m,
    .u-width-4\/10\@m {
        width: 40%
    }

    .u-width-3\/5\@m,
    .u-width-6\/10\@m {
        width: 60%
    }

    .u-width-4\/5\@m,
    .u-width-8\/10\@m {
        width: 80%
    }

    .u-width-1\/6\@m,
    .u-width-2\/12\@m {
        width: 16.666%
    }

    .u-width-10\/12\@m,
    .u-width-5\/6\@m {
        width: 83.333%
    }

    .u-width-1\/8\@m {
        width: 12.5%
    }

    .u-width-3\/8\@m {
        width: 37.5%
    }

    .u-width-5\/8\@m {
        width: 62.5%
    }

    .u-width-7\/8\@m {
        width: 87.5%
    }

    .u-width-1\/9\@m {
        width: 11.111%
    }

    .u-width-2\/9\@m {
        width: 22.222%
    }

    .u-width-4\/9\@m {
        width: 44.444%
    }

    .u-width-5\/9\@m {
        width: 55.556%
    }

    .u-width-7\/9\@m {
        width: 77.778%
    }

    .u-width-8\/9\@m {
        width: 88.889%
    }

    .u-width-1\/10\@m {
        width: 10%
    }

    .u-width-3\/10\@m {
        width: 30%
    }

    .u-width-7\/10\@m {
        width: 70%
    }

    .u-width-9\/10\@m {
        width: 90%
    }

    .u-width-1\/12\@m {
        width: 8.333%
    }

    .u-width-5\/12\@m {
        width: 41.666%
    }

    .u-width-7\/12\@m {
        width: 58.333%
    }

    .u-width-11\/12\@m {
        width: 91.666%
    }

    .u-w-full\@m {
        width: 100%
    }

    .u-w-2\/4\@m,
    .u-w-3\/6\@m,
    .u-w-4\/8\@m,
    .u-w-5\/10\@m,
    .u-w-6\/12\@m,
    .u-w-half\@m {
        width: 50%
    }

    .u-w-1\/3\@m,
    .u-w-2\/6\@m,
    .u-w-3\/9\@m,
    .u-w-4\/12\@m {
        width: 33.333%
    }

    .u-w-2\/3\@m,
    .u-w-4\/6\@m,
    .u-w-6\/9\@m,
    .u-w-8\/12\@m {
        width: 66.666%
    }

    .u-w-1\/4\@m,
    .u-w-2\/8\@m,
    .u-w-3\/12\@m {
        width: 25%
    }

    .u-w-3\/4\@m,
    .u-w-6\/8\@m,
    .u-w-9\/12\@m {
        width: 75%
    }

    .u-w-1\/5\@m,
    .u-w-2\/10\@m {
        width: 20%
    }

    .u-w-2\/5\@m,
    .u-w-4\/10\@m {
        width: 40%
    }

    .u-w-3\/5\@m,
    .u-w-6\/10\@m {
        width: 60%
    }

    .u-w-4\/5\@m,
    .u-w-8\/10\@m {
        width: 80%
    }

    .u-w-1\/6\@m,
    .u-w-2\/12\@m {
        width: 16.666%
    }

    .u-w-10\/12\@m,
    .u-w-5\/6\@m {
        width: 83.333%
    }

    .u-w-1\/8\@m {
        width: 12.5%
    }

    .u-w-3\/8\@m {
        width: 37.5%
    }

    .u-w-5\/8\@m {
        width: 62.5%
    }

    .u-w-7\/8\@m {
        width: 87.5%
    }

    .u-w-1\/9\@m {
        width: 11.111%
    }

    .u-w-2\/9\@m {
        width: 22.222%
    }

    .u-w-4\/9\@m {
        width: 44.444%
    }

    .u-w-5\/9\@m {
        width: 55.556%
    }

    .u-w-7\/9\@m {
        width: 77.778%
    }

    .u-w-8\/9\@m {
        width: 88.889%
    }

    .u-w-1\/10\@m {
        width: 10%
    }

    .u-w-3\/10\@m {
        width: 30%
    }

    .u-w-7\/10\@m {
        width: 70%
    }

    .u-w-9\/10\@m {
        width: 90%
    }

    .u-w-1\/12\@m {
        width: 8.333%
    }

    .u-w-5\/12\@m {
        width: 41.666%
    }

    .u-w-7\/12\@m {
        width: 58.333%
    }

    .u-w-11\/12\@m {
        width: 91.666%
    }
}

@media only screen and (max-width: 479px) {
    .u-width-full\@s {
        width: 100%
    }

    .u-width-2\/4\@s,
    .u-width-3\/6\@s,
    .u-width-4\/8\@s,
    .u-width-5\/10\@s,
    .u-width-6\/12\@s,
    .u-width-half\@s {
        width: 50%
    }

    .u-width-1\/3\@s,
    .u-width-2\/6\@s,
    .u-width-3\/9\@s,
    .u-width-4\/12\@s {
        width: 33.333%
    }

    .u-width-2\/3\@s,
    .u-width-4\/6\@s,
    .u-width-6\/9\@s,
    .u-width-8\/12\@s {
        width: 66.666%
    }

    .u-width-1\/4\@s,
    .u-width-2\/8\@s,
    .u-width-3\/12\@s {
        width: 25%
    }

    .u-width-3\/4\@s,
    .u-width-6\/8\@s,
    .u-width-9\/12\@s {
        width: 75%
    }

    .u-width-1\/5\@s,
    .u-width-2\/10\@s {
        width: 20%
    }

    .u-width-2\/5\@s,
    .u-width-4\/10\@s {
        width: 40%
    }

    .u-width-3\/5\@s,
    .u-width-6\/10\@s {
        width: 60%
    }

    .u-width-4\/5\@s,
    .u-width-8\/10\@s {
        width: 80%
    }

    .u-width-1\/6\@s,
    .u-width-2\/12\@s {
        width: 16.666%
    }

    .u-width-10\/12\@s,
    .u-width-5\/6\@s {
        width: 83.333%
    }

    .u-width-1\/8\@s {
        width: 12.5%
    }

    .u-width-3\/8\@s {
        width: 37.5%
    }

    .u-width-5\/8\@s {
        width: 62.5%
    }

    .u-width-7\/8\@s {
        width: 87.5%
    }

    .u-width-1\/9\@s {
        width: 11.111%
    }

    .u-width-2\/9\@s {
        width: 22.222%
    }

    .u-width-4\/9\@s {
        width: 44.444%
    }

    .u-width-5\/9\@s {
        width: 55.556%
    }

    .u-width-7\/9\@s {
        width: 77.778%
    }

    .u-width-8\/9\@s {
        width: 88.889%
    }

    .u-width-1\/10\@s {
        width: 10%
    }

    .u-width-3\/10\@s {
        width: 30%
    }

    .u-width-7\/10\@s {
        width: 70%
    }

    .u-width-9\/10\@s {
        width: 90%
    }

    .u-width-1\/12\@s {
        width: 8.333%
    }

    .u-width-5\/12\@s {
        width: 41.666%
    }

    .u-width-7\/12\@s {
        width: 58.333%
    }

    .u-width-11\/12\@s {
        width: 91.666%
    }

    .u-w-full\@s {
        width: 100%
    }

    .u-w-2\/4\@s,
    .u-w-3\/6\@s,
    .u-w-4\/8\@s,
    .u-w-5\/10\@s,
    .u-w-6\/12\@s,
    .u-w-half\@s {
        width: 50%
    }

    .u-w-1\/3\@s,
    .u-w-2\/6\@s,
    .u-w-3\/9\@s,
    .u-w-4\/12\@s {
        width: 33.333%
    }

    .u-w-2\/3\@s,
    .u-w-4\/6\@s,
    .u-w-6\/9\@s,
    .u-w-8\/12\@s {
        width: 66.666%
    }

    .u-w-1\/4\@s,
    .u-w-2\/8\@s,
    .u-w-3\/12\@s {
        width: 25%
    }

    .u-w-3\/4\@s,
    .u-w-6\/8\@s,
    .u-w-9\/12\@s {
        width: 75%
    }

    .u-w-1\/5\@s,
    .u-w-2\/10\@s {
        width: 20%
    }

    .u-w-2\/5\@s,
    .u-w-4\/10\@s {
        width: 40%
    }

    .u-w-3\/5\@s,
    .u-w-6\/10\@s {
        width: 60%
    }

    .u-w-4\/5\@s,
    .u-w-8\/10\@s {
        width: 80%
    }

    .u-w-1\/6\@s,
    .u-w-2\/12\@s {
        width: 16.666%
    }

    .u-w-10\/12\@s,
    .u-w-5\/6\@s {
        width: 83.333%
    }

    .u-w-1\/8\@s {
        width: 12.5%
    }

    .u-w-3\/8\@s {
        width: 37.5%
    }

    .u-w-5\/8\@s {
        width: 62.5%
    }

    .u-w-7\/8\@s {
        width: 87.5%
    }

    .u-w-1\/9\@s {
        width: 11.111%
    }

    .u-w-2\/9\@s {
        width: 22.222%
    }

    .u-w-4\/9\@s {
        width: 44.444%
    }

    .u-w-5\/9\@s {
        width: 55.556%
    }

    .u-w-7\/9\@s {
        width: 77.778%
    }

    .u-w-8\/9\@s {
        width: 88.889%
    }

    .u-w-1\/10\@s {
        width: 10%
    }

    .u-w-3\/10\@s {
        width: 30%
    }

    .u-w-7\/10\@s {
        width: 70%
    }

    .u-w-9\/10\@s {
        width: 90%
    }

    .u-w-1\/12\@s {
        width: 8.333%
    }

    .u-w-5\/12\@s {
        width: 41.666%
    }

    .u-w-7\/12\@s {
        width: 58.333%
    }

    .u-w-11\/12\@s {
        width: 91.666%
    }
}

@media only screen and (max-width: 375px) {
    .u-width-full\@xs {
        width: 100%
    }

    .u-width-2\/4\@xs,
    .u-width-3\/6\@xs,
    .u-width-4\/8\@xs,
    .u-width-5\/10\@xs,
    .u-width-6\/12\@xs,
    .u-width-half\@xs {
        width: 50%
    }

    .u-width-1\/3\@xs,
    .u-width-2\/6\@xs,
    .u-width-3\/9\@xs,
    .u-width-4\/12\@xs {
        width: 33.333%
    }

    .u-width-2\/3\@xs,
    .u-width-4\/6\@xs,
    .u-width-6\/9\@xs,
    .u-width-8\/12\@xs {
        width: 66.666%
    }

    .u-width-1\/4\@xs,
    .u-width-2\/8\@xs,
    .u-width-3\/12\@xs {
        width: 25%
    }

    .u-width-3\/4\@xs,
    .u-width-6\/8\@xs,
    .u-width-9\/12\@xs {
        width: 75%
    }

    .u-width-1\/5\@xs,
    .u-width-2\/10\@xs {
        width: 20%
    }

    .u-width-2\/5\@xs,
    .u-width-4\/10\@xs {
        width: 40%
    }

    .u-width-3\/5\@xs,
    .u-width-6\/10\@xs {
        width: 60%
    }

    .u-width-4\/5\@xs,
    .u-width-8\/10\@xs {
        width: 80%
    }

    .u-width-1\/6\@xs,
    .u-width-2\/12\@xs {
        width: 16.666%
    }

    .u-width-10\/12\@xs,
    .u-width-5\/6\@xs {
        width: 83.333%
    }

    .u-width-1\/8\@xs {
        width: 12.5%
    }

    .u-width-3\/8\@xs {
        width: 37.5%
    }

    .u-width-5\/8\@xs {
        width: 62.5%
    }

    .u-width-7\/8\@xs {
        width: 87.5%
    }

    .u-width-1\/9\@xs {
        width: 11.111%
    }

    .u-width-2\/9\@xs {
        width: 22.222%
    }

    .u-width-4\/9\@xs {
        width: 44.444%
    }

    .u-width-5\/9\@xs {
        width: 55.556%
    }

    .u-width-7\/9\@xs {
        width: 77.778%
    }

    .u-width-8\/9\@xs {
        width: 88.889%
    }

    .u-width-1\/10\@xs {
        width: 10%
    }

    .u-width-3\/10\@xs {
        width: 30%
    }

    .u-width-7\/10\@xs {
        width: 70%
    }

    .u-width-9\/10\@xs {
        width: 90%
    }

    .u-width-1\/12\@xs {
        width: 8.333%
    }

    .u-width-5\/12\@xs {
        width: 41.666%
    }

    .u-width-7\/12\@xs {
        width: 58.333%
    }

    .u-width-11\/12\@xs {
        width: 91.666%
    }

    .u-w-full\@xs {
        width: 100%
    }

    .u-w-2\/4\@xs,
    .u-w-3\/6\@xs,
    .u-w-4\/8\@xs,
    .u-w-5\/10\@xs,
    .u-w-6\/12\@xs,
    .u-w-half\@xs {
        width: 50%
    }

    .u-w-1\/3\@xs,
    .u-w-2\/6\@xs,
    .u-w-3\/9\@xs,
    .u-w-4\/12\@xs {
        width: 33.333%
    }

    .u-w-2\/3\@xs,
    .u-w-4\/6\@xs,
    .u-w-6\/9\@xs,
    .u-w-8\/12\@xs {
        width: 66.666%
    }

    .u-w-1\/4\@xs,
    .u-w-2\/8\@xs,
    .u-w-3\/12\@xs {
        width: 25%
    }

    .u-w-3\/4\@xs,
    .u-w-6\/8\@xs,
    .u-w-9\/12\@xs {
        width: 75%
    }

    .u-w-1\/5\@xs,
    .u-w-2\/10\@xs {
        width: 20%
    }

    .u-w-2\/5\@xs,
    .u-w-4\/10\@xs {
        width: 40%
    }

    .u-w-3\/5\@xs,
    .u-w-6\/10\@xs {
        width: 60%
    }

    .u-w-4\/5\@xs,
    .u-w-8\/10\@xs {
        width: 80%
    }

    .u-w-1\/6\@xs,
    .u-w-2\/12\@xs {
        width: 16.666%
    }

    .u-w-10\/12\@xs,
    .u-w-5\/6\@xs {
        width: 83.333%
    }

    .u-w-1\/8\@xs {
        width: 12.5%
    }

    .u-w-3\/8\@xs {
        width: 37.5%
    }

    .u-w-5\/8\@xs {
        width: 62.5%
    }

    .u-w-7\/8\@xs {
        width: 87.5%
    }

    .u-w-1\/9\@xs {
        width: 11.111%
    }

    .u-w-2\/9\@xs {
        width: 22.222%
    }

    .u-w-4\/9\@xs {
        width: 44.444%
    }

    .u-w-5\/9\@xs {
        width: 55.556%
    }

    .u-w-7\/9\@xs {
        width: 77.778%
    }

    .u-w-8\/9\@xs {
        width: 88.889%
    }

    .u-w-1\/10\@xs {
        width: 10%
    }

    .u-w-3\/10\@xs {
        width: 30%
    }

    .u-w-7\/10\@xs {
        width: 70%
    }

    .u-w-9\/10\@xs {
        width: 90%
    }

    .u-w-1\/12\@xs {
        width: 8.333%
    }

    .u-w-5\/12\@xs {
        width: 41.666%
    }

    .u-w-7\/12\@xs {
        width: 58.333%
    }

    .u-w-11\/12\@xs {
        width: 91.666%
    }
}

@charset "UTF-8";

@-webkit-keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(227, 5, 19, .6);
        box-shadow: 0 0 0 0 rgba(227, 5, 19, .6)
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px transparent;
        box-shadow: 0 0 0 10px transparent
    }

    to {
        -webkit-box-shadow: 0 0 0 0 transparent;
        box-shadow: 0 0 0 0 transparent
    }
}

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(227, 5, 19, .6);
        box-shadow: 0 0 0 0 rgba(227, 5, 19, .6)
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px transparent;
        box-shadow: 0 0 0 10px transparent
    }

    to {
        -webkit-box-shadow: 0 0 0 0 transparent;
        box-shadow: 0 0 0 0 transparent
    }
}

.o-blocks {
    list-style: none;
    padding: 0;
    margin: 0 -16px;
    position: relative;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media only screen and (max-width: 760px) {
    .o-blocks {
        margin-right: -8px;
        margin-left: -8px
    }
}

.o-blocks__item {
    padding-left: 16px;
    padding-right: 16px;
    position: relative
}

@media only screen and (max-width: 760px) {
    .o-blocks__item {
        padding-right: 8px;
        padding-left: 8px
    }
}

.o-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-left: -16px;
    margin-right: -16px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.o-card__item {
    padding-left: 16px;
    padding-right: 16px;
    position: relative
}

.o-card__box {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #979797;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    min-height: 100%;
    padding: 24px
}

@media only screen and (max-width: 1279px) {
    .o-card__box {
        padding: 16px
    }
}

.o-card__box--left {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.o-card__head {
    text-align: center;
    margin-bottom: 24px
}

.o-crop {
    position: relative;
    display: block;
    overflow: hidden
}

.o-crop__content {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none
}

.o-crop__content--right {
    left: auto;
    right: 0
}

.o-crop__content--bottom {
    bottom: 0;
    top: auto
}

.o-crop__content--center {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.o-crop--1\:1 {
    padding-bottom: 100%
}

.o-crop--2\:1 {
    padding-bottom: 50%
}

.o-crop--4\:3 {
    padding-bottom: 75%
}

.o-crop--16\:9 {
    padding-bottom: 56.25%
}

.o-media,
.o-media__img>img {
    display: block
}

.o-media:after {
    content: "" !important;
    display: block !important;
    clear: both !important
}

.o-media__img {
    float: left;
    margin-right: 8px
}

.o-media__body {
    overflow: hidden;
    display: block
}

.o-media__body,
.o-media__body>:last-child {
    margin-bottom: 0
}

.o-ratio {
    position: relative;
    display: block;
    overflow: hidden
}

.o-ratio:before {
    content: "";
    display: block;
    width: 100%
}

.o-ratio>embed,
.o-ratio>iframe,
.o-ratio>object,
.o-ratio__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.o-ratio--1\:1:before,
.o-ratio:before {
    padding-bottom: 100%
}

.o-ratio--2\:1:before {
    padding-bottom: 50%
}

.o-ratio--4\:3:before {
    padding-bottom: 75%
}

.o-ratio--16\:9:before {
    padding-bottom: 56.25%
}

.c-404 {
    display: inline-block;
    margin-bottom: 224px;
    margin-top: 144px;
    padding: 40px;
    text-align: left
}

.c-404__title {
    font-size: 5.75rem;
    line-height: .8
}

.c-accordion__title {
    cursor: pointer;
    line-height: 1;
    padding-top: 16px;
    position: relative
}

.c-accordion__title:before {
    font-size: 1.75rem;
    background-color: #282827;
    content: "";
    height: 2px;
    line-height: inherit;
    position: absolute;
    right: 0;
    top: 24px;
    width: 18px
}

.c-accordion__title:after {
    background-color: #282827;
    content: "";
    height: 18px;
    position: absolute;
    right: 8px;
    top: 16px;
    -webkit-transition: all .2s;
    transition: all .2s;
    width: 2px
}

.c-accordion__content {
    margin: 0;
    max-height: 1px;
    overflow: hidden;
    -webkit-transition: all .5s;
    transition: all .5s;
    color: #777
}

.c-accordion__content .send-by-email,
.c-accordion__content .send-by-wetransfer {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.c-accordion__item {
    max-height: 56px;
    overflow: hidden;
    padding-bottom: 16px;
    -webkit-transition: all 1s;
    transition: all 1s
}

@media only screen and (max-width: 760px) {
    .c-accordion__item {
        max-height: 102px
    }
}

.c-accordion__item.is-open {
    max-height: 100%;
    overflow: visible
}

.c-accordion__item.is-open .c-accordion__title:after {
    content: "";
    height: 0
}

.c-accordion__item.is-open .c-accordion__content.opened {
    max-height: 100%;
    overflow: visible;
    -webkit-transition: all 1s;
    transition: all 1s
}

.c-accordion__generic {
    margin-bottom: 16px;
    position: relative
}

.c-accordion__generic h4,
.c-accordion__generic h4.c-accordion__generic--packaging {
    cursor: pointer;
    font-weight: 400;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.c-accordion__generic h4 {
    margin-bottom: 0;
    margin-left: 16px;
    margin-right: 16px;
    background-color: #d8d8d8;
    padding: 12px 16px
}

.c-accordion__generic h4.c-accordion__generic--packaging {
    font-family: Arial;
    margin: 0;
    background-color: transparent;
    padding: 12px 16px 12px 0
}

.c-accordion__generic h4:after {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-arrow-right-black.svg);
    margin-right: 8px;
    position: absolute;
    right: 48px;
    top: 12px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: all .3s;
    transition: all .3s
}

.c-accordion__generic h4.c-accordion__generic--packaging:after {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-accordion-expand.svg);
    margin-right: 8px;
    position: absolute;
    right: 0;
    top: 12px;
    -webkit-transition: all .3s;
    transition: all .3s
}

.c-accordion__generic h4.active:after,
.c-basket__toggle-express-production h4.active:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.c-accordion__generic h4.c-accordion__generic--packaging.active:after {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-accordion-close.svg);
    -webkit-transform: rotate(0);
    transform: rotate(0);
    top: 6px
}

.c-accordion__generic .c-accordion__generic-content {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all .5s;
    transition: all .5s
}

.c-accordion__generic .c-accordion__generic-content.is-open {
    max-height: 1000px
}

.c-accordion--inline h3:before {
    display: none
}

.c-accordion--inline h3:after {
    background: 0 0;
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-arrow-down-black.svg);
    left: 106%;
    margin-right: 8px;
    position: absolute;
    top: 16px;
    -webkit-transition: all .3s;
    transition: all .3s
}

@media only screen and (max-width: 479px) {
    .c-accordion--inline h3:after {
        left: 102%
    }
}

@media only screen and (max-width: 375px) {
    .c-accordion--inline h3:after {
        left: 94%
    }
}

@media only screen and (max-width: 479px) {
    .c-accordion--inline .c-accordion__title {
        padding-right: 24px
    }
}

.c-accordion--inline .c-accordion__item.is-open h3:after {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-arrow-down-black.svg);
    left: 110%;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    top: 42px
}

@media only screen and (max-width: 1020px) {
    .c-accordion--inline .c-accordion__item.is-open h3:after {
        content: url(https://www.digitalprintingireland.ie/img/ui/icon-arrow-down-black.svg);
        left: 106%;
        top: 16px;
        -webkit-transform: none;
        transform: none
    }
}

@media only screen and (max-width: 479px) {
    .c-accordion--inline .c-accordion__item.is-open h3:after {
        content: url(https://www.digitalprintingireland.ie/img/ui/icon-arrow-down-black.svg);
        left: 102%
    }
}

@media only screen and (max-width: 375px) {
    .c-accordion--inline .c-accordion__item.is-open h3:after {
        content: url(https://www.digitalprintingireland.ie/img/ui/icon-arrow-down-black.svg);
        left: 94%
    }
}

.c-accordion--convenient .c-accordion__item.is-open h3:after,
.c-accordion--convenient h3:after {
    left: 114%
}

@media only screen and (max-width: 375px) {
    .c-accordion--convenient h3:after {
        left: 94%
    }
}

@media only screen and (max-width: 375px) {
    .c-accordion--convenient .c-accordion__item.is-open h3:after {
        left: 94%
    }
}

.c-banner-2half {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 1px 4px
}

@media only screen and (max-width: 1020px) {
    .c-banner-2half {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 0
    }
}

@media only screen and (max-width: 760px) {
    .c-banner-2half {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .c-banner-2half .c-stars {
        display: none
    }
}

.c-banner-2half .reviews {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #d3f7d8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 2px;
    padding: 16px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%
}

@media only screen and (max-width: 1020px) {
    .c-banner-2half .reviews {
        margin-right: 0
    }
}

@media only screen and (max-width: 760px) {
    .c-banner-2half .reviews {
        padding: 8px
    }
}

.c-banner-2half .reviews svg {
    margin-right: 20px;
    max-width: 200px
}

@media only screen and (max-width: 760px) {
    .c-banner-2half .reviews svg {
        max-width: 120px
    }
}

.c-banner-2half .reviews-svg {
    line-height: 1
}

@media only screen and (max-width: 479px) {
    .c-banner-2half .reviews-svg {
        max-width: 25px;
        overflow: hidden
    }
}

.c-banner-2half .delivery {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #80d0f0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 2px;
    padding: 12px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%
}

@media only screen and (max-width: 1020px) {
    .c-banner-2half .delivery {
        margin-left: 0
    }
}

@media only screen and (max-width: 760px) {
    .c-banner-2half .delivery {
        padding: 8px
    }
}

.c-banner-2half .delivery p {
    font-size: .8125rem;
    line-height: 19px;
    margin-top: 4px
}

@media only screen and (max-width: 760px) {
    .c-banner-2half .delivery p {
        margin-top: 0
    }
}

.c-banner-reviews,
.c-banner-reviews .reviews {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-banner-reviews {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-top: 32px;
    width: 100%
}

@media only screen and (max-width: 1279px) {
    .c-banner-reviews {
        margin-left: 39%
    }
}

@media only screen and (max-width: 1020px) {
    .c-banner-reviews {
        margin-left: 35%
    }
}

@media only screen and (max-width: 760px) {
    .c-banner-reviews {
        margin-left: 0
    }
}

.c-banner-reviews .reviews {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #d3f7d8;
    border-radius: 4px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 2px;
    padding: 16px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%
}

@media only screen and (max-width: 1020px) {
    .c-banner-reviews .reviews {
        margin-right: 0
    }
}

@media only screen and (max-width: 760px) {
    .c-banner-reviews .reviews {
        padding: 8px
    }
}

.c-banner-reviews .reviews svg {
    margin-right: 20px;
    max-width: 140px
}

@media only screen and (max-width: 760px) {
    .c-banner-reviews .reviews svg {
        max-width: 100px
    }
}

.c-banner-reviews .reviews-svg {
    line-height: 1
}

@media only screen and (max-width: 479px) {
    .c-banner-reviews .reviews-svg {
        max-width: 25px;
        overflow: hidden
    }
}

.c-banner-reviews .reviews-rating {
    border-bottom: none !important;
    padding-bottom: 0 !important
}

.c-banner-reviews .c-banner-review__text {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    font-size: 20px;
    text-align: center
}

.c-banner-reviews .c-banner-review__text p {
    padding-bottom: 4px
}

.c-company-award-banner {
    background-color: #f3f3f3;
    padding: 32px 0
}

.c-company-award-banner img {
    width: 100%
}

.c-company-award-banner .c-company-award-banner__1280px {
    display: block
}

.c-company-award-banner .c-company-award-banner__320px,
.c-company-award-banner .c-company-award-banner__640px,
.c-company-award-banner .c-company-award-banner__768px,
.c-company-award-banner .c-company-award-banner__960px {
    display: none
}

@media only screen and (min-width: 960px) and (max-width:1279px) {
    .c-company-award-banner .c-company-award-banner__960px {
        display: block
    }

    .c-company-award-banner .c-company-award-banner__1280px,
    .c-company-award-banner .c-company-award-banner__320px,
    .c-company-award-banner .c-company-award-banner__640px,
    .c-company-award-banner .c-company-award-banner__768px {
        display: none
    }
}

@media only screen and (min-width: 768px) and (max-width:959px) {
    .c-company-award-banner .c-company-award-banner__768px {
        display: block
    }

    .c-company-award-banner .c-company-award-banner__1280px,
    .c-company-award-banner .c-company-award-banner__320px,
    .c-company-award-banner .c-company-award-banner__640px,
    .c-company-award-banner .c-company-award-banner__960px {
        display: none
    }
}

@media only screen and (min-width: 376px) and (max-width:767px) {
    .c-company-award-banner .c-company-award-banner__640px {
        display: block
    }

    .c-company-award-banner .c-company-award-banner__1280px,
    .c-company-award-banner .c-company-award-banner__320px,
    .c-company-award-banner .c-company-award-banner__768px,
    .c-company-award-banner .c-company-award-banner__960px {
        display: none
    }
}

@media only screen and (max-width: 375px) {
    .c-company-award-banner .c-company-award-banner__320px {
        display: block
    }

    .c-company-award-banner .c-company-award-banner__1280px,
    .c-company-award-banner .c-company-award-banner__640px,
    .c-company-award-banner .c-company-award-banner__768px,
    .c-company-award-banner .c-company-award-banner__960px {
        display: none
    }
}

.c-basket label.c-checkbox {
    font-size: .8125rem;
    color: #777;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-basket label.c-checkbox span,
.c-basket__versions span {
    color: #282827
}

.c-basket .c-btn--small {
    min-width: 100px
}

.c-basket h3,
.c-confirmation label {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif
}

.c-basket__content .basket-price {
    font-size: 1.3125rem
}

@media only screen and (max-width: 760px) {
    .c-basket__content .basket-price {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        bottom: 24px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        right: 0
    }
}

@media only screen and (max-width: 479px) {
    .c-basket__content .basket-price {
        position: static
    }
}

.c-basket__table.responsive .c-checkout__basket-qty {
    height: 36px;
    margin-right: 8px;
    max-width: 80px
}

.c-basket__table.responsive .remove {
    font-size: .8125rem;
    color: #e30513
}

.c-basket__item {
    padding-bottom: 16px
}

@media only screen and (max-width: 479px) {
    .c-basket__item {
        padding-bottom: 0
    }
}

.c-basket__ctas {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: right
}

@media only screen and (max-width: 479px) {
    .c-basket__ctas {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-bottom: 16px;
        text-align: left
    }
}

.c-basket__ctas a,
.c-basket__table.responsive .remove {
    font-family: "Arial", sans-serif;
    text-decoration: underline
}

.c-basket__ctas .edit {
    margin-bottom: 4px
}

@media only screen and (max-width: 479px) {
    .c-basket__ctas .edit {
        margin-bottom: 0;
        margin-right: 16px
    }
}

.c-basket__ctas .remove {
    color: #e30513
}

.c-basket__table {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    text-align: left;
    width: 100%
}

@media only screen and (max-width: 760px) {
    .c-basket__table thead {
        display: none
    }
}

.c-basket__table tbody td,
.c-basket__table thead th {
    border-bottom: 1px solid #d8d8d8;
    letter-spacing: -.15px
}

.c-basket__table thead th {
    padding-bottom: 8px
}

@media only screen and (max-width: 760px) {
    .c-basket__table tbody tr {
        border-bottom: 1px solid #d8d8d8;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative
    }

    .c-basket__table tbody tr:first-child {
        border-bottom: none
    }
}

.c-basket__table tbody td {
    padding-top: 16px;
    vertical-align: top
}

@media only screen and (max-width: 760px) {
    .c-basket__table tbody td {
        border: 0
    }
}

.c-basket__table .table-links,
.c-confirmation h2 {
    padding-bottom: 16px
}

@media only screen and (max-width: 760px) {
    .c-basket__table .table-links {
        padding-bottom: 0;
        position: absolute;
        right: 0;
        top: 0
    }
}

@media only screen and (max-width: 479px) {
    .c-basket__table .table-links {
        position: static
    }
}

.c-basket__table .price-title {
    font-size: 1rem;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    letter-spacing: -.15px;
    margin-right: 12px
}

.c-basket__table .mobile-border {
    border-bottom: 1px solid #d8d8d8;
    display: none;
    padding-bottom: 8px
}

@media only screen and (max-width: 760px) {
    .c-basket__table .mobile-border {
        display: block
    }
}

@media only screen and (max-width: 1279px) {
    .c-basket__side {
        margin-top: -64px
    }
}

@media only screen and (max-width: 760px) {
    .c-basket__side {
        margin-top: 16px
    }
}

.c-basket__side .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 4px
}

.c-basket__side .total {
    font-size: 1.3125rem;
    border-top: 1px solid #d8d8d8;
    margin-bottom: 16px;
    margin-top: 12px;
    padding-top: 12px
}

.c-basket__side .grand.money,
.c-basket__table thead th,
.c-confirmation .c-confirmation__note span {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-basket__ctas a,
.c-basket__side .promo input[type=text] {
    font-size: .8125rem
}

@media only screen and (max-width: 479px) {
    .c-basket__side .promo input[type=text] {
        margin-bottom: 8px
    }

    .c-basket__side .promo input[type=submit] {
        width: 100%
    }
}

.c-basket__side .promo label {
    display: block;
    margin-bottom: 2px
}

.c-basket__add-more,
.c-basket__side .promo label,
.c-basket__toggle-express-production h4 {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif
}

.c-basket__add-more {
    background-color: #80d0f0;
    color: #fff;
    letter-spacing: -.15px;
    margin: 16px 0;
    padding: 12px 16px;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

@media only screen and (max-width: 479px) {
    .c-basket__add-more {
        margin-bottom: 0
    }
}

.c-basket__add-more:before {
    content: url(https://www.digitalprintingireland.ie/img/ui/triangle-blue.svg);
    height: 17px;
    position: absolute;
    top: -16px;
    width: 20px
}

.c-basket__offers {
    background-color: #80d0f0;
    color: #fff;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    list-style: none;
    margin: 0;
    padding: 12px;
    text-align: center
}

.c-basket__totals {
    background-color: #f3f3f3;
    padding: 16px 32px
}

@media only screen and (max-width: 479px) {
    .c-basket__totals {
        padding: 16px 24px 24px
    }
}

#errors-basket,
.c-basket__proceed-mobile,
.c-basket__totals .row.u-hidden {
    display: none
}

@media only screen and (max-width: 760px) {
    .c-basket__proceed-mobile {
        background-color: #fff;
        bottom: 0;
        -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .16);
        box-shadow: 0 0 8px rgba(0, 0, 0, .16);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        left: 0;
        padding: 16px;
        position: fixed;
        width: 100%;
        z-index: 10
    }
}

.c-basket__toggle-express-production a {
    color: #e30513;
    text-decoration: underline
}

.c-basket__toggle-express-production h4 {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 400;
    width: 175px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0
}

.c-basket__toggle-express-production h4:after,
.c-products .c-products__downloads h4:after {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-arrow-right-black.svg);
    margin-right: 8px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: all .3s;
    transition: all .3s
}

.c-basket__toggle-express-production .c-basket__toggle-express-production-content {
    font-family: Arial, sans-serif;
    font-size: smaller;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all .7s;
    transition: all .7s
}

.c-basket__toggle-express-production .c-basket__toggle-express-production-content.is-open {
    max-height: unset
}

.c-basket__versions {
    font-size: .8125rem;
    color: #777;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

#innerBlog #main p.vcard,
.c-confirmation-wrap .c-breadcrumbs {
    margin: 0
}

.c-breadcrumbs {
    font-size: .8125rem;
    margin: 12px 0 24px;
    position: relative;
    z-index: 0
}

.c-breadcrumbs ul {
    list-style-type: none;
    margin: 0
}

.c-breadcrumbs li {
    display: inline-block;
    margin-right: 8px
}

.c-breadcrumbs li:last-child a {
    text-decoration: none
}

.c-breadcrumbs a {
    color: #777
}

@media only screen and (max-width: 760px) {
    .c-breadcrumbs li {
        display: none
    }

    .c-breadcrumbs li:nth-last-child(2) {
        display: block
    }

    .c-breadcrumbs li:nth-last-child(2) a {
        text-decoration: underline
    }

    .c-breadcrumbs li:nth-last-child(2) a:before {
        content: "<"
    }
}

.c-confirmation {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 32px 48px;
    text-align: center
}

@media only screen and (max-width: 1020px) {
    .c-confirmation {
        margin: 0 32px
    }
}

@media only screen and (max-width: 760px) {
    .c-confirmation {
        margin: 24px 32px
    }
}

@media only screen and (max-width: 479px) {
    .c-confirmation {
        margin: 0
    }
}

.c-confirmation header,
.c-confirmation section {
    -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    max-width: 1074px;
    width: 100%
}

.c-confirmation header {
    background-color: #fff;
    padding: 32px 24px
}

.c-confirmation h1 {
    padding-bottom: 12px
}

.c-confirmation h2,
.c-confirmation h3 {
    max-width: 600px
}

.c-confirmation section {
    background: #f3f3f3;
    margin-bottom: 64px;
    padding: 32px 16px
}

.c-confirmation form,
.c-confirmation header,
.c-confirmation section,
.c-contact__social ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-confirmation form,
.c-confirmation header,
.c-confirmation section {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.c-confirmation input[type=password] {
    margin-bottom: 8px;
    max-width: 300px
}

.c-confirmation .c-confirmation__note,
.c-confirmation label.c-confirmation__strength {
    color: #777;
    font-family: "Arial", sans-serif;
    margin-bottom: 8px
}

.c-confirmation .c-confirmation__note {
    font-size: .8125rem;
    margin-bottom: 20px
}

.c-confirmation #pwd-str {
    margin-bottom: 4px;
    max-width: 300px;
    width: 100%
}

.c-confirmation #pwd-str li {
    margin-right: 12px;
    width: calc((100%/7) - 10px) !important
}

.c-confirmation #pwd-desc {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-confirmation .c-page-foot__minimal {
    margin-bottom: 0
}

.c-confirmation .c-page-foot__minimal li {
    text-align: left
}

@media only screen and (max-width: 1020px) {
    .c-confirmation .c-page-foot__minimal li {
        margin-bottom: 16px
    }

    .c-confirmation .c-page-foot__minimal li:last-child {
        margin-bottom: 0
    }
}

.c-contact .c-quote__uploader .qq-upload-button {
    font-size: .9375rem;
    background-color: #e30513;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-weight: 700;
    min-width: 162px;
    padding: 7px 32px;
    text-align: center
}

.c-contact #html5-uploader-csv.contact .qq-upload-list li,
.c-contact #html5-uploader.contact .qq-upload-list li {
    background: 0 0;
    border: 0;
    cursor: auto;
    height: auto;
    margin: 0;
    padding: 0
}

.c-contact__details {
    background-color: #f3f3f3;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    letter-spacing: -.15px;
    padding: 24px
}

.c-contact__details h3 {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 12px
}

.c-checkout__login form,
.c-contact__details h4 {
    margin-top: 24px
}

.c-contact__details .item {
    color: #777
}

.c-breadcrumbs a,
.c-contact__details a {
    text-decoration: underline
}

.c-contact__social ul {
    list-style: none;
    margin: 8px 0 0
}

.c-contact__social li {
    margin-right: 8px
}

.c-content__max-width {
    margin-bottom: 72px;
    max-width: 633px
}

.c-landing__content.edit-recommendations ul,
.s-cms .c-how-it-works--small ul,
.v-card {
    margin: 0
}

.c-checkbox__wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-checkbox {
    display: inline-block;
    line-height: 1.2;
    vertical-align: middle
}

.c-checkbox--center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#order-steps #steps .delivery-country.unavailable,
.c-checkbox input[type=checkbox] {
    display: none
}

.c-checkbox input[type=checkbox]:checked+.c-checkbox__trigger {
    background-image: url(/img/ui/checkbox.svg)
}

.c-checkbox__trigger {
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    border-color: #d8d8d8;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    margin-right: 8px;
    padding-right: 0;
    position: relative;
    width: 30px
}

.c-checkbox__label {
    cursor: pointer;
    display: inline-block;
    margin-left: 8px;
    vertical-align: text-bottom
}

.c-checkout,
.c-checkout__login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-checkout {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 852px;
    margin: 0 auto 32px
}

.c-checkout h1 {
    margin-bottom: 32px;
    text-align: center
}

.c-checkout h2,
.c-checkout--seo h1,
.c-checkout-onepage h3 {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-checkout h2,
.c-checkout--seo h1 {
    font-size: 1.3125rem;
    color: #282827;
    letter-spacing: -.2px;
    line-height: 27px;
    margin-bottom: 12px
}

.c-checkout--seo h1 {
    text-align: left
}

.c-checkout p {
    font-size: .8125rem;
    line-height: 19px
}

.c-checkout__login {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%
}

@media only screen and (max-width: 760px) {
    .c-checkout__login {
        margin: 0 16px
    }
}

.c-checkout__login .c-btn--guest {
    margin-top: 12px
}

.c-checkout__box-register {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background: #fff;
    -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 32px;
    z-index: 1
}

@media only screen and (max-width: 760px) {
    .c-checkout__box-register {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
}

@media only screen and (max-width: 479px) {
    .c-checkout__box-register {
        padding: 24px 16px
    }
}

.c-checkout__box-signin {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background: #f3f3f3;
    -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 32px
}

@media only screen and (max-width: 760px) {
    .c-checkout__box-signin {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
}

@media only screen and (max-width: 479px) {
    .c-checkout__box-signin {
        padding: 24px 16px
    }
}

.c-checkout__box-signin label,
.c-page-foot strong,
.c-page-foot__minimal strong {
    color: #282827;
    display: block
}

.c-checkout__box-signin #forgot-form,
.c-checkout__box-signin #login-form {
    height: 0;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: opacity .2s;
    transition: opacity .2s
}

.c-checkout__box-signin #forgot-form.show,
.c-checkout__box-signin #login-form.show {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    opacity: 1
}

#back-to-login,
#forgot {
    margin-top: 4px;
    text-decoration: underline
}

.c-checkout-onepage {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.c-checkout-onepage h3 {
    color: #d8d8d8;
    padding-bottom: 12px
}

.c-checkout-onepage h4 {
    font-size: 1rem;
    color: #282827;
    font-weight: 600;
    letter-spacing: -.15px;
    line-height: 23px;
    margin-bottom: 4px
}

.c-checkout-onepage form label,
.c-checkout-onepage label,
.c-form-narrow#register-form label {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif
}

.c-checkout-onepage label.with-tooltip {
    padding-right: 0
}

.c-checkout-onepage form label {
    letter-spacing: -.1px;
    line-height: 23px
}

.c-checkout-onepage input[type=email],
.c-checkout-onepage input[type=number],
.c-checkout-onepage input[type=password],
.c-checkout-onepage input[type=text],
.c-checkout-onepage select,
.c-checkout-onepage textarea,
.c-profile__details--addressbook li select {
    background-color: #fff
}

.c-checkout-onepage,
.c-checkout-onepage .expiry-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-checkout-onepage .expiry-date select:first-child {
    margin-right: 8px
}

.c-checkout-onepage .expiry-date select:last-child {
    margin-left: 8px
}

.c-checkout-onepage .summary-cols {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 24px
}

.c-checkout-onepage .summary-cols .col1,
.c-checkout-onepage .summary-cols .col2 {
    width: 220px
}

.c-checkout-onepage .highlight {
    font-size: .9375rem;
    line-height: 19px;
    margin-bottom: 8px;
    margin-top: 8px
}

.c-checkout-onepage__wrap {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 912px;
    padding: 0 32px;
    width: 100%
}

@media only screen and (max-width: 1020px) {
    .c-checkout-onepage__wrap {
        padding: 0 24px
    }
}

@media only screen and (max-width: 760px) {
    .c-checkout-onepage__wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media only screen and (max-width: 479px) {
    .c-checkout-onepage__wrap {
        padding: 0
    }
}

@media only screen and (max-width: 1020px) {
    .c-checkout-onepage__wrap .c-form-narrow {
        padding: 24px
    }
}

.c-checkout__step {
    border-bottom: 1px solid #d8d8d8;
    max-height: 50px;
    padding-top: 12px
}

@media only screen and (max-width: 479px) {
    .c-checkout__step .c-btn {
        min-width: 128px
    }
}

.c-checkout__onepage-content {
    margin-bottom: 48px;
    width: 100%;
    z-index: 2
}

@media only screen and (max-width: 760px) {
    .c-checkout__onepage-content {
        margin-bottom: 0;
        max-width: 100%
    }
}

.c-checkout__onepage-content small {
    font-family: "Arial", sans-serif;
    color: #777;
    display: block;
    letter-spacing: -.1px;
    line-height: 19px;
    margin-bottom: 12px
}

.c-checkout__onepage-content .c-checkbox__wrap {
    margin-bottom: 12px
}

.c-checkout__onepage-content li label {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    letter-spacing: -.15px
}

@media only screen and (max-width: 760px) {
    .c-checkout .inner-wrapper-sticky {
        position: static !important;
        width: 100% !important
    }
}

.c-checkout-onepage__btns,
li.c-checkout__sms {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-checkout-onepage__btns {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 24px
}

li.c-checkout__sms {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 12px
}

@media only screen and (max-width: 760px) {
    li.c-checkout__sms {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

li.c-checkout__sms label {
    margin-right: 12px;
    min-width: 120px;
    padding-right: 0
}

.c-checkout-onepage__delivery-options:after,
.c-checkout__delivery-option-search:after,
.c-checkout__delivery-option:after {
    border: 2px solid #d8d8d8;
    border-radius: 10px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1
}

.c-checkout__delivery-option {
    font-family: "AvenirNextRoundedW01-Re", "Arial", sans-serif;
    margin-bottom: 12px;
    padding: 16px;
    position: relative
}

.c-checkout__delivery-option .c-radio__wrap .c-radio__trigger {
    height: 27px;
    width: 27px;
    margin-right: 12px;
    padding-top: 2px;
    padding-left: 4px
}

.c-checkout__delivery-option .c-radio__wrap .c-radio__trigger:before {
    height: 17px;
    margin-left: -8px;
    margin-top: -8px;
    width: 17px
}

.c-checkout__delivery-option .js-name {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-checkout__delivery-option .js-description {
    font-size: .8125rem;
    color: #777;
    font-family: "Arial", sans-serif
}

.c-checkout__delivery-option .js-estimate {
    font-size: 1rem;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif
}

.c-checkout__delivery-option .js-price {
    font-size: 1.3125rem;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

@media only screen and (max-width: 1020px) {
    .c-checkout__delivery-option .js-price {
        text-align: right
    }
}

.c-checkout__delivery-option .js-delivery-at {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    margin-right: 8px
}

.c-checkout__delivery-option .c-checkout__delivery-select {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin-right: 16px
}

@media only screen and (max-width: 1020px) {
    .c-checkout__delivery-option .c-checkout__delivery-select {
        margin-bottom: 8px;
        margin-right: 0
    }
}

.c-checkout__delivery-option .delivery-cost-date,
.c-checkout__payment-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.c-checkout__delivery-option .delivery-cost-date {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 8px;
    font-family: "Arial", sans-serif
}

@media only screen and (max-width: 1020px) {
    .c-checkout__delivery-option .delivery-cost-date {
        -webkit-box-align: normal;
        -ms-flex-align: normal;
        align-items: normal;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.c-checkout__delivery-option .delivery-cost-date label {
    min-width: 88px;
    padding-left: 0;
    padding-top: 0;
    vertical-align: baseline
}

.c-checkout__delivery-option .delivery-cost-date select {
    padding-right: 40px
}

.c-checkout__delivery-option label {
    padding-right: 0 !important
}

.c-checkout__delivery-option-search.selected:after,
.c-checkout__delivery-option.selected:after {
    border: 2px solid #37a003
}

.c-checkout__delivery-option.not-available * {
    cursor: not-allowed;
    color: #d8d8d8 !important
}

.c-checkout__payment-option {
    border: 2px solid #d8d8d8;
    border-radius: 10px;
    margin-bottom: 12px;
    padding: 16px
}

.c-checkout__payment-option label {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    padding-right: 12px
}

@media only screen and (max-width: 479px) {
    .c-checkout__payment-option label {
        margin-bottom: 8px
    }

    .c-checkout__payment-option img {
        max-width: 100px
    }
}

.js-hpp-iframe-container iframe {
    border: 2px solid #e30513;
    border-radius: 12px
}

@media only screen and (max-width: 479px) {
    .js-hpp-iframe-container iframe {
        min-height: 640px
    }
}

.c-checkout__step-content {
    opacity: 0;
    overflow: hidden;
    max-height: 0
}

.c-checkout__step.active {
    max-height: 100%
}

.c-checkout__step.active .c-checkout__step-content {
    opacity: 1;
    overflow: visible;
    max-height: 100%
}

.c-checkout__step.active .c-checkout__step-toggle {
    color: #282827;
    position: relative
}

.c-checkout__step.completed .c-checkout__step-toggle {
    color: #d8d8d8;
    cursor: pointer;
    position: relative
}

.c-checkout__step.completed .c-checkout__step-toggle:after {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-tick-green.svg);
    position: absolute;
    right: 0;
    top: 3px
}

.c-checkout-onepage__aside-outer {
    max-width: 335px;
    width: 100%;
    z-index: 1
}

@media only screen and (max-width: 760px) {
    .c-checkout-onepage__aside-outer {
        margin-bottom: 64px;
        max-width: 100%
    }

    .c-checkout-onepage__aside-outer .inner-wrapper-sticky {
        position: static !important;
        width: 100% !important
    }
}

.c-checkout-onepage__aside {
    background-color: #f3f3f3;
    -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    padding: 32px
}

.c-checkout-onepage__aside .product {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 16px;
    padding-bottom: 16px
}

.c-checkout-onepage__aside .price {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    margin-bottom: 4px
}

.c-checkout-onepage__aside .grand,
.c-checkout-onepage__aside .step2 {
    border-top: 1px solid #d8d8d8;
    margin-top: 16px;
    padding-top: 16px
}

.c-checkout-onepage__aside .grand {
    font-size: 1.3125rem
}

.c-checkout-onepage__aside .grand .price,
.c-page-foot__sticky-wrap.no-content .live-chat #scd9DY {
    margin-bottom: 0
}

.c-checkout-onepage__aside h3,
.c-feature-box h3 {
    color: #282827;
    margin-bottom: 12px
}

.c-checkout-onepage__details>div,
.c-checkout-onepage__item-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.c-checkout-onepage__item-price {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.c-checkout-onepage__details>div {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.c-checkout__convenient-title {
    font-size: 1.3125rem;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -.2px;
    margin-bottom: 4px
}

.c-checkout-onepage .c-checkout__card-type {
    background-image: url(https://www.digitalprintingireland.ie/img/icon-card-base.svg);
    background-repeat: no-repeat;
    background-position: left 5px top 4px;
    background-size: 47px;
    color: #777;
    padding-left: 68px;
    -webkit-columns: 5;
    -moz-columns: 5;
    columns: 5;
    -webkit-column-gap: 12px;
    -moz-column-gap: 12px;
    column-gap: 12px
}

.c-checkout-onepage .c-checkout__card-type.mastercard {
    background-image: url(https://www.digitalprintingireland.ie/img/icon-mastercard.svg)
}

.c-checkout-onepage .c-checkout__card-type.visa {
    background-image: url(https://www.digitalprintingireland.ie/img/icon-visa.svg)
}

.c-checkout-onepage .c-checkout__card-type.amex {
    background-image: url(https://www.digitalprintingireland.ie/img/icon-amex.svg)
}

.c-show-more-image-rotate {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: -webkit-transform .3s linear;
    transition: transform .3s linear;
    transition: transform .3s linear, -webkit-transform .3s linear
}

.u-maxheight-close {
    max-height: 0;
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
    overflow: hidden;
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.u-maxheight-open {
    max-height: 800px !important;
    margin-bottom: 16px
}

.c-checkout__delivery-option-search {
    margin-bottom: 12px;
    padding: 16px;
    position: relative
}

.c-checkout__delivery-option-search .delivery-results {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.c-checkout__delivery-option-search .delivery-results span {
    text-decoration: underline;
    padding-bottom: 2px;
    font-size: 12px;
    color: #777;
    margin-bottom: 8px !important
}

.c-checkout__delivery-option-search .dropdown-arrow {
    position: absolute;
    right: 12px
}

.c-checkout__delivery-option-search .postcode-search-btn {
    display: inline-block !important;
    min-width: 120px !important
}

.c-checkout__delivery-option-search .postcode-search-field {
    display: inline-block !important;
    width: 50% !important
}

.show-more-or-less {
    color: #000;
    font-size: 10pt;
    text-decoration: underline;
    cursor: pointer
}

.c-checkout__convenient-h3 {
    color: #282827 !important;
    padding-bottom: 0 !important;
    padding-top: 0
}

.c-checkout__convenient-h3:after {
    top: 0 !important
}

.c-accordion--inline .c-accordion__item.is-open .c-checkout__convenient-h3:after {
    height: 18px;
    min-width: 10px;
    position: absolute;
    top: 25px
}

.c-checkout__convenient-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.c-checkout__convenient-content input[type=text] {
    margin-right: 12px;
    max-width: 200px
}

.c-checkout__delivery-option-search .c-accordion__item {
    overflow: visible
}

.js-vat {
    margin-bottom: 24px
}

.js-vat input[type=radio] {
    margin-right: 8px
}

.js-vat_number {
    margin-top: 12px
}

.js-delivery-estimate-note {
    text-align: center
}

.js-delivery-estimate-note span {
    display: inline-block
}

.js-delivery-estimate-note .hand {
    background: #00bfff;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 15px;
    padding: 5px
}

.js-delivery-estimate-note .note {
    display: inline-block;
    font-size: smaller;
    position: relative;
    top: -3px
}

.c-cta,
.c-cta__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.c-cta__item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: center
}

.c-delivery,
.c-feature-box article {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.c-delivery {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 250px;
    padding-left: 40px
}

@media only screen and (max-width: 760px) {
    .c-delivery {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0
    }
}

@media only screen and (max-width: 479px) {
    .c-delivery {
        background-position: center
    }
}

.c-delivery .c-delivery__text h2 {
    background-color: #fff;
    color: #e30513;
    font-family: "AvenirNextRoundedW01-Bo1075574", "Arial", san-serif;
    font-size: 56px;
    line-height: 1.188
}

@media only screen and (max-width: 1020px) {
    .c-delivery .c-delivery__text h2 {
        font-size: 38px
    }
}

@media only screen and (max-width: 479px) {
    .c-delivery .c-delivery__text h2 {
        font-size: 32px
    }
}

.c-delivery .c-delivery__text h3 {
    font-weight: 200
}

@media only screen and (max-width: 1020px) {
    .c-delivery .c-delivery__text h3 {
        font-size: 16px
    }
}

@media only screen and (max-width: 760px) {
    .c-delivery .c-delivery__text {
        padding: 24px
    }
}

.c-delivery__img {
    max-height: 250px;
    max-width: 500px
}

@media only screen and (max-width: 760px) {
    .c-delivery__img {
        max-height: 100%;
        max-width: 100%
    }
}

.c-delivery__img img {
    margin-bottom: 0 !important
}

.c-error {
    background-color: #e9e9ea
}

.c-feature-boxes {
    background-color: #f3f3f3
}

@media only screen and (max-width: 760px) {
    .c-feature-boxes {
        padding: 24px 0
    }
}

.c-feature-boxes__wrap {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.c-feature-boxes__wrap .o-grid__item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.c-feature-box {
    background: #fff;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    margin-bottom: 24px;
    width: 100%
}

.c-feature-box h3 {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-feature-box article {
    padding: 24px
}

.c-feature-box p {
    color: #777
}

.c-feature-box sup {
    top: -3px
}

.c-feature-box,
.c-feature-box article,
.c-feature-box.c-feature-box__contact a,
.c-feature-box.c-feature-box__tracking a,
.c-feature-box.dpguarantee a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto
}

.c-feature-box__reviews .home-testimonial {
    margin-bottom: 20px
}

.c-feature-box__reviews p,
.c-mailing-list form input[type=email] {
    color: #282827
}

.c-form ol {
    list-style: none;
    margin: 0
}

.c-form .half {
    margin-right: 1.5%;
    width: 48.5%
}

.c-form .half:last-child {
    margin-left: 1.5%;
    margin-right: 0
}

.c-form label {
    margin-bottom: 4px
}

#pwd-str,
.c-form__flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-form__item {
    margin-bottom: 16px
}

.c-form-narrow {
    background: #fff;
    -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    padding: 32px
}

.c-form-narrow ol {
    list-style: none
}

.c-form-narrow li,
.c-how-it-works img,
.c-landing__content.edit-recommendations li {
    margin-bottom: 8px
}

.c-form-narrow label {
    line-height: 18px;
    padding-right: 55px
}

.c-form-narrow .required {
    position: relative
}

.c-form-narrow .required:before {
    font-size: .625rem;
    color: #a0a0a0;
    content: "Required";
    font-weight: 700;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 6px
}

.c-form-narrow .c-checkbox label,
.c-form-narrow .marketing label {
    padding-right: 0
}

.pwd-field {
    padding-bottom: 16px;
    text-align: center
}

.pwd-field label {
    font-size: .875rem;
    color: #777;
    padding-right: 0
}

#pwd-str {
    margin: 0 0 12px
}

#pwd-str li {
    background-color: #ddd;
    display: inline-block !important;
    height: 4px !important;
    margin-bottom: 0;
    margin-right: 16px;
    width: calc((100%/7) - 13px) !important
}

#pwd-str li:last-child {
    margin-right: 0
}

#pwd-str li.red {
    background-color: #e30513
}

#pwd-str li.light-red {
    background-color: #fb535d
}

#pwd-str li.green {
    background-color: #37a003
}

#pwd-str li.light-green {
    background-color: #5dfa0f
}

.c-form-narrow .marketing.required:before,
.c-how-it-works ul+ul,
.c-page-foot__bottom .no-amex li.card3 {
    display: none
}

#guest-registration-form input[type=password] {
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    -webkit-animation-iteration-count: 3;
    animation-iteration-count: 3;
    -webkit-box-shadow: 0 0 0 #e30513;
    box-shadow: 0 0 0 #e30513;
    cursor: pointer
}

.c-how-it-works {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #282827;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 24px 1px
}

@media only screen and (max-width: 1279px) {
    .c-how-it-works {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.c-how-it-works header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media only screen and (max-width: 1279px) {
    .c-how-it-works header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }
}

@media only screen and (max-width: 760px) {
    .c-how-it-works header {
        width: 100%
    }
}

.c-how-it-works h2 {
    font-family: "AvenirNextRoundedW01-Re", "Arial", sans-serif;
    font-weight: 400;
    letter-spacing: -.4px;
    line-height: 36px;
    margin-right: 32px
}

@media only screen and (max-width: 1279px) {
    .c-how-it-works h2 {
        margin-bottom: 12px;
        margin-right: 0
    }
}

.c-how-it-works ul,
.c-landing__banner #landing_title {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-how-it-works li,
.c-how-it-works ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-how-it-works ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    list-style: none;
    margin: 0
}

@media only screen and (max-width: 479px) {
    .c-how-it-works ul {
        margin-bottom: 10px
    }
}

.c-how-it-works .u-flex-direction-column {
    margin-bottom: 0 !important
}

@media only screen and (max-width: 479px) {
    .c-how-it-works ul+ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.c-how-it-works li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
}

@media only screen and (max-width: 760px) {
    .c-how-it-works li {
        padding: 0 20px
    }
}

.c-how-it-works .spacer {
    margin: 20px 4px
}

@media only screen and (max-width: 760px) {
    .c-how-it-works .spacer {
        display: none
    }
}

.c-how-it-works .c-btn {
    min-width: 300px
}

@media only screen and (max-width: 1279px) {
    .c-how-it-works .c-btn {
        -ms-flex-item-align: center;
        align-self: center;
        margin-bottom: 24px;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }
}

.c-how-it-works--small .c-btn {
    min-width: 100px
}

.c-hr {
    background-color: #bebebe;
    border: 0;
    height: 1px
}

.c-label {
    display: block;
    margin-bottom: 4px
}

.c-label--inline {
    display: inline-block;
    padding-right: 8px
}

.c-landing__banner,
.c-landing__reviews header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-landing__banner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #e30513;
    color: #fff;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 16px
}

.c-landing__banner #landing_title {
    font-size: 3.375rem;
    line-height: 60px;
    letter-spacing: -1.6px
}

.c-landing__banner #landing_subtitle {
    font-size: 2.125rem;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    letter-spacing: -1.2px
}

.c-landing__content.edit-recommendations {
    margin-top: 16px;
    padding: 16px
}

#landing_category_description {
    white-space: pre-wrap
}

.c-landing__reviews header {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media only screen and (max-width: 760px) {
    .c-landing__reviews header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .c-landing__reviews h2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        padding-top: 16px
    }
}

.c-landing__reviews ul {
    list-style: none;
    margin: 24px 0 0
}

.c-landing__reviews li {
    margin-bottom: 24px
}

.c-landing__reviews .details {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.c-list-numbers {
    counter-reset: section;
    list-style: none;
    margin: 0 0 24px
}

.c-list-numbers li {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 16px
}

.c-list-numbers li:before {
    background: #e30513;
    border-radius: 12px;
    color: #fff;
    content: counter(section);
    counter-increment: section;
    font-weight: 700;
    height: 24px;
    margin: 0 9px 0 0;
    padding: 0;
    text-align: center;
    min-width: 24px
}

.c-landing__reviews .details,
.c-list-numbers li,
.c-mailing-list .c-mailing-list-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media only screen and (max-width: 1020px) {
    .c-mailing-list .c-mailing-list-form {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

#how-it-works li,
.c-mailing-list .c-mailing-list__first {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media only screen and (max-width: 1020px) {
    .c-mailing-list .c-mailing-list__first {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-bottom: 12px
    }
}

.c-mailing-list .c-mailing-list__icon {
    margin-right: 24px;
    min-width: 60px
}

@media only screen and (max-width: 1020px) {
    .c-mailing-list .c-mailing-list__icon {
        margin-bottom: 12px
    }
}

.c-mailing-list .c-mailing-list__info h2,
.c-mailing-list input[type=email] {
    margin-bottom: 12px
}

.c-mailing-list .c-mailing-list__error {
    background: #e30513;
    color: #fff;
    display: block;
    max-height: 0;
    overflow: hidden;
    text-align: center;
    -webkit-transition: max-height .3s linear;
    transition: max-height .3s linear;
    width: 100%
}

.c-mailing-list .c-mailing-list__error.c-mailing-list__error--open {
    max-height: 50px !important
}

.c-mailing-list .c-mailing-list__error p {
    color: #fff;
    font-weight: 700;
    padding: 10px;
    font-size: 16px
}

.c-mailing-list form,
.c-mailing-list form input[type=submit],
.c-mailing-list h2,
.c-mailing-list p {
    color: #fff
}

.c-mailing-list .c-btn {
    min-width: unset;
    width: 100%;
    padding: 12px 5px
}

.c-mailing-list .c-mailing-list-fix {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media only screen and (max-width: 760px) {
    .c-mailing-list .c-mailing-list-fix {
        margin-bottom: 8px
    }
}

@media only screen and (max-width: 479px) {
    .c-mailing-list .c-mailing-list-fix {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }
}

.c-mailing-list .c-mailing-list-nowrap,
.c-mailing-list .c-mailing-list-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.c-mailing-list .c-mailing-list-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.c-mailing-list .c-mailing-list-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto
}

.c-mailing-list .c-mailing-list-sumbits {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 8px
}

@media only screen and (max-width: 760px) {
    .c-mailing-list .c-mailing-list-sumbits {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media only screen and (max-width: 479px) {
    .c-mailing-list .c-mailing-list-sumbits {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-mailing-list .c-mailing-list-sumbits label {
    line-height: 23px
}

.c-mailing-list .c-mailing-list-sumbits div.c-checkbox {
    margin-right: 10px;
    max-width: 30px
}

@media only screen and (max-width: 760px) {
    .c-mailing-list .c-mailing-list-sumbits input[type=submit] {
        width: 150px
    }
}

.img-right {
    float: right;
    position: relative;
    top: -40px
}

@media only screen and (max-width: 479px) {
    .img-right {
        float: none;
        margin-left: 0;
        top: 0
    }
}

.artwork-img,
.faq-bleed {
    float: right;
    margin-left: 16px;
    margin-bottom: 16px
}

@media only screen and (max-width: 479px) {

    .artwork-img,
    .faq-bleed {
        float: none;
        margin-left: 0
    }
}

.list-red-tick li {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 8px;
    background-image: url(https://www.digitalprintingireland.ie/img/ui/icon-tick-green.svg);
    background-position: left calc(50% - 4px);
    background-repeat: no-repeat;
    background-size: 18px;
    padding-bottom: 8px;
    padding-left: 28px
}

.c-page-foot ul,
.faq-list,
.list-red-tick {
    margin: 0;
    list-style: none
}

.faq-list li {
    border-bottom: 1px solid #d8d8d8;
    padding: 0;
    margin: 16px 0 0
}

.c-about-count {
    font-size: 1.125rem;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    margin: 0
}

.stars {
    color: #fff;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-right: 6px;
    position: relative
}

.full-stars {
    color: #f47e27;
    left: 0;
    letter-spacing: 2px;
    overflow: hidden;
    position: absolute;
    top: 0;
    white-space: nowrap
}

.empty-stars:before,
.full-stars:before {
    content: "\2605\2605\2605\2605\2605";
    font-size: 1em;
    letter-spacing: 2px
}

.is-wedding-stationery .c-breadcrumbs,
.is-wedding-stationery .c-breadcrumbs a {
    color: #fff;
    z-index: 1
}

.is-wedding-stationery .outer {
    background: url(https://www.digitalprintingireland.ie/img/bg-wedding.png);
    margin-top: -56px;
    padding-top: 56px
}

.is-wedding-stationery .main-title {
    color: #fff
}

.c-how-works-order-btn {
    bottom: 0;
    position: absolute
}

#how-it-works {
    overflow: hidden;
    width: 100%
}

#how-it-works li {
    background: #b0b0b0 url(https://www.digitalprintingireland.ie/img/sprites.png) no-repeat -225px -34px;
    border-radius: 70px;
    color: #fff;
    float: left;
    font: .8em "AvenirNextRoundedW01-De 1075568", arial, helvetica, sans-serif;
    font-weight: 700;
    height: 120px;
    line-height: 1.1;
    margin: 0 10px;
    padding: 70px 16px 8px;
    text-align: center;
    width: 120px
}

#how-it-works li:first-child {
    margin-left: 0
}

#how-it-works li a {
    color: #fff;
    font-weight: 700
}

#how-it-works li.upload {
    background-position: -223px -178px
}

#how-it-works li.submit {
    background-position: -225px -310px
}

#how-it-works li.track {
    background-position: -225px -454px
}

#how-it-works li.relax {
    background-position: -217px -595px
}

#how-it-works li.start {
    background-position: -217px -735px;
    margin-right: 0
}

.stage {
    border-bottom: 1px solid #9fa3a6;
    height: auto;
    margin: 12px;
    overflow: hidden;
    width: 100%
}

.stage .icon {
    background: #e30513 url(https://www.digitalprintingireland.ie/img/sprites.png) no-repeat;
    border-radius: 36px;
    float: left;
    height: 72px;
    margin: 12px 10px 23px;
    width: 72px
}

.stage .icon.stage1 {
    background-position: 21px -939px;
    margin-top: 6px
}

.stage .icon.stage2 {
    background-position: 21px -993px
}

.stage .icon.stage3 {
    background-position: 20px -1049px
}

.stage .icon.stage4 {
    background-position: 23px -1106px
}

.stage p {
    float: left;
    padding-left: 8px;
    padding-top: 12px;
    width: calc(100% - 95px)
}

#bg-size-guide .c-btn {
    bottom: 130px;
    left: 450px;
    padding-left: 16px;
    padding-right: 16px;
    position: absolute
}

@media only screen and (max-width: 1020px) {
    #bg-size-guide .c-btn {
        bottom: auto;
        left: auto;
        right: 20px;
        top: 252px
    }
}

@media only screen and (max-width: 479px) {
    #bg-size-guide .c-btn {
        margin-bottom: 8px;
        margin-left: 8px;
        margin-top: 16px;
        position: static
    }
}

.size-guide-list {
    margin: 0 !important;
    position: absolute;
    text-align: left !important;
    top: 16px
}

.size-guide-list.left {
    left: 350px
}

@media only screen and (max-width: 1279px) {
    .size-guide-list.left {
        left: 330px
    }
}

@media only screen and (max-width: 1020px) {
    .size-guide-list.left {
        margin: 0 32px 16px 16px !important;
        position: static
    }
}

@media only screen and (max-width: 479px) {
    .size-guide-list.left {
        margin-bottom: 0 !important;
        margin-left: 8px !important
    }
}

.size-guide-list.right {
    left: 600px;
    width: 240px !important
}

@media only screen and (max-width: 1279px) {
    .size-guide-list.right {
        left: 540px
    }
}

@media only screen and (max-width: 1020px) {
    .size-guide-list.right {
        position: static
    }
}

@media only screen and (max-width: 479px) {
    .size-guide-list.right {
        margin-left: 8px !important;
        width: 100% !important
    }
}

.spine-thickness {
    margin-left: 16px
}

.spine-thickness span {
    font-size: 1.25rem
}

.round-spinner-bg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f3f3f3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 150px;
    top: 70px;
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
    width: 100px
}

.round-spinner-bg.active {
    max-height: 100%;
    opacity: 1
}

@media only screen and (max-width: 1020px) {
    .round-spinner-bg.active {
        right: 90px
    }
}

@media only screen and (max-width: 760px) {
    .round-spinner-bg.active {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 14
    }
}

.c-page-foot h3,
.round-spinner-bg p,
.spine-thickness span {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.round-spinner-bg p {
    margin-top: 12px
}

.round-spinner {
    border-radius: 50%;
    -webkit-box-shadow: inset 0 0 0 1em;
    box-shadow: inset 0 0 0 1em;
    color: #e30513;
    font-size: 6px;
    height: 10em;
    position: relative;
    text-indent: -99999em;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 10em
}

.round-spinner:after,
.round-spinner:before {
    position: absolute;
    content: "";
    width: 5.2em;
    height: 10.8em;
    background: #f3f3f3;
    border-radius: 10.2em 0 0 10.2em;
    top: -.1em;
    left: -.1em;
    -webkit-transform-origin: 5.2em 5.1em;
    transform-origin: 5.2em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s
}

.round-spinner:after {
    border-radius: 0 10.2em 10.2em 0;
    left: 5.1em;
    -webkit-transform-origin: 0 5.1em;
    transform-origin: 0 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease
}

.s-cms ul.faq-list {
    margin-left: 0
}

.c-modal {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .85);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 1111
}

.c-modal.is-open {
    opacity: 1;
    visibility: visible
}

.c-modal__header {
    background-color: #fff;
    color: #282827;
    font-weight: 500;
    padding-bottom: 16px;
    padding-top: 16px;
    text-align: center
}

.c-modal__inner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative
}

.c-modal__content {
    background: #fff;
    border: 2px solid #e30513;
    margin: 0 auto;
    overflow: hidden;
    padding: 32px 24px 24px;
    position: relative;
    max-width: 680px
}

.c-modal__close {
    background-image: url(https://www.digitalprintingireland.ie/img/ui/modal-close.svg);
    background-position: center;
    background-repeat: no-repeat;
    height: 16px;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    z-index: 801
}

.mfp-figure button.mfp-close {
    background: url(https://www.digitalprintingireland.ie/img/ui/modal-close.png) no-repeat center center;
    background-size: 48px;
    height: 32px;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 56px;
    width: 32px
}

.c-page-foot {
    font-size: .8125rem;
    padding-bottom: 96px;
    padding-top: 32px;
    position: relative
}

@media only screen and (max-width: 1020px) {
    .c-page-foot {
        padding-bottom: 0
    }
}

.c-page-foot h3 {
    color: #282827;
    margin-bottom: 16px;
    position: relative
}

@media only screen and (max-width: 1020px) {
    .c-page-foot h3 {
        cursor: pointer;
        margin-bottom: 8px
    }

    .c-page-foot h3:before {
        background: #000;
        content: "";
        display: inline-block;
        height: 1px;
        line-height: 1px;
        position: absolute;
        right: 20px;
        top: 13px;
        width: 11px
    }

    .c-page-foot h3:after {
        background: #000;
        content: "";
        min-height: 11px;
        position: absolute;
        right: 25px;
        top: 8px;
        -webkit-transition: min-height .2s;
        transition: min-height .2s;
        width: 1px
    }
}

.c-page-foot h3.open:after {
    min-height: 0
}

.c-page-foot p {
    line-height: 19px
}

.c-page-foot .email {
    font-size: 1rem;
    color: #e30513;
    display: block;
    margin-bottom: 16px
}

.c-page-foot .link-blog {
    color: #777;
    display: block;
    margin-bottom: 12px
}

.c-page-foot .link-blog:hover {
    color: #a0a0a0
}

.c-page-foot__left,
.footer-awards .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__left {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-page-foot__left section {
    margin-right: 48px
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__left section {
        border-bottom: 1px solid #d8d8d8;
        margin-right: 0;
        margin-bottom: 8px
    }
}

.c-page-foot__left section.footer-social {
    -ms-flex-preferred-size: 170px;
    flex-basis: 170px;
    margin-right: 0
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__left section.footer-social {
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }
}

.c-page-foot__left section.footer-contact {
    -ms-flex-preferred-size: 190px;
    flex-basis: 190px
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__left section.footer-contact {
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .footer-awards {
        max-width: 100%
    }
}

.footer-awards .wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media only screen and (max-width: 1020px) {
    .footer-awards .wrapper {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

.c-page-foot__links {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 32px;
    padding-bottom: 32px
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__links {
        padding-bottom: 0
    }
}

.c-page-foot__bottom,
.c-page-foot__bottom .footer-links ul,
.c-page-foot__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-page-foot__social li {
    margin-right: 8px
}

.c-page-foot__social img:hover {
    opacity: .9
}

.c-page-foot__bottom {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 24px
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-page-foot__bottom .footer-links {
    color: #777
}

.c-page-foot__bottom .footer-links ul {
    font-family: "Arial", sans-serif;
    line-height: 12px;
    margin-top: 8px
}

@media only screen and (max-width: 479px) {
    .c-page-foot__bottom .footer-links ul {
        display: block
    }
}

.c-page-foot__bottom .footer-links ul li {
    margin-right: 8px;
    padding-right: 8px;
    border: 0
}

@media only screen and (max-width: 479px) {
    .c-page-foot__bottom .footer-links ul li {
        display: inline-block;
        margin-right: 4px;
        padding-right: 4px
    }
}

.c-page-foot__bottom .footer-links ul li:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0
}

.c-page-foot__bottom .footer-cards,
.c-page-foot__bottom .footer-cards ul {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.c-page-foot__bottom .footer-cards {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__bottom .footer-cards {
        margin-top: 24px
    }
}

.c-page-foot__bottom .footer-cards ul {
    margin-right: 8px
}

@media only screen and (max-width: 760px) {
    .c-page-foot__bottom .footer-cards ul {
        -ms-flex-preferred-size: 200px;
        flex-basis: 200px;
        margin-right: 0
    }
}

@media only screen and (max-width: 479px) {
    .c-page-foot__bottom .footer-cards ul {
        -ms-flex-preferred-size: 200px;
        flex-basis: 200px;
        margin-right: 0
    }
}

.c-page-foot__bottom .footer-cards ul li {
    margin-right: 8px
}

.c-page-foot__bottom .footer-cards ul li:last-child {
    margin-right: 0
}

@media only screen and (max-width: 760px) {
    .c-page-foot__bottom .footer-cards ul li {
        margin-bottom: 2px;
        margin-right: 2px
    }

    .c-page-foot__bottom #footer-cards-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%
    }

    .c-page-foot__bottom .logo-erdf {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }
}

@media only screen and (max-width: 479px) {
    .c-page-foot__bottom .logo-erdf {
        -ms-flex-preferred-size: 88px;
        flex-basis: 88px
    }
}

.c-page-foot__bottom span {
    display: block;
    height: 0;
    overflow: hidden;
    width: 0
}

.c-page-foot__sticky {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #282827;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 16px;
    position: fixed;
    width: 100%;
    z-index: 10
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__sticky {
        position: static;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-page-foot__sticky a,
.c-page-foot__sticky p {
    color: #fff;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
}

@media only screen and (max-width: 479px) {
    .c-page-foot__sticky p {
        max-width: 230px
    }
}

.c-page-foot__sticky .c-btn {
    min-width: 300px
}

@media only screen and (max-width: 479px) {
    .c-page-foot__sticky .c-btn {
        min-width: 270px
    }
}

.c-page-foot__sticky-wrap {
    position: relative;
    z-index: 99999
}

.c-page-foot__sticky-wrap.no-content .c-page-foot__sticky {
    padding: 0
}

.c-page-foot__sticky-wrap.no-content .live-chat {
    bottom: 0
}

.c-page-foot__sticky-wrap.no-content .live-chat .psmw_d9DY {
    -webkit-transform: none !important;
    transform: none !important
}

@media only screen and (max-width: 479px) {
    .c-page-foot__sticky-wrap.no-content .live-chat .psmw_d9DY.ps_messenger__wrap {
        right: 0 !important
    }

    .c-page-foot__sticky-wrap.no-content .live-chat .psmw_d9DY,
    .c-page-foot__sticky-wrap.no-content .live-chat .psmw_d9DY.ps_rvm__hidden {
        right: -108px !important
    }
}

.c-page-foot__sticky-wrap .live-chat {
    overflow: hidden
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__sticky-wrap .live-chat {
        bottom: 0;
        right: -108px
    }
}

@media only screen and (max-width: 479px) {
    .c-page-foot__sticky-wrap .live-chat {
        bottom: 0;
        right: 0
    }
}

.c-page-foot__sticky-wrap .live-chat #cid9DY {
    position: absolute;
    z-index: 100
}

.c-page-foot__sticky-wrap .live-chat #sdd9DY {
    display: none
}

@media only screen and (max-width: 479px) {
    .chat_shift .c-page-foot__sticky-wrap.no-content .live-chat {
        bottom: 82px
    }
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__item {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        -webkit-transition: all .3s;
        transition: all .3s
    }
}

.c-page-foot__item .c-page__sidebar ul li {
    border-bottom: none;
    font-family: "Arial", sans-serif;
    padding-bottom: 0
}

@media only screen and (max-width: 1020px) {
    .c-page-foot__item .c-page__sidebar ul li {
        margin-bottom: 16px
    }
}

.c-page-foot__item.open {
    max-height: 100%;
    opacity: 1
}

.c-page-foot__minimal,
.c-page-foot__minimal ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.c-page-foot__minimal {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 32px auto 16px;
    max-width: 852px
}

@media only screen and (max-width: 760px) {
    .c-page-foot__minimal {
        margin: 32px auto 80px
    }
}

.c-page-foot__minimal ul {
    font-size: .8125rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 16px;
    width: 100%
}

.c-page-foot__minimal li {
    -ms-flex-preferred-size: calc(100%/3);
    flex-basis: calc(100%/3);
    position: relative
}

@media only screen and (max-width: 760px) {
    .c-page-foot__minimal li {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-bottom: 16px
    }
}

.c-page-foot__minimal li:before {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-tick-green.svg);
    left: 8px;
    position: absolute;
    top: 5px
}

.c-page-foot__minimal p {
    line-height: 19px;
    padding: 0 42px
}

.c-page-foot__minimal ul,
.c-paging {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-paging--right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.c-paging__link,
.c-paging__null {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #979797;
    color: #979797;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    width: 48px
}

.c-paging__link:not(:last-child),
.c-paging__null:not(:last-child) {
    margin-right: 12px
}

.c-paging__link:hover {
    border-color: #515151;
    color: #515151
}

.c-profile__nav {
    margin-top: 16px
}

.c-profile__nav ul {
    list-style-type: none;
    margin-left: 0
}

.c-profile__nav li {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 4px;
    padding-top: 4px
}

.c-profile__nav a {
    color: #777;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif
}

.c-profile__nav a:hover {
    color: #282827
}

.c-profile__section {
    margin-bottom: 40px
}

.c-profile__heading {
    background-color: #f3f3f3;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 8px 16px
}

.c-profile__heading h4 {
    font-weight: 600
}

.c-profile__content {
    padding: 0 16px
}

@media only screen and (max-width: 760px) {
    .c-profile__content {
        padding: 0
    }
}

.c-profile__content.options>ul {
    list-style: none;
    margin-left: 0
}

#paging-box #paging .next,
#paging-box #paging .page-num,
#paging-box #paging .prev,
.c-profile__content.options .c-profile__details {
    margin-left: 0
}

.c-profile__content.options .c-profile__content {
    padding: 0
}

.c-profile__btns {
    padding: 0 16px
}

.c-profile__btns--address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.c-profile__btns--address:first-child {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.c-profile__details {
    list-style-type: none
}

.c-profile__details li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 4px
}

@media only screen and (max-width: 760px) {
    .c-profile__details li {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-profile__details li label {
    -ms-flex-item-align: center;
    align-self: center;
    font-weight: 700;
    width: 200px
}

@media only screen and (max-width: 760px) {
    .c-profile__details li label {
        -ms-flex-item-align: start;
        align-self: flex-start;
        padding-left: 12px;
        width: auto
    }
}

.c-profile__details li input {
    width: 250px
}

.c-profile__details li input:-moz-read-only {
    border: 1px solid transparent;
    width: calc(100% - 200px)
}

.c-profile__details li input:read-only {
    border: 1px solid transparent;
    width: calc(100% - 200px)
}

.c-profile__details--myprofile li.occupation label {
    -ms-flex-item-align: center;
    align-self: center;
    width: 880px
}

@media only screen and (max-width: 760px) {
    .c-profile__details--myprofile li.occupation label {
        -ms-flex-item-align: start;
        align-self: flex-start;
        width: auto
    }
}

.c-profile__details--myprofile li.occupation select:disabled {
    background-image: none;
    border: 1px solid transparent
}

.c-profile__details--addressbook li,
.c-profile__details--myprofile li.choose-offers {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.c-profile__details--myprofile li.choose-offers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-profile__details--myprofile li.choose-offers p {
    padding-bottom: 16px;
    padding-top: 12px
}

.c-profile__details--myprofile li.choose-offers label {
    width: 100px
}

@media only screen and (max-width: 760px) {
    .c-profile__details--myprofile li.choose-offers label {
        -ms-flex-item-align: start;
        align-self: flex-start;
        width: auto
    }
}

.c-profile__details--addressbook li.default-address input,
.c-profile__details--myprofile li.choose-offers input {
    margin-right: 16px;
    width: auto
}

@media only screen and (max-width: 760px) {
    .c-profile__details--receive {
        padding-left: 12px
    }
}

.c-profile__details--addressbook {
    background-color: #f3f3f3;
    padding: 40px 32px 32px
}

.c-profile__details--addressbook li {
    padding-bottom: 8px
}

.c-profile__details--addressbook li label {
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-weight: 400
}

.c-profile__details--addressbook li input {
    width: 100%
}

.c-profile__details--addressbook li.default-address {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-top: 16px
}

.c-profile__details--addressbook li.default-address label {
    width: auto
}

@media only screen and (max-width: 760px) {
    .c-profile__details--addressbook {
        margin-left: 16px;
        margin-right: 16px
    }
}

.c-profile__orderdetails-wrap {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 16px
}

@media only screen and (max-width: 760px) {
    .c-profile__orderdetails-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-profile__orderdetails {
    width: 100%;
    margin-right: 16px
}

@media only screen and (max-width: 760px) {
    .c-profile__orderdetails {
        margin-bottom: 24px
    }
}

.c-profile__orderdetails-heading {
    background-color: #d8d8d8;
    padding: 6px 16px
}

.c-profile__orderfigures-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.c-profile__order-figures {
    width: 100%
}

.c-profile__order-figures div {
    background-color: #f3f3f3;
    border-bottom: 1px solid #d8d8d8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 8px 16px
}

.c-profile__delivery-figures div:last-child,
.c-profile__discount-figures div:last-child,
.c-profile__order-figures div:last-child {
    border-bottom: none
}

.c-profile__order-figures div select {
    min-width: 220px
}

@media only screen and (max-width: 760px) {
    .c-profile__order-figures div select {
        min-width: initial
    }
}

.c-profile__deliverydetails-wrap {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 16px
}

@media only screen and (max-width: 760px) {
    .c-profile__deliverydetails-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-profile__deliverydetails {
    width: 100%;
    margin-right: 16px
}

@media only screen and (max-width: 760px) {
    .c-profile__deliverydetails {
        margin-bottom: 24px
    }
}

.c-profile__deliveryfigures-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.c-profile__delivery-figures {
    width: 100%
}

.c-profile__delivery-figures div,
.c-profile__discount-figures div {
    border-bottom: 1px solid #f3f3f3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 8px 16px
}

.c-profile__discount-wrap {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 16px
}

@media only screen and (max-width: 760px) {
    .c-profile__discount-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-profile__discount {
    margin-right: 16px
}

@media only screen and (max-width: 760px) {
    .c-profile__discount {
        margin-bottom: 24px;
        width: 100%
    }
}

.c-profile__discount-heading {
    background-color: #d8d8d8;
    padding: 6px 16px
}

.c-profile__figures-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 300px
}

@media only screen and (max-width: 760px) {
    .c-profile__figures-wrap {
        width: 100%
    }
}

.c-profile__discount-figures {
    width: 100%
}

.c-profile__discount-figures div {
    background-color: #f3f3f3;
    border-bottom: 1px solid #d8d8d8
}

.c-profile__discount-figures div p:last-child {
    font-weight: 700;
    width: 70px
}

.c-profile__discount-figures div p.normal {
    font-weight: 400
}

.c-profile__discount-figures .u-bg-green {
    background-color: #37a003 !important
}

.c-profile__spend {
    border: 2px solid #d8d8d8;
    padding: 24px 32px
}

.c-profile__history #search-order form {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 16px
}

.c-profile__history .c-btn--view {
    margin-right: 12px;
    max-width: 80px;
    min-width: 80px
}

.c-profile__history .c-btn--reorder {
    max-width: 100px;
    min-width: 100px
}

.c-profile__history .c-basket__table tbody tr:first-child {
    border-bottom: 1px solid #d8d8d8
}

.c-profile__history .c-basket__table thead th {
    background-color: #d8d8d8;
    padding: 12px 0 12px 16px
}

.c-profile__history .c-basket__table tbody td {
    background-color: #f3f3f3;
    padding: 12px 16px
}

@media only screen and (max-width: 760px) {
    .c-profile__history .c-basket__table tbody td {
        padding: 8px 16px
    }
}

.c-profile__history .order-date,
.c-profile__history .product {
    min-width: 120px
}

.js-StickySideBarProfile--trigger.is-affixed .inner-wrapper-sticky {
    padding: 0 0 0 16px
}

.c-products__list h2 {
    font-size: 1rem;
    background-color: #282827;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 16px;
    padding: 8px;
    text-align: center;
    width: 100%
}

.c-products__list section:last-child {
    margin-bottom: 40px
}

.c-products__list h3 {
    font-size: 1rem;
    color: #777;
    margin-top: 4px;
    text-decoration: underline
}

.c-products__list img[height],
.c-products__list img[width] {
    max-width: 100%;
    width: auto;
    height: auto
}

.c-products .c-accordion h3.c-accordion__title {
    border-top: 1px solid #d8d8d8
}

.c-products .c-accordion h3,
.c-products .c-accordion h3.c-accordion__title {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    line-height: 27px;
    margin-bottom: 12px;
    padding-top: 12px
}

.c-products__subcategory {
    background-color: #f3f3f3;
    border: 2px solid #fff;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    height: 100%;
    min-height: 100%;
    position: relative;
    -webkit-transition: border .2s;
    transition: border .2s
}

.c-products__subcategory:hover {
    border: 2px solid #e30513
}

.c-products__subcategory-link {
    background-color: #e8e8e8
}

.c-products__order-within.delivery {
    margin-bottom: 32px
}

@media only screen and (max-width: 760px) {
    .c-products__order-within.delivery {
        margin-bottom: 0
    }
}

.c-products .c-products__review-overview img,
.c-products__order-within.delivery img,
.c-products__order-within.delivery p {
    margin-bottom: 0
}

.c-products__subcategory-detail {
    color: #777;
    padding: 20px 16px 56px;
    position: relative;
    text-align: center
}

.c-products__subcategory-offer {
    font-size: .8125rem;
    background-color: #e30513;
    border-radius: 17.5px;
    color: #fff;
    left: 0;
    line-height: 1;
    margin: 0 auto;
    max-width: 220px;
    padding: 7px 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: -16px
}

.c-products__subcategory-footer,
.c-products__subcategory-title {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    text-align: center
}

.c-products__subcategory-title {
    font-size: 1.3125rem;
    color: #282827;
    letter-spacing: -.2px;
    line-height: 1.2;
    margin-bottom: 12px
}

.c-products__subcategory-footer {
    bottom: 16px;
    color: #e30513;
    left: 16px;
    letter-spacing: -.15px;
    position: absolute;
    right: 16px;
    text-decoration: underline
}

.c-products__subcategory-footer input[type=button] {
    background: 0 0;
    border: 0;
    text-decoration: underline
}

.c-products .c-products__review-overview {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-products .c-products__review-overview small {
    color: #777;
    margin: 0 8px
}

.c-products .c-products__review-overview span {
    margin-left: 4px
}

.c-products__landing-slider-main .c-products__image-wrap {
    display: none
}

.c-products__landing-slider-main .c-products__image-wrap:first-child {
    display: block
}

.c-products__landing-slider-main.slick-initialized .c-products__image-wrap {
    display: block !important
}

.c-products__landing-slider-main {
    overflow: hidden
}

.c-products__landing-slider-main .slick-prev {
    background-color: #fff;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    height: 37px;
    width: 30px;
    left: 0;
    top: calc(50% - 15px)
}

@media only screen and (max-width: 760px) {
    .c-products__landing-slider-main .slick-prev {
        display: none !important
    }
}

.c-products__landing-slider-main .slick-next {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    height: 37px;
    width: 30px;
    right: 0;
    top: calc(50% - 15px)
}

@media only screen and (max-width: 760px) {
    .c-products__landing-slider-main .slick-next {
        display: none !important
    }
}

.c-products__landing-slider-navigate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    overflow: hidden
}

.c-products__landing-slider-navigate .slick-current {
    opacity: .5
}

.c-products__landing-slider-navigate img {
    cursor: pointer;
    max-width: 134px
}

@media only screen and (max-width: 1020px) {
    .c-products__landing-slider-navigate img {
        max-width: 180px
    }
}

@media only screen and (max-width: 760px) {
    .c-products__landing-slider-navigate img {
        max-width: 240px
    }
}

@media only screen and (max-width: 479px) {
    .c-products__landing-slider-navigate img {
        max-width: 145px
    }
}

.c-products__landing-slider-navigate .slick-next,
.c-products__landing-slider-navigate .slick-prev {
    background-color: #fff;
    border-radius: 18px;
    -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, .32);
    box-shadow: 0 0 16px rgba(0, 0, 0, .32);
    height: 36px;
    width: 36px;
    top: calc(50% - 15px)
}

.c-products__landing-slider-navigate .slick-prev {
    left: 8px
}

.c-products__landing-slider-navigate .slick-next {
    right: 8px
}

.c-products__top-wrap.o-grid--narrow>.o-grid__item {
    padding-right: 2px;
    padding-left: 2px
}

.c-products .c-products__slider-tip {
    background-color: #e30513;
    border-radius: 17px;
    color: #fff;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    left: 32px;
    padding: 6px 20px;
    position: absolute;
    top: 16px;
    z-index: 2
}

@media only screen and (max-width: 479px) {
    .c-products .c-products__slider-tip {
        border-radius: 0;
        position: static
    }
}

.c-products .c-products__recommendations,
.c-products .content-list {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    list-style: none;
    margin: 0 0 24px
}

.c-products .c-products__recommendations li,
.c-products .content-list li {
    margin-bottom: 8px;
    position: relative
}

.c-products .c-products__recommendations li:before,
.c-products .content-list li:before {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-tick-green.svg);
    margin-right: 12px
}

.c-products .c-accordion__content p {
    margin-bottom: 12px
}

.c-products .c-products__templates-formats,
.c-products .c-products__templates-formats li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-products .c-products__templates-formats {
    list-style: none;
    margin: 0 0 16px
}

.c-products .c-products__templates-formats img {
    margin: 0 8px 0 0
}

.c-products .c-products__templates-formats li {
    font-size: .8125rem;
    font-weight: 700;
    margin-right: 16px
}

.c-products .c-products__downloads {
    margin-bottom: 16px
}

.c-products .c-products__downloads h4,
.c-products .c-products__downloads li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.c-products .c-products__downloads h4 {
    background-color: #d8d8d8;
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 0;
    padding: 12px 16px
}

.c-products .c-products__downloads h4.active:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.c-products .c-products__downloads h5 {
    font-family: "Arial", sans-serif;
    font-weight: 700;
    margin: 0
}

.c-products .c-products__downloads .c-products__downloads-content {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all .7s;
    transition: all .7s
}

.c-products .c-products__downloads .c-products__downloads-content.is-open {
    max-height: unset
}

.c-products .c-products__downloads ul,
.mfp-figure figure {
    margin: 0
}

.c-products .c-products__downloads li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 2px;
    padding: 8px 12px 8px 16px
}

.c-products .c-products__downloads li div a {
    padding-left: 8px
}

.c-products .c-products__downloads img {
    margin: 0;
    width: 32px
}

.c-products .c-products__online-tool {
    background-color: #d700ff;
    color: #fff;
    display: block;
    margin-top: 16px;
    padding: 16px;
    text-align: center
}

@media only screen and (max-width: 1020px) {
    .c-products .c-products__intro-details {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

@media only screen and (max-width: 760px) {
    .c-products .c-products__intro-details {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

@media only screen and (max-width: 1020px) {

    .c-products .buy-time,
    .c-products .c-products__intro-details ul {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%
    }

    .c-products .buy-time {
        padding-left: 20px
    }
}

@media only screen and (max-width: 479px) {
    .c-products .buy-time {
        padding-left: 0
    }
}

.c-products__mobile-buy {
    display: none
}

@media only screen and (max-width: 479px) {
    .c-products__mobile-buy {
        background-color: rgba(255, 255, 255, .9);
        bottom: 0;
        display: block;
        left: 0;
        padding: 12px 16px;
        position: fixed;
        right: 0;
        z-index: 9
    }
}

.c-products__image-wrap {
    position: relative
}

.c-products__image-wrap .c-products__zoom {
    bottom: 8px;
    cursor: pointer;
    position: absolute;
    right: 8px;
    z-index: 2
}

.c-products__description {
    color: #777
}

.c-products__description p:first-child {
    font-size: 1.125rem;
    color: #000;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    letter-spacing: -.2px;
    line-height: 27px
}

.c-products__checklist ul {
    list-style-type: none;
    margin-bottom: 10px;
    margin-left: 0
}

.c-products__checklist ul li {
    font-size: 13px;
    padding-bottom: 8px;
    position: relative;
    padding-left: 20px
}

.c-products__checklist ul li:before {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-tick-green-small.svg);
    height: 16px;
    margin-right: 8px;
    width: 16px;
    position: absolute;
    left: 0;
    top: 0
}

.c-products__checklist p {
    color: #777;
    font-size: 13px;
    line-height: 18px
}

.social-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.social-icons .social {
    background: url(//media.digitalprinting.co.uk/img/sprites-vector.svg) 0 -208px no-repeat;
    height: 41px;
    margin: 7px 5px 10px 0;
    text-indent: -999em;
    width: 41px
}

.social-icons .social.facebook {
    background-position: 0 -166px
}

.social-icons .social.twitter {
    background-position: 0 -125px
}

.social-icons .social.linkedin {
    background-position: 0 -83px
}

.social-icons .social.googleplus {
    background-position: 0 0
}

.social-icons .social.pinterest {
    background-position: 0 -41px
}

.social-icons .social a {
    display: block;
    height: 41px
}

.social-icons .social a:hover {
    background: rgba(255, 255, 255, .2)
}

.c-product-container,
.c-search-results__item a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.c-product-checklist {
    background-color: #f3f3f3;
    padding: 20px;
    max-width: 300px
}

@media only screen and (max-width: 1020px) {
    .c-product-checklist {
        margin-bottom: 24px;
        max-width: 100%;
        width: 100%
    }
}

.c-product-checklist--reorder {
    max-width: 100%
}

#main .fold.icon {
    height: 82px;
    background-repeat: no-repeat;
    background-image: url(//media.digitalprinting.co.uk/img/sprite-features.png)
}

#main .fold.icon.feature-39 {
    background-image: url(//media.digitalprinting.co.uk/img/icons/fold/half-fold.svg);
    background-position: top left 36px;
    background-size: 24px;
    height: 80px;
    padding-bottom: 16px
}

@media only screen and (max-width: 375px) {
    #main .fold.icon.feature-39 {
        padding-bottom: 0
    }
}

#main .fold.icon.feature-40 {
    background-image: url(//media.digitalprinting.co.uk/img/icons/fold/roll-fold.svg);
    background-position: top left 36px;
    background-size: 24px;
    height: auto;
    padding-bottom: 16px
}

@media only screen and (max-width: 375px) {
    #main .fold.icon.feature-40 {
        padding-bottom: 0
    }
}

#main .fold.icon.feature-41 {
    background-image: url(//media.digitalprinting.co.uk/img/icons/fold/z-fold.svg);
    background-position: top left 36px;
    background-size: 24px;
    height: auto;
    padding-bottom: 16px
}

@media only screen and (max-width: 375px) {
    #main .fold.icon.feature-41 {
        padding-bottom: 0
    }
}

#main .fold.icon.feature-42 {
    background-image: url(//media.digitalprinting.co.uk/img/icons/fold/gate-fold.svg);
    background-position: top left 36px;
    background-size: 24px;
    height: auto;
    padding-bottom: 16px
}

@media only screen and (max-width: 375px) {
    #main .fold.icon.feature-42 {
        padding-bottom: 0
    }
}

#main .fold.icon.feature-48 {
    background-position: -136px -3771px
}

#main .fold.icon.feature-49 {
    background-position: -131px -3690px
}

#main .fold.icon.feature-50 {
    background-position: -120px -3851px
}

#main .fold.icon p {
    margin-left: 101px;
    padding-top: 10px
}

#main .c-products .button {
    font-size: .9375rem;
    border-bottom: none;
    background-color: #e30513;
    border-color: transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    letter-spacing: -.23px;
    line-height: 14px;
    min-width: 160px;
    font-weight: 700;
    padding: 12px 32px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear
}

.infinite-pop,
.pop {
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-box-shadow: 0 0 0 rgba(227, 5, 19, .6);
    box-shadow: 0 0 0 rgba(227, 5, 19, .6);
    cursor: pointer
}

.pop {
    -webkit-animation-iteration-count: 2;
    animation-iteration-count: 2
}

.c-radio__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 8px
}

#paging-box #paging .page-num.first,
#paging-box #paging .totals,
.c-radio input[type=radio],
.c-radio__wrap.u-hidden,
.c-tabs input[type=radio] {
    display: none
}

.c-radio {
    display: inline-block;
    max-height: 27px;
    vertical-align: middle
}

.c-radio input[type=radio]:checked+.c-radio__trigger {
    border-color: #a0a0a0
}

.c-radio input[type=radio]:checked+.c-radio__trigger:before {
    display: block
}

.c-radio .c-radio__trigger {
    background-color: #fff;
    border-color: #a0a0a0;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    cursor: pointer;
    display: inline-block;
    height: 27px;
    margin-right: 8px;
    padding-right: 0;
    position: relative;
    width: 27px
}

.c-radio .c-radio__trigger:before {
    background-color: #37a003;
    border-radius: 50%;
    content: "";
    display: none;
    height: 17px;
    left: 11px;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute;
    top: 11px;
    width: 17px
}

.c-radio .c-radio__label {
    cursor: pointer;
    display: inline-block;
    margin-left: 8px;
    vertical-align: text-bottom
}

.c-radio .c-radio__label--profile {
    padding: 0 !important;
    margin-left: 0;
    margin-right: 24px
}

.c-radio__disabled label {
    cursor: not-allowed;
    opacity: .25
}

.c-radio__disabled label .c-radio .c-radio__trigger {
    cursor: not-allowed !important
}

.c-review-box {
    background-color: #f3f3f3
}

.c-review-box .c-review-box__title {
    font-size: 1.3125rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #d3f7d8;
    border-bottom: 1px solid #d8d8d8;
    color: #282827;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    letter-spacing: -.2px;
    line-height: 27px;
    padding: 16px 40px
}

@media only screen and (max-width: 1279px) {
    .c-review-box .c-review-box__title {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 16px 32px;
        text-align: left
    }
}

.c-review-box .c-review-box__title p {
    color: #000;
    margin-bottom: 0;
    line-height: 24px
}

.c-review-box .c-review-box__title #reviews-logo {
    max-width: 120px
}

.c-review-box .c-review-box__title .rating,
.reviews-rating {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-review-box .c-review-box__title .rating img {
    margin-bottom: 0;
    margin-left: 12px
}

.c-review-box .c-review-box__title--company {
    background-color: #d3f7d8;
    margin: 0;
    padding: 24px
}

.c-review-box .c-review-box__list {
    list-style: none;
    margin: 0;
    padding: 24px 40px
}

@media only screen and (max-width: 1279px) {
    .c-review-box .c-review-box__list {
        padding: 24px 32px
    }
}

.c-review-box .c-review-box__list ul {
    margin: 0;
    list-style: none
}

.c-review-box .c-review-box__list .c-stars-full {
    color: #ffd500
}

.c-review-box .c-review-box__list .c-stars-empty:before,
.c-review-box .c-review-box__list .c-stars-full:before {
    font-size: 26px
}

@media only screen and (max-width: 479px) {
    .c-review-box .c-review-box__list {
        padding: 24px 16px
    }
}

.c-review-box .c-review-box__list--company {
    margin-left: 0;
    padding: 16px 24px 0
}

.c-review-box .c-review-box__list--company .c-stars-full {
    color: #f5893b
}

.c-review-box .c-review-box__review {
    margin-bottom: 12px;
    padding-bottom: 12px
}

.c-review-box .c-review-box__review:not(:last-child) {
    border-bottom: 1px solid #d8d8d8
}

.c-review-box .c-review-box__head {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.c-review-box .c-review-box__name {
    font-size: .8125rem;
    letter-spacing: -.1px;
    font-weight: 700;
    color: #282827;
    margin-right: 4px
}

.c-review-box .c-review-box__name--company {
    color: #000
}

.c-review-box .c-review-box__when {
    font-size: .8125rem;
    letter-spacing: -.1px;
    color: #777
}

.c-review-box .c-review-box__detail {
    color: #777;
    margin-bottom: 0;
    width: 100%
}

.c-review-box .c-review-box__detail--landing,
.c-reviews__filters label,
.c-search-results__popular li a,
.s-cms em {
    color: #777
}

.c-reviews .reviews-rating {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 16px;
    margin-top: 4px;
    padding-bottom: 16px
}

.c-reviews .reviews-rating .c-stars-empty:before,
.c-reviews .reviews-rating .c-stars-full:before {
    font-size: 2.625rem
}

.c-reviews .reviews-rating span {
    font-size: 1rem;
    color: #777;
    font-family: "AvenirNextRoundedW01-Re", "Arial", sans-serif;
    margin-left: 16px;
    margin-top: 4px
}

.c-reviews__list,
.c-samplepack ol {
    list-style: none;
    margin: 0
}

.c-reviews__list li {
    background-color: #f3f3f3;
    margin-bottom: 16px;
    padding: 24px
}

@media only screen and (max-width: 479px) {
    .c-reviews__list li {
        padding: 16px
    }
}

.c-reviews__list .c-stars-empty:before,
.c-reviews__list .c-stars-full:before {
    font-size: 1.625rem
}

.c-reviews__list time {
    font-size: .8125rem;
    color: #777
}

@media only screen and (max-width: 479px) {
    .c-reviews__list p {
        padding-bottom: 8px
    }
}

.c-reviews .reviews-rating,
.c-reviews__filters,
.c-reviews__filters .sort-by {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-reviews__filters {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 24px;
    max-width: 350px
}

.c-reviews__filters .sort-by {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-right: 32px
}

@media only screen and (max-width: 479px) {
    .c-reviews__filters .sort-by {
        margin-right: 16px
    }
}

.c-reviews__filters .sort-by label {
    min-width: 60px
}

.c-reviews__filters .filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.c-reviews__filters .filter label {
    min-width: 40px
}

.c-reviews__filters select {
    background-position: right 16px top 8px;
    border: 0;
    padding: 0
}

@media only screen and (max-width: 479px) {
    .c-reviews__filters select {
        background-position: right 0 top 8px
    }
}

.c-reviews__filters--products {
    width: 140px
}

.c-reviews__aside {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, .16);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .16);
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 12px 16px 32px;
    text-align: center;
    width: 100%
}

@media only screen and (max-width: 1279px) {
    .c-reviews__aside {
        margin-left: 39%
    }
}

@media only screen and (max-width: 1020px) {
    .c-reviews__aside {
        margin-left: 35%
    }
}

@media only screen and (max-width: 760px) {
    .c-reviews__aside {
        margin-left: 0
    }
}

.c-reviews__aside .stars-yellow {
    margin: 12px 0
}

#paging-box #paging,
#paging-box #paging ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

#paging-box #paging {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 24px 0;
    width: 100%
}

#paging-box #paging ul {
    list-style-type: none
}

#paging-box #paging ul li {
    margin-right: 8px;
    padding: 0;
    border: 0
}

#paging-box #paging li a {
    background-color: #f3f3f3;
    border-radius: 30px;
    color: #777;
    font-size: 16px;
    height: 32px;
    padding: 8px 12px;
    text-align: center;
    width: 32px
}

#paging-box #paging li a.active {
    background-color: #777;
    color: #fff
}

#paging-box #paging .prev li:last-child a {
    background-color: #f3f3f3;
    background-image: url(/img/ui/icon-prev-arrow.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px 16px;
    border-radius: 30px;
    height: 34px;
    margin-top: -4px;
    padding: 8px 17px;
    width: 34px
}

#paging-box #paging .next li a,
#paging-box #paging .prev li a {
    background-color: #f3f3f3
}

#paging-box #paging .prev li:first-child {
    display: none
}

#paging-box #paging .next li {
    margin-right: 0
}

#paging-box #paging .next li:first-child a {
    background-color: #f3f3f3;
    background-image: url(/img/ui/icon-next-arrow.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px 16px;
    border-radius: 30px;
    height: 34px;
    margin-top: -4px;
    padding: 8px 17px;
    width: 34px
}

#paging-box #paging .next li:last-child {
    display: none
}

#paging-box #paging .hidden {
    display: none;
    visibility: hidden
}

.c-review__product {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, .16);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .16);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    letter-spacing: -.15px;
    line-height: 23px;
    margin-bottom: 16px
}

.c-review__product-title {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 24px;
    padding-bottom: 24px
}

.c-review__product-image {
    margin-right: 12px;
    max-width: 120px
}

.c-review__product-name p {
    padding-bottom: 0
}

.rewards #rewards-banner {
    margin-bottom: 24px;
    position: relative
}

.rewards #rewards-banner .rewards-strapline {
    font-size: 1.875rem;
    background-color: rgba(255, 255, 255, .8);
    bottom: 0;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    left: 0;
    line-height: 34px;
    margin: 0 auto;
    max-width: 600px;
    padding: 8px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%
}

@media only screen and (max-width: 1020px) {
    .rewards #rewards-banner .rewards-strapline {
        font-size: 1.625rem;
        line-height: 30px;
        max-width: 100%;
        padding: 8px 0 0;
        position: static;
        text-align: left
    }
}

.rewards #rewards-banner .red-discount {
    background-color: #e30513;
    border-radius: 17px;
    color: #fff;
    padding: 6px 20px;
    position: absolute;
    right: 24px;
    top: 16px;
    z-index: 2
}

@media only screen and (max-width: 1020px) {
    .rewards #rewards-banner .red-discount {
        right: 8px;
        top: 8px
    }
}

.rewards #rewards-banner .red-discount b {
    display: inline-block;
    margin-right: 4px
}

.rewards #rewards-table {
    position: absolute;
    right: -300px;
    top: 250px;
    max-width: 300px
}

@media only screen and (max-width: 1020px) {
    .rewards #rewards-table {
        max-width: 100%;
        position: static
    }
}

.rewards #rewards-table.fixed {
    max-width: unset;
    position: unset;
    width: 100%
}

.rewards #rewards-table thead {
    background: #c3c3c3;
    border: 1px solid #969696;
    color: #000
}

.rewards #rewards-table tbody {
    border: 1px solid #969696
}

.rewards #rewards-table th {
    padding: 13px 0 17px;
    text-align: center
}

body.rewards #rewards-table td {
    padding: 10px 0;
    text-align: center
}

.rewards #rewards-table .hr {
    border: 0;
    border-top: 1px solid #969696;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    height: 0;
    margin: 6px 30px 0
}

.rewards #rewards-table thead .row2 {
    padding-top: 4px
}

.rewards #rewards-table tbody tr {
    border-bottom: 1px solid #969696
}

.rewards #rewards-table tbody td:nth-child(1) {
    width: 33%
}

.emphasise {
    font-style: italic
}

.c-samplepack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media only screen and (max-width: 1020px) {
    .c-samplepack {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-samplepack header {
    border-bottom: 1px solid #d1d1d1;
    padding: 0 16px 24px 48px
}

@media only screen and (max-width: 1020px) {
    .c-samplepack header {
        padding: 0 48px 24px
    }
}

@media only screen and (max-width: 760px) {
    .c-samplepack header {
        padding: 0 24px 24px
    }
}

.c-samplepack h3 {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    padding-bottom: 24px
}

.c-samplepack label {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    letter-spacing: -.15px
}

.c-samplepack select,
.c-samplepack__images img {
    width: 100%
}

.c-samplepack #order-steps {
    max-width: 520px;
    padding: 32px 16px 32px 48px
}

@media only screen and (max-width: 1020px) {
    .c-samplepack #order-steps {
        max-width: 100%;
        padding: 24px 48px
    }
}

@media only screen and (max-width: 760px) {
    .c-samplepack #order-steps {
        max-width: 100%;
        padding: 24px
    }
}

.c-samplepack #order-steps li,
.c-samplepack__images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.c-samplepack #order-steps li {
    margin-bottom: 8px
}

.c-samplepack__images {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

@media only screen and (max-width: 1020px) {
    .c-samplepack__images {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        min-height: 400px
    }
}

@media only screen and (max-width: 760px) {
    .c-samplepack__images {
        min-height: 300px
    }
}

@media only screen and (max-width: 479px) {
    .c-samplepack__images {
        min-height: 200px
    }
}

.c-samplepack__images .image-1 {
    background-image: url(/img/sample-pack-1.jpg)
}

.c-samplepack__images .image-2 {
    background-image: url(/img/sample-pack-2.jpg)
}

.c-samplepack__images h3 {
    background-color: rgba(255, 255, 255, .8);
    display: inline-block;
    margin: 16px;
    padding: 8px 16px
}

@media only screen and (max-width: 479px) {
    .c-samplepack__images h3 {
        margin: 8px;
        line-height: 1;
        padding: 8px
    }
}

.c-samplepack__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50%
}

@media only screen and (max-width: 1020px) {
    .c-samplepack__image {
        height: 400px;
        width: 100%
    }
}

@media only screen and (max-width: 760px) {
    .c-samplepack__image {
        height: 300px
    }
}

@media only screen and (max-width: 479px) {
    .c-samplepack__image {
        height: 200px
    }
}

.c-samplepack__content {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.c-samplepack__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.c-checkout__included {
    border-top: 1px solid #d1d1d1;
    padding: 32px 16px 24px 48px
}

@media only screen and (max-width: 760px) {
    .c-checkout__included {
        max-width: 100%;
        padding: 24px
    }
}

.c-checkout__included ul {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 12px;
    -moz-column-gap: 12px;
    column-gap: 12px;
    list-style: none;
    margin: 0
}

@media only screen and (max-width: 760px) {
    .c-checkout__included ul {
        -webkit-columns: 1;
        -moz-columns: 1;
        columns: 1
    }
}

.c-checkout__included li {
    padding-bottom: 12px;
    padding-left: 40px;
    position: relative
}

.c-checkout__included li:before {
    content: url(https://www.digitalprintingireland.ie/img/ui/icon-tick-green.svg);
    left: 0;
    position: absolute;
    top: 12px
}

.c-checkout__included h5,
.reviews-rating span {
    font-family: "Arial", sans-serif;
    font-weight: 700
}

.c-checkout__included p {
    font-size: .8125rem;
    color: #777;
    line-height: 19px
}

.c-checkout__included--samplepack {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media only screen and (max-width: 760px) {
    .c-checkout__included--samplepack {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-checkout__included--samplepack p {
    min-height: 60px
}

.c-checkout__included--samplepack section {
    max-width: 320px
}

.c-checkout__included--samplepack ul {
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1
}

.c-checkout__included--login li,
.c-checkout__included--samplepack li {
    background-image: url(https://www.digitalprintingireland.ie/img/ui/icon-tick-green.svg);
    background-repeat: no-repeat
}

.c-checkout__included--login li:before,
.c-checkout__included--samplepack li:before {
    content: ""
}

.c-checkout__included--login {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.c-checkout__included--login ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%
}

@media only screen and (max-width: 479px) {
    .c-checkout__included--login ul {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.c-checkout__included--login li {
    width: 50%
}

@media only screen and (max-width: 479px) {
    .c-checkout__included--login li {
        width: 100%
    }
}

#search-window .c-search-results {
    background-color: #f3f3f3;
    border: 3px solid #e30513;
    color: #282827;
    left: 0;
    max-height: calc(100vh - 224px);
    max-width: 1298px;
    overflow-y: auto;
    padding: 40px 22px 16px;
    position: absolute;
    top: 51px;
    width: 100%;
    z-index: 1
}

@media only screen and (max-width: 1279px) {
    #search-window .c-search-results {
        left: -16px;
        width: calc(100% + 32px)
    }
}

@media only screen and (max-width: 1020px) {
    #search-window .c-search-results {
        left: -4px;
        width: calc(100% + 8px)
    }
}

.c-checkout__included--login,
.c-search-results__none,
.c-search-results__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-search-results__wrap {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.c-search-results__none {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%
}

.c-search-results__popular {
    -ms-flex-item-align: center;
    align-self: center;
    border: 2px solid #d8d8d8;
    border-radius: 10px;
    margin-bottom: 80px;
    padding: 32px 24px 24px;
    max-width: 670px;
    text-align: center
}

.c-search-results__popular ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-fill: auto;
    -moz-column-fill: auto;
    column-fill: auto;
    -webkit-column-gap: 32px;
    -moz-column-gap: 32px;
    column-gap: 32px;
    list-style: none
}

.c-search-results__popular li {
    margin: 0 0 12px
}

.c-search-results__item {
    background-color: #fff;
    border: 2px solid #d8d8d8;
    margin: 0 12px 24px;
    width: 225px;
    text-align: center
}

@media only screen and (max-width: 1279px) {
    .c-search-results__item {
        margin: 0 8px 20px;
        border-radius: 0
    }
}

@media only screen and (max-width: 1020px) {
    .c-search-results__item {
        margin: 0 6px 20px;
        border-radius: 15px
    }
}

@media only screen and (max-width: 760px) {
    .c-search-results__item {
        padding: 0;
        text-align: left;
        width: 100%;
        border-radius: 10px
    }
}

@media only screen and (max-width: 479px) {
    .c-search-results__item {
        padding: 16px
    }
}

@media only screen and (max-width: 1020px) {
    .c-search-results__item a {
        border-radius: 10px
    }
}

@media only screen and (max-width: 760px) {
    .c-search-results__item a {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        text-align: left
    }
}

.c-search-results__item:hover {
    border-color: #37a003
}

.c-search-results__item aside {
    margin-bottom: 16px;
    max-height: 140px
}

@media only screen and (max-width: 760px) {
    .c-search-results__item aside {
        margin-bottom: 0;
        margin-right: 16px
    }
}

.c-search-results__item header {
    padding: 16px
}

@media only screen and (max-width: 760px) {
    .c-search-results__item img {
        height: auto;
        max-width: 150px;
        padding: 2px
    }
}

@media only screen and (max-width: 479px) {
    .c-search-results__item img {
        height: auto;
        max-width: 100px
    }
}

.c-search-results__item .short-description {
    font-size: .8125rem;
    color: #777;
    line-height: 16px
}

.c-search-results__item .description {
    font-size: 1rem;
    color: #777;
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif;
    left: -.15px
}

.c-search-results__item .from-price {
    font-size: 1.3125rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #282827;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    letter-spacing: -.2px;
    margin-top: 8px
}

@media only screen and (max-width: 760px) {
    .c-search-results__item .from-price {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media only screen and (max-width: 479px) {
    .c-search-results__item .from-price {
        margin-top: 0
    }
}

.c-search-results__item .from-price span {
    font-size: .8125rem;
    color: #777;
    font-weight: 400;
    margin-right: 4px
}

.c-select,
.c-stars {
    display: inline-block
}

.c-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(/img/ui/select-arrow.svg);
    background-position: center right 12px;
    background-repeat: no-repeat;
    border: 1px solid;
    cursor: pointer;
    padding: 7px 48px 7px 16px
}

.c-select[disabled] {
    background-color: rgba(216, 216, 216, .1)
}

.c-stars {
    color: #bebebe;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-right: 3px;
    position: relative
}

.c-stars-full {
    color: #f5893b;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    white-space: nowrap
}

.c-stars--product .c-stars-full,
.c-stars-full--yellow {
    color: #ffd500
}

.reviews-rating span {
    font-size: .8125rem;
    color: #282827
}

.c-stars-empty:before,
.c-stars-full:before {
    content: "\2605\2605\2605\2605\2605";
    font-size: 24px;
    line-height: 1
}

.c-table-responsive {
    display: block;
    width: 100%;
    min-height: .1%;
    overflow-x: auto
}

.c-table-responsive table {
    white-space: nowrap
}

.c-table td,
.c-table th {
    padding: 8px 0
}

.c-table tr:last-child td,
.c-table tr:last-child th {
    border-bottom: none
}

.c-table--lined td,
.c-table--lined th {
    border-bottom: 1px solid #979797
}

.c-table--break {
    word-break: break-word
}

@media only screen and (max-width: 479px) {

    .c-table--rwd table,
    .c-table--rwd tbody,
    .c-table--rwd td,
    .c-table--rwd th,
    .c-table--rwd tr {
        display: block
    }

    .c-table--rwd thead {
        display: none
    }

    .c-table--rwd tbody tr {
        border-bottom: 1px solid #979797
    }

    .c-table--rwd tbody td {
        position: relative;
        border: 0;
        padding-bottom: 8px;
        padding-top: 8px;
        text-align: left;
        width: 100%
    }

    .c-table--rwd tbody td[data-th] {
        padding-left: 30%
    }

    .c-table--rwd tbody td[data-th]:before {
        left: 6px;
        padding-right: 10px;
        position: absolute;
        top: 6px;
        white-space: nowrap;
        width: 30%;
        content: attr(data-th);
        font-weight: 700
    }
}

.c-tabs,
.c-tabs__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.c-tabs {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    overflow: hidden
}

.c-tabs__label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-right: 1px solid #777;
    border-top: 1px solid #777;
    border-bottom: 1px solid #777;
    cursor: pointer;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 16px 24px;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    z-index: 1
}

@media only screen and (max-width: 1020px) {
    .c-tabs__label {
        display: none
    }
}

.c-tabs__label:first-of-type {
    border-left: 1px solid #777
}

.c-tabs__content {
    display: block;
    max-height: 0;
    opacity: 0;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    overflow: hidden;
    width: 100%;
    -webkit-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out
}

.c-tabs input[type=radio]:checked+.c-tabs__label+.c-tabs__content {
    height: auto;
    max-height: 5000px;
    opacity: 1;
    padding-bottom: 40px;
    padding-top: 40px
}

@media only screen and (max-width: 479px) {
    .c-tabs input[type=radio]:checked+.c-tabs__label+.c-tabs__content {
        padding-bottom: 24px;
        padding-top: 24px
    }
}

.c-tabs__selector {
    display: none
}

@media only screen and (max-width: 1020px) {
    .c-tabs__selector {
        display: block;
        width: 100%
    }
}

.c-testimonial p {
    font-size: 1.3125rem;
    color: #777;
    font-family: "AvenirNextRoundedW01-Re", "Arial", sans-serif
}

.c-testimonial .details {
    color: #282827;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.c-textbox {
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid;
    padding: 7px 16px
}

.c-textbox[disabled] {
    background-color: rgba(216, 216, 216, .1)
}

.c-tips {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: center
}

@media only screen and (max-width: 1020px) {
    .c-tips {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        text-align: left;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }
}

@media only screen and (max-width: 760px) {
    .c-tips {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

@media only screen and (max-width: 1020px) {

    .c-tips .tip1,
    .c-tips .tip2 {
        border-bottom: 1px solid #d8d8d8
    }
}

@media only screen and (max-width: 760px) {
    .c-tips .tip4 {
        border: 0
    }
}

.c-tips__single {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 235px;
    padding: 16px 0
}

@media only screen and (max-width: 1020px) {
    .c-tips__single {
        min-width: calc(50% - 1px);
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }
}

@media only screen and (max-width: 760px) {
    .c-tips__single {
        border-bottom: 1px solid #d8d8d8;
        min-width: 100%
    }
}

.c-tips__single a {
    color: #777;
    letter-spacing: -.15px;
    max-width: 260px;
    padding: 0 2px
}

@media only screen and (max-width: 1020px) {
    .c-tips__single a {
        max-width: 100%;
        padding: 0 30px 0 10px
    }
}

@media only screen and (max-width: 760px) {
    .c-tips__single a {
        max-width: 100%
    }
}

.c-tips__single img {
    margin-right: 8px
}

.c-tips .spacer {
    background-color: #d8d8d8;
    height: 90px;
    width: 1px
}

@media only screen and (max-width: 1020px) {
    .c-tips .spacer {
        height: 40px;
        margin-top: 20px
    }
}

@media only screen and (max-width: 760px) {
    .c-tips .spacer {
        display: none
    }
}

.c-tooltip {
    display: inline-block;
    position: relative
}

.c-tooltip__trigger {
    font-size: .8125rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 600;
    height: 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 4px;
    position: relative;
    width: 16px
}

.c-tooltip__trigger-text {
    display: block;
    pointer-events: none
}

.c-tooltip__base {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #456aa6;
    bottom: 2em;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 80%;
    margin-left: -150px;
    opacity: 0;
    padding: 0 16px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    width: 300px;
    z-index: 100
}

@media only screen and (max-width: 479px) {
    .c-tooltip__base {
        pointer-events: unset
    }
}

.c-tooltip__base--flip:before,
.c-tooltip__base:before {
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    height: 11px;
    left: 45%;
    position: absolute;
    width: 23px
}

.c-tooltip__base:before {
    background-image: url(/img/ui/tooltip-triangle.svg);
    bottom: -10px
}

.c-tooltip__base--flip {
    bottom: unset !important;
    top: 2em
}

.c-tooltip__base--flip:before {
    background-image: url(/img/ui/tooltip-triangle-flip.svg);
    top: -10px
}

.c-tooltip__content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    position: relative;
    height: 0;
    overflow: hidden
}

.c-tooltip-opacityUp {
    opacity: 1 !important;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    height: auto
}

@media only screen and (max-width: 479px) {
    .c-tooltip-opacityUp {
        bottom: 0 !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        left: 0 !important;
        margin: 0;
        position: fixed;
        right: 0 !important;
        top: 0 !important;
        width: 100%;
        z-index: 111;
        background-image: url(/img/ui/icon-close-white.svg);
        background-repeat: no-repeat;
        background-position: top 32px right 32px;
        padding: 16px
    }

    .c-tooltip-opacityUp:after {
        content: "Ok, got it!";
        font-size: .9375rem;
        background-color: #37a003;
        border-color: transparent;
        border-radius: 4px;
        border-style: solid;
        border-width: 2px;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        letter-spacing: -.23px;
        line-height: 14px;
        min-width: 160px;
        font-weight: 700;
        padding: 12px 32px;
        text-align: center;
        -webkit-transition: background-color .2s linear;
        transition: background-color .2s linear;
        position: fixed;
        bottom: 48px
    }

    .c-tooltip-opacityUp:after:hover {
        background-color: #6ac43e
    }
}

.c-tooltip__base.c-tooltip-opacityUp {
    padding: 16px
}

.c-tooltip__content.c-tooltip-opacityUp:after {
    display: none
}

.c-video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0
}

.c-video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.s-cms h1,
.s-cms h2 {
    color: #282827;
    margin-bottom: 24px
}

.s-cms h3,
.s-cms h4,
.s-cms h5 {
    color: #282827;
    margin-bottom: 16px
}

.s-cms h5 {
    margin-bottom: 8px
}

.s-cms p {
    line-height: 1.5
}

.s-cms p>a {
    text-decoration: none;
    border-bottom: 1px solid #282827
}

.s-cms ol,
.s-cms p,
.s-cms ul {
    color: #777;
    margin-bottom: 24px
}

.s-cms ul {
    list-style: disc inside
}

.s-cms ol {
    list-style: decimal outside;
    margin-left: 32px
}

.s-cms img {
    max-width: 100%;
    margin-bottom: 16px
}

.s-cms img.img-right,
.s-cms img[align=right],
.s-cms ul {
    margin-left: 16px
}

.s-cms img[align=left] {
    margin-right: 16px
}

.s-cms .right {
    float: right;
    margin: 12px 0 12px 24px;
    text-align: center;
    width: 320px
}

.slick-list,
.slick-slider {
    position: relative;
    overflow: hidden;
    display: block
}

.slick-slider {
    -ms-touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.slick-list {
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    z-index: 0
}

.slick-track:after,
.slick-track:before {
    content: "";
    display: table
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-initialized .slick-slide,
.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.mfp-align-top .mfp-container:before,
.slick-arrow.slick-hidden {
    display: none
}

.slick-dots {
    bottom: 24px;
    list-style-type: none;
    margin: 0;
    position: absolute;
    right: 24px
}

.slick-dots li {
    display: inline-block;
    margin-left: 8px
}

.slick-dots button {
    background-color: #fff;
    display: block;
    height: 16px;
    text-indent: -9999px;
    width: 16px
}

.slick-dots li.slick-active button {
    background-color: #777
}

.slick-next,
.slick-prev {
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 56px;
    position: absolute;
    text-indent: -9999rem;
    top: 100px;
    width: 24px;
    z-index: 8
}

.slick-prev {
    background-image: url(https://www.digitalprintingireland.ie/img/ui/icon-slick-left.svg);
    left: -14px
}

.slick-next {
    background-image: url(https://www.digitalprintingireland.ie/img/ui/icon-slick-right.svg);
    right: -14px
}

.mfp-bg {
    background: #fff;
    opacity: .75;
    overflow: hidden;
    position: fixed;
    z-index: 1042
}

.mfp-bg,
.mfp-container,
.mfp-wrap {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.mfp-wrap {
    z-index: 1043;
    position: fixed;
    outline: 0 !important;
    -webkit-backface-visibility: hidden
}

.mfp-container {
    text-align: center;
    position: absolute;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.mfp-container:before,
.mfp-content {
    display: inline-block;
    vertical-align: middle
}

.mfp-container:before {
    content: "";
    height: 100%
}

.mfp-content {
    position: relative;
    margin: 0 auto;
    text-align: left;
    z-index: 1045
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
    width: 100%;
    cursor: auto
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -webkit-zoom-out;
    cursor: zoom-out
}

.mfp-zoom {
    cursor: -webkit-zoom-in;
    cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
    cursor: auto
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.mfp-loading.mfp-figure,
.mfp-s-error .mfp-content,
.mfp-s-loading .mfp-arrow,
.mfp-s-ready .mfp-preloader {
    display: none
}

.mfp-hide {
    display: none !important
}

.mfp-preloader {
    color: #ccc;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -.8em;
    left: 8px;
    right: 8px;
    z-index: 1044
}

.mfp-preloader a {
    color: #ccc
}

.mfp-preloader a:hover {
    color: #fff
}

button.mfp-arrow,
button.mfp-close {
    overflow: visible;
    cursor: pointer;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-close-btn-in .mfp-close {
    color: #333
}

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
    color: #fff;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #ccc;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    top: 50%;
    margin: -55px 0 0;
    padding: 0;
    width: 48px;
    height: 110px;
    -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:focus,
.mfp-arrow:hover {
    opacity: 1
}

.mfp-arrow-left {
    background: url(https://www.digitalprintingireland.ie/img/ui/icon-slick-left-white.svg) no-repeat right center;
    left: 0
}

.mfp-arrow-right {
    background: url(https://www.digitalprintingireland.ie/img/ui/icon-slick-right-white.svg) no-repeat left center;
    right: 0
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px
}

.mfp-iframe-holder .mfp-close {
    top: -40px
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #000
}

.mfp-figure:after,
img.mfp-img {
    display: block;
    width: auto;
    height: auto
}

img.mfp-img {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0;
    margin: 0 auto
}

.mfp-figure,
img.mfp-img {
    line-height: 0
}

.mfp-figure:after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    right: 0;
    z-index: -1;
    background: #444
}

.mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px
}

.mfp-image-holder .mfp-content,
img.mfp-img {
    max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer
}

@media screen and (max-width: 800px) and (orientation:landscape),
screen and (max-height:300px) {
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        text-align: center;
        padding: 0
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px
    }
}

.select2-container {
    display: inline-block;
    vertical-align: middle
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.select2-container .select2-choice {
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #d8d8d8;
    color: #444;
    display: block;
    height: auto;
    line-height: 24px;
    overflow: hidden;
    padding: 8px 0 8px 16px;
    position: relative;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap
}

.select2-container.select2-drop-above .select2-choice {
    border-bottom-color: #aaa;
    border-radius: 0 0 4px 4px
}

.select2-container .select2-choice span {
    display: block;
    margin-right: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.select2-container .select2-choice abbr {
    display: block;
    background: url(/img/ui/icon-select.svg) right top no-repeat;
    border: 0;
    cursor: pointer;
    font-size: 1px;
    height: 12px;
    outline: 0;
    position: absolute;
    right: 26px;
    text-decoration: none;
    top: 8px;
    width: 12px
}

.select2-container .select2-choice abbr:hover {
    background-position: right -11px;
    cursor: pointer
}

.select2-drop-mask {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9998;
    background-color: #fff;
    opacity: 0
}

.select2-drop {
    background: #fff;
    border-radius: 0 0 4px 4px;
    border: 1px solid #aaa;
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
    box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
    color: #000;
    margin-top: -1px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 9999
}

.select2-drop.select2-drop-above {
    margin-top: 1px;
    border-top: 1px solid #aaa;
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
    box-shadow: 0 -4px 5px rgba(0, 0, 0, .15)
}

.select2-container .select2-choice div {
    background-clip: padding-box;
    background-color: none;
    border-radius: 0 4px 4px 0;
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px
}

.select2-container .select2-choice div b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(/img/ui/icon-select.svg) no-repeat 0 18px
}

.select2-search {
    display: inline-block;
    margin: 0;
    min-height: 26px;
    padding-left: 4px;
    padding-right: 4px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 10000
}

.select2-search-hidden {
    display: block;
    position: absolute;
    left: -10000px
}

.select2-search input {
    width: 100%;
    height: auto !important;
    min-height: 26px;
    padding: 4px 20px 4px 5px;
    margin: 0;
    outline: 0;
    font-family: sans-serif;
    font-size: 1em;
    border: 1px solid #aaa;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #fff url(/img/ui/icon-select.svg) no-repeat 100% -22px
}

.select2-drop.select2-drop-above .select2-search input {
    margin-top: 4px
}

.select2-search input.select2-active {
    background: #fff url(/img/select2-spinner.gif) no-repeat 100%
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    border: 1px solid #5897fb;
    outline: 0;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    box-shadow: 0 0 5px rgba(0, 0, 0, .3)
}

.select2-dropdown-open .select2-choice {
    border-bottom-color: transparent;
    -webkit-box-shadow: 0 1px 0 #fff inset;
    box-shadow: 0 1px 0 #fff inset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #fff
}

.select2-dropdown-open .select2-choice div {
    background: 0 0;
    border-left: none;
    -webkit-filter: none;
    filter: none
}

.select2-results {
    max-height: 200px;
    padding: 0 0 0 4px;
    margin: 4px 4px 4px 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: transparent
}

.select2-results ul.select2-result-sub {
    margin: 0
}

.select2-results li {
    list-style: none;
    display: list-item;
    background-image: none
}

.select2-results li.select2-result-with-children>.select2-result-label {
    font-weight: 700
}

.select2-results .select2-result-label {
    cursor: pointer;
    margin: 0;
    min-height: 1em;
    padding: 3px 7px 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.select2-results .select2-highlighted {
    background: #3875d7;
    color: #fff
}

.select2-results li em {
    background: #feffde;
    font-style: normal
}

.select2-results .select2-highlighted em {
    background: 0 0
}

.select2-results .select2-highlighted ul {
    background: #fff;
    color: #000
}

.select2-more-results,
.select2-results .select2-disabled,
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    background: #f4f4f4;
    display: list-item
}

.select2-results .select2-disabled.select2-highlighted {
    color: #666;
    background: #f4f4f4;
    display: list-item;
    cursor: default
}

.select2-results .select2-disabled {
    cursor: default
}

.select2-container.select2-container-disabled .select2-choice abbr,
.select2-results .select2-selected {
    display: none
}

.select2-more-results.select2-active {
    background: #f4f4f4 url(/img/select2-spinner.gif) no-repeat 100%
}

.select2-container.select2-container-disabled .select2-choice {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default
}

.select2-container.select2-container-disabled .select2-choice div {
    background-color: #f4f4f4;
    background-image: none;
    border-left: 0
}

.select2-container-multi .select2-choices {
    height: auto !important;
    margin: 0;
    padding: 0;
    position: relative;
    border: 1px solid #aaa;
    cursor: text;
    overflow: hidden;
    background-color: #fff;
    min-height: 26px
}

.select2-locked {
    padding: 3px 5px !important
}

.select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #5897fb;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    outline: 0
}

.select2-container-multi .select2-choices li {
    float: left;
    list-style: none
}

.select2-container-multi .select2-choices .select2-search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap
}

.select2-container-multi .select2-choices .select2-search-field input {
    padding: 5px;
    margin: 1px 0;
    font-family: sans-serif;
    font-size: 100%;
    color: #666;
    outline: 0;
    border: 0;
    background: 0 0 !important
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
    background: #fff url(/img/select2-spinner.gif) no-repeat 100% !important
}

.select2-default {
    color: #999 !important
}

.select2-container-multi .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 18px;
    margin: 3px 0 3px 5px;
    position: relative;
    line-height: 13px;
    color: #333;
    cursor: default;
    border: 1px solid #aaa;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, .05);
    background-clip: padding-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #e4e4e4
}

.select2-container-multi .select2-choices .select2-search-choice span {
    cursor: default
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
    background: #d4d4d4
}

.select2-search-choice-close {
    background: url(/img/ui/icon-select.svg) right top no-repeat;
    display: block;
    font-size: 1px;
    height: 13px;
    outline: 0;
    position: absolute;
    right: 3px;
    top: 4px;
    width: 12px
}

.select2-container-multi .select2-search-choice-close {
    left: 3px
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover,
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
    background-position: right -11px
}

.select2-container-multi.select2-container-disabled .select2-choices {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
    padding: 3px 5px;
    border: 1px solid #ddd;
    background-image: none;
    background-color: #f4f4f4
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
    display: none
}

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
    text-decoration: underline
}

.select2-offscreen {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-resolution:144dpi) {

    .select2-container .select2-choice abbr,
    .select2-container .select2-choice div b,
    .select2-search input,
    .select2-search-choice-close {
        background-image: url(/img/ui/icon-select.svg) !important;
        background-repeat: no-repeat !important;
        background-size: 60px 40px !important
    }

    .select2-search input {
        background-position: 100% -21px !important
    }
}

.swal-overlay {
    background-color: rgba(255, 255, 255, .75)
}

.swal-modal {
    border: 3px solid #e30513;
    max-width: 600px;
    padding: 0 32px 12px;
    width: 100%
}

.swal-footer {
    margin-top: 0;
    text-align: center
}

.swal-button {
    background-color: #37a003;
    color: #fff
}

.swal-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.swal-button--bespoke {
    background-color: #a0a0a0;
    color: #fff
}

.swal-title {
    color: #000;
    font-size: 21px;
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.swal-text {
    color: #777;
    line-height: 23px;
    text-align: center
}

body.confirmation .swal-overlay,
body.paypal .swal-overlay {
    background-color: rgba(0, 0, 0, .5)
}

body.confirmation .swal-modal,
body.paypal .swal-modal {
    border: 0;
    max-width: 500px
}

body.confirmation .swal-text,
body.paypal .swal-text {
    text-align: left
}

body.confirmation .swal-button--confirm,
body.confirmation .swal-button-container,
body.paypal .swal-button--confirm,
body.paypal .swal-button-container {
    width: 100%
}

.admin_sidepanel_openbtn {
    position: fixed;
    bottom: 1%;
    z-index: 999999998;
    font-size: 20px;
    cursor: pointer;
    background-color: #282827;
    color: #fff;
    padding: 10px 15px;
    border: 0
}

.admin_sidepanel_openbtn:hover {
    background-color: #444
}

.admin_sidepanel {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999999999;
    bottom: 1%;
    left: 0;
    background-color: rgba(40, 40, 39, .9);
    overflow-x: hidden;
    padding-top: 60px;
    -webkit-transition: .5s;
    transition: .5s
}

.admin_sidepanel .admin_sidepanel_closebtn {
    position: absolute;
    top: .1em;
    right: .25em;
    padding: .5em;
    font-size: 1.5em;
    display: block;
    -webkit-transition: .3s;
    transition: .3s;
    color: #d8d8d8
}

.admin_sidepanel .admin_sidepanel_closebtn:hover {
    color: #fff
}

.admin_sidepanel_content {
    padding: 0 .5em .5em 1em;
    color: #fff
}

.u-a-left,
.u-align-left {
    text-align: left
}

.u-a-right,
.u-align-right {
    text-align: right
}

.u-a-center,
.u-align-center {
    text-align: center
}

.u-a-middle,
.u-align-middle {
    -ms-flex-item-align: center;
    align-self: center
}

.u-a-top,
.u-align-top {
    -ms-flex-item-align: start;
    align-self: flex-start
}

.u-align-bottom .u-a-bottom {
    -ms-flex-item-align: end;
    align-self: flex-end
}

.u-a-baseline,
.u-align-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline
}

.u-a-full,
.u-align-full {
    -ms-flex-item-align: stretch;
    align-self: stretch
}

.u-align-initial {
    vertical-align: initial
}

@media only screen and (max-width: 1279px) {

    .u-a-left\@xl,
    .u-align-left\@xl {
        text-align: left
    }

    .u-a-right\@xl,
    .u-align-right\@xl {
        text-align: right
    }

    .u-a-center\@xl,
    .u-align-center\@xl {
        text-align: center
    }

    .u-a-middle\@xl,
    .u-align-middle\@xl {
        -ms-flex-item-align: center;
        align-self: center
    }

    .u-a-top\@xl,
    .u-align-top\@xl {
        -ms-flex-item-align: start;
        align-self: flex-start
    }

    .u-a-bottom\@xl,
    .u-align-bottom\@xl {
        -ms-flex-item-align: end;
        align-self: flex-end
    }

    .u-a-baseline\@xl,
    .u-align-baseline\@xl {
        -ms-flex-item-align: baseline;
        align-self: baseline
    }

    .u-a-full\@xl,
    .u-align-full\@xl {
        -ms-flex-item-align: stretch;
        align-self: stretch
    }

    .u-a-initial\@xl,
    .u-align-initial\@xl {
        vertical-align: initial
    }
}

@media only screen and (max-width: 1020px) {

    .u-a-left\@l,
    .u-align-left\@l {
        text-align: left
    }

    .u-a-right\@l,
    .u-align-right\@l {
        text-align: right
    }

    .u-a-center\@l,
    .u-align-center\@l {
        text-align: center
    }

    .u-a-middle\@l,
    .u-align-middle\@l {
        -ms-flex-item-align: center;
        align-self: center
    }

    .u-a-top\@l,
    .u-align-top\@l {
        -ms-flex-item-align: start;
        align-self: flex-start
    }

    .u-a-bottom\@l,
    .u-align-bottom\@l {
        -ms-flex-item-align: end;
        align-self: flex-end
    }

    .u-a-baseline\@l,
    .u-align-baseline\@l {
        -ms-flex-item-align: baseline;
        align-self: baseline
    }

    .u-a-full\@l,
    .u-align-full\@l {
        -ms-flex-item-align: stretch;
        align-self: stretch
    }

    .u-a-initial\@l,
    .u-align-initial\@l {
        vertical-align: initial
    }
}

@media only screen and (max-width: 760px) {

    .u-a-left\@m,
    .u-align-left\@m {
        text-align: left
    }

    .u-a-right\@m,
    .u-align-right\@m {
        text-align: right
    }

    .u-a-center\@m,
    .u-align-center\@m {
        text-align: center
    }

    .u-a-middle\@m,
    .u-align-middle\@m {
        -ms-flex-item-align: center;
        align-self: center
    }

    .u-a-top\@m,
    .u-align-top\@m {
        -ms-flex-item-align: start;
        align-self: flex-start
    }

    .u-a-bottom\@m,
    .u-align-bottom\@m {
        -ms-flex-item-align: end;
        align-self: flex-end
    }

    .u-a-baseline\@m,
    .u-align-baseline\@m {
        -ms-flex-item-align: baseline;
        align-self: baseline
    }

    .u-a-full\@m,
    .u-align-full\@m {
        -ms-flex-item-align: stretch;
        align-self: stretch
    }

    .u-a-initial\@m,
    .u-align-initial\@m {
        vertical-align: initial
    }
}

@media only screen and (max-width: 479px) {

    .u-a-left\@s,
    .u-align-left\@s {
        text-align: left
    }

    .u-a-right\@s,
    .u-align-right\@s {
        text-align: right
    }

    .u-a-center\@s,
    .u-align-center\@s {
        text-align: center
    }

    .u-a-middle\@s,
    .u-align-middle\@s {
        -ms-flex-item-align: center;
        align-self: center
    }

    .u-a-top\@s,
    .u-align-top\@s {
        -ms-flex-item-align: start;
        align-self: flex-start
    }

    .u-a-bottom\@s,
    .u-align-bottom\@s {
        -ms-flex-item-align: end;
        align-self: flex-end
    }

    .u-a-baseline\@s,
    .u-align-baseline\@s {
        -ms-flex-item-align: baseline;
        align-self: baseline
    }

    .u-a-full\@s,
    .u-align-full\@s {
        -ms-flex-item-align: stretch;
        align-self: stretch
    }

    .u-a-initial\@s,
    .u-align-initial\@s {
        vertical-align: initial
    }
}

@media only screen and (max-width: 375px) {

    .u-a-left\@xs,
    .u-align-left\@xs {
        text-align: left
    }

    .u-a-right\@xs,
    .u-align-right\@xs {
        text-align: right
    }

    .u-a-center\@xs,
    .u-align-center\@xs {
        text-align: center
    }

    .u-a-middle\@xs,
    .u-align-middle\@xs {
        -ms-flex-item-align: center;
        align-self: center
    }

    .u-a-top\@xs,
    .u-align-top\@xs {
        -ms-flex-item-align: start;
        align-self: flex-start
    }

    .u-a-bottom\@xs,
    .u-align-bottom\@xs {
        -ms-flex-item-align: end;
        align-self: flex-end
    }

    .u-a-baseline\@xs,
    .u-align-baseline\@xs {
        -ms-flex-item-align: baseline;
        align-self: baseline
    }

    .u-a-full\@xs,
    .u-align-full\@xs {
        -ms-flex-item-align: stretch;
        align-self: stretch
    }

    .u-a-initial\@xs,
    .u-align-initial\@xs {
        vertical-align: initial
    }
}

.u-bg-black {
    background-color: #000
}

.u-bg-white {
    background-color: #fff
}

.u-bg-red {
    background-color: #e30513
}

.u-bg-red2 {
    background-color: #f4b5b9
}

.u-bg-blue {
    background-color: #456aa6
}

.u-bg-blue2 {
    background-color: #80d0f0
}

.u-bg-green {
    background-color: #37a003
}

.u-bg-green2 {
    background-color: #c1e8ae
}

.u-bg-yellow {
    background-color: #ffd500
}

.u-bg-mint {
    background-color: #d3f7d8
}

.u-bg-grass {
    background-color: #4d6012
}

.u-bg-gold {
    background-color: #ffd500
}

.u-bg-gold2 {
    background-color: #f6f0cd
}

.u-bg-grey {
    background-color: #777
}

.u-bg-grey2 {
    background-color: #a0a0a0
}

.u-bg-grey-dark {
    background-color: #282827
}

.u-bg-grey-dark2 {
    background-color: #51514f
}

.u-bg-grey-light {
    background-color: #d8d8d8
}

.u-bg-grey-light2 {
    background-color: #f3f3f3
}

.u-bg-text {
    background-color: #282827
}

.u-bg-divider {
    background-color: #979797
}

.u-bg-transparent {
    background-color: transparent
}

.u-border {
    border: 1px solid #979797
}

.u-border-b {
    border-bottom: 1px solid #979797
}

.u-border-l {
    border-left: 1px solid #979797
}

.u-border-r {
    border-right: 1px solid #979797
}

.u-border-t {
    border-top: 1px solid #979797
}

.u-border-x {
    border-left: 1px solid #979797;
    border-right: 1px solid #979797
}

.u-border-y {
    border-bottom: 1px solid #979797;
    border-top: 1px solid #979797
}

.u-border-0 {
    border: 0 !important
}

.u-border-b-0 {
    border-bottom: 0 !important
}

.u-border-l-0 {
    border-left: 0 !important
}

.u-border-r-0 {
    border-right: 0 !important
}

.u-border-t-0 {
    border-top: 0 !important
}

.u-center {
    margin-right: auto;
    margin-left: auto
}

.u-middle {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.u-cf:after,
.u-clearfix:after {
    content: "" !important;
    display: block !important;
    clear: both !important
}

.u-c-black,
.u-color-black {
    color: #000
}

.u-c-white,
.u-color-white {
    color: #fff
}

.u-c-red,
.u-color-red {
    color: #e30513
}

.u-c-red2,
.u-color-red2 {
    color: #f4b5b9
}

.u-c-blue,
.u-color-blue {
    color: #456aa6
}

.u-c-blue2,
.u-color-blue2 {
    color: #80d0f0
}

.u-c-green,
.u-color-green {
    color: #37a003
}

.u-c-green2,
.u-color-green2 {
    color: #c1e8ae
}

.u-c-yellow,
.u-color-yellow {
    color: #ffd500
}

.u-c-mint,
.u-color-mint {
    color: #d3f7d8
}

.u-c-grass,
.u-color-grass {
    color: #4d6012
}

.u-c-gold,
.u-color-gold {
    color: #ffd500
}

.u-c-gold2,
.u-color-gold2 {
    color: #f6f0cd
}

.u-c-grey,
.u-color-grey {
    color: #777
}

.u-c-grey2,
.u-color-grey2 {
    color: #a0a0a0
}

.u-c-grey-dark,
.u-color-grey-dark {
    color: #282827
}

.u-c-grey-dark2,
.u-color-grey-dark2 {
    color: #51514f
}

.u-c-grey-light,
.u-color-grey-light {
    color: #d8d8d8
}

.u-c-grey-light2,
.u-color-grey-light2 {
    color: #f3f3f3
}

.u-c-text,
.u-color-text {
    color: #282827
}

.u-c-divider,
.u-color-divider {
    color: #979797
}

.u-cursor-pointer {
    cursor: pointer
}

.u-cursor-nope {
    cursor: not-allowed
}

.u-f-left,
.u-float-left {
    float: left
}

.u-f-right,
.u-float-right {
    float: right
}

.u-f-none,
.u-float-none {
    float: none
}

@media only screen and (max-width: 1279px) {

    .u-f-left\@xl,
    .u-float-left\@xl {
        float: left
    }

    .u-f-right\@xl,
    .u-float-right\@xl {
        float: right
    }

    .u-f-none\@xl,
    .u-float-none\@xl {
        float: none
    }
}

@media only screen and (max-width: 1020px) {

    .u-f-left\@l,
    .u-float-left\@l {
        float: left
    }

    .u-f-right\@l,
    .u-float-right\@l {
        float: right
    }

    .u-f-none\@l,
    .u-float-none\@l {
        float: none
    }
}

@media only screen and (max-width: 760px) {

    .u-f-left\@m,
    .u-float-left\@m {
        float: left
    }

    .u-f-right\@m,
    .u-float-right\@m {
        float: right
    }

    .u-f-none\@m,
    .u-float-none\@m {
        float: none
    }
}

@media only screen and (max-width: 479px) {

    .u-f-left\@s,
    .u-float-left\@s {
        float: left
    }

    .u-f-right\@s,
    .u-float-right\@s {
        float: right
    }

    .u-f-none\@s,
    .u-float-none\@s {
        float: none
    }
}

@media only screen and (max-width: 375px) {

    .u-f-left\@xs,
    .u-float-left\@xs {
        float: left
    }

    .u-f-right\@xs,
    .u-float-right\@xs {
        float: right
    }

    .u-f-none\@xs,
    .u-float-none\@xs {
        float: none
    }
}

.u-font-heading {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif
}

.u-font-base {
    font-family: "Arial", sans-serif
}

.u-text-meta {
    font-size: .75rem
}

.u-text-cart {
    font-size: .8125rem
}

.u-text-small {
    font-size: .875rem
}

.u-text-normal {
    font-size: 1rem
}

.u-text-20 {
    font-size: 1.25rem
}

.u-text-21 {
    font-size: 1.3125rem
}

.u-text-intro {
    font-size: 1.375rem
}

.u-text-28 {
    font-size: 1.75rem
}

.u-text-medium {
    font-size: 2rem
}

.u-text-large {
    font-size: 3rem
}

.u-text-huge {
    font-size: 6rem
}

@media only screen and (max-width: 1279px) {

    .u-text-h1,
    .u-text-h2 {
        font-size: 2rem;
        line-height: 2.375rem
    }

    .u-text-h3 {
        font-size: 1.3125rem;
        line-height: 1.6875rem
    }

    .u-text-h4 {
        font-size: 1rem;
        line-height: 1.4375rem
    }

    .u-text-h5 {
        font-size: .8125rem;
        line-height: 1.1875rem
    }
}

@media only screen and (max-width: 1020px) {

    .u-text-h1,
    .u-text-h2 {
        font-size: 2rem;
        line-height: 2.375rem
    }

    .u-text-h3 {
        font-size: 1.3125rem;
        line-height: 1.6875rem
    }

    .u-text-h4 {
        font-size: 1rem;
        line-height: 1.4375rem
    }

    .u-text-h5 {
        font-size: .8125rem;
        line-height: 1.1875rem
    }
}

@media only screen and (max-width: 760px) {

    .u-text-h1,
    .u-text-h2 {
        font-size: 2rem;
        line-height: 2.375rem
    }

    .u-text-h3 {
        font-size: 1.3125rem;
        line-height: 1.6875rem
    }

    .u-text-h4 {
        font-size: 1rem;
        line-height: 1.4375rem
    }

    .u-text-h5 {
        font-size: .8125rem;
        line-height: 1.1875rem
    }
}

@media only screen and (max-width: 479px) {

    .u-text-h1,
    .u-text-h2 {
        font-size: 2rem;
        line-height: 2.375rem
    }

    .u-text-h3 {
        font-size: 1.3125rem;
        line-height: 1.6875rem
    }

    .u-text-h4 {
        font-size: 1rem;
        line-height: 1.4375rem
    }

    .u-text-h5 {
        font-size: .8125rem;
        line-height: 1.1875rem
    }
}

@media only screen and (max-width: 375px) {

    .u-text-h1,
    .u-text-h2 {
        font-size: 2rem;
        line-height: 2.375rem
    }

    .u-text-h3 {
        font-size: 1.3125rem;
        line-height: 1.6875rem
    }

    .u-text-h4 {
        font-size: 1rem;
        line-height: 1.4375rem
    }

    .u-text-h5 {
        font-size: .8125rem;
        line-height: 1.1875rem
    }
}

@media only screen and (max-width: 1279px) {
    .u-text-meta\@xl {
        font-size: .75rem
    }

    .u-text-small\@xl {
        font-size: .875rem
    }

    .u-text-normal\@xl {
        font-size: 1rem
    }

    .u-text-intro\@xl {
        font-size: 1.375rem
    }

    .u-text-large\@xl {
        font-size: 3rem
    }
}

@media only screen and (max-width: 1020px) {
    .u-text-meta\@l {
        font-size: .75rem
    }

    .u-text-small\@l {
        font-size: .875rem
    }

    .u-text-normal\@l {
        font-size: 1rem
    }

    .u-text-intro\@l {
        font-size: 1.375rem
    }

    .u-text-large\@l {
        font-size: 3rem
    }
}

@media only screen and (max-width: 760px) {
    .u-text-meta\@m {
        font-size: .75rem
    }

    .u-text-small\@m {
        font-size: .875rem
    }

    .u-text-normal\@m {
        font-size: 1rem
    }

    .u-text-intro\@m {
        font-size: 1.375rem
    }

    .u-text-large\@m {
        font-size: 3rem
    }
}

@media only screen and (max-width: 479px) {
    .u-text-meta\@s {
        font-size: .75rem
    }

    .u-text-small\@s {
        font-size: .875rem
    }

    .u-text-normal\@s {
        font-size: 1rem
    }

    .u-text-intro\@s {
        font-size: 1.375rem
    }

    .u-text-large\@s {
        font-size: 3rem
    }
}

@media only screen and (max-width: 375px) {
    .u-text-meta\@xs {
        font-size: .75rem
    }

    .u-text-small\@xs {
        font-size: .875rem
    }

    .u-text-normal\@xs {
        font-size: 1rem
    }

    .u-text-intro\@xs {
        font-size: 1.375rem
    }

    .u-text-large\@xs {
        font-size: 3rem
    }
}

.u-italic {
    font-style: italic
}

.u-normal {
    font-style: normal
}

.u-light {
    font-weight: 300
}

.u-normal {
    font-weight: 400
}

.u-medium {
    font-weight: 500
}

.faq-page h2,
.u-semibold {
    font-weight: 600
}

.faq-page h1,
.u-bold {
    font-weight: 700
}

.u-extrabold {
    font-weight: 900
}

.u-heading-medium {
    font-family: "AvenirNextRoundedW01-Md1075562", "Arial", sans-serif
}

.u-heading-demi {
    font-family: "AvenirNextRoundedW01-De1075568", "Arial", sans-serif
}

.u-heading-bold {
    font-family: "AvenirNextRoundedW01-Bo1075574", "Arial", sans-serif
}

.u-grad-grey {
    background: linear-gradient(135deg, #51514f 0, #282827 100%)
}

.u-line-1 {
    line-height: 1 !important
}

.u-line-1\/2 {
    line-height: 1.2 !important
}

.u-line-1\/3 {
    line-height: 1.3 !important
}

.u-line-1\/4 {
    line-height: 1.4 !important
}

.u-line-1\/5 {
    line-height: 1.5 !important
}

.u-line-1\/6 {
    line-height: 1.6 !important
}

.u-line-1\/7 {
    line-height: 1.7 !important
}

.u-line-1\/8 {
    line-height: 1.8 !important
}

.u-line-1\/9 {
    line-height: 1.9 !important
}

.u-line-2 {
    line-height: 2 !important
}

.u-list-unstyled {
    list-style: none !important
}

.u-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.u-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

.u-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
}

.u-order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4
}

.u-order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5
}

.u-order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6
}

.u-order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7
}

.u-order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8
}

.u-order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9
}

.u-order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10
}

.u-order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11
}

.u-order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12
}

@media only screen and (max-width: 1279px) {
    .u-order-1\@xl {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .u-order-2\@xl {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .u-order-3\@xl {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .u-order-4\@xl {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .u-order-5\@xl {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .u-order-6\@xl {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .u-order-7\@xl {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .u-order-8\@xl {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .u-order-9\@xl {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .u-order-10\@xl {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .u-order-11\@xl {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .u-order-12\@xl {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }
}

@media only screen and (max-width: 1020px) {
    .u-order-1\@l {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .u-order-2\@l {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .u-order-3\@l {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .u-order-4\@l {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .u-order-5\@l {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .u-order-6\@l {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .u-order-7\@l {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .u-order-8\@l {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .u-order-9\@l {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .u-order-10\@l {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .u-order-11\@l {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .u-order-12\@l {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }
}

@media only screen and (max-width: 760px) {
    .u-order-1\@m {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .u-order-2\@m {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .u-order-3\@m {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .u-order-4\@m {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .u-order-5\@m {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .u-order-6\@m {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .u-order-7\@m {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .u-order-8\@m {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .u-order-9\@m {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .u-order-10\@m {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .u-order-11\@m {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .u-order-12\@m {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }
}

@media only screen and (max-width: 479px) {
    .u-order-1\@s {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .u-order-2\@s {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .u-order-3\@s {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .u-order-4\@s {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .u-order-5\@s {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .u-order-6\@s {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .u-order-7\@s {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .u-order-8\@s {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .u-order-9\@s {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .u-order-10\@s {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .u-order-11\@s {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .u-order-12\@s {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }
}

@media only screen and (max-width: 375px) {
    .u-order-1\@xs {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    .u-order-2\@xs {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    .u-order-3\@xs {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3
    }

    .u-order-4\@xs {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4
    }

    .u-order-5\@xs {
        -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5
    }

    .u-order-6\@xs {
        -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6
    }

    .u-order-7\@xs {
        -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7
    }

    .u-order-8\@xs {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8
    }

    .u-order-9\@xs {
        -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9
    }

    .u-order-10\@xs {
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10
    }

    .u-order-11\@xs {
        -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11
    }

    .u-order-12\@xs {
        -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12
    }
}

.u-overflow-hidden {
    overflow: hidden
}

.u-relative {
    position: relative
}

.u-line-through {
    text-decoration: line-through
}

.u-text-underline {
    text-decoration: underline
}

.u-capitalize {
    text-transform: capitalize
}

.u-uppercase {
    text-transform: uppercase
}

.u-lowercase {
    text-transform: lowercase
}

.u-underline {
    border-bottom: 1px solid #979797;
    padding-bottom: 4px
}

.u-error-asterix {
    font-size: .875rem;
    color: #e30513
}

#innerBlog>#content>#inner-content>#main>article>section.entry-content.clearfix>ul>li {
    list-style-type: disc;
    padding-left: 2em
}

.faq-page h1 {
    font-size: 1.5rem;
    margin-bottom: 1em
}

.faq-page h2 {
    font-size: 1.35rem
}

.faq-page h3.c-accordion__title {
    margin-bottom: 0;
    padding-right: 32px;
    word-break: break-word;
    padding-top: 8px
}

@media only screen and (max-width: 760px) {
    .faq-page h3.c-accordion__title {
        padding-top: 0
    }
}

.faq-page .c-accordion__item {
    padding: 0;
    max-height: unset
}

.faq-page div.faq-question-container {
    line-height: initial
}

.faq-page div.faq-answer {
    padding-top: 1.2em
}

@media print {

    *,
    :after,
    :before {
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important
    }

    a,
    a:visited {
        text-decoration: underline
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    blockquote,
    pre {
        border: 1px solid #979797
    }

    thead {
        display: table-header-group
    }

    blockquote,
    img,
    pre,
    tr {
        page-break-inside: avoid
    }

    img {
        max-width: 100% !important
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    .c-form,
    .c-navbar,
    .c-page-foot,
    fieldset {
        display: none
    }
}
</style>